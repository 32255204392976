import { setNotifications } from '../actions/notification'
import { NotificationItem } from './../../types/data'
import { createReducer } from '@reduxjs/toolkit'

interface NotificationsReducer {
    items: NotificationItem[]
}

const initialState: NotificationsReducer = {
    items: [],
}

const notificationReducer = createReducer<NotificationsReducer>(initialState, (builder) => {
    builder.addCase(setNotifications, (state, action) => {
        state.items = action.payload
    })
})

export default notificationReducer
