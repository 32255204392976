import React, { ReactNode } from 'react'
import './tables.sass'

const TableHeader: React.FC<{ children?: ReactNode; positionIndex?: number; className?: string }> = ({
    children,
    positionIndex,
    className,
}) => {
    const bgImageClass = positionIndex ? { backgroundPositionY: `${Math.abs(positionIndex) * 10}px` } : null

    return (
        <div className={`table-header ${className || ''}`} style={bgImageClass || { backgroundImage: 'none' }}>
            {bgImageClass && <div className="table-header__blur-box" />}
            <div className="table-header__content-box">{children}</div>
        </div>
    )
}

export default TableHeader
