import React, { useEffect, useState } from 'react'
import Button from '../../../components/Buttons/Button'
import { useAppSelector } from '../../../store/reducers/store'
import { getRawIcooneStartMatrix, importIcooneStartMatrix } from '../../../resources/api-constants'
import ImportFileModal from '../../../components/Modal/ImportFileModal'
import Paragraph from '../../../components/Typography/Paragraph'
import { ToastError, ToastSuccess } from '../../../utility/toast'
import { AxiosError } from 'axios'

const IcooneStartPage: React.FC = () => {
    const user = useAppSelector((data) => data.user)
    const [resetFlag, setResetFlag] = useState(false)
    const [showImportModal, setShowImportModal] = useState(false)
    const [downloadIsLoading, setDownloadIsLoading] = useState(false)
    const [importIsLoading, setImportIsLoading] = useState(false)

    useEffect(() => {
        if (resetFlag) setResetFlag(!resetFlag)
    }, [resetFlag])

    const getStartingModalFile = async () => {
        try {
            setDownloadIsLoading(true)
            const rawFileData = await getRawIcooneStartMatrix(user.loggedUserData?.authToken || '')

            const link = document.createElement('a')
            link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${rawFileData}`
            link.setAttribute(
                'download',
                `Matrice_icoone_start_${new Date().toLocaleDateString().replaceAll('/', '_')}.xlsx`
            )
            document.body.appendChild(link)
            link.click()
        } catch (error) {
            console.error(error)
        }
        setDownloadIsLoading(false)
    }

    const startImportProcedure = async (file: any) => {
        try {
            setImportIsLoading(true)
            const res = await importIcooneStartMatrix(user.loggedUserData?.authToken || '', file).then(
                (response) => response
            )
            if (res) setShowImportModal(false)
            ToastSuccess('Matrice importata con successo')
        } catch (error) {
            console.error(error)
            ToastError((error as any).response?.data.message || (error as AxiosError).message)
        }
        setImportIsLoading(false)
    }

    return (
        <>
            {showImportModal && (
                <ImportFileModal
                    modalTitle="Importa matrice"
                    modalMessage="Seleziona il file con estensione .xlsx da importare."
                    acceptedFileType=".xlsx"
                    loading={importIsLoading}
                    onFileImport={(file: any) => void startImportProcedure(file)}
                    onClose={() => setShowImportModal(false)}
                />
            )}
            <div className="page-header-section">
                <div className="page-header-section__left-box">
                    <span className="page-title">Matrice iCOONE start</span>
                </div>
            </div>
            <div className="page-content-block">
                <div className="elevated-card full-width">
                    <div className="text-paragraphs-container">
                        <Paragraph>
                            Gestisci l&apos;aggiornamento massivo dei protocolli ed il suggerimento di tali elementi
                            durante il processo di iCOONE start relativo ad un nuovo trattamento.
                        </Paragraph>
                        <Paragraph>
                            Ricordati di mantenere lo stesso formato della matrice appena scaricata e di modificare il
                            foglio di calcolo aggiungendo solamente le X relativamente ai valori desiderati.
                        </Paragraph>
                        <span>
                            Per cominciare: assicurati di aver creato i protocolli necessari nell&apos;apposita sezione;
                            una volta creati torna su questa pagina e clicca su &quot;Scarica matrice attiva&quot;.
                            Aggiorna la matrice e importala nel sistema cliccando su &quot;Importa matrice&quot;.
                        </span>
                    </div>
                    <div className="items-row">
                        <Button
                            buttonType="primary"
                            loading={downloadIsLoading}
                            onClick={() => void getStartingModalFile()}
                        >
                            Scarica matrice attiva
                        </Button>
                        <Button buttonType="secondary" onClick={() => setShowImportModal(true)}>
                            Importa matrice
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default IcooneStartPage
