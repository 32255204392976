import { createReducer } from '@reduxjs/toolkit'
import { logout, setLoggedUserData, setUserTablePageSize } from '../actions/user'
import { LoggedUser } from '../../types/user'

interface UserReducer {
    loggedUserData: LoggedUser | null
    userTablePageSize: string
}

const initialState: UserReducer = {
    loggedUserData: null,
    userTablePageSize: '10',
}

const userReducer = createReducer<UserReducer>(initialState, (builder) => {
    builder.addCase(setLoggedUserData, (state, action) => {
        state.loggedUserData = action.payload
    })
    builder.addCase(setUserTablePageSize, (state, action) => {
        state.userTablePageSize = action.payload
    })
    builder.addCase(logout, (state) => {
        (state.loggedUserData = initialState.loggedUserData),
        (state.userTablePageSize = initialState.userTablePageSize)
    })
})

export default userReducer
