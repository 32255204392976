import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../resources/routes-constants'
import { getElementIdFromCurrentRoute } from '../../utility/functions'
import NewResultPage from './Tabs/NewResultPage'
import ResultInfoPage from './Tabs/ResultInfoPage'
import { EntityStatus, ResultsEvaluation } from '../../types/data'
import StatusLabel from '../../components/Badges/StatusLabel'

const ResultsDetailPage: React.FC = () => {
    const currentCenterId = getElementIdFromCurrentRoute(window.location.pathname)
    const [status, setStatus] = useState<EntityStatus>('' as EntityStatus)
    const [evaluation, setEvaluation] = useState<ResultsEvaluation>('' as ResultsEvaluation)

    const renderCurrentPage = useMemo(() => {
        switch (currentCenterId) {
            case '-1':
                return <NewResultPage />
            default:
                return (
                    <ResultInfoPage
                        idResult={currentCenterId}
                        emitResultData={(status, evaluation) => {
                            setStatus(status)
                            if (evaluation) setEvaluation(evaluation as ResultsEvaluation)
                        }}
                    />
                )
        }
    }, [currentCenterId, window.location.pathname])

    return (
        <>
            <div className="page-header-section">
                <div className="page-header-section__left-box">
                    <span className="page-title" style={{ display: 'flex', gap: 16 }}>
                        <Link to={ROUTES.RESULTS_ROUTE}>Elenco risultati</Link> /{' '}
                        {currentCenterId === '-1' ? 'Nuovo risultato' : `RIS-${currentCenterId}` || ''}
                        {currentCenterId !== '-1' && (
                            <div style={{ display: 'flex', gap: 8, fontSize: '.6em' }}>
                                <StatusLabel status={status} />
                                {evaluation && <StatusLabel status={status} label={evaluation} />}
                            </div>
                        )}
                    </span>
                </div>
            </div>
            {renderCurrentPage}
        </>
    )
}

export default ResultsDetailPage
