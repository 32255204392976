import { useEffect, useState } from 'react'

interface KeyboardEventObject {
    key: string
    keyCode?: number
}

export const useKeyDetect = (): KeyboardEventObject | undefined => {
    const [event, setEvent] = useState<KeyboardEventObject | undefined>(undefined)
    const keyDownHandler = (event: KeyboardEvent) => {
        if (event) {
            if (event.key === 'Enter') {
                event.preventDefault()
            }
            setEvent({ key: event.key, keyCode: event.keyCode })
        }
    }
    useEffect(() => {
        document.addEventListener('keydown', keyDownHandler)
        return () => {
            document.removeEventListener('keydown', keyDownHandler)
        }
    }, [])

    return event
}
