import React, { ReactNode } from 'react'
import './Link.sass'
import { useNavigate } from 'react-router-dom'

interface Props {
    internalRoute?: string
    externalRoute?: string
    underlined?: boolean
    children?: string | ReactNode
}

const Link: React.FC<Props> = (props) => {
    const navigate = useNavigate()

    if (props.internalRoute) {
        return (
            <span
                className="reset-appearance custom-link-element"
                onClick={() => navigate(props.internalRoute || '')}
                style={{
                    textDecoration: props.underlined !== undefined && props.underlined === false ? 'none' : 'underline',
                }}
            >
                {props.children}
            </span>
        )
    }
    return (
        <a className="reset-appearance custom-link-element" href={props.externalRoute} target="_blank" rel="noreferrer">
            {props.children}
        </a>
    )
}

export default Link
