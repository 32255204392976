import React, { useEffect, useMemo, useState } from 'react'
import Button from '../../components/Buttons/Button'
import TextInput from '../../components/Inputs/TextInput'
import { ROUTES } from '../../resources/routes-constants'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../store/reducers/store'
import { ToastError, ToastSuccess } from '../../utility/toast'
import { formatItemsForSelectOptions, getElementIdFromCurrentRoute, isValidUrl } from '../../utility/functions'
import AlertModal from '../../components/Modal/AlertModal'
import Link from '../../components/Link/Link'
import {
    addMediaFile,
    createSingleIcooneMedia,
    deleteMediaFile,
    deleteSingleIcooneMedia,
    getSingleIcooneMedia,
    updateSingleIcooneMedia,
} from '../../resources/api-constants'
import '../../styles/Pages/detailPages.sass'
import {
    AcceptedMedia,
    EditableIcooneMediaItem,
    IcooneMediaItem,
    MediaFilesEntity,
    MediaShareGrant,
    MediaTarget,
    MediaVisibility,
} from '../../types/data'
import MultiSelect from '../../components/Inputs/MultiSelect'
import SingleSelect from '../../components/Inputs/SingleSelect'
import {
    IcooneMaterialScope,
    IcooneMediaShareType,
    TargetEntity,
    icooneMediaPlaceholderImage,
} from '../../resources/other-constants'
import DevicesAssociation from '../../components/DevicesAssociation/DevicesAssociation'
import FileInput from '../../components/Inputs/FileInput'
// import TextLabel from '../../components/Badges/TextLabel'
import MediaModal from '../../components/Modal/MediaModal'
import UppercaseLabel from '../../components/Typography/UppercaseLabel'
import TextLabel from '../../components/Badges/TextLabel'
import Checkbox from '../../components/Checkbox/Checkbox'

const defaultMedia: EditableIcooneMediaItem = {
    id: '',
    name: '',
    visibility: '' as MediaVisibility,
    shareGrant: MediaShareGrant.View,
    targets: [],
    creationDate: '',
    updateDate: '',
    devices: [],
    idMediaDirectory: '',
    url: '',
    isTracked: false,
    hasLockedControls: false
}

const IcooneMediaDetailPage: React.FC = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const user = useAppSelector((data) => data.user)
    const data = useAppSelector((data) => data.data)
    const currentIcooneMediaId = getElementIdFromCurrentRoute(window.location.pathname)

    const [editableIcooneMedia, setEditableIcooneMedia] = useState<EditableIcooneMediaItem>({
        ...defaultMedia,
        idMediaDirectory: location.state?.scope || '',
    })
    const [icooneMedia, setIcooneMedia] = useState<IcooneMediaItem | null>()

    const [fetchedIcooneMediaName, setFetchedIcooneMediaName] = useState('')
    const [lockVideoControls, setLockVideoControls] = useState(false)
    const [viewsMonitoring, setViewsMonitoring] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showMediaModal, setShowMediaModal] = useState(false)
    const [selectedMedia, setSelectedMedia] = useState('')
    const [newMedia, setNewMedia] = useState<any[]>([])
    const [deletedLocale, setDeletedLocale] = useState<any[]>([])
    const [currentMediaItems, setCurrentMediaItems] = useState<any[]>([])
    const [externalFile, setExternalFile] = useState(false)
    const [isInvalidUrl, setIsInvalidUrl] = useState(false)

    const fetchIcooneMediaData = async () => {
        try {
            setIsLoading(true)
            const fetchedIcooneMedia = await getSingleIcooneMedia(
                user.loggedUserData?.authToken || '',
                currentIcooneMediaId
            )

            if (fetchedIcooneMedia) {
                setIcooneMedia(fetchedIcooneMedia)
                setEditableIcooneMedia({
                    ...fetchedIcooneMedia,
                    targets: fetchedIcooneMedia.targets.map((i) => i.id),
                    devices: fetchedIcooneMedia.devices.map((i) => i.id.toString()),
                    idMediaDirectory: fetchedIcooneMedia.mediaDirectory.id.toString(),
                })
                if (fetchedIcooneMedia.url) {
                    setExternalFile(true)
                }

                setFetchedIcooneMediaName(fetchedIcooneMedia.name)
            }
        } catch (error) {
            console.error(error)
            ToastError('Si è verificato un errore durante il recupero dei dati!')
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (currentIcooneMediaId === '-1') {
            setEditableIcooneMedia({ ...editableIcooneMedia, idMediaDirectory: location.state.scope })
        } else {
            void fetchIcooneMediaData()
        }
    }, [currentIcooneMediaId])

    useEffect(() => {
        setIsInvalidUrl(!isValidUrl(editableIcooneMedia.url ?? ''))
    }, [editableIcooneMedia.url])

    useEffect(() => {
        const items = icooneMedia?.files.map((file) => file.locale) || []
        let withoutDeletedLocales = items.filter((item) => !deletedLocale.includes(item))
        withoutDeletedLocales = [...withoutDeletedLocales, ...newMedia.map((item) => item.locale)]
        setCurrentMediaItems(withoutDeletedLocales)
    }, [newMedia, deletedLocale, icooneMedia])

    useEffect(() => {
        if (externalFile) {
            setEditableIcooneMedia({
                ...editableIcooneMedia,
                shareGrant: IcooneMediaShareType[0].value,
            })
            setNewMedia([])
        } else {
            setEditableIcooneMedia({
                ...editableIcooneMedia,
                url: '',
            })
        }
    }, [externalFile])

    const isDefaultCompiled = useMemo(() => {
        if (externalFile) {
            return true
        }
        return currentMediaItems.find((item) => item === 'en')
    }, [currentMediaItems])

    const dataIsNotValid = useMemo((): boolean => {
        return (
            !editableIcooneMedia.name ||
            editableIcooneMedia.targets.length === 0 ||
            editableIcooneMedia.shareGrant === '' ||
            !editableIcooneMedia.visibility ||
            (externalFile && !isValidUrl(editableIcooneMedia.url || ''))
        )
    }, [
        editableIcooneMedia.name,
        editableIcooneMedia.targets,
        editableIcooneMedia.shareGrant,
        editableIcooneMedia.visibility,
        editableIcooneMedia.url,
    ])

    const startDeleteProcedure = () => {
        setShowDeleteModal(true)
    }

    const endRemoveIcooneMediaProcedure = () => {
        setShowDeleteModal(false)
        ToastSuccess('Icoone media rimosso con successo')
        navigate(`${ROUTES.ICOONE_MEDIA_FOLDER_DETAIL_ROUTE}${icooneMedia?.mediaDirectory.id}`)
    }

    const removeIcooneMedia = async () => {
        try {
            setIsLoading(true)
            const res = await deleteSingleIcooneMedia(user.loggedUserData?.authToken || '', icooneMedia?.id || '')
            if (res) endRemoveIcooneMediaProcedure()
            else ToastError('Si è verificato un errore durante la procedura di eliminazione!')
        } catch (error) {
            console.error(error)
        }
        setIsLoading(false)
    }

    const startCreateIcooneMediaProcedure = async () => {
        if (!isDefaultCompiled) {
            return
        }
        try {
            setIsLoading(true)
            const data: any = {
                ...editableIcooneMedia,
                devices: editableIcooneMedia.devices.map((device) => parseInt(device, 10)),
                hasLockedControls: lockVideoControls ?? false,
                isTracked: viewsMonitoring ?? false,
                url: externalFile ? editableIcooneMedia.url : undefined
            }

            const res = await createSingleIcooneMedia(user.loggedUserData?.authToken || '', data)
            if (!externalFile) {
                for (let i = 0; i < newMedia.length; i++) {
                    await addMediaFile(
                        user.loggedUserData?.authToken || '',
                        newMedia[i].file,
                        res?.id || '',
                        MediaFilesEntity.ICOONE_MEDIA,
                        newMedia[i].locale
                    )
                }
            }

            if (res) {
                ToastSuccess('Icoone media creato con successo')
                navigate(`${ROUTES.ICOONE_MEDIA_DETAIL_ROUTE}${res.id}`)
            }
        } catch (error) {
            console.error(error)
            ToastError('Si è verificato un errore durante la procedura di creazione!')
        }
        setIsLoading(false)
    }

    const startUpdateIcooneMediaProcedure = async () => {
        if (!isDefaultCompiled) {
            return
        }
        try {
            setIsLoading(true)

            const data: any = {
                ...editableIcooneMedia,
                devices: editableIcooneMedia.devices.map((device) => parseInt(device, 10)),
                hasLockedControls: lockVideoControls ?? false,
                isTracked: viewsMonitoring ?? false,
                url: externalFile ? editableIcooneMedia.url : undefined
            }

            const res = await updateSingleIcooneMedia(user.loggedUserData?.authToken || '', currentIcooneMediaId, data)

            if (externalFile && icooneMedia?.files.length) {
                await Promise.all(
                    icooneMedia?.files.map(async (file) => {
                        await deleteMediaFile(
                            user.loggedUserData?.authToken || '',
                            res?.id || '',
                            MediaFilesEntity.ICOONE_MEDIA,
                            file.locale
                        )
                    })
                )
            } else {
                for (let i = 0; i < newMedia.length; i++) {
                    await addMediaFile(
                        user.loggedUserData?.authToken || '',
                        newMedia[i].file,
                        res?.id || '',
                        MediaFilesEntity.ICOONE_MEDIA,
                        newMedia[i].locale
                    )
                }
                for (let i = 0; i < deletedLocale.length; i++) {
                    await deleteMediaFile(
                        user.loggedUserData?.authToken || '',
                        res?.id || '',
                        MediaFilesEntity.ICOONE_MEDIA,
                        deletedLocale[i]
                    )
                }
            }

            if (res) {
                ToastSuccess('Icoone media aggiornato con successo')
                await fetchIcooneMediaData()
            }
        } catch (error) {
            console.error(error)
            ToastError('Si è verificato un errore durante la procedura di aggiornamento!')
        }
        setIsLoading(false)
    }

    return (
        <>
            {showMediaModal && (
                <MediaModal media={selectedMedia} mediaType={null} onClose={() => setShowMediaModal(false)} />
            )}
            {showDeleteModal && (
                <AlertModal
                    modalTitle="Elimina Icoone media"
                    modalMessage={`Sei sicuro di voler eliminare l'elemento ${
                        fetchedIcooneMediaName || ''
                    }?\nQuesta operazione è irreversibile.`}
                    onClose={() => setShowDeleteModal(false)}
                    onConfirm={() => void removeIcooneMedia()}
                />
            )}
            <div className="page-header-section">
                <div className="page-header-section__left-box">
                    <span className="page-title">
                        <Link
                            internalRoute={`${ROUTES.ICOONE_MEDIA_FOLDER_DETAIL_ROUTE}${icooneMedia?.mediaDirectory.id}`}
                        >
                            Elenco materiali
                        </Link>{' '}
                        / {currentIcooneMediaId === '-1' ? 'Nuovo materiale' : fetchedIcooneMediaName || ''}
                    </span>
                </div>
                <div className="page-header-section__right-box">
                    <Button
                        disabled={isLoading}
                        buttonType="secondary"
                        onClick={() => {
                            navigate(
                                parseInt(editableIcooneMedia.idMediaDirectory, 10) === IcooneMaterialScope.marketing
                                    ? ROUTES.ICOONE_MARKETING_MEDIA_ROUTE
                                    : ROUTES.ICOONE_TRAINING_MEDIA_ROUTE
                            )
                        }}
                    >
                        Annulla
                    </Button>
                    {currentIcooneMediaId !== '-1' && (
                        <Button
                            disabled={isLoading}
                            buttonType="secondary-error"
                            onClick={() => void startDeleteProcedure()}
                        >
                            Elimina
                        </Button>
                    )}
                    <Button
                        loading={isLoading}
                        buttonType="primary"
                        onClick={() => {
                            if (currentIcooneMediaId === '-1') {
                                void startCreateIcooneMediaProcedure()
                            } else {
                                void startUpdateIcooneMediaProcedure()
                            }
                        }}
                        disabled={dataIsNotValid || !isDefaultCompiled}
                    >
                        {currentIcooneMediaId === '-1' ? 'Aggiungi materiale' : 'Aggiorna materiale'}
                    </Button>
                </div>
            </div>

            <div className="page-content-flow">
                <div className="elevated-card full-width">
                    <div className="input-form-box">
                        <UppercaseLabel>Risorsa esterna</UppercaseLabel>
                        <div className="input-form-box__five-col-row">
                            <Checkbox
                                label={'File esterno'}
                                onCheckChange={() => {
                                    setExternalFile(!externalFile)
                                    setLockVideoControls(false)
                                    setViewsMonitoring(false)
                                }}
                                checked={externalFile}
                                disabled={viewsMonitoring || lockVideoControls}
                            />
                        </div>
                        <UppercaseLabel>Impostazioni video tracciabile</UppercaseLabel>
                        <div className="input-form-box__five-col-row">
                            <Checkbox
                                label="Blocca controlli video"
                                onCheckChange={() => {
                                    setExternalFile(false)
                                    setLockVideoControls(!lockVideoControls)
                                }}
                                checked={lockVideoControls}
                                disabled={externalFile}
                            />
                            <Checkbox
                                label="Monitora visualizzazioni"
                                onCheckChange={() => {
                                    setExternalFile(false)
                                    setViewsMonitoring(!viewsMonitoring)
                                }}
                                checked={viewsMonitoring}
                                disabled={externalFile}
                            />
                        </div>
                    </div>
                </div>
                <div className="elevated-card full-width">
                    <div className="input-form-box">
                        <div className="input-form-box__three-col-row">
                            <TextInput
                                inputLabel="nome file"
                                value={editableIcooneMedia?.name || ''}
                                onValueChange={(newVal) => {
                                    setEditableIcooneMedia({ ...editableIcooneMedia, name: newVal })
                                }}
                            />
                            <MultiSelect
                                inputLabel="Target"
                                placeholder="Seleziona uno o più target"
                                options={data.mediaTargets.map((item) => ({ value: item.id, label: item.name }))}
                                values={formatItemsForSelectOptions<MediaTarget>(
                                    data.mediaTargets.filter(
                                        (item) => editableIcooneMedia.targets.indexOf(item.id) !== -1
                                    )
                                )}
                                onValueChange={(selectedOption) =>
                                    setEditableIcooneMedia({
                                        ...editableIcooneMedia,
                                        targets: selectedOption.map((opt) => opt.value) || [],
                                    })
                                }
                            />
                            <SingleSelect
                                inputLabel="Visibilità"
                                placeholder="Seleziona uno o più tipologie di utenti"
                                options={TargetEntity}
                                value={TargetEntity.find((i) => i.value === editableIcooneMedia.visibility)}
                                onValueChange={(selectedOption) =>
                                    setEditableIcooneMedia({
                                        ...editableIcooneMedia,
                                        visibility: selectedOption?.value || MediaVisibility.all,
                                    })
                                }
                            />
                            <SingleSelect
                                disabled={externalFile || viewsMonitoring}
                                inputLabel="Condivisione"
                                placeholder="Seleziona una modalità di condivisione"
                                options={IcooneMediaShareType}
                                value={IcooneMediaShareType.find((i) => i.value === editableIcooneMedia.shareGrant)}
                                onValueChange={(selectedOption) =>
                                    setEditableIcooneMedia({
                                        ...editableIcooneMedia,
                                        shareGrant: selectedOption?.value || '',
                                    })
                                }
                            />
                            {externalFile && (
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <TextInput
                                        inputLabel="URL file esterno"
                                        value={editableIcooneMedia?.url || ''}
                                        onValueChange={(newVal) => {
                                            setEditableIcooneMedia({ ...editableIcooneMedia, url: newVal })
                                        }}
                                    />
                                    {isInvalidUrl && (
                                        <p style={{ color: 'red', fontSize: 12, margin: 2, fontStyle: 'italic' }}>
                                            URL NON VALIDO
                                        </p>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <DevicesAssociation
                    title="dispositivi associati"
                    emptyMessage="I dispositivi associati a questo materiale verranno mostrati qui."
                    currentSelection={
                        // eslint-disable-next-line max-len
                        data.devices.filter((device) =>
                            editableIcooneMedia.devices.find((dev) => dev === device.id.toString())
                        ) || []
                    }
                    onSelectionChange={(devices) => {
                        setEditableIcooneMedia({
                            ...editableIcooneMedia,
                            devices: devices.map((device) => device.id.toString()),
                        })
                    }}
                />
                {!externalFile && (
                    <div className="elevated-card full-width">
                        <div className="input-form-box">
                            <UppercaseLabel>Localizzazione</UppercaseLabel>
                            <div className="input-form-box__five-col-row">
                                {data.languages.map((language) => (
                                    <div key={language.id} className="single-language-box">
                                        <div
                                            style={{
                                                display: 'flex',
                                                gap: '10%',
                                                alignItems: 'center',
                                                paddingBottom: language.isDefault ? 0 : 8,
                                            }}
                                        >
                                            <UppercaseLabel>{language.label}</UppercaseLabel>
                                            {language.isDefault && (
                                                <TextLabel customColor="#76AA86">
                                                    <UppercaseLabel>Default</UppercaseLabel>
                                                </TextLabel>
                                            )}
                                        </div>
                                        <FileInput
                                            showMediaPreview={(media) => {
                                                setSelectedMedia(media)
                                                setShowMediaModal(true)
                                            }}
                                            disableDelete={language.isDefault}
                                            acceptedFormat={AcceptedMedia.ALL}
                                            placeholder={icooneMediaPlaceholderImage}
                                            currentFile={
                                                icooneMedia?.files.find((file) => file.locale === language.code)?.url ||
                                                ''
                                            }
                                            onFileChange={(newFile) => {
                                                setNewMedia([
                                                    ...newMedia,
                                                    {
                                                        file: newFile,
                                                        locale: language.code,
                                                    },
                                                ])
                                            }}
                                            onFileRemove={(removedFile) => {
                                                if (typeof removedFile === 'string') {
                                                    setDeletedLocale([
                                                        ...deletedLocale,
                                                        icooneMedia?.files.find((file) => file.url === removedFile)
                                                            ?.locale || '',
                                                    ])
                                                } else {
                                                    const newFiles = newMedia.filter(
                                                        (file) => file.file.name !== removedFile.name
                                                    )
                                                    setNewMedia(newFiles)
                                                }
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default IcooneMediaDetailPage
