import React, { ReactNode, useEffect, useRef } from 'react'
import './AdvancedSearch.sass'
import Button from '../Buttons/Button'
import { useKeyDetect } from '../../utility/customHooks'

const AdvancedSearch: React.FC<{
    children: ReactNode
    onSearch: () => void
    onReset: () => void
    isVisible?: boolean
}> = ({ children, onSearch, onReset, isVisible = false }) => {
    const detectedKey = useKeyDetect()
    const advancedSearchContainerRef = useRef<any>(null)

    useEffect(() => {
        if (
            detectedKey &&
            detectedKey.key === 'Enter' &&
            advancedSearchContainerRef.current &&
            advancedSearchContainerRef.current.contains(document.activeElement)
        )
            // eslint-disable-next-line nonblock-statement-body-position
            onSearch()
    }, [detectedKey, advancedSearchContainerRef.current])

    return (
        <div ref={advancedSearchContainerRef} className={`advanced-search-panel ${isVisible ? '' : 'display-none'}`}>
            <div className="advanced-search-panel__params-container">{children}</div>
            <div className="advanced-search-panel__actions-container">
                <Button buttonType="secondary" onClick={() => onReset()}>
                    Ripristina
                </Button>
                <Button buttonType="primary" onClick={() => onSearch()}>
                    Cerca
                </Button>
            </div>
        </div>
    )
}

export default AdvancedSearch
