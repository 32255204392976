import React, { useEffect, useMemo, useState } from 'react'
import Button from '../../../components/Buttons/Button'
import TextInput from '../../../components/Inputs/TextInput'
import MultiSelect from '../../../components/Inputs/MultiSelect'
import { ROUTES } from '../../../resources/routes-constants'
import { useNavigate } from 'react-router-dom'
import { AppType, Device, InfoItem } from '../../../types/data'
import { useAppSelector } from '../../../store/reducers/store'
import { ToastError, ToastSuccess } from '../../../utility/toast'
import { formatItemsForSelectOptions, getElementIdFromCurrentRoute } from '../../../utility/functions'
import AlertModal from '../../../components/Modal/AlertModal'
import {
    createSingleDevice,
    deleteSingleDevice,
    getSingleDevice,
    updateSingleDevice,
} from '../../../resources/api-constants'
import Link from '../../../components/Link/Link'

export interface DeviceToEdit {
    id?: string
    name: string
    applicationTypes: string[]
    bodyAreas: string[]
}

const defaultDevice: DeviceToEdit = {
    id: '-1',
    name: '',
    applicationTypes: [],
    bodyAreas: [],
}

const DeviceDetailPage: React.FC = () => {
    const user = useAppSelector((data) => data.user)
    const data = useAppSelector((data) => data.data)
    const [device, setDevice] = useState<DeviceToEdit>(defaultDevice)
    const [fetchedDeviceName, setFetchedDeviceName] = useState('')
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const currentDeviceId = getElementIdFromCurrentRoute(window.location.pathname)
    const navigate = useNavigate()

    const parseDevice = (deviceToParse: Device): DeviceToEdit => {
        return {
            id: deviceToParse.id,
            name: deviceToParse.name,
            bodyAreas: deviceToParse.bodyAreas.map((bodyArea) => bodyArea.id.toString()),
            applicationTypes: deviceToParse.applicationTypes.map((appType) => appType.id.toString()),
        }
    }

    const fetchDeviceData = async () => {
        try {
            setIsLoading(true)
            const fetchedDevice = await getSingleDevice(user.loggedUserData?.authToken || '', currentDeviceId)
            if (fetchedDevice) {
                const parsedDevice = parseDevice(fetchedDevice)
                setDevice(parsedDevice)
                setFetchedDeviceName(fetchedDevice.name)
            }
        } catch (error) {
            console.error(error)
            ToastError('Si è verificato un errore durante il recupero dei dati!')
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (currentDeviceId !== '-1') void fetchDeviceData()
    }, [currentDeviceId])

    const startDeleteProcedure = () => {
        setShowDeleteModal(true)
    }

    const endRemoveDeviceProcedure = () => {
        setShowDeleteModal(false)
        ToastSuccess('Dispositivo rimosso con successo')
        navigate(ROUTES.DEVICES_ROUTE)
    }

    const removeDevice = async () => {
        try {
            setIsLoading(true)
            const res = await deleteSingleDevice(user.loggedUserData?.authToken || '', device?.id || '')
            if (res) endRemoveDeviceProcedure()
            else ToastError('Si è verificato un errore durante la procedura di eliminazione!')
        } catch (error) {
            console.error(error)
        }
        setIsLoading(false)
    }

    const startCreateDeviceProcedure = async () => {
        try {
            setIsLoading(true)
            const data = {
                ...device,
            }
            delete data.id
            const res = await createSingleDevice(user.loggedUserData?.authToken || '', data)
            if (res) {
                ToastSuccess('Dispositivo creato con successo')
                navigate(`${ROUTES.DEVICE_DETAIL_ROUTE}${res.id}`)
            }
        } catch (error) {
            console.error(error)
            ToastError('Si è verificato un errore durante la procedura di creazione!')
        }
        setIsLoading(false)
    }

    const startUpdateDeviceProcedure = async () => {
        try {
            setIsLoading(true)
            const res = await updateSingleDevice(user.loggedUserData?.authToken || '', currentDeviceId, device)
            if (res) {
                ToastSuccess('Dispositivo aggiornato con successo')
                await fetchDeviceData()
            }
        } catch (error) {
            console.error(error)
            ToastError('Si è verificato un errore durante la procedura di aggiornamento!')
        }
        setIsLoading(false)
    }

    const dataIsNotValid = useMemo((): boolean => {
        return (
            !device.name ||
            !device.applicationTypes ||
            !device.bodyAreas ||
            device.applicationTypes.length === 0 ||
            device.bodyAreas.length === 0
        )
    }, [device.name, device.applicationTypes, device.bodyAreas])

    return (
        <>
            {showDeleteModal && (
                <AlertModal
                    modalTitle="Elimina dispositivo"
                    modalMessage={`Sei sicuro di voler eliminare l'elemento ${
                        device?.name || ''
                    }? Questa operazione è irreversibile.`}
                    onClose={() => setShowDeleteModal(false)}
                    onConfirm={() => void removeDevice()}
                />
            )}
            <div className="page-header-section">
                <div className="page-header-section__left-box">
                    <span className="page-title">
                        <Link internalRoute={ROUTES.DEVICES_ROUTE}>Elenco dispositivi</Link> /{' '}
                        {currentDeviceId === '-1' ? 'Nuovo dispositivo' : fetchedDeviceName || ''}
                    </span>
                </div>
                <div className="page-header-section__right-box">
                    <Button disabled={isLoading} buttonType="secondary" onClick={() => navigate(ROUTES.DEVICES_ROUTE)}>
                        Annulla
                    </Button>
                    {currentDeviceId !== '-1' && (
                        <Button
                            disabled={isLoading}
                            buttonType="secondary-error"
                            onClick={() => void startDeleteProcedure()}
                        >
                            Elimina
                        </Button>
                    )}
                    <Button
                        loading={isLoading}
                        disabled={dataIsNotValid}
                        buttonType="primary"
                        onClick={() => {
                            if (currentDeviceId === '-1') {
                                void startCreateDeviceProcedure()
                            } else {
                                void startUpdateDeviceProcedure()
                            }
                        }}
                    >
                        {currentDeviceId === '-1' ? 'Crea dispositivo' : 'Aggiorna dispositivo'}
                    </Button>
                </div>
            </div>
            <div className="page-content-flow">
                <div className="elevated-card full-width">
                    <div className="input-form-box">
                        <div className="input-form-box__three-col-row">
                            <TextInput
                                inputLabel="nome"
                                value={device?.name || ''}
                                onValueChange={(newVal) => setDevice({ ...device, name: newVal })}
                            />
                            <MultiSelect
                                inputLabel="area interessata"
                                placeholder="Seleziona una o più aree del corpo"
                                options={data.bodyAreas.map((bodyArea) => ({
                                    value: bodyArea.id.toString(),
                                    label: bodyArea.name,
                                }))}
                                values={formatItemsForSelectOptions<InfoItem>(
                                    data.bodyAreas.filter((bodyArea) => device.bodyAreas.indexOf(bodyArea.id) !== -1)
                                )}
                                onValueChange={(newVals) =>
                                    setDevice({
                                        ...device,
                                        bodyAreas: newVals.map((val) => val.value),
                                    })
                                }
                            />
                            <MultiSelect
                                inputLabel="tipologia"
                                placeholder="Seleziona una o più tipologie"
                                options={data.appTypes.map((appType) => ({
                                    value: appType.id.toString(),
                                    label: appType.name,
                                }))}
                                values={formatItemsForSelectOptions<AppType>(
                                    data.appTypes.filter(
                                        (appType) => device.applicationTypes.indexOf(appType.id) !== -1
                                    )
                                )}
                                onValueChange={(newVals) =>
                                    setDevice({
                                        ...device,
                                        applicationTypes: newVals.map((val) => val.value),
                                    })
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeviceDetailPage
