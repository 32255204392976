import React, { useEffect, useMemo, useState } from 'react'
import Button from '../../../components/Buttons/Button'
import TextInput from '../../../components/Inputs/TextInput'
import { ROUTES } from '../../../resources/routes-constants'
import { useNavigate } from 'react-router-dom'
import { AcceptedMedia, Certification, MediaFilesEntity } from '../../../types/data'
import { useAppSelector } from '../../../store/reducers/store'
import { ToastError, ToastSuccess } from '../../../utility/toast'
import { getElementIdFromCurrentRoute } from '../../../utility/functions'
import AlertModal from '../../../components/Modal/AlertModal'
import Link from '../../../components/Link/Link'
import TextareaInput from '../../../components/Inputs/TextareaInput'
import {
    addMediaFile,
    createSingleCertification,
    deleteSingleCertification,
    getSingleCertification,
    updateSingleCertification,
} from '../../../resources/api-constants'
import UppercaseLabel from '../../../components/Typography/UppercaseLabel'
import Paragraph from '../../../components/Typography/Paragraph'
import '../../../styles/Pages/detailPages.sass'
import FileInput from '../../../components/Inputs/FileInput'
import FixedMeasureInput from '../../../components/Inputs/FixedMeasureInput'
import TranslationModal from '../../../components/Modal/TranslationModal'
import MediaModal from '../../../components/Modal/MediaModal'

const defaultCertification: Certification = {
    id: '-1',
    name: '',
    description: '',
    avatar: '',
    validity: '',
    localized: { it: { name: '', description: '' } },
}

const certificationPlaceholderImage =
    'https://icoone-media.s3.eu-west-1.amazonaws.com/defaults/badge_avatar_placeholder.png'

const CertificationDetailPage: React.FC = () => {
    const user = useAppSelector((data) => data.user)
    const [importedAvatar, setImportedAvatar] = useState<File | null>(null)
    const [certification, setCertification] = useState<Certification>(defaultCertification)
    const [fetchedCertificationName, setFetchedCertificationName] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [validityValueError, setValidityValueError] = useState(false)
    const currentCertificationId = getElementIdFromCurrentRoute(window.location.pathname)
    const [isLocalizationVisible, setIsLocalizationVisible] = useState(false)

    const [showMediaModal, setShowMediaModal] = useState(false)
    const [selectedMedia, setSelectedMedia] = useState('')

    const navigate = useNavigate()

    const fetchCertificationData = async () => {
        try {
            setIsLoading(true)
            const fetchedCertification = await getSingleCertification(
                user.loggedUserData?.authToken || '',
                currentCertificationId
            )
            if (fetchedCertification) {
                setCertification(fetchedCertification)
                setFetchedCertificationName(fetchedCertification.name)
            }
        } catch (error) {
            console.error(error)
            ToastError('Si è verificato un errore durante il recupero dei dati!')
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (currentCertificationId !== '-1') void fetchCertificationData()
    }, [currentCertificationId])

    const startDeleteProcedure = () => {
        setShowDeleteModal(true)
    }

    const endRemoveCertificationProcedure = () => {
        setShowDeleteModal(false)
        ToastSuccess('Certificazione rimossa con successo')
        navigate(ROUTES.CERTIFICATIONS_ROUTE)
    }

    const removeCertification = async () => {
        try {
            setIsLoading(true)
            const res = await deleteSingleCertification(user.loggedUserData?.authToken || '', certification?.id || '')
            if (res) endRemoveCertificationProcedure()
            else ToastError('Si è verificato un errore durante la procedura di eliminazione!')
        } catch (error) {
            console.error(error)
        }
        setIsLoading(false)
    }

    const startCreateCertificationProcedure = async () => {
        try {
            setIsLoading(true)
            const parsedValidity = parseInt(certification.validity, 10)
            const data = {
                ...certification,
                avatar: null,
                validity: Number.isNaN(parsedValidity) ? 0 : Math.max(parsedValidity, 0),
            }
            delete data.id
            const res = await createSingleCertification(user.loggedUserData?.authToken || '', data)
            if (importedAvatar) {
                await addMediaFile(
                    user.loggedUserData?.authToken || '',
                    importedAvatar,
                    certification.id || '',
                    MediaFilesEntity.CERTIFICATION
                )
            }
            if (res) {
                ToastSuccess('Certificazione creata con successo')
                navigate(`${ROUTES.CERTIFICATION_DETAIL_ROUTE}${res.id}`)
            }
        } catch (error) {
            console.error(error)
            ToastError('Si è verificato un errore durante la procedura di creazione!')
        }
        setIsLoading(false)
    }

    const startUpdateCertificationProcedure = async () => {
        try {
            setIsLoading(true)
            const parsedValidity = parseInt(certification.validity, 10)
            const data = {
                ...certification,
                avatar: certification.avatar,
                stars: Number.isNaN(parsedValidity) ? 0 : Math.max(parsedValidity, 0),
            }
            const res = await updateSingleCertification(
                user.loggedUserData?.authToken || '',
                currentCertificationId,
                data
            )
            if (importedAvatar) {
                await addMediaFile(
                    user.loggedUserData?.authToken || '',
                    importedAvatar,
                    res?.id || '',
                    MediaFilesEntity.CERTIFICATION
                )
            }
            if (res) {
                ToastSuccess('Certificazione aggiornata con successo')
                await fetchCertificationData()
            }
        } catch (error) {
            console.error(error)
            ToastError('Si è verificato un errore durante la procedura di aggiornamento!')
        }
        setIsLoading(false)
    }

    const dataIsNotValid = useMemo((): boolean => {
        const parsedValidity = parseInt(certification.validity, 10)
        const validityValue = !Number.isNaN(parsedValidity) ? Math.max(parsedValidity, 0) : 0
        return !validityValue || validityValueError || !certification.localized
    }, [certification.validity, certification.localized, validityValueError])

    return (
        <>
            {showMediaModal && (
                <MediaModal media={selectedMedia} mediaType={null} onClose={() => setShowMediaModal(false)} />
            )}
            {isLocalizationVisible && (
                <TranslationModal
                    modalTitle="Gestione traduzioni"
                    attributesSchema={certification.localized}
                    ignoreKeys={['name']}
                    onClose={() => setIsLocalizationVisible(false)}
                    onConfirm={(localized) => {
                        setCertification({ ...certification, localized })
                        setIsLocalizationVisible(false)
                    }}
                />
            )}
            {showDeleteModal && (
                <AlertModal
                    modalTitle="Elimina certification"
                    modalMessage={`Sei sicuro di voler eliminare l'elemento ${
                        fetchedCertificationName || ''
                    }?\nTutti i centri che hanno ottenuto questo badge lo perderanno. Questa operazione è irreversibile.`}
                    onClose={() => setShowDeleteModal(false)}
                    onConfirm={() => void removeCertification()}
                />
            )}
            <div className="page-header-section">
                <div className="page-header-section__left-box">
                    <span className="page-title">
                        <Link internalRoute={ROUTES.CERTIFICATIONS_ROUTE}>Elenco certificazioni</Link> /{' '}
                        {currentCertificationId === '-1' ? 'Nuova certificazione' : fetchedCertificationName || ''}
                    </span>
                </div>
                <div className="page-header-section__right-box">
                    <Button disabled={isLoading} buttonType="secondary" onClick={() => setIsLocalizationVisible(true)}>
                        Gestione traduzioni
                    </Button>
                    <Button
                        disabled={isLoading}
                        buttonType="secondary"
                        onClick={() => navigate(ROUTES.CERTIFICATIONS_ROUTE)}
                    >
                        Annulla
                    </Button>
                    {currentCertificationId !== '-1' && (
                        <Button
                            disabled={isLoading}
                            buttonType="secondary-error"
                            onClick={() => void startDeleteProcedure()}
                        >
                            Elimina
                        </Button>
                    )}
                    <Button
                        loading={isLoading}
                        buttonType="primary"
                        disabled={dataIsNotValid}
                        onClick={() => {
                            if (currentCertificationId === '-1') {
                                void startCreateCertificationProcedure()
                            } else {
                                void startUpdateCertificationProcedure()
                            }
                        }}
                    >
                        {currentCertificationId === '-1' ? 'Crea nuova certificazione' : 'Aggiorna certificazione'}
                    </Button>
                </div>
            </div>
            <div className="page-content-flow">
                <div className="elevated-card full-width">
                    <div className="input-form-box">
                        <div className="entity-avatar-wrapper">
                            <div className="entity-avatar-wrapper__avatar-box">
                                <FileInput
                                    showMediaPreview={(media) => {
                                        setSelectedMedia(media)
                                        setShowMediaModal(true)
                                    }}
                                    acceptedFormat={AcceptedMedia.IMAGE}
                                    placeholder={certificationPlaceholderImage}
                                    currentFile={certification.avatar}
                                    onFileChange={(newImageFile) => setImportedAvatar(newImageFile)}
                                    onFileRemove={() => {
                                        setCertification({ ...certification, avatar: '' })
                                        setImportedAvatar(null)
                                    }}
                                />
                            </div>
                            <div className="entity-avatar-wrapper__text-box">
                                <UppercaseLabel>Avatar</UppercaseLabel>
                                <Paragraph>
                                    Modifica l’avatar della certificazione cliccando sul riquadro a fianco.
                                </Paragraph>
                            </div>
                        </div>
                        <div className="input-form-box__three-col-row">
                            <TextInput
                                inputLabel="nome"
                                value={certification?.localized.it.name || ''}
                                onValueChange={(newVal) => {
                                    const local = JSON.parse(JSON.stringify(certification.localized))
                                    local.it.name = newVal
                                    setCertification({ ...certification, localized: local })
                                }}
                            />
                            <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                                <UppercaseLabel>validità</UppercaseLabel>
                                <FixedMeasureInput
                                    validationCondition={/^[1-9]\d*$/}
                                    valuePostfix="mesi"
                                    type="number"
                                    step={1}
                                    min={1}
                                    value={certification?.validity || ''}
                                    onValueChange={(newVal) => setCertification({ ...certification, validity: newVal })}
                                    onInputError={(isError) => setValidityValueError(isError)}
                                />
                            </div>
                            {!certification.localized && (
                                <div style={{ marginLeft: 6 }}>
                                    <div style={{ marginBottom: 6 }}>
                                        <UppercaseLabel>Traduzioni</UppercaseLabel>
                                    </div>
                                    <div>
                                        Genera o modifica le traduzioni dei campi di questa certificazione per poter{' '}
                                        <b>salvare le modifiche</b>.
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="full-width">
                            <TextareaInput
                                inputLabel="descrizione"
                                value={certification?.localized.it.description || ''}
                                onValueChange={(newVal) => {
                                    const local = JSON.parse(JSON.stringify(certification.localized))
                                    local.it.description = newVal
                                    setCertification({ ...certification, localized: local })
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CertificationDetailPage
