import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../resources/routes-constants'
import TabsSelector from '../../components/TabsSelector/TabsSelector'
import DealerAnagraphic from './Tabs/DealerAnagraphic'
import AssociatedCenters from './Tabs/AssociatedCenters'
import { getElementIdFromCurrentRoute } from '../../utility/functions'
import StatusLabel from '../../components/Badges/StatusLabel'
import { EntityStatus } from '../../types/data'

const pageTabs = [
    {
        value: 'anagraphics',
        label: 'Anagrafica',
    },
    {
        value: 'associated_centers',
        label: 'Centri associati',
    },
]

const DealersDetailPage: React.FC = () => {
    const [selectedTab, setSelectedTab] = useState<{ value: string; label: string }>(pageTabs[0])
    const [dealerName, setDealerName] = useState('')
    const [dealerStatus, setDealerStatus] = useState(EntityStatus.PENDING)
    const [dealerId, setDealerId] = useState(getElementIdFromCurrentRoute(window.location.pathname))

    const renderCurrentTab = useMemo(() => {
        switch (selectedTab.value) {
            case 'associated_centers':
                return <AssociatedCenters dealerId={dealerId} />
            case 'anagraphics':
            default:
                return (
                    <DealerAnagraphic
                        dealerId={dealerId}
                        emitDealerData={(id, name, status) => {
                            setDealerName(name)
                            setDealerStatus(status)
                            setDealerId(id)
                        }}
                    />
                )
        }
    }, [selectedTab, dealerId])

    return (
        <>
            <div className="page-header-section">
                <div className="page-header-section__left-box">
                    <span className="page-title">
                        <Link to={ROUTES.DEALERS_ROUTE}>Elenco distributori</Link> /{' '}
                        {dealerId === '-1' ? 'Nuovo distributore' : dealerName || ''}
                        <div style={{ display: 'inline-block', marginLeft: 8, fontSize: 14 }}>
                            <StatusLabel status={dealerStatus} />
                        </div>
                    </span>
                </div>
            </div>
            <TabsSelector
                tabs={pageTabs}
                activeTabValue={selectedTab.value}
                onChangeTab={(newTab) => setSelectedTab(newTab)}
            />
            {renderCurrentTab}
        </>
    )
}

export default DealersDetailPage
