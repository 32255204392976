import React, { useEffect, useState } from 'react'
import Button from '../../../components/Buttons/Button'
import { useAppSelector } from '../../../store/reducers/store'
import { getRawSerialsFile, importSerials } from '../../../resources/api-constants'
import ImportFileModal from '../../../components/Modal/ImportFileModal'
import Paragraph from '../../../components/Typography/Paragraph'
import { ToastError, ToastSuccess } from '../../../utility/toast'

const SerialsPage: React.FC = () => {
    const user = useAppSelector((data) => data.user)
    const [resetFlag, setResetFlag] = useState(false)
    const [showImportModal, setShowImportModal] = useState(false)
    const [downloadIsLoading, setDownloadIsLoading] = useState(false)
    const [importIsLoading, setImportIsLoading] = useState(false)

    useEffect(() => {
        if (resetFlag) setResetFlag(!resetFlag)
    }, [resetFlag])

    const getStartingModalFile = async () => {
        try {
            setDownloadIsLoading(true)
            const rawFileData = await getRawSerialsFile(user.loggedUserData?.authToken || '')
            const link = document.createElement('a')
            link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${rawFileData}`
            link.setAttribute(
                'download',
                `Associazione-seriali_${new Date().toLocaleDateString().replaceAll('/', '_')}.xlsx`
            )
            document.body.appendChild(link)
            link.click()
        } catch (error) {
            console.error(error)
        }
        setDownloadIsLoading(false)
    }

    const startImportProcedure = async (file: any) => {
        try {
            setImportIsLoading(true)
            const res = await importSerials(user.loggedUserData?.authToken || '', file).then((response) => response)
            if (res) setShowImportModal(false)
            ToastSuccess('Lista importata con successo')
        } catch (error) {
            console.error(error)
            ToastError('Errore durante la procedura di import')
        }
        setImportIsLoading(false)
    }

    return (
        <>
            {showImportModal && (
                <ImportFileModal
                    modalTitle="Importa database"
                    modalMessage="Seleziona il file con estensione .xlsx da importare."
                    acceptedFileType=".xlsx"
                    loading={importIsLoading}
                    onFileImport={(file: any) => void startImportProcedure(file)}
                    onClose={() => setShowImportModal(false)}
                />
            )}
            <div className="page-header-section">
                <div className="page-header-section__left-box">
                    <span className="page-title">Associazioni Codice Seriale - Dispositivo</span>
                </div>
            </div>
            <div className="page-content-block">
                <div className="elevated-card full-width">
                    <div className="text-paragraphs-container">
                        <Paragraph>
                            Gestisci l&apos;aggiornamento massivo delle associazioni Codice Seriale - Dispositivo
                        </Paragraph>
                        <Paragraph>
                            Ricordati di mantenere lo stesso formato della lista appena scaricata e di integrare nel
                            foglio di calcolo aggiungendo le associazioni che si desidera integrare a quelle già
                            registrate.
                        </Paragraph>
                        <span>
                            Scarica il file template. Aggiorna la lista e importala nel sistema cliccando su
                            &quot;Importa lista&quot;.
                        </span>
                    </div>
                    <div className="items-row">
                        <Button
                            buttonType="primary"
                            loading={downloadIsLoading}
                            onClick={() => void getStartingModalFile()}
                        >
                            Scarica associazioni attive
                        </Button>
                        <Button buttonType="secondary" onClick={() => setShowImportModal(true)}>
                            Importa lista
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SerialsPage
