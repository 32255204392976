import React, { useEffect, useState } from 'react'
import Table, { TableAction, TableColumn } from '../../components/Table/Table'
import TableHeader from '../../components/Table/TableHeader'
import Button from '../../components/Buttons/Button'
import { ROUTES } from '../../resources/routes-constants'
import { MediaDirectory } from '../../types/data'
import Link from '../../components/Link/Link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolder, faPen } from '@fortawesome/free-solid-svg-icons'
import { confirmFilterObject } from '../../utility/functions'
import { IcooneMaterialScope } from '../../resources/other-constants'
import FolderDetailModal from '../../components/Modal/FolderDetailModal'

export interface IcooneMediaFilters {
    name: string
    format: string[]
    media_scope: string
    media_scope_category: string[]
    visibility: string
    media_target: string[]
    devices: string[]
}

const defaultFiltersState: IcooneMediaFilters = {
    name: '',
    format: [],
    media_scope: '',
    media_scope_category: [],
    visibility: '',
    media_target: [],
    devices: [],
}

export interface IcooneMaterialsProps {
    icooneMaterialScope: IcooneMaterialScope
}

const IcooneMaterialsFoldersPage: React.FC<IcooneMaterialsProps> = (props) => {
    const [resetFlag, setResetFlag] = useState(false)

    const [filters] = useState<IcooneMediaFilters>(defaultFiltersState)
    const [initialFilters, setInitialFilters] = useState<IcooneMediaFilters | undefined>(defaultFiltersState)
    const [finalFilters, setFinalFilters] = useState<IcooneMediaFilters | undefined>(undefined)
    const [selectedFolder, setSelectedFolder] = useState<MediaDirectory | undefined>(undefined)
    const [showFolderInfoModal, setShowFolderInfoModal] = useState(false)

    useEffect(() => {
        setInitialFilters({ ...filters, media_scope: props.icooneMaterialScope.toString() })
    }, [props.icooneMaterialScope])

    useEffect(() => {
        setFinalFilters(confirmFilterObject(initialFilters))
    }, [initialFilters])

    useEffect(() => {
        if (resetFlag) setResetFlag(!resetFlag)
    }, [resetFlag])

    const columns: TableColumn[] = [
        {
            title: 'Nome file',
            field: 'name',
            render: (data: MediaDirectory) => (
                <Link internalRoute={`${ROUTES.ICOONE_MEDIA_FOLDER_DETAIL_ROUTE}${data.id}`} underlined={false}>
                    <FontAwesomeIcon icon={faFolder} size="lg" /> {data.name}
                </Link>
            ),
        },
        {
            title: 'Ultima modifica',
            field: 'update_date',
            render: (data: MediaDirectory) => <p>{new Date(data.updateDate).toLocaleString()}</p>,
        },
    ]

    const actions: TableAction[] = [
        (rowData: MediaDirectory) => ({
            icon: () => <FontAwesomeIcon icon={faPen} size="xs" />,
            onClick: () => {
                setSelectedFolder(rowData)
                setShowFolderInfoModal(true)
            },
            tooltip: 'Modifica',
        }),
    ]

    return (
        <>
            {showFolderInfoModal && (
                <FolderDetailModal
                    mediaDirectory={selectedFolder}
                    onClose={() => {
                        setFinalFilters(confirmFilterObject(initialFilters))
                        setShowFolderInfoModal(false)
                    }}
                    idMediaScope={props.icooneMaterialScope.toString()}
                />
            )}
            <div className="page-header-section">
                <div className="page-header-section__left-box">
                    <span className="page-title">
                        {props.icooneMaterialScope === IcooneMaterialScope.marketing ? 'Marketing' : 'Training'}{' '}
                        Materials
                    </span>
                </div>
                <div className="page-header-section__right-box">
                    <Button buttonType="primary" onClick={() => setShowFolderInfoModal(true)}>
                        Aggiungi cartella
                    </Button>
                </div>
            </div>
            <div className="page-content-block">
                <div className="relative">
                    {initialFilters?.media_scope && (
                        <>
                            <TableHeader positionIndex={23} />
                            <Table
                                actions={actions}
                                columns={columns}
                                filterParams={finalFilters}
                                endpoint="media-directories"
                            />
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

export default IcooneMaterialsFoldersPage
