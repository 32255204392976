import React, { useCallback, useId, useMemo } from 'react'
import './ChatMessage.sass'
import { Message, StaleMessage, StaleMessageType } from '../../types/data'
import { timestampToString } from '../../utility/functions'
import { UserTypes } from '../../types/user'

interface Props {
    message: Message
}

const ChatMessage: React.FC<Props> = (props) => {
    const itemId = useId()

    const messageComponent = useMemo(() => {
        return <div className="text-wrapper">{props.message.message}</div>
    }, [])

    const parsedTime = useMemo(() => timestampToString(new Date(props.message.creationDate).getTime()), [])

    const messageClassByType = useCallback((message: Message | StaleMessage): string => {
        // If is a stale message
        if ((message as StaleMessage).type !== undefined) {
            const staleMessage = message as StaleMessage

            switch (staleMessage.type) {
                case StaleMessageType.Loading:
                    return 'loading'
                case StaleMessageType.Errored:
                    return 'error'
                default:
                    return 'request'
            }
        }

        // Frome here is a real message
        if ((message as Message).sender.type === UserTypes.ADMINISTRATOR) {
            return 'request'
        }

        return 'response'
    }, [])

    return (
        <div id={itemId} className={`message-box ${messageClassByType(props.message)}-message`}>
            <div
                className="message-bubble"
                style={{
                    backgroundColor: 'invalid',
                }}
            >
                <div>{messageComponent}</div>
                <div
                    className="message-footer"
                    onMouseDown={() => false}
                    onSelectCapture={() => false}
                    onSelect={() => false}
                >
                    <span className="time-indicator" data-unselectable={parsedTime} />
                </div>
            </div>
        </div>
    )
}

export default ChatMessage
