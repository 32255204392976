import React, { ReactNode } from 'react'
import './badges.sass'

const TextLabel: React.FC<{ children?: ReactNode; customColor?: string | null }> = ({ children, customColor }) => {
    return (
        <div style={{ backgroundColor: customColor ?? '#eee' }} className="text-label">
            {children}
        </div>
    )
}

export default TextLabel
