import { useRef, useState } from 'react'
import { EntityType, Language, Message, StaleMessage, StaleMessageType } from '../../types/data'
import { getRequestHeader } from '../functions'
import { useAppSelector } from '../../store/reducers/store'
import { baseUrl, translate } from '../../resources/api-constants'
import CustomAxios from '../customAxios'

export interface FetchCompletionBody {
    message: string
    id_session: number
}

type MessageRequest = (requestMessage: string) => Promise<void>
type NextPageRequest = () => Promise<void>
type FetchListRequest = () => Promise<void>

const useChat = (
    entityType: EntityType,
    entityId: string
): [(Message | StaleMessage)[], boolean, MessageRequest, NextPageRequest, FetchListRequest] => {
    const [list, setList] = useState<(Message | StaleMessage)[]>([])
    const [sentMessagesLength, setSentMessagesLength] = useState<number>(0)
    const [loadingData, setLoadingData] = useState<boolean>(false)
    const [hasMoreItemToFetch, setHasMoreItemToFetch] = useState<boolean>(true)
    const currentPage = useRef<number>(0)
    const user = useAppSelector((state) => state.user)
    const data = useAppSelector((state) => state.data)

    // useEffect(() => {
    //     void fetchList()
    // }, [])

    const fetchList: FetchListRequest = async () => {
        if (!hasMoreItemToFetch) {
            return
        }

        setLoadingData(true)

        const chatId = `${entityType.charAt(0).toUpperCase()}${entityId}`

        try {
            const userMessagesHistory: {
                items: Message[]
                count: number
            } = await CustomAxios.get(
                `${baseUrl}chats/${chatId}/messages`,
                getRequestHeader(user.loggedUserData?.authToken || '')
            ).then((response) => response.data)

            const finalList = [
                ...userMessagesHistory.items.sort(
                    (a, b) => new Date(a.creationDate).getTime() - new Date(b.creationDate).getTime()
                ),
                ...list,
            ]

            setList(finalList)

            if (finalList.length / 2 >= userMessagesHistory.count) {
                setHasMoreItemToFetch(false)
            }
        } catch (e) {
            console.error(e)
        }

        setLoadingData(false)
    }

    const translateData = async (text: string) => {
        const sourceLanguage = user.loggedUserData?.language as string

        const targetsLanguages = data.languages.map((i: Language) => i.code)

        const response = await translate(
            user.loggedUserData?.authToken ?? '',
            { message: text },
            sourceLanguage ?? 'en',
            targetsLanguages,
            []
        )

        const localized: { [key: string]: { message: string } } = { en: { message: text } }
        localized[sourceLanguage as string] = { message: text }
        Object.keys(response).forEach((locale) => {
            localized[locale] = response[locale]
        })

        return localized
    }

    const sendMessage: MessageRequest = async (requestMessage: string) => {
        const newId = list[list.length - 1] && list[list.length - 1].id ? parseInt(list[list.length - 1].id, 10) + 1 : 0
        const newMessage: StaleMessage = {
            id: `${newId}-request`,
            message: requestMessage,
            creationDate: new Date().toISOString(),
            type: StaleMessageType.Loading,
        }

        const newList: (Message | StaleMessage)[] = [...JSON.parse(JSON.stringify(list)), newMessage]

        setList(newList)

        const chatId = `${entityType.charAt(0).toUpperCase()}${entityId}`

        try {
            const translated = await translateData(requestMessage)

            const newResponseObject = await CustomAxios.post(
                `${baseUrl}chats/${chatId}/messages`,
                {
                    localized: translated,
                },
                getRequestHeader(user.loggedUserData?.authToken || '')
            ).then((response) => response.data)

            if (newResponseObject) {
                setList(replaceLoadingItem(newList, newResponseObject))
                setSentMessagesLength(sentMessagesLength + 1)
            }
        } catch (error) {
            const errorMessage: StaleMessage = {
                ...newMessage,
                type: StaleMessageType.Errored,
            }

            setList(replaceLoadingItem(newList, errorMessage))
            console.error(error)
        }
    }

    const fetchNewPage: NextPageRequest = async () => {
        currentPage.current = currentPage.current + 1
        await fetchList()
    }

    return [list, loadingData, sendMessage, fetchNewPage, fetchList]
}

const replaceLoadingItem = (list: (Message | StaleMessage)[], newMessage: Message | StaleMessage) => {
    const copiedList = JSON.parse(JSON.stringify(list))

    copiedList.splice(list.length - 1, 1, newMessage)

    return copiedList
}

export interface SectionListGroup {
    title: string
    data: (Message | StaleMessage)[]
}

export const groupListByDate = (list: (Message | StaleMessage)[]) => {
    const groupedMessages: SectionListGroup[] = []
    const localeString = 'it'

    for (let i = 0; i < list.length; i++) {
        const foundIndex = groupedMessages.findIndex(
            (message) => message.title === new Date(list[i].creationDate).toLocaleDateString(localeString)
        )

        if (foundIndex !== -1) {
            groupedMessages[foundIndex].data.push(list[i])
        } else {
            groupedMessages.push({
                data: [list[i]],
                title: new Date(list[i].creationDate).toLocaleDateString(localeString),
            })
        }
    }

    return groupedMessages
}

export default useChat
