import React, { useEffect, useState } from 'react'
import Table, { TableAction, TableColumn } from '../../components/Table/Table'
import TableHeader from '../../components/Table/TableHeader'
import Button from '../../components/Buttons/Button'
import AdvancedSearch from '../../components/AdvancedSearch/AdvancedSearch'
import Checkbox from '../../components/Checkbox/Checkbox'
import { ROUTES } from '../../resources/routes-constants'
import { useNavigate } from 'react-router-dom'
import { Center, EntityStatus, FAQItem, NotificationEntityType } from '../../types/data'
import { useAppSelector } from '../../store/reducers/store'
import { ToastError, ToastSuccess } from '../../utility/toast'
import { deleteSingleFAQ } from '../../resources/api-constants'
import Link from '../../components/Link/Link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { confirmFilterObject } from '../../utility/functions'
import AlertModal from '../../components/Modal/AlertModal'
import StatusLabel from '../../components/Badges/StatusLabel'

export interface SearchFilters {
    status: EntityStatus
}

const defaultFiltersState: SearchFilters = {
    status: '' as EntityStatus,
}

const FaqsPage: React.FC = () => {
    const user = useAppSelector((data) => data.user)
    const [selectedItem, setSelectedItem] = useState<FAQItem | null>(null)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [forceDeleteReload, setForceDeleteReload] = useState(false)
    const navigate = useNavigate()

    const [showAdvancedSearch, setShowAdvancedSearch] = useState(false)
    const [resetFlag, setResetFlag] = useState(false)
    const [filters, setFilters] = useState<SearchFilters>(defaultFiltersState)
    const [finalFilters, setFinalFilters] = useState<SearchFilters | undefined>(undefined)

    useEffect(() => {
        if (resetFlag) setResetFlag(!resetFlag)
    }, [resetFlag])

    useEffect(() => {
        if (forceDeleteReload) setForceDeleteReload(!forceDeleteReload)
    }, [forceDeleteReload])

    const resetSearchParams = () => {
        if (JSON.stringify(filters) === JSON.stringify(defaultFiltersState)) return
        setResetFlag(true)
        setFilters(defaultFiltersState)
        setFinalFilters(confirmFilterObject(defaultFiltersState))
    }

    const startDeleteProcedure = () => {
        setShowDeleteModal(true)
    }

    const endRemoveProcedure = () => {
        setShowDeleteModal(false)
        ToastSuccess('FAQ rimossa con successo')
        setResetFlag(true)
    }

    const removeItem = async () => {
        try {
            await deleteSingleFAQ(user.loggedUserData?.authToken || '', selectedItem?.id || '')
            endRemoveProcedure()
        } catch {
            ToastError('Si è verificato un errore durante la procedura di eliminazione!')
        }
    }

    const columns: TableColumn[] = [
        {
            title: '',
            field: '',
            sorting: false,
            render: (rowData: FAQItem) => (
                <div className="notification-dot-container">
                    {rowData.notifications &&
                        rowData.notifications.length > 0 &&
                        rowData.notifications.map((notification) => (
                            <div
                                key={notification.id}
                                className={`notification-dot ${notification.type.toLowerCase()}-notification`}
                            />
                        ))}
                </div>
            ),
            width: '3%',
        },
        {
            title: 'Stato',
            field: 'status',
            render: (rowData: FAQItem) => <StatusLabel status={rowData.status} />,
        },
        {
            title: 'Domanda',
            field: 'question',
            render: (rowData: FAQItem) => (
                <Link internalRoute={`${ROUTES.FAQ_DETAIL_ROUTE}${rowData.id}`}>{rowData.question}</Link>
            ),
        },
        {
            title: 'Risposta',
            field: 'answer',
            render: (rowData: FAQItem) => <p>{rowData.answer}</p>,
        },
        {
            title: 'Data di invio',
            field: 'answer',
            render: (rowData: FAQItem) => <p>{new Date(rowData.creationDate).toLocaleString()}</p>,
        },
    ]

    const actions: TableAction[] = [
        (rowData: Center) => ({
            icon: () => <FontAwesomeIcon icon={faPen} size="xs" />,
            onClick: () => navigate(`${ROUTES.FAQ_DETAIL_ROUTE}${rowData.id}`),
            tooltip: 'Modifica',
        }),
        (rowData: FAQItem) => ({
            icon: () => <FontAwesomeIcon icon={faTrash} size="xs" />,
            onClick: () => {
                setSelectedItem(rowData)
                startDeleteProcedure()
            },
            tooltip: 'Elimina',
        }),
    ]

    return (
        <>
            {showDeleteModal && (
                <AlertModal
                    modalTitle="Elimina FAQ"
                    modalMessage={'Sei sicuro di voler eliminare la FAQ? Questa operazione è irreversibile.'}
                    onClose={() => setShowDeleteModal(false)}
                    onConfirm={() => void removeItem()}
                />
            )}
            <div className="page-header-section">
                <div className="page-header-section__left-box">
                    <span className="page-title">Elenco FAQ</span>
                </div>
                <div className="page-header-section__right-box">
                    <Button
                        customClassName={`${showAdvancedSearch ? 'active' : ''}`}
                        buttonType="ghost"
                        onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
                    >
                        Ricerca avanzata
                    </Button>
                    <Button buttonType="primary" onClick={() => navigate(`${ROUTES.FAQ_DETAIL_ROUTE}new`)}>
                        Crea nuova FAQ
                    </Button>
                </div>
            </div>
            <div className="page-content-block">
                <AdvancedSearch
                    isVisible={showAdvancedSearch}
                    onReset={() => resetSearchParams()}
                    onSearch={() => setFinalFilters(confirmFilterObject(filters))}
                >
                    <div className="input-form-box__three-col-row">
                        <Checkbox
                            label="Mostra solo risultati in attesa"
                            checked={filters?.status === EntityStatus.PENDING}
                            onCheckChange={(val) => {
                                if (val) {
                                    setFilters({ ...filters, status: EntityStatus.PENDING })
                                } else {
                                    setFilters(defaultFiltersState)
                                }
                            }}
                        />
                    </div>
                </AdvancedSearch>
                <div className="relative">
                    <TableHeader positionIndex={23} />
                    <Table
                        actions={actions}
                        columns={columns}
                        reloads={resetFlag}
                        forceDeleteReload={forceDeleteReload}
                        filterParams={finalFilters}
                        endpoint="faq"
                        notificationFetcher={{ entity: NotificationEntityType.faq }}
                    />
                </div>
            </div>
        </>
    )
}

export default FaqsPage
