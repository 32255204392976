import React, { ReactNode, useEffect, useState } from 'react'
import './inputs.sass'
import UppercaseLabel from '../Typography/UppercaseLabel'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    onValueChange: (newVal: string) => void
    iconButton?: ReactNode
    inputLabel?: string
    validationCondition?: RegExp
    onInputError?: (isError: boolean) => void
}

const TextInput: React.FC<Props> = ({
    iconButton,
    onValueChange,
    inputLabel,
    validationCondition,
    onInputError,
    ...props
}) => {
    const [inputValue, setInputValue] = useState('')
    const [isErrorStatus, setIsErrorStatus] = useState(false)

    useEffect(() => {
        if (!validationCondition) return
        const timer = setTimeout(() => {
            if (!inputValue) setIsErrorStatus(false)
            else {
                const errorStat = !validationCondition?.test(inputValue)
                setIsErrorStatus(errorStat)
                if (onInputError) onInputError(errorStat)
            }
        }, 500)

        return () => clearTimeout(timer)
    }, [inputValue])

    return (
        <div className="text-input-wrapper">
            {inputLabel && (
                <div className="text-input-wrapper__label-container">
                    <UppercaseLabel>{inputLabel}</UppercaseLabel>
                </div>
            )}
            <div className={`text-input-component ${isErrorStatus ? 'input-error' : ''}`}>
                <input
                    className="text-input-component__input-element reset-appearance"
                    type={props.type}
                    value={props.value}
                    onChange={(e) => {
                        setInputValue(e.target.value)
                        onValueChange(e.target.value)
                    }}
                    {...props}
                />
                {iconButton && (
                    <div className="text-input-component__right-icon-container center-box">{iconButton}</div>
                )}
            </div>
        </div>
    )
}

export default TextInput
