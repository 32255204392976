import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import it from 'date-fns/locale/it'
import './Datepicker.sass'
import { handleDropdownClick } from '../../utility/functions'
import TextInput from '../Inputs/TextInput'

interface Props {
    sendDate: (date: Date | null) => void
}

const Datepicker: React.FC<Props> = (props) => {
    const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null)
    const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null)
    const [selectedDate, setSelectedDate] = useState('')
    const [datepickerIsOpen, setDatepickerIsOpen] = useState(false)
    const datepickerTrigger = useRef(null)
    const datepickerPanel = useRef(null)

    /**
     * Al caricamento del componente viene aggiunto un listener per gestire i click (mousedown) sul datepicker.
     */
    useEffect(() => {
        window.addEventListener('mousedown', (e) => {
            setDatepickerIsOpen(
                (datepickerIsOpen) =>
                    (datepickerIsOpen = handleDropdownClick(e, datepickerIsOpen, datepickerTrigger, datepickerPanel))
            )
        })
        return () => {
            window.removeEventListener('mousedown', (e) => {
                setDatepickerIsOpen(
                    (datepickerIsOpen) =>
                        (datepickerIsOpen = handleDropdownClick(
                            e,
                            datepickerIsOpen,
                            datepickerTrigger,
                            datepickerPanel
                        ))
                )
            })
        }
    }, [])

    return (
        <div style={{ position: 'relative' }} className="datepicker-wrapper-box">
            <div
                ref={datepickerTrigger}
                className="input datepicker-input-trigger"
                onClick={() => {
                    setDatepickerIsOpen(!datepickerIsOpen)
                    setSelectedStartDate(null)
                    setSelectedEndDate(null)
                }}
            >
                <div className="icon">
                    <FontAwesomeIcon icon={faCalendar} />
                </div>
            </div>
            <div ref={datepickerPanel} className="datepicker-container">
                <ReactDatePicker
                    locale={it}
                    startDate={selectedStartDate}
                    endDate={selectedEndDate}
                    open={datepickerIsOpen}
                    onChange={(date: Date | null) => {
                        setSelectedDate(date?.toLocaleDateString() || '')
                        props.sendDate(date)
                    }}
                />
            </div>
            <div style={{ width: '100%' }}>
                <TextInput value={selectedDate} placeholder="Seleziona una data" disabled onValueChange={() => null} />
            </div>
        </div>
    )
}

export default Datepicker
