import React, { useEffect, useMemo, useState } from 'react'
import Table from '../components/Table/Table'
import TableHeader from '../components/Table/TableHeader'
import NumericBadge from '../components/Badges/NumericBadge'
import { Center, Dealer, FAQItem, NotificationItem, Result } from '../types/data'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../resources/routes-constants'
import Link from '../components/Link/Link'
import { useAppSelector } from '../store/reducers/store'
import { getCenters, getDealers } from '../resources/api-constants'

const HomePage: React.FC = () => {
    const user = useAppSelector((data) => data.user)
    const countries = useAppSelector((data) => data.data.countries)
    const notificationsItems = useAppSelector((data) => data.notification.items)
    const [centers, setCenters] = useState<Center[]>([])
    const [dealers, setDealers] = useState<Dealer[]>([])

    const navigate = useNavigate()

    const [resultItems, setResultItems] = useState<number>(0)
    const [faqItems, setFaqItems] = useState<number>(0)
    const [subscriptionItems, setSubscriptionItems] = useState<(Dealer | Center)[]>([])

    const fetchPendingSubscriptionData = async () => {
        try {
            const centers = await getCenters(user.loggedUserData?.authToken || '')
            const dealers = await getDealers(user.loggedUserData?.authToken || '')

            const pendingCenters = centers.items.filter((center) => center.status === 'PENDING')
            const pendingDealers = dealers.items.filter((dealer) => dealer.status === 'PENDING')

            const items = [...pendingCenters, ...pendingDealers]

            setSubscriptionItems(items)
            setCenters(centers.items)
            setDealers(dealers.items)
        } catch (error) {
            console.error(error)
        }
    }

    const findCenterOrDealer = (entityId: number, entityType: string) => {
        if (entityType === 'CENTER') {
            return centers.find((center) => parseInt(center.id, 10) === entityId)
        }
        if (entityType === 'DEALER') {
            return dealers.find((dealer) => (dealer.id ? parseInt(dealer.id, 10) === entityId : false))
        }
    }

    const messagesItems = useMemo(() => {
        return notificationsItems.filter(
            (item) =>
                item.type === 'MESSAGE' &&
                item.additionalData &&
                Object.keys(item.additionalData).length > 0 &&
                item.readOn === null
        )
    }, [notificationsItems])

    useEffect(() => {
        void fetchPendingSubscriptionData()
    }, [])

    const truncateText = (text: string, length: number) => {
        return text.length > length ? `${text.substring(0, length)}...` : text
    }

    return (
        <>
            <div className="page-header-section">
                <span className="page-title">Homepage</span>
            </div>
            <div className="page-content-flow">
                <div className="relative">
                    <TableHeader className="result-notification">
                        <span className="table-head-title">risultati in attesa</span>
                        <NumericBadge value={resultItems} />
                    </TableHeader>
                    <Table
                        onTotalCountChange={setResultItems}
                        actions={[
                            (rowData: Result) => ({
                                icon: () => <FontAwesomeIcon icon={faEye} size="xs" />,
                                onClick: () => navigate(`${ROUTES.RESULT_DETAIL_ROUTE}${rowData.id}`),
                                tooltip: 'Valuta',
                            }),
                        ]}
                        filterParams={{ statuses: 'PENDING' }}
                        columns={[
                            {
                                title: 'ID',
                                field: 'id',
                                render: (rowData: Result) => (
                                    <Link internalRoute={`${ROUTES.RESULT_DETAIL_ROUTE}${rowData.id}`}>
                                        RIS-{rowData.id}
                                    </Link>
                                ),
                            },
                            {
                                title: 'Nazione',
                                field: 'country',
                                sorting: false,
                                render: (rowData: Result) => {
                                    if (!rowData.center) return <></>
                                    const country = countries.find(
                                        (country) => country.id === rowData.center?.idCountry
                                    )
                                    if (!country) return <></>
                                    return <p>{country.name}</p>
                                },
                            },
                            {
                                title: 'Centro',
                                field: 'center',
                                sorting: false,
                                render: (rowData: Result) => {
                                    if (rowData.center)
                                        // eslint-disable-next-line nonblock-statement-body-position
                                        return (
                                            <Link internalRoute={`${ROUTES.CENTER_DETAIL_ROUTE}${rowData.center?.id}`}>
                                                {rowData.center.name}
                                            </Link>
                                        )
                                    return <></>
                                },
                            },
                            {
                                title: 'Data di invio',
                                field: 'creation_date',
                                render: (rowData: Result) => (
                                    <p>{new Date(rowData.creationDate).toLocaleDateString()}</p>
                                ),
                            },
                        ]}
                        endpoint="results"
                    />
                </div>
                <div className="relative">
                    <TableHeader className="subscription-notification">
                        <span className="table-head-title">richieste di iscrizione</span>
                        <NumericBadge value={subscriptionItems.length} />
                    </TableHeader>
                    {subscriptionItems && subscriptionItems !== null && (
                        <Table
                            actions={[
                                (data: Center | Dealer) => ({
                                    icon: () => <FontAwesomeIcon icon={faEye} size="xs" />,
                                    onClick: () =>
                                        navigate(
                                            `${
                                                (data as Center).badges
                                                    ? ROUTES.CENTER_DETAIL_ROUTE
                                                    : ROUTES.DEALER_DETAIL_ROUTE
                                            }${data.id}`
                                        ),
                                    tooltip: 'Valuta',
                                }),
                            ]}
                            filterParams={{ status: 'PENDING' }}
                            columns={[
                                {
                                    title: 'Tipologia',
                                    field: 'question',
                                    render: (data: any) => <p>{data.badges ? 'CENTRO' : 'DISTRIBUTORE'}</p>,
                                },
                                {
                                    title: 'Nome',
                                    field: 'name',
                                    render: (data: Center | Dealer) => {
                                        return (
                                            <Link
                                                internalRoute={`${
                                                    (data as Center).badges
                                                        ? ROUTES.CENTER_DETAIL_ROUTE
                                                        : ROUTES.DEALER_DETAIL_ROUTE
                                                }${data.id}`}
                                            >
                                                {data.name}
                                            </Link>
                                        )
                                    },
                                },
                                {
                                    title: 'Data di invio della richiesta',
                                    field: 'creation_date',
                                    render: (data: any) => <p>{new Date(data.creationDate).toLocaleString()}</p>,
                                },
                            ]}
                            items={subscriptionItems || null}
                        />
                    )}
                </div>
                <div className="relative">
                    <TableHeader className="message-notification">
                        <span className="table-head-title">nuovi messaggi</span>
                        <NumericBadge value={messagesItems.length} />
                    </TableHeader>
                    <Table
                        actions={[
                            (data: any) => ({
                                icon: () => <FontAwesomeIcon icon={faEye} size="xs" />,
                                onClick: () => {
                                    if (data.additionalData?.father === 'DEALER') {
                                        navigate(`${ROUTES.DEALER_DETAIL_ROUTE}${data.additionalData?.fatherId}`)
                                    } else {
                                        navigate(`${ROUTES.CENTER_DETAIL_ROUTE}${data.additionalData?.fatherId}`)
                                    }
                                },
                                tooltip: 'Visualizza',
                            }),
                        ]}
                        columns={[
                            {
                                title: 'Ragione sociale',
                                field: 'name',
                                sorting: false,
                                render: (data: NotificationItem) => (
                                    <Link
                                        internalRoute={`${ROUTES.CENTER_DETAIL_ROUTE}${data.additionalData?.fatherId}`}
                                    >
                                        <p>
                                            {
                                                findCenterOrDealer(
                                                    data.additionalData?.fatherId || -1,
                                                    data.additionalData?.father || 'CENTER'
                                                )?.name
                                            }
                                        </p>
                                    </Link>
                                ),
                            },
                            {
                                title: 'Nazione',
                                field: 'country',
                                sorting: false,
                                render: (data: NotificationItem) => {
                                    return (
                                        <p>
                                            {
                                                findCenterOrDealer(
                                                    data.additionalData?.fatherId || -1,
                                                    data.additionalData?.father || 'CENTER'
                                                )?.country.name
                                            }
                                        </p>
                                    )
                                },
                            },
                            {
                                title: 'Provincia',
                                field: 'province',
                                sorting: false,
                                render: (data: NotificationItem) => {
                                    return (
                                        <p>
                                            {
                                                findCenterOrDealer(
                                                    data.additionalData?.fatherId || -1,
                                                    data.additionalData?.father || 'CENTER'
                                                )?.province
                                            }
                                        </p>
                                    )
                                },
                            },
                            {
                                title: 'Messaggio',
                                field: 'message',
                                sorting: false,
                                render: (data: any) => <p>{truncateText(data.additionalData?.message || '', 200)}</p>,
                            },
                        ]}
                        items={messagesItems}
                    />
                </div>
                {/* <div className="relative">
                    <TableHeader className="devices-notification text-white">
                        <span className="table-head-title">richieste associazione dispositivi</span>
                        <NumericBadge value={10} />
                    </TableHeader>
                    <Table actions={[]} data={[]} columns={columnsFour} endpoint="notifications" />
                </div> */}
                <div className="relative">
                    <TableHeader className="faq-notification">
                        <span className="table-head-title">richieste aggiunta f.a.q.</span>
                        <NumericBadge value={faqItems} />
                    </TableHeader>
                    <Table
                        onTotalCountChange={setFaqItems}
                        actions={[
                            (rowData: FAQItem) => ({
                                icon: () => <FontAwesomeIcon icon={faEye} size="xs" />,
                                onClick: () => navigate(`${ROUTES.FAQ_DETAIL_ROUTE}${rowData.id}`),
                                tooltip: 'Valuta',
                            }),
                        ]}
                        filterParams={{ status: 'PENDING' }}
                        columns={[
                            {
                                title: 'Domanda',
                                field: 'question',
                                render: (data: FAQItem) => <p>{data.question}</p>,
                            },
                            {
                                title: 'Risposta',
                                field: 'answer',
                                render: (data: FAQItem) => <p>{data.answer}</p>,
                            },
                            {
                                title: 'Data di invio della richiesta',
                                field: 'creation_date',
                                render: (data: FAQItem) => <p>{new Date(data.creationDate).toLocaleString()}</p>,
                            },
                        ]}
                        endpoint="faq"
                    />
                </div>
            </div>
        </>
    )
}

export default HomePage
