import React, { useEffect, useRef, useState } from 'react'
import './Navbar.sass'
import MainLogoContainer from '../MainLogoContainer/MainLogoContainer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleUser, faBell } from '@fortawesome/free-solid-svg-icons'
import { handleDropdownClick } from '../../utility/functions'
import NotificationWidget from './NotificationsWidget'
import { useAppDispatch, useAppSelector } from '../../store/reducers/store'
import { logout } from '../../store/actions/user'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../resources/routes-constants'

const Navbar: React.FC = () => {
    const [userProfilePanelIsOpen, setUserProfilePanelIsOpen] = useState(false)
    const [userNotificationsPanelIsOpen, setUserNotificationsPanelIsOpen] = useState(false)
    const userProfileTrigger = useRef(null)
    const userProfilePanel = useRef(null)
    const userNotificationsTrigger = useRef(null)
    const userNotificationsPanel = useRef(null)

    useEffect(() => {
        window.addEventListener('click', (e) => {
            setUserProfilePanelIsOpen(
                (userProfilePanelIsOpen) =>
                    (userProfilePanelIsOpen = handleDropdownClick(
                        e,
                        userProfilePanelIsOpen,
                        userProfileTrigger,
                        userProfilePanel
                    ))
            )
            setUserNotificationsPanelIsOpen(
                (userNotificationsPanelIsOpen) =>
                    (userNotificationsPanelIsOpen = handleDropdownClick(
                        e,
                        userNotificationsPanelIsOpen,
                        userNotificationsTrigger,
                        userNotificationsPanel
                    ))
            )
        })
        return () => {
            window.removeEventListener('click', (e) => {
                setUserProfilePanelIsOpen(
                    (userProfilePanelIsOpen) =>
                        (userProfilePanelIsOpen = handleDropdownClick(
                            e,
                            userProfilePanelIsOpen,
                            userProfileTrigger,
                            userProfilePanel
                        ))
                )
                setUserNotificationsPanelIsOpen(
                    (userNotificationsPanelIsOpen) =>
                        (userNotificationsPanelIsOpen = handleDropdownClick(
                            e,
                            userNotificationsPanelIsOpen,
                            userNotificationsTrigger,
                            userNotificationsPanel
                        ))
                )
            })
        }
    }, [])

    return (
        <div className="main-navbar">
            <div className="left-section">
                <MainLogoContainer />
            </div>
            <div className="right-section">
                <div className="navbar-widget-wrapper">
                    <div className={`action-icon ${userNotificationsPanelIsOpen}`} ref={userNotificationsTrigger}>
                        <FontAwesomeIcon icon={faBell} style={{ transform: 'rotateZ(20deg)', height: 20 }} />
                    </div>
                    {userNotificationsPanelIsOpen && (
                        <div ref={userNotificationsPanel}>
                            <NotificationWidget onClose={() => setUserNotificationsPanelIsOpen(false)} />
                        </div>
                    )}
                </div>
                <div className="navbar-widget-wrapper">
                    <div ref={userProfileTrigger} className={`action-icon ${userProfilePanelIsOpen ? 'active' : ''}`}>
                        <FontAwesomeIcon icon={faCircleUser} style={{ height: 20 }} />
                    </div>
                    {userProfilePanelIsOpen && (
                        <div ref={userProfilePanel} className="user-profile-panel action-panel">
                            <ProfileWiget />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

const ProfileWiget: React.FC = () => {
    const user = useAppSelector((data) => data.user)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const startLogoutProcedure = async () => {
        try {
            await dispatch(logout())
            navigate(ROUTES.LOGIN_ROUTE)
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <>
            <div className="user-profile-panel__upper-section">
                <div className="user-profile-panel__upper-section__profile-avatar">
                    {user.loggedUserData?.firstName[0]}
                    {user.loggedUserData?.lastName[0]}
                </div>
                <div className="user-profile-panel__upper-section__profile-info">
                    <span className="user-name">
                        {user.loggedUserData?.firstName} {user.loggedUserData?.lastName}
                    </span>
                    <span className="user-email">{user.loggedUserData?.email}</span>
                </div>
            </div>
            <div className="user-profile-panel__lower-section">
                <div className="user-profile-panel__lower-section__option" onClick={() => void startLogoutProcedure()}>
                    Esci
                </div>
            </div>
        </>
    )
}

export default Navbar
