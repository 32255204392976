import React, { useEffect, useState } from 'react'
import Table, { TableAction, TableColumn } from '../../components/Table/Table'
import TableHeader from '../../components/Table/TableHeader'
import Button from '../../components/Buttons/Button'
import AdvancedSearch from '../../components/AdvancedSearch/AdvancedSearch'
import TextInput from '../../components/Inputs/TextInput'
import MultiSelect from '../../components/Inputs/MultiSelect'
import { ROUTES } from '../../resources/routes-constants'
import { useNavigate } from 'react-router-dom'
import { AcceptedMedia, Device, IcooneMediaItem, MediaDirectory, MediaTarget } from '../../types/data'
import { useAppSelector } from '../../store/reducers/store'
import { ToastError, ToastSuccess } from '../../utility/toast'
import {
    deleteSingleIcooneMedia,
    deleteSingleMediaDirectory,
    getSingleMediaDirectory,
} from '../../resources/api-constants'
import LinkComponent from '../../components/Link/Link'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { confirmFilterObject, formatItemsForSelectOptions, getElementIdFromCurrentRoute } from '../../utility/functions'
import { CircleFlag } from 'react-circle-flags'

import { IcooneMaterialScope, TargetEntity, icooneMediaPlaceholderImage } from '../../resources/other-constants'
import SingleSelect from '../../components/Inputs/SingleSelect'
import AlertModal from '../../components/Modal/AlertModal'
import FileInput from '../../components/Inputs/FileInput'
import MediaModal from '../../components/Modal/MediaModal'

export interface IcooneMaterialsDirectoryFilters {
    media_directory: string
    name: string
    format: string[]
    media_scope: string
    media_scope_category: string[]
    visibility: string
    media_target: string[]
    devices: string[]
}

const defaultFiltersState: IcooneMaterialsDirectoryFilters = {
    media_directory: '',
    name: '',
    format: [],
    media_scope: '',
    media_scope_category: [],
    visibility: '',
    media_target: [],
    devices: [],
}

const defaultDirectory: MediaDirectory = {
    id: '-1',
    name: '',
    mediaScope: {
        id: '',
        label: '',
    },
    creationDate: '',
    updateDate: '',
}

const IcooneMaterialsFolderDetailPage: React.FC = () => {
    const user = useAppSelector((data) => data.user)
    const data = useAppSelector((data) => data.data)
    const navigate = useNavigate()

    const [icooneDirectory, setIcooneDirectory] = useState<MediaDirectory>(defaultDirectory)

    const [showMediaModal, setShowMediaModal] = useState(false)
    const [selectedMedia, setSelectedMedia] = useState('')

    const [showAdvancedSearch, setShowAdvancedSearch] = useState(false)
    const [resetFlag, setResetFlag] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showDeleteFolderModal, setShowDeleteFolderModal] = useState(false)
    const [forceDeleteReload, setForceDeleteReload] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const currentIcooneMediaFolderId = getElementIdFromCurrentRoute(window.location.pathname)
    const [selectedIcooneMedia, setSelectedIcooneMedia] = useState<IcooneMediaItem | null>(null)
    const [filters, setFilters] = useState<IcooneMaterialsDirectoryFilters>(defaultFiltersState)
    const [initialFilters, setInitialFilters] = useState<IcooneMaterialsDirectoryFilters | undefined>(
        defaultFiltersState
    )
    const [finalFilters, setFinalFilters] = useState<IcooneMaterialsDirectoryFilters | undefined>(undefined)

    const fetchDetail = async () => {
        if (initialFilters?.media_directory === '') return

        try {
            setIsLoading(true)
            const fetchedItem = await getSingleMediaDirectory(
                user.loggedUserData?.authToken || '',
                currentIcooneMediaFolderId
            )
            if (fetchedItem) {
                setIcooneDirectory(fetchedItem)
            }
        } catch (error) {
            console.error(error)
            ToastError('Si è verificato un errore durante il recupero dei dati!')
        }
        setIsLoading(false)
    }

    useEffect(() => {
        setInitialFilters({ ...filters, media_directory: currentIcooneMediaFolderId })
        setFinalFilters({ ...filters, media_directory: currentIcooneMediaFolderId })
    }, [currentIcooneMediaFolderId])

    useEffect(() => {
        setFinalFilters(confirmFilterObject(initialFilters))
        void fetchDetail()
    }, [initialFilters])

    useEffect(() => {
        if (resetFlag) setResetFlag(!resetFlag)
    }, [resetFlag])

    const resetSearchParams = () => {
        if (JSON.stringify(filters) === JSON.stringify(defaultFiltersState)) return
        setResetFlag(true)
        setFilters({ ...defaultFiltersState, media_directory: currentIcooneMediaFolderId })
        setFinalFilters(confirmFilterObject({ ...defaultFiltersState, media_directory: currentIcooneMediaFolderId }))
    }

    const startDeleteProcedure = (media: IcooneMediaItem) => {
        setSelectedIcooneMedia(media)
        setShowDeleteModal(true)
    }

    const startDeleteFolderProcedure = async () => {
        try {
            await deleteSingleMediaDirectory(user.loggedUserData?.authToken || '', icooneDirectory?.id || '')
            navigate(
                icooneDirectory.mediaScope?.id.toString() === IcooneMaterialScope.marketing.toString()
                    ? ROUTES.ICOONE_MARKETING_MEDIA_ROUTE
                    : ROUTES.ICOONE_TRAINING_MEDIA_ROUTE
            )
            ToastSuccess('Cartella eliminata con successo!')
        } catch {
            ToastError('Si è verificato un errore durante la procedura di eliminazione!')
        }
    }

    const endDeleteProcedure = () => {
        setSelectedIcooneMedia(null)
        setShowDeleteModal(false)
        setForceDeleteReload(true)
    }

    const removeIcooneMedia = async () => {
        try {
            const res = await deleteSingleIcooneMedia(
                user.loggedUserData?.authToken || '',
                selectedIcooneMedia?.id || ''
            )
            if (res) endDeleteProcedure()
            else ToastError('Si è verificato un errore durante la procedura di eliminazione!')
        } catch (error) {
            console.error(error)
        }
    }

    const columns: TableColumn[] = [
        {
            title: '',
            field: '',
            render: (data: IcooneMediaItem) => (
                <FileInput
                    showMediaPreview={(media) => {
                        setSelectedMedia(media)
                        setShowMediaModal(true)
                    }}
                    disableDelete
                    disableEdit
                    acceptedFormat={AcceptedMedia.ALL}
                    placeholder={icooneMediaPlaceholderImage}
                    currentFile={data?.files.find((file) => file.locale === 'en')?.url || ''}
                />
            ),
            sorting: false,
        },
        {
            title: 'Nome file',
            field: 'name',
            render: (data: IcooneMediaItem) => (
                <LinkComponent internalRoute={`${ROUTES.ICOONE_MEDIA_DETAIL_ROUTE}${data.id}`}>
                    {data.name}
                </LinkComponent>
            ),
        },
        {
            title: 'Target',
            field: 'target',
            sorting: false,
            render: (data: IcooneMediaItem) => <p>{data.targets.map((item) => item.name).join(', ')}</p>,
        },
        {
            title: 'Traduzioni',
            field: 'locale',
            sorting: false,
            render: (data: IcooneMediaItem) => (
                <p>
                    <div style={{ display: 'flex', gap: '8px' }}>
                        {data.files.length > 0 &&
                            data.files.map((item) => (
                                <CircleFlag
                                    key={item.locale}
                                    countryCode={item.locale.replace('en', 'uk')}
                                    height="25"
                                />
                            ))}
                    </div>
                </p>
            ),
        },
        {
            title: 'Ultima modifica',
            field: 'last_edit',
            sorting: false,
            render: (data: IcooneMediaItem) => <p>{new Date(data.updateDate).toLocaleString()}</p>,
        },
    ]

    const actions: TableAction[] = [
        (rowData: IcooneMediaItem) => ({
            icon: () => <FontAwesomeIcon icon={faPen} size="xs" />,
            onClick: () => navigate(`${ROUTES.ICOONE_MEDIA_DETAIL_ROUTE}${rowData.id}`),
            tooltip: 'Modifica',
        }),
        (rowData: IcooneMediaItem) => ({
            icon: () => <FontAwesomeIcon icon={faTrash} size="xs" />,
            onClick: () => startDeleteProcedure(rowData),
            tooltip: 'Elimina',
        }),
    ]

    return (
        <>
            {showMediaModal && (
                <MediaModal media={selectedMedia} mediaType={null} onClose={() => setShowMediaModal(false)} />
            )}
            {showDeleteFolderModal && (
                <AlertModal
                    modalTitle="Elimina cartella"
                    modalMessage={`Sei sicuro di voler eliminare la cartella ${icooneDirectory.name}? Non ti sarà più possibile recuperare il suo contenuto. Questa operazione è irreversibile.`}
                    onClose={() => setShowDeleteFolderModal(false)}
                    onConfirm={() => void startDeleteFolderProcedure()}
                />
            )}
            {showDeleteModal && (
                <AlertModal
                    modalTitle="Elimina icoone media"
                    modalMessage={`Sei sicuro di voler eliminare l'elemento ${selectedIcooneMedia?.name}? Questa operazione è irreversibile.`}
                    onClose={() => setShowDeleteModal(false)}
                    onConfirm={() => void removeIcooneMedia()}
                />
            )}
            <div className="page-header-section">
                <div className="page-header-section__left-box">
                    <span className="page-title">
                        <Link
                            to={
                                icooneDirectory.mediaScope?.id.toString() === IcooneMaterialScope.marketing.toString()
                                    ? ROUTES.ICOONE_MARKETING_MEDIA_ROUTE
                                    : ROUTES.ICOONE_TRAINING_MEDIA_ROUTE
                            }
                        >
                            {icooneDirectory.mediaScope?.label} Materials
                        </Link>{' '}
                        / {currentIcooneMediaFolderId === '-1' ? 'Nuova cartella' : icooneDirectory.name || ''}
                    </span>
                </div>

                <div className="page-header-section__right-box">
                    <Button
                        customClassName={`${showAdvancedSearch ? 'active' : ''}`}
                        buttonType="ghost"
                        onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
                    >
                        Ricerca avanzata
                    </Button>
                    <Button
                        disabled={isLoading}
                        buttonType="secondary-error"
                        onClick={() => setShowDeleteFolderModal(true)}
                    >
                        Elimina cartella
                    </Button>

                    <Button
                        disabled={isLoading}
                        buttonType="primary"
                        onClick={() =>
                            navigate(`${ROUTES.ICOONE_MEDIA_DETAIL_ROUTE}new`, {
                                state: {
                                    scope: currentIcooneMediaFolderId,
                                },
                            })
                        }
                    >
                        Aggiungi materiale
                    </Button>
                </div>
            </div>
            <div className="page-content-block">
                <AdvancedSearch
                    isVisible={showAdvancedSearch}
                    onReset={() => resetSearchParams()}
                    onSearch={() =>
                        setFinalFilters(
                            confirmFilterObject({ ...filters, media_directory: currentIcooneMediaFolderId })
                        )
                    }
                >
                    <div className="input-form-box__three-col-row">
                        <TextInput
                            inputLabel="Nome file"
                            value={filters.name}
                            onValueChange={(newVal) => setFilters({ ...filters, name: newVal })}
                        />
                        <SingleSelect
                            inputLabel="Visibilità"
                            placeholder="Seleziona uno o più tipologie di utenti"
                            options={TargetEntity}
                            reset={resetFlag}
                            value={TargetEntity.find((i) => i.value === filters.visibility)}
                            onValueChange={(selectedOption) =>
                                setFilters({
                                    ...filters,
                                    visibility: selectedOption?.value || '',
                                })
                            }
                        />
                        <MultiSelect
                            inputLabel="Target"
                            placeholder="Seleziona uno o più target"
                            options={data.mediaTargets.map((item) => ({ value: item.id, label: item.name }))}
                            reset={resetFlag}
                            values={formatItemsForSelectOptions<MediaTarget>(
                                data.mediaTargets.filter((item) => filters.media_target.indexOf(item.id) !== -1)
                            )}
                            onValueChange={(selectedOption) =>
                                setFilters({
                                    ...filters,
                                    media_target: selectedOption.map((opt) => opt.value) || [],
                                })
                            }
                        />
                        <MultiSelect
                            inputLabel="Dispositivi"
                            placeholder="Seleziona uno o più dispositivi"
                            options={data.devices.map((device) => ({ value: device.id, label: device.name }))}
                            reset={resetFlag}
                            values={formatItemsForSelectOptions<Device>(
                                data.devices.filter((device) => filters.devices.indexOf(device.id) !== -1)
                            )}
                            onValueChange={(selectedOption) =>
                                setFilters({
                                    ...filters,
                                    devices: selectedOption.map((opt) => opt.value) || [],
                                })
                            }
                        />
                    </div>
                </AdvancedSearch>
                <div className="relative">
                    {finalFilters?.media_directory && (
                        <>
                            <TableHeader positionIndex={23} />
                            <Table
                                actions={actions}
                                columns={columns}
                                filterParams={finalFilters}
                                endpoint="media-files"
                                forceDeleteReload={forceDeleteReload}
                            />
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

export default IcooneMaterialsFolderDetailPage
