import React, { useEffect, useRef } from 'react'
import './inputs.sass'
import AsyncSelect from 'react-select/async'
import UppercaseLabel from '../Typography/UppercaseLabel'
import CustomAxios from '../../utility/customAxios'
import { baseUrl } from '../../resources/api-constants'
import { getRequestHeader } from '../../utility/functions'

export interface SelectOption {
    value: string
    label: string
}

const AsyncSingleSelect: React.FC<{
    options: SelectOption[]
    onValueChange: (selectedOption: SelectOption | null) => void
    placeholder?: string
    inputLabel?: string
    disabled?: boolean
    reset?: boolean
    endpoint?: string
    userToken?: string
    forcedItems?: SelectOption[]
}> = ({ options, onValueChange, placeholder, inputLabel, disabled, reset, endpoint, userToken = '', forcedItems }) => {
    const selectRef = useRef<any>(null)

    useEffect(() => {
        if (reset && selectRef.current) selectRef.current.clearValue()
    }, [reset, selectRef.current])

    const loadOptions = async (query: string) => {
        if (!query || query.length < 3) return []
        const items = await CustomAxios.get(`${baseUrl}${endpoint}?name=${query}`, getRequestHeader(userToken)).then(
            (response) => response.data.items
        )
        const elements = items.map((item: any) => ({
            value: typeof item.id === 'string' ? item.id : item.id.toString(),
            label: item.name,
        }))

        if (forcedItems) {
            elements.unshift(...forcedItems)
        }
        return elements
    }

    return (
        <div>
            {inputLabel && (
                <div className="text-input-wrapper__label-container">
                    <UppercaseLabel>{inputLabel}</UppercaseLabel>
                </div>
            )}
            <AsyncSelect
                ref={selectRef}
                classNames={{
                    control: () => 'select-input-object',
                }}
                styles={{
                    control: (styles) => ({
                        ...styles,
                        border: '2px solid rgba(17, 17, 17, 0.14)',
                        borderRadius: 4,
                        padding: 1,
                    }),
                }}
                isDisabled={disabled}
                loadOptions={async (querySearch) => await loadOptions(querySearch)}
                cacheOptions
                maxMenuHeight={120}
                hideSelectedOptions
                noOptionsMessage={(input) =>
                    input.inputValue.length < 3 ? 'Inserisci almeno 3 caratteri' : 'Nessuna opzione'
                }
                options={options}
                isMulti={false}
                onChange={(selectedOption) => onValueChange(selectedOption)}
                placeholder={placeholder || ''}
            />
        </div>
    )
}

export default AsyncSingleSelect
