/* eslint-disable nonblock-statement-body-position */
import React, { useEffect, useState } from 'react'
import './Sidebar.sass'
import ProjectVersionTag from '../ProjectVersionTag/ProjectVersionTag'
import { ROUTES } from '../../resources/routes-constants'
import { NavLink } from 'react-router-dom'
import NumericBadge from '../Badges/NumericBadge'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { useAppDispatch, useAppSelector } from '../../store/reducers/store'
import { setExpandedSidebarSection, setSidebarIsCollapsed } from '../../store/actions/graphic'
import { NotificationEntityType } from '../../types/data'

interface Route {
    name: string
    slug?: ROUTES
    entityKey?: NotificationEntityType
    subroutes?: {
        name: string
        slug: string
    }[]
}

const routes: Route[] = [
    {
        name: 'Homepage',
        slug: ROUTES.HOMEPAGE_ROUTE,
    },
    { name: 'Centri', entityKey: NotificationEntityType.center, slug: ROUTES.CENTERS_ROUTE },
    {
        name: 'Distributori',
        entityKey: NotificationEntityType.dealer,
        slug: ROUTES.DEALERS_ROUTE,
    },
    {
        name: 'Risultati',
        entityKey: NotificationEntityType.results,
        slug: ROUTES.RESULTS_ROUTE,
    },

    { name: 'FAQ', entityKey: NotificationEntityType.faq, slug: ROUTES.FAQS_ROUTE },
    {
        name: 'Materiale iCOONE',
        subroutes: [
            {
                name: 'Training',
                slug: ROUTES.ICOONE_TRAINING_MEDIA_ROUTE,
            },
            {
                name: 'Marketing',
                slug: ROUTES.ICOONE_MARKETING_MEDIA_ROUTE,
            },
            {
                name: 'After Sales',
                slug: ROUTES.ICOONE_AFTER_SALES_MEDIA_ROUTE,
            },
        ],
    },
    {
        name: 'Analitiche',
        slug: ROUTES.ANALYTICS_ROUTE,
    },
    {
        name: 'Configurazione',
        subroutes: [
            {
                name: 'Protocolli',
                slug: ROUTES.PROTOCOLS_ROUTE,
            },
            {
                name: 'iCOONE Badge',
                slug: ROUTES.BADGES_ROUTE,
            },
            {
                name: 'Certificazioni',
                slug: ROUTES.CERTIFICATIONS_ROUTE,
            },
            {
                name: 'Dispositivi',
                slug: ROUTES.DEVICES_ROUTE,
            },
            {
                name: 'Area commerciale',
                slug: ROUTES.COMMERCIAL_AREA_ROUTE,
            },
            {
                name: 'iCOONE start',
                slug: ROUTES.ICOONE_START_ROUTE,
            },
            {
                name: 'Codici Seriali',
                slug: ROUTES.SERIALS_ROUTE,
            },
        ],
    },
]

const Sidebar: React.FC = () => {
    const notificationItems = useAppSelector((appData) => appData.notification.items)
    const graphic = useAppSelector((data) => data.graphic)
    const [expandedRouteName, setExpandedRouteName] = useState('')
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false)
    const [notifications, setNotifications] = useState<{ [key: string]: number }>({})

    const dispatch = useAppDispatch()

    useEffect(() => {
        const notificationReader = {
            [NotificationEntityType.center]: notificationItems.filter(
                (notification) => notification.entity === NotificationEntityType.center
            ).length,
            [NotificationEntityType.dealer]: notificationItems.filter(
                (notification) => notification.entity === NotificationEntityType.dealer
            ).length,
            [NotificationEntityType.results]: notificationItems.filter(
                (notification) => notification.entity === NotificationEntityType.results
            ).length,
            [NotificationEntityType.faq]: notificationItems.filter(
                (notification) => notification.entity === NotificationEntityType.faq
            ).length,
        }
        setNotifications(notificationReader)
    }, [notificationItems])

    useEffect(() => {
        setSidebarCollapsed(graphic.sidebarIsCollapsed)
    }, [])

    useEffect(() => {
        setExpandedRouteName(graphic.expandedSidebarSection)
    }, [])

    const handleExpandedSectionUpdate = (expandedRoute: string) => {
        setExpandedRouteName(expandedRoute)
        dispatch(setExpandedSidebarSection(expandedRoute))
    }

    const handleNavbarCollapseUpdate = () => {
        setSidebarCollapsed(!sidebarCollapsed)
        dispatch(setSidebarIsCollapsed(!sidebarCollapsed))
    }

    return (
        <div className={`main-sidebar ${sidebarCollapsed ? 'collapsed-navbar' : ''}`}>
            <div className="sidebar-link-container">
                {routes.map((route) => (
                    <SidebarLink
                        key={route.name}
                        route={route}
                        badgeCounter={route.entityKey && notifications[route.entityKey]}
                        expandedRoute={expandedRouteName}
                        onExpand={(expandedRoute) => {
                            handleExpandedSectionUpdate(expandedRoute)
                        }}
                    />
                ))}
            </div>
            <div onClick={() => handleNavbarCollapseUpdate()} className="collapse-sidebar-trigger center-box">
                <div
                    className={`collapse-sidebar-trigger__icon-box center-box ${
                        sidebarCollapsed ? 'rotate-collapsed-icon' : ''
                    }`}
                >
                    <FontAwesomeIcon icon={faChevronLeft} />
                </div>
            </div>
            <div className="sidebar-bottom-container">
                <ProjectVersionTag />
            </div>
        </div>
    )
}

const SidebarLink: React.FC<{
    route: Route
    expandedRoute: string
    badgeCounter?: number
    onExpand: (expandedRoute: string) => void
}> = ({ route, expandedRoute, badgeCounter, onExpand }) => {
    if (route.subroutes)
        return (
            <div
                className={`sidebar-link-box sidebar-link-expandable ${expandedRoute === route.name ? 'expanded' : ''}`}
            >
                <div
                    className="sidebar-link-expandable__trigger"
                    onClick={() => onExpand(expandedRoute === route.name ? '' : route.name)}
                >
                    <span>{route.name}</span>
                    <div className={`expand-arrow ${expandedRoute === route.name ? 'rotated-arrow' : ''}`}>
                        <FontAwesomeIcon icon={faChevronDown} />
                    </div>
                </div>

                {route.subroutes.map((subroute) => (
                    <NavLink key={subroute.name} to={subroute.slug} className="sidebar-link__content">
                        {subroute.name}
                    </NavLink>
                ))}
            </div>
        )

    return (
        <div className="sidebar-link-box sidebar-link">
            <NavLink to={route.slug || ''} className="sidebar-link__content">
                {route.name}
                {badgeCounter !== undefined && badgeCounter !== 0 && <NumericBadge value={badgeCounter} />}
            </NavLink>
        </div>
    )
}

export default Sidebar
