import React, { useEffect, useMemo, useState } from 'react'
import Button from '../../../components/Buttons/Button'
import TextInput from '../../../components/Inputs/TextInput'
import { ROUTES } from '../../../resources/routes-constants'
import { useNavigate } from 'react-router-dom'
import { AcceptedMedia, Badge, MediaFilesEntity } from '../../../types/data'
import { useAppSelector } from '../../../store/reducers/store'
import { ToastError, ToastSuccess } from '../../../utility/toast'
import { getElementIdFromCurrentRoute } from '../../../utility/functions'
import AlertModal from '../../../components/Modal/AlertModal'
import Link from '../../../components/Link/Link'
import TextareaInput from '../../../components/Inputs/TextareaInput'
import {
    addMediaFile,
    createSingleBadge,
    deleteSingleBadge,
    getSingleBadge,
    updateSingleBadge,
} from '../../../resources/api-constants'
import UppercaseLabel from '../../../components/Typography/UppercaseLabel'
import Paragraph from '../../../components/Typography/Paragraph'
import '../../../styles/Pages/detailPages.sass'
import FileInput from '../../../components/Inputs/FileInput'
import TranslationModal from '../../../components/Modal/TranslationModal'
import MediaModal from '../../../components/Modal/MediaModal'

const defaultBadge: Badge = {
    id: '-1',
    name: '',
    description: '',
    avatar: '',
    stars: '',
    localized: { it: { name: '', description: '' } },
}

const badgePlaceholderImage = 'https://icoone-media.s3.eu-west-1.amazonaws.com/defaults/badge_avatar_placeholder.png'

const BadgeDetailPage: React.FC = () => {
    const user = useAppSelector((data) => data.user)
    const [badge, setBadge] = useState<Badge>(defaultBadge)
    const [importedAvatar, setImportedAvatar] = useState<File | null>(null)
    const [fetchedBadgeName, setFetchedBadgeName] = useState('')
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [starsValueError, setStarsValueError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isLocalizationVisible, setIsLocalizationVisible] = useState(false)
    const [showMediaModal, setShowMediaModal] = useState(false)
    const [selectedMedia, setSelectedMedia] = useState('')

    const currentBadgeId = getElementIdFromCurrentRoute(window.location.pathname)
    const navigate = useNavigate()

    const fetchBadgeData = async () => {
        try {
            setIsLoading(true)
            const fetchedBadge = await getSingleBadge(user.loggedUserData?.authToken || '', currentBadgeId)
            if (fetchedBadge) {
                setBadge(fetchedBadge)
                setFetchedBadgeName(fetchedBadge.name)
            }
        } catch (error) {
            console.error(error)
            ToastError('Si è verificato un errore durante il recupero dei dati!')
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (currentBadgeId !== '-1') void fetchBadgeData()
    }, [currentBadgeId])

    const startDeleteProcedure = () => {
        setShowDeleteModal(true)
    }

    const endRemoveBadgeProcedure = () => {
        setShowDeleteModal(false)
        ToastSuccess('Badge rimosso con successo')
        navigate(ROUTES.BADGES_ROUTE)
    }

    const removeBadge = async () => {
        try {
            setIsLoading(true)
            const res = await deleteSingleBadge(user.loggedUserData?.authToken || '', badge?.id || '')
            if (res) endRemoveBadgeProcedure()
            else ToastError('Si è verificato un errore durante la procedura di eliminazione!')
        } catch (error) {
            console.error(error)
        }
        setIsLoading(false)
    }

    const startCreateBadgeProcedure = async () => {
        try {
            setIsLoading(true)
            const parsedStars = parseInt(badge.stars, 10)
            const data = {
                ...badge,
                stars: Number.isNaN(parsedStars) ? 0 : Math.max(parsedStars, 0),
            }
            delete data.id
            const res = await createSingleBadge(user.loggedUserData?.authToken || '', data)
            if (importedAvatar) {
                await addMediaFile(
                    user.loggedUserData?.authToken || '',
                    importedAvatar,
                    res?.id || '',
                    MediaFilesEntity.BADGE
                )
            }
            if (res) {
                ToastSuccess('Badge creato con successo')
                navigate(`${ROUTES.BADGE_DETAIL_ROUTE}${res.id}`)
            }
        } catch (error) {
            console.error(error)
            ToastError('Si è verificato un errore durante la procedura di creazione!')
        }
        setIsLoading(false)
    }

    const startUpdateBadgeProcedure = async () => {
        try {
            setIsLoading(true)
            const parsedStars = parseInt(badge.stars, 10)
            const data = {
                ...badge,
                stars: Number.isNaN(parsedStars) ? 0 : Math.max(parsedStars, 0),
            }
            const res = await updateSingleBadge(user.loggedUserData?.authToken || '', currentBadgeId, data)
            if (importedAvatar) {
                await addMediaFile(
                    user.loggedUserData?.authToken || '',
                    importedAvatar,
                    res?.id || '',
                    MediaFilesEntity.BADGE
                )
            }
            if (res) {
                ToastSuccess('Badge aggiornato con successo')
                await fetchBadgeData()
            }
        } catch (error) {
            console.error(error)
            ToastError('Si è verificato un errore durante la procedura di aggiornamento!')
        }
        setIsLoading(false)
    }

    const dataIsNotValid = useMemo(() => {
        const parsedStars = parseInt(badge.stars, 10)
        const validityValue = !Number.isNaN(parsedStars) ? Math.max(parsedStars, 0) : 0
        return !validityValue || starsValueError || !badge.localized
    }, [badge.description, badge.localized, starsValueError])

    return (
        <>
            {showMediaModal && (
                <MediaModal media={selectedMedia} mediaType={null} onClose={() => setShowMediaModal(false)} />
            )}
            {isLocalizationVisible && (
                <TranslationModal
                    modalTitle="Gestione traduzioni"
                    attributesSchema={badge.localized}
                    ignoreKeys={['name']}
                    onClose={() => setIsLocalizationVisible(false)}
                    onConfirm={(localized) => {
                        setBadge({ ...badge, localized })
                        setIsLocalizationVisible(false)
                    }}
                />
            )}
            {showDeleteModal && (
                <AlertModal
                    modalTitle="Elimina badge"
                    modalMessage={`Sei sicuro di voler eliminare l'elemento ${
                        fetchedBadgeName || ''
                    }?\nTutti i centri che hanno ottenuto questo badge lo perderanno. Questa operazione è irreversibile.`}
                    onClose={() => setShowDeleteModal(false)}
                    onConfirm={() => void removeBadge()}
                />
            )}
            <div className="page-header-section">
                <div className="page-header-section__left-box">
                    <span className="page-title">
                        <Link internalRoute={ROUTES.BADGES_ROUTE}>Elenco badge</Link> /{' '}
                        {currentBadgeId === '-1' ? 'Nuovo badge' : fetchedBadgeName || ''}
                    </span>
                </div>
                <div className="page-header-section__right-box">
                    <Button disabled={isLoading} buttonType="secondary" onClick={() => setIsLocalizationVisible(true)}>
                        Gestione traduzioni
                    </Button>

                    <Button disabled={isLoading} buttonType="secondary" onClick={() => navigate(ROUTES.BADGES_ROUTE)}>
                        Annulla
                    </Button>
                    {currentBadgeId !== '-1' && (
                        <Button
                            disabled={isLoading}
                            buttonType="secondary-error"
                            onClick={() => void startDeleteProcedure()}
                        >
                            Elimina
                        </Button>
                    )}
                    <Button
                        loading={isLoading}
                        disabled={dataIsNotValid}
                        buttonType="primary"
                        onClick={() => {
                            if (currentBadgeId === '-1') {
                                void startCreateBadgeProcedure()
                            } else {
                                void startUpdateBadgeProcedure()
                            }
                        }}
                    >
                        {currentBadgeId === '-1' ? 'Crea nuovo badge' : 'Aggiorna badge'}
                    </Button>
                </div>
            </div>
            <div className="page-content-flow">
                <div className="elevated-card full-width">
                    <div className="input-form-box">
                        <div className="entity-avatar-wrapper">
                            <div className="entity-avatar-wrapper__avatar-box">
                                <FileInput
                                    showMediaPreview={(media) => {
                                        setSelectedMedia(media)
                                        setShowMediaModal(true)
                                    }}
                                    onFileRemove={() => {
                                        setBadge({ ...badge, avatar: '' })
                                        setImportedAvatar(null)
                                    }}
                                    acceptedFormat={AcceptedMedia.IMAGE}
                                    placeholder={badgePlaceholderImage}
                                    currentFile={badge.avatar}
                                    onFileChange={(newImageFile) => setImportedAvatar(newImageFile)}
                                />
                            </div>
                            <div className="entity-avatar-wrapper__text-box">
                                <UppercaseLabel>Avatar</UppercaseLabel>
                                <Paragraph>Modifica l’avatar del badge cliccando sul riquadro a fianco.</Paragraph>
                            </div>
                        </div>
                        <div className="input-form-box__three-col-row">
                            <TextInput
                                inputLabel="nome"
                                value={badge?.localized.it.name || ''}
                                onValueChange={(newVal) => {
                                    const local = JSON.parse(JSON.stringify(badge.localized))
                                    local.it.name = newVal
                                    setBadge({ ...badge, localized: local })
                                }}
                            />
                            <TextInput
                                validationCondition={/^[1-9]\d*$/}
                                inputLabel="stars necessarie"
                                type="number"
                                step={1}
                                min={0}
                                value={badge?.stars || ''}
                                onValueChange={(newVal) => setBadge({ ...badge, stars: newVal })}
                                onInputError={(isError) => setStarsValueError(isError)}
                            />
                            {!badge.localized && (
                                <div style={{ marginLeft: 6 }}>
                                    <div style={{ marginBottom: 6 }}>
                                        <UppercaseLabel>Traduzioni</UppercaseLabel>
                                    </div>
                                    <div>
                                        Genera o modifica le traduzioni dei campi di questo badge per poter{' '}
                                        <b>salvare le modifiche</b>.
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="full-width">
                            <TextareaInput
                                inputLabel="descrizione"
                                value={badge?.localized.it.description || ''}
                                onValueChange={(newVal) => {
                                    const local = JSON.parse(JSON.stringify(badge.localized))
                                    local.it.description = newVal
                                    setBadge({ ...badge, localized: local })
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BadgeDetailPage
