import React, { ReactNode, useState } from 'react'
import './modals.sass'
import Button from '../Buttons/Button'
import AsyncMultiSelect from '../Inputs/AsyncMultiSelect'
import { useAppSelector } from '../../store/reducers/store'

const ImportProtocolModal: React.FC<{
    modalTitle?: string | ReactNode
    importedIds: string[]
    onConfirm: (protocolIds: string[]) => void
    onClose: () => void
}> = ({ modalTitle, importedIds, onConfirm, onClose }) => {
    const [selectedProtocols, setSelectedProtocols] = useState<string[]>([])
    const user = useAppSelector((data) => data.user)

    return (
        <div className="modal-wrapper">
            <div className="dark-cover" />

            <div className="modal-component">
                <div className="modal-component__header">
                    <span className="page-title">{modalTitle}</span>
                </div>
                <div className="modal-component__body" style={{ paddingBottom: 100 }}>
                    <AsyncMultiSelect
                        inputLabel="protocolli"
                        placeholder="Seleziona uno o più protocolli"
                        options={[]}
                        filterOption={(option) => importedIds.indexOf(option.value) === -1}
                        endpoint="protocols"
                        userToken={user.loggedUserData?.authToken}
                        onValueChange={(newVals) => setSelectedProtocols(newVals.map((val) => val.value))}
                    />
                </div>
                <div className="modal-component__footer">
                    <Button buttonType="secondary" onClick={() => onClose()}>
                        Annulla
                    </Button>
                    <Button buttonType="primary" onClick={() => onConfirm(selectedProtocols)}>
                        Conferma
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default ImportProtocolModal
