import React, { ReactNode, useState } from 'react'
import './modals.sass'
import Button from '../Buttons/Button'
import Paragraph from '../Typography/Paragraph'
import FileSelector from '../Inputs/FileSelector'
import CircularLoader from '../InfiniteLoader/CircularLoader'

const ImportFileModal: React.FC<{
    modalTitle?: string | ReactNode
    modalMessage?: string | ReactNode
    acceptedFileType: string
    loading?: boolean
    onFileImport: (file: any) => void
    onClose: () => void
}> = ({ modalTitle, modalMessage, acceptedFileType, loading, onFileImport, onClose }) => {
    const [file, setFile] = useState<any>()
    const [isLoading] = useState(loading)

    return (
        <div className="modal-wrapper">
            <div className="dark-cover" />

            <div className="modal-component">
                <div className="modal-component__header">
                    <span className="page-title">{modalTitle}</span>
                </div>
                <div className="modal-component__body">
                    <Paragraph>{modalMessage}</Paragraph>
                </div>
                {isLoading ? (
                    <CircularLoader />
                ) : (
                    <FileSelector
                        currentFile=""
                        onFileChange={(newFile) => setFile(newFile)}
                        acceptedFormat={acceptedFileType}
                    />
                )}
                <div className="modal-component__footer">
                    <Button buttonType="secondary" disabled={isLoading} onClick={() => onClose()}>
                        Annulla
                    </Button>
                    <Button buttonType="primary" disabled={isLoading || !file} onClick={() => onFileImport(file)}>
                        Importa
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default ImportFileModal
