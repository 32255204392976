import React, { ReactNode } from 'react'
import './modals.sass'
import Button from '../Buttons/Button'
import Paragraph from '../Typography/Paragraph'

const AlertModal: React.FC<{
    modalTitle?: string | ReactNode
    modalMessage?: string | ReactNode
    onConfirm: () => void
    onClose: () => void
}> = ({ modalTitle, modalMessage, onConfirm, onClose }) => {
    return (
        <div className="modal-wrapper">
            <div className="dark-cover" />

            <div className="modal-component">
                <div className="modal-component__header">
                    <span className="page-title">{modalTitle}</span>
                </div>
                <div className="modal-component__body">
                    <Paragraph>{modalMessage}</Paragraph>
                </div>
                <div className="modal-component__footer">
                    <Button buttonType="secondary" onClick={() => onClose()}>
                        Annulla
                    </Button>
                    <Button buttonType="primary" onClick={() => onConfirm()}>
                        Conferma
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default AlertModal
