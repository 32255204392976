import { useEffect, useId, useRef, useState } from 'react'
import { getFileNameFromURL, handleFileUploadFromInput } from '../../utility/functions'
import './inputs.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faEye, faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { AcceptedMedia } from '../../types/data'
import TextLabel from '../Badges/TextLabel'

interface Props {
    currentFile: string
    placeholder: string
    acceptedFormat?: AcceptedMedia
    disableDelete?: boolean
    disableEdit?: boolean
    disabled?: boolean
    onFileChange?: (newFile: any) => void
    onFileRemove?: (file: any) => void
    showMediaPreview?: (media: string) => void
}

const sanitizeFileName = (file: any, id: string): any => {
    if (!file || !file.name) return file
    const fileName = file.name.replaceAll(/[', $!@|-]/g, '_').toLocaleLowerCase()
    const blob = file.slice(0, file.size, file.type)
    return new File([blob], `${id}_${fileName}`, { type: file.type })
}

const FileInput: React.FC<Props> = ({
    disabled,
    currentFile,
    placeholder,
    onFileChange,
    acceptedFormat,
    disableDelete,
    disableEdit,
    onFileRemove,
    showMediaPreview,
}) => {
    const [newFile, setNewFile] = useState<any>(null)
    const [fileType, setFileType] = useState('')
    const fileRef = useRef<any>(null)
    const id = useId()

    useEffect(() => {
        setNewFile(currentFile)
        if (!currentFile) {
            setFileType(AcceptedMedia.IMAGE)
            return
        }
        const extension = currentFile.slice(currentFile.lastIndexOf('.')).toLocaleLowerCase()
        setFileType(extension)
    }, [currentFile])

    useEffect(() => {
        if (newFile && currentFile !== newFile) {
            onFileChange && onFileChange(sanitizeFileName(newFile, id))
        }

        if (newFile && newFile.name) {
            const extension = newFile.name.slice(newFile.name.lastIndexOf('.')).toLocaleLowerCase()
            setFileType(extension)
        }
    }, [newFile])

    const previewEnabled = () => {
        return (
            AcceptedMedia.IMAGE.includes(fileType) ||
            AcceptedMedia.VIDEO.includes(fileType) ||
            AcceptedMedia.AUDIO.includes(fileType) ||
            AcceptedMedia.PDF.includes(fileType)
        )
    }

    const handleDownload = async () => {
        // using Java Script method to get PDF file
        await fetch(newFile)
            .then((response) => {
                void response.blob().then((blob) => {
                    // Creating new object of PDF file
                    const fileURL = window.URL.createObjectURL(blob)

                    // Setting various property values
                    const alink = document.createElement('a')
                    alink.href = fileURL
                    alink.download = 'SamplePDF.pdf'
                    alink.click()
                })
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const renderNewFilePreview = () => {
        if (AcceptedMedia.IMAGE.includes(fileType)) {
            return <img className="image-input-box__image" src={newFile || placeholder} ref={fileRef} alt="" />
        }

        if (AcceptedMedia.VIDEO.includes(fileType)) {
            return (
                // eslint-disable-next-line jsx-a11y/media-has-caption
                <video className="image-input-box__video" src={newFile || placeholder} ref={fileRef}>
                    <track />
                </video>
            )
        }

        if (AcceptedMedia.AUDIO.includes(fileType)) {
            return (
                // eslint-disable-next-line jsx-a11y/media-has-caption
                <audio className="image-input-box__video" src={newFile} ref={fileRef}>
                    <track />
                </audio>
            )
        }
        if (AcceptedMedia.PDF.includes(fileType)) {
            return (
                <div className="image-input-box__file" style={{ gap: '2px' }}>
                    <TextLabel customColor={newFile ? '#76aa86' : '#ccc'}>{fileType}</TextLabel>
                    <TextLabel customColor={newFile ? '#76aa86' : '#ccc'}>
                        {newFile && newFile.name ? newFile.name : getFileNameFromURL(newFile)}
                    </TextLabel>
                </div>
            )
        }

        if (AcceptedMedia.DOC.includes(fileType)) {
            return (
                <div className="image-input-box__file" style={{ gap: '2px' }}>
                    <TextLabel customColor={newFile ? '#76aa86' : '#ccc'}>{fileType}</TextLabel>
                    <TextLabel customColor={newFile ? '#76aa86' : '#ccc'}>
                        {newFile && newFile.name ? newFile.name : getFileNameFromURL(newFile)}
                    </TextLabel>
                </div>
            )
        }
    }

    return (
        <div className="image-input-box">
            {!disabled && (
                <div className="image-input-box__dark-cover">
                    {!disableEdit && (
                        <label className="image-input-box__dark-cover__icon" htmlFor={`avatar-input_${id}`}>
                            <FontAwesomeIcon icon={faPen} />
                        </label>
                    )}

                    {currentFile && previewEnabled() && (
                        <label
                            className="image-input-box__dark-cover__icon"
                            onClick={() => {
                                if (disabled) return
                                if (acceptedFormat === AcceptedMedia.PDF) {
                                    window.open(currentFile)
                                    return
                                }
                                if (showMediaPreview) showMediaPreview(currentFile)
                            }}
                        >
                            <FontAwesomeIcon icon={faEye} />
                        </label>
                    )}

                    {currentFile && !previewEnabled() && (
                        <label
                            className="image-input-box__dark-cover__icon"
                            onClick={() => {
                                if (disabled) return
                                if (acceptedFormat === AcceptedMedia.PDF) {
                                    window.open(currentFile)
                                    return
                                }
                                void handleDownload()
                            }}
                        >
                            <FontAwesomeIcon icon={faDownload} />
                        </label>
                    )}

                    {!disableDelete && (
                        <label
                            className={`image-input-box__dark-cover__icon ${!currentFile && !newFile && 'disabled'}`}
                        >
                            <FontAwesomeIcon
                                icon={faTrash}
                                onClick={() => {
                                    if (onFileRemove) onFileRemove(sanitizeFileName(newFile, id))
                                    if (fileRef.current) fileRef.current.src = null
                                    setNewFile('')
                                }}
                            />
                        </label>
                    )}
                </div>
            )}

            {newFile && renderNewFilePreview()}

            {!newFile && <img className="image-input-box__image" src={newFile || placeholder} ref={fileRef} alt="" />}
            <input
                className="image-input-box__hidden-input"
                id={`avatar-input_${id}`}
                type="file"
                onChange={(e) => handleFileUploadFromInput(e, setNewFile, fileRef)}
                accept={acceptedFormat}
                disabled={disableEdit || disabled}
            />
        </div>
    )
}

export default FileInput
