import React, { ReactNode, useEffect, useState } from 'react'
import './modals.sass'
import Button from '../Buttons/Button'
import UppercaseLabel from '../Typography/UppercaseLabel'
import TextInput from '../Inputs/TextInput'
import { useAppSelector } from '../../store/reducers/store'
import { translate } from '../../resources/api-constants'
import TextLabel from '../Badges/TextLabel'
import TextareaInput from '../Inputs/TextareaInput'
import { Language } from '../../types/data'

const TranslationModal: React.FC<{
    modalTitle?: string | ReactNode
    attributesSchema: any
    ignoreKeys?: string[]
    onConfirm: (localized: any) => void
    onClose: () => void
}> = ({ modalTitle, attributesSchema, ignoreKeys, onConfirm, onClose }) => {
    const user = useAppSelector((data) => data.user)
    const data = useAppSelector((data) => data.data)
    const [localized, setLocalized] = useState<any>()
    const [languages, setLanguages] = useState<Language[]>([])
    const defaultLanguageCode = 'it'

    useEffect(() => {
        if (data.languages.length === 0) return
        const items = [...data.languages]
        const reorderedItem = items.sort((a, b) => (a.id < b.id ? -1 : 1))
        setLanguages(reorderedItem)
    }, [data.languages])

    const translateData = async () => {
        const sourceLanguage = 'it'
        const targetsLanguages = languages.filter((i) => i.code !== sourceLanguage)?.map((i) => i.code)
        const defaultLocaleData = localized[sourceLanguage]

        const response = await translate(
            user.loggedUserData?.authToken ?? '',
            defaultLocaleData,
            sourceLanguage,
            targetsLanguages,
            []
        )

        const local = JSON.parse(JSON.stringify(localized))

        Object.keys(response).forEach((locale) => {
            local[locale] = response[locale]
            if (ignoreKeys) {
                ignoreKeys.forEach((ignoreKeys) => {
                    local[locale][ignoreKeys] = defaultLocaleData[ignoreKeys]
                })
            }
        })

        setLocalized(local)
    }

    useEffect(() => {
        const localizations: any = {}

        // eslint-disable-next-line max-len, array-callback-return
        const templateKeys = Object.keys(attributesSchema[Object.keys(attributesSchema)[0]])

        const templateNode: any = {}
        templateKeys.forEach((key) => {
            if (ignoreKeys && ignoreKeys.includes(key)) return
            templateNode[key] = ''
        })

        languages.forEach((language) => {
            if (attributesSchema[language.code] !== undefined) {
                if (ignoreKeys) {
                    ignoreKeys.forEach((ignoreKeys) => {
                        if (language.code !== defaultLanguageCode) {
                            delete attributesSchema[language.code][ignoreKeys]
                        }
                    })
                }
                localizations[language.code] = attributesSchema[language.code]
            } else {
                localizations[language.code] = templateNode
            }
        })
        setLocalized(localizations)
    }, [attributesSchema, languages])

    return (
        <div className="modal-wrapper">
            <div className="dark-cover" />

            <div className="translation-modal-component">
                <div className="translation-modal-component__header">{modalTitle}</div>
                <div className="translation-modal-component__body">
                    <div className="translations-container">
                        {localized &&
                            Object.keys(localized).length > 0 &&
                            languages.length > 0 &&
                            languages.map((lang) => (
                                <div
                                    className={`translations-container__translation-box ${
                                        lang.code === defaultLanguageCode ? 'default-language-box' : ''
                                    }`}
                                    key={lang.id}
                                >
                                    <div className="translations-container__translation-box__header">
                                        <UppercaseLabel>{lang.label}</UppercaseLabel>
                                        {lang.code === defaultLanguageCode && (
                                            <TextLabel customColor="#76AA86">
                                                <UppercaseLabel>Default</UppercaseLabel>
                                            </TextLabel>
                                        )}
                                    </div>

                                    <div className="translations-container__translation-box__fields-container">
                                        {Object.keys(localized[lang.code])
                                            .filter((key) => !ignoreKeys?.includes(key))
                                            .map((key) => {
                                                if (key === 'description')
                                                    // eslint-disable-next-line nonblock-statement-body-position
                                                    return (
                                                        <TextareaInput
                                                            key={key}
                                                            inputLabel={key}
                                                            value={localized[lang.code][key]}
                                                            onValueChange={(e) => {
                                                                const local = JSON.parse(JSON.stringify(localized))
                                                                local[lang.code][key] = e
                                                                setLocalized(local)
                                                            }}
                                                        />
                                                    )
                                                return (
                                                    <TextInput
                                                        key={key}
                                                        inputLabel={key}
                                                        value={localized[lang.code][key]}
                                                        onValueChange={(e) => {
                                                            const local = JSON.parse(JSON.stringify(localized))
                                                            local[lang.code][key] = e
                                                            setLocalized(local)
                                                        }}
                                                    />
                                                )
                                            })}
                                    </div>
                                    {lang.code === defaultLanguageCode && (
                                        <Button buttonType="secondary" onClick={async () => translateData()}>
                                            Traduci
                                        </Button>
                                    )}
                                </div>
                            ))}
                    </div>
                </div>
                <div className="translation-modal-component__footer">
                    <Button buttonType="secondary" onClick={() => onClose()}>
                        Annulla
                    </Button>
                    <Button buttonType="primary" onClick={() => onConfirm(localized)}>
                        Conferma
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default TranslationModal
