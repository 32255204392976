import React, { ReactNode, useState } from 'react'
import './modals.sass'
import Button from '../Buttons/Button'
import Checkbox from '../Checkbox/Checkbox'
import { ResultsEvaluation } from '../../types/data'
import Paragraph from '../Typography/Paragraph'

const evaluationsOptions = [
    {
        id: '0',
        label: '1: Formato non corretto (Posizione del soggetto, Zoom, Luci), risultato non sufficientemente visibile',
        message: 'Il risultato non verrà reso pubblico.',
        evaluation: ResultsEvaluation.ONE,
    },
    {
        id: '1',
        label: '2: Formato non corretto (Posizione del soggetto, Zoom, Luci), risultato sufficiente',
        message: 'Il risultato non verrà reso pubblico.',
        evaluation: ResultsEvaluation.TWO,
    },
    {
        id: '2',
        label: '3: Formato corretto (Posizione del soggetto, Zoom, Luci), risultato sufficiente',
        message: 'Il risultato verrà reso pubblico e consultabile da tutti gli utenti.',
        evaluation: ResultsEvaluation.THREE,
    },
    {
        id: '3',
        label: '3plus: Formato corretto e risultato eclatante',
        message:
            'Il risultato verrà reso pubblico e consultabile da tutti gli utenti e verrà utilizzato ai fini di esempio.',
        evaluation: ResultsEvaluation.THREE_PLUS,
    },
]

const EvaluationModal: React.FC<{
    modalTitle?: string | ReactNode
    modalMessage?: string | ReactNode
    onConfirm: (evaluation: ResultsEvaluation) => void
    onClose: () => void
}> = ({ modalTitle, onConfirm, onClose }) => {
    const [selectedOption, setSelectedOption] = useState('')

    const handleConfirm = () => {
        const selectedEvaluation = evaluationsOptions.find((evalOpt) => evalOpt.id === selectedOption)
        if (selectedEvaluation) onConfirm(selectedEvaluation.evaluation)
    }

    return (
        <div className="modal-wrapper">
            <div className="dark-cover" />

            <div className="modal-component">
                <div className="modal-component__header">
                    <span className="page-title">{modalTitle}</span>
                </div>
                <div className="modal-component__body">
                    <div>
                        {evaluationsOptions.map((option) => (
                            <div key={option.id}>
                                <Checkbox
                                    label={option.label}
                                    onCheckChange={() => {
                                        if (option.id === selectedOption) setSelectedOption('')
                                        setSelectedOption(option.id)
                                    }}
                                    checked={selectedOption === option.id}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="horizontal-line-separator" />
                    <Paragraph>
                        {evaluationsOptions.find((evalOpt) => evalOpt.id === selectedOption)?.message}
                    </Paragraph>
                </div>
                <div className="modal-component__footer">
                    <Button buttonType="secondary" onClick={() => onClose()}>
                        Annulla
                    </Button>
                    <Button buttonType="primary" onClick={() => handleConfirm()}>
                        Invia
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default EvaluationModal
