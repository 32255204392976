import { createAction } from '@reduxjs/toolkit'
import {
    AppType,
    Badge,
    Certification,
    Country,
    Currency,
    Device,
    InfoItem,
    Language,
    MediaScope,
    MediaTarget,
    MediaType,
    Priority,
    SimpleEntity,
} from '../../types/data'

export const setGoals = createAction<InfoItem[]>('data/setGoals')
export const setPriorities = createAction<Priority[]>('data/setPriorities')
export const setBodyAreas = createAction<InfoItem[]>('data/setBodyAreas')
export const setDevices = createAction<Device[]>('data/setDevices')
export const setCountries = createAction<Country[]>('data/setCountries')
export const setCurrencies = createAction<Currency[]>('data/setCurrencies')
export const setAppTypes = createAction<AppType[]>('data/setAppTypes')
export const setBadges = createAction<Badge[]>('data/setBadges')
export const setCertifications = createAction<Certification[]>('data/setCertifications')
export const setLanguages = createAction<Language[]>('data/setLanguages')
export const setMediaScopes = createAction<MediaScope[]>('data/setMediaScopes')
export const setMediaTargets = createAction<MediaTarget[]>('data/setMediaTargets')
export const setMediaTypes = createAction<MediaType[]>('data/setMediaTypes')
export const setLifeMoments = createAction<InfoItem[]>('data/setLifeMoments')
export const setPhisicalActivities = createAction<InfoItem[]>('data/setPhisicalActivities')
export const setSkinTypes = createAction<InfoItem[]>('data/setSkinTypes')
export const setFrequencies = createAction<SimpleEntity[]>('data/setFrequencies')
export const setSessionsDurations = createAction<SimpleEntity[]>('data/setSessionsDurations')
export const setSessionsNumbers = createAction<SimpleEntity[]>('data/setSessionsNumbers')
export const setPriorityIntensities = createAction<InfoItem[]>('data/setPriorityIntensities')
export const setCustomerGenders = createAction<InfoItem[]>('data/setCustomerGenders')
export const setBodySections = createAction<InfoItem[]>('data/setBodySections')
export const setHealthyDiets = createAction<InfoItem[]>('data/setHealthyDiets')
export const setStressLevels = createAction<InfoItem[]>('data/setStressLevels')
export const setChildbirthTypes = createAction<InfoItem[]>('data/setChildbirthTypes')
export const setAlcoholConsumptions = createAction<InfoItem[]>('data/setAlcoholConsumptions')
