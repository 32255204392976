import { Slide, toast } from 'react-toastify'

export const ToastSuccess = (text: string): React.ReactText =>
    toast.success(text, {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide
    })

export const ToastError = (text: string): React.ReactText =>
    toast.error(text, {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide
    })