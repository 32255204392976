import React, { useEffect, useState } from 'react'
import Table, { TableAction, TableColumn } from '../../components/Table/Table'
import TableHeader from '../../components/Table/TableHeader'
import Button from '../../components/Buttons/Button'
import AdvancedSearch from '../../components/AdvancedSearch/AdvancedSearch'
import MultiSelect, { SelectOption } from '../../components/Inputs/MultiSelect'
import { ROUTES } from '../../resources/routes-constants'
import { useNavigate } from 'react-router-dom'
import { AppType, EntityStatus, InfoItem, NotificationEntityType, Result, ResultsEvaluation } from '../../types/data'
import { useAppSelector } from '../../store/reducers/store'
import { ToastError } from '../../utility/toast'
import { deleteSingleResult } from '../../resources/api-constants'
import Link from '../../components/Link/Link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobileAlt, faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { confirmFilterObject, formatEntityStatusName, formatItemsForSelectOptions } from '../../utility/functions'
import AsyncMultiSelect from '../../components/Inputs/AsyncMultiSelect'
import AsyncSingleSelect from '../../components/Inputs/AsyncSingleSelect'
import StatusLabel from '../../components/Badges/StatusLabel'
import AlertModal from '../../components/Modal/AlertModal'
import ResultsMobile from './ResultsMobile'

export interface ResultsSearchFilters {
    center?: string
    statuses: (EntityStatus | string)[]
    types: string[]
    bodyAreas: string[]
    goals: string[]
    priorities: string[]
    protocols: string[]
    evaluations: ResultsEvaluation[]
}

const defaultFiltersState: ResultsSearchFilters = {
    center: '',
    statuses: [],
    types: [],
    bodyAreas: [],
    goals: [],
    priorities: [],
    protocols: [],
    evaluations: [],
}

export const evaluationOptions = [
    {
        value: '1',
        label: ResultsEvaluation.ONE,
    },
    {
        value: '2',
        label: ResultsEvaluation.TWO,
    },
    {
        value: '3',
        label: ResultsEvaluation.THREE,
    },
    {
        value: '4',
        label: ResultsEvaluation.THREE_PLUS,
    },
]

export const statusSelectOptions = [
    {
        value: EntityStatus.ACTIVE,
        label: 'Attivo',
    },
    {
        value: EntityStatus.CANCELED,
        label: 'Cancellato',
    },
    {
        value: EntityStatus.PENDING,
        label: 'In attesa',
    },
]

const ResultsPage: React.FC = () => {
    const user = useAppSelector((data) => data.user)
    const data = useAppSelector((data) => data.data)
    const [selectedResult, setSelectedResult] = useState<Result | null>(null)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [forceDeleteReload, setForceDeleteReload] = useState(false)
    const navigate = useNavigate()

    const [showAdvancedSearch, setShowAdvancedSearch] = useState(false)
    const [resetFlag, setResetFlag] = useState(false)
    const [resultFilters, setResultFilters] = useState<ResultsSearchFilters>(defaultFiltersState)
    const [finalFilters, setFinalFilters] = useState<ResultsSearchFilters | undefined>(undefined)
    const [mobileView, setMobileView] = useState(false)

    useEffect(() => {
        if (resetFlag) setResetFlag(!resetFlag)
    }, [resetFlag])

    const resetSearchParams = () => {
        if (JSON.stringify(resultFilters) === JSON.stringify(defaultFiltersState)) return
        setResetFlag(true)
        setResultFilters(defaultFiltersState)
        setFinalFilters(confirmFilterObject(defaultFiltersState))
    }

    const formatItemsForStatuses = (items: EntityStatus[] | undefined): SelectOption[] | undefined => {
        if (!items || items.length === 0) return
        return items.map((item) => ({ value: item, label: formatEntityStatusName(item) }))
    }

    const formatItemsForEvaluations = (items: ResultsEvaluation[] | undefined): SelectOption[] | undefined => {
        if (!items || items.length === 0) return
        return items.map((item) => ({
            value: evaluationOptions.find((evaluation) => evaluation.label === item)?.value || '-1',
            label: item,
        }))
    }

    const startDeleteProcedure = (result: Result) => {
        setSelectedResult(result)
        setShowDeleteModal(true)
    }

    const endRemoveResultProcedure = () => {
        setSelectedResult(null)
        setShowDeleteModal(false)
        setForceDeleteReload(true)
    }

    const removeResult = async () => {
        try {
            const res = await deleteSingleResult(user.loggedUserData?.authToken || '', selectedResult?.id || '')
            if (res) endRemoveResultProcedure()
            else ToastError('Si è verificato un errore durante la procedura di eliminazione!')
        } catch (error) {
            console.error(error)
        }
    }

    const columns: TableColumn[] = [
        {
            title: '',
            field: '',
            sorting: false,
            render: (rowData: Result) => (
                <div className="notification-dot-container">
                    {rowData.notifications &&
                        rowData.notifications.length > 0 &&
                        rowData.notifications.map((notification) => (
                            <div
                                key={notification.id}
                                className={`notification-dot ${notification.type.toLowerCase()}-notification`}
                            />
                        ))}
                </div>
            ),
            width: '3%',
        },
        {
            title: 'ID',
            field: 'id',
            render: (rowData: Result) => (
                <Link internalRoute={`${ROUTES.RESULT_DETAIL_ROUTE}${rowData.id}`}>RIS-{rowData.id}</Link>
            ),
        },
        {
            title: 'Stato',
            field: 'status',
            render: (rowData: Result) => {
                const evaluationSuffix = () => {
                    switch (rowData.status) {
                        case EntityStatus.ACTIVE:
                        case EntityStatus.CANCELED:
                            return <StatusLabel status={rowData.status} label={rowData.evaluation} />
                        case EntityStatus.PENDING:
                        default:
                            return <></>
                    }
                }
                return (
                    <div style={{ display: 'flex', gap: 8 }}>
                        <StatusLabel status={rowData.status} />
                        {evaluationSuffix()}
                    </div>
                )
            },
        },

        {
            title: 'Nazione',
            field: 'country',
            sorting: false,
            render: (rowData: Result) => {
                if (!rowData.center) return <></>
                const country = data.countries.find((country) => country.id === rowData.center?.idCountry)
                if (!country) return <></>
                return <p>{country.name}</p>
            },
        },
        {
            title: 'Centro',
            field: 'center',
            sorting: false,
            render: (rowData: Result) => {
                if (rowData.center)
                    // eslint-disable-next-line nonblock-statement-body-position
                    return (
                        <Link internalRoute={`${ROUTES.CENTER_DETAIL_ROUTE}${rowData.center?.id}`}>
                            {rowData.center.name}
                        </Link>
                    )
                return <p>ICOONE RESULTS LAB</p>
            },
        },
        {
            title: 'Data di invio',
            field: 'creation_date',
            render: (rowData: Result) => <p>{new Date(rowData.creationDate).toLocaleDateString()}</p>,
        },
    ]

    const actions: TableAction[] = [
        (rowData: Result) => ({
            icon: () => <FontAwesomeIcon icon={faPen} size="xs" />,
            onClick: () => navigate(`${ROUTES.RESULT_DETAIL_ROUTE}${rowData.id}`),
            tooltip: 'Modifica',
        }),
        (rowData: Result) => ({
            icon: () => <FontAwesomeIcon icon={faTrash} size="xs" />,
            onClick: () => startDeleteProcedure(rowData),
            tooltip: 'Elimina',
        }),
    ]

    return (
        <>
            {showDeleteModal && (
                <AlertModal
                    modalTitle="Elimina risultato"
                    modalMessage={`Sei sicuro di voler eliminare l'elemento RIS-${selectedResult?.id}? Questa operazione è irreversibile.`}
                    onClose={() => setShowDeleteModal(false)}
                    onConfirm={() => void removeResult()}
                />
            )}
            <div className="page-header-section">
                <div className="page-header-section__left-box">
                    <span className="page-title">Elenco risultati</span>
                </div>
                <div className="page-header-section__right-box">
                    {!mobileView && (
                        <>
                            <Button
                                customClassName={`${showAdvancedSearch ? 'active' : ''}`}
                                buttonType="ghost"
                                onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
                            >
                                Ricerca avanzata
                            </Button>
                            <Button buttonType="primary" onClick={() => navigate(`${ROUTES.RESULT_DETAIL_ROUTE}new`)}>
                                Carica risultato
                            </Button>
                        </>
                    )}

                    <Button
                        buttonType={mobileView ? 'primary' : 'secondary'}
                        onClick={() => setMobileView(!mobileView)}
                    >
                        <FontAwesomeIcon icon={faMobileAlt} />
                    </Button>
                </div>
            </div>
            <div className="page-content-block">
                <AdvancedSearch
                    isVisible={showAdvancedSearch}
                    onReset={() => resetSearchParams()}
                    onSearch={() => setFinalFilters(confirmFilterObject(resultFilters))}
                >
                    <div className="input-form-box__three-col-row">
                        <AsyncSingleSelect
                            reset={resetFlag}
                            inputLabel="centro"
                            placeholder="Seleziona un centro"
                            options={[]}
                            endpoint="centers"
                            userToken={user.loggedUserData?.authToken}
                            onValueChange={(newVal) => setResultFilters({ ...resultFilters, center: newVal?.value })}
                            forcedItems={[{ value: 'null', label: 'ICOONE RESULTS LAB' }]}
                        />
                        <MultiSelect
                            reset={resetFlag}
                            inputLabel="stato"
                            placeholder="Seleziona uno o più stati"
                            options={statusSelectOptions}
                            values={formatItemsForStatuses(
                                statusSelectOptions
                                    .filter((status) => resultFilters.statuses.indexOf(status.value) !== -1)
                                    .map((val) => val.value)
                            )}
                            onValueChange={(newVals) =>
                                setResultFilters({ ...resultFilters, statuses: newVals.map((val) => val.value) })
                            }
                        />
                        <MultiSelect
                            reset={resetFlag}
                            inputLabel="tipologia"
                            placeholder="Seleziona una o più tipologie"
                            options={data.appTypes.map((appType) => ({
                                value: appType.id.toString(),
                                label: appType.name,
                            }))}
                            values={formatItemsForSelectOptions<AppType>(
                                data.appTypes.filter((appType) => resultFilters.types.indexOf(appType.name) !== -1)
                            )}
                            onValueChange={(newVals) =>
                                setResultFilters({ ...resultFilters, types: newVals.map((val) => val.value) })
                            }
                        />
                        <MultiSelect
                            reset={resetFlag}
                            inputLabel="area interessata"
                            placeholder="Seleziona una o più aree del corpo"
                            options={data.bodyAreas.map((bodyArea) => ({
                                value: bodyArea.id.toString(),
                                label: bodyArea.name,
                            }))}
                            values={formatItemsForSelectOptions<InfoItem>(
                                data.bodyAreas.filter(
                                    (bodyArea) => resultFilters.bodyAreas.indexOf(bodyArea.name) !== -1
                                )
                            )}
                            onValueChange={(newVals) =>
                                setResultFilters({ ...resultFilters, bodyAreas: newVals.map((val) => val.value) })
                            }
                        />
                        <MultiSelect
                            reset={resetFlag}
                            inputLabel="obiettivi"
                            placeholder="Seleziona uno o più obiettivi"
                            options={data.goals.map((goal) => ({
                                value: goal.id.toString(),
                                label: goal.name,
                            }))}
                            values={formatItemsForSelectOptions<InfoItem>(
                                data.goals.filter((goal) => resultFilters.goals.indexOf(goal.name) !== -1)
                            )}
                            onValueChange={(newVals) =>
                                setResultFilters({ ...resultFilters, goals: newVals.map((val) => val.value) })
                            }
                        />
                        <MultiSelect
                            reset={resetFlag}
                            inputLabel="problemi da risolvere"
                            placeholder="Seleziona uno o più problemi"
                            options={data.priorities.map((problem) => ({
                                value: problem.id.toString(),
                                label: problem.name,
                            }))}
                            values={formatItemsForSelectOptions<InfoItem>(
                                data.priorities.filter(
                                    (problem) => resultFilters.priorities.indexOf(problem.name) !== -1
                                )
                            )}
                            onValueChange={(newVals) =>
                                setResultFilters({ ...resultFilters, priorities: newVals.map((val) => val.value) })
                            }
                        />
                        <AsyncMultiSelect
                            reset={resetFlag}
                            inputLabel="protocolli"
                            placeholder="Seleziona uno o più protocolli"
                            options={[]}
                            endpoint="protocols"
                            userToken={user.loggedUserData?.authToken}
                            onValueChange={(newVals) =>
                                setResultFilters({ ...resultFilters, protocols: newVals.map((val) => val.value) })
                            }
                        />
                        <MultiSelect
                            reset={resetFlag}
                            inputLabel="valutazione"
                            placeholder="Seleziona una o più valutazioni"
                            options={evaluationOptions}
                            values={formatItemsForEvaluations(
                                evaluationOptions
                                    .filter((evaluation) => resultFilters.evaluations.indexOf(evaluation.label) !== -1)
                                    .map((val) => val.label)
                            )}
                            onValueChange={(newVals) =>
                                setResultFilters({
                                    ...resultFilters,
                                    evaluations: newVals.map((val) => val.label as ResultsEvaluation),
                                })
                            }
                        />
                    </div>
                </AdvancedSearch>
                {mobileView ? (
                    <ResultsMobile />
                ) : (
                    <div className="relative">
                        <TableHeader positionIndex={23} />
                        <Table
                            actions={actions}
                            columns={columns}
                            endpoint="results"
                            reloads={resetFlag}
                            forceDeleteReload={forceDeleteReload}
                            notificationFetcher={{ entity: NotificationEntityType.results }}
                            filterParams={finalFilters}
                        />
                    </div>
                )}
            </div>
        </>
    )
}

export default ResultsPage
