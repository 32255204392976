import React, { useEffect, useMemo, useRef, useState } from 'react'
import Paragraph from '../../components/Typography/Paragraph'
import TextInput from '../../components/Inputs/TextInput'
import MainLogoContainer from '../../components/MainLogoContainer/MainLogoContainer'
import Link from '../../components/Link/Link'
import { ROUTES } from '../../resources/routes-constants'
import Button from '../../components/Buttons/Button'
import '../../styles/Pages/authPages.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { performUserLogin } from '../../store/actions/thunkActions'
import { mailRegex } from '../../resources/other-constants'
import { useAppDispatch } from '../../store/reducers/store'
import { ToastError } from '../../utility/toast'
import ProjectVersionTag from '../../components/ProjectVersionTag/ProjectVersionTag'
import { useKeyDetect } from '../../utility/customHooks'

const LoginPage: React.FC = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [loading, setLoading] = useState(false)
    const dispatch = useAppDispatch()
    const detectedKey = useKeyDetect()
    const formRef = useRef<any>(null)

    useEffect(() => {
        if (
            detectedKey &&
            detectedKey.key === 'Enter' &&
            formRef.current &&
            formRef.current.contains(document.activeElement)
        )
            // eslint-disable-next-line nonblock-statement-body-position
            void startLoginProcedure()
    }, [detectedKey, formRef.current])

    const dataIsNotValid = useMemo(() => {
        return !email || !password || !mailRegex.test(email.trim())
    }, [email, password])

    const startLoginProcedure = async () => {
        setLoading(true)
        const loginError = await dispatch(performUserLogin({ email: email.trim(), password }))
        if ((loginError as any).error) {
            ToastError("L'email o la password inserite sembrano errate, per favore riprova.")
        }
        setLoading(false)
    }

    return (
        <div className="center-box full-height-page">
            <div ref={formRef} className="auth-form-container">
                <MainLogoContainer />
                <Paragraph>Accedi al pannello amministrativo di ICOONE.</Paragraph>
                <TextInput
                    value={email}
                    placeholder="Email"
                    type="email"
                    onValueChange={(userEmail) => setEmail(userEmail)}
                />
                <TextInput
                    value={password}
                    placeholder="Password"
                    type={showPassword ? 'text' : 'password'}
                    onValueChange={(userPassword) => setPassword(userPassword)}
                    iconButton={
                        <FontAwesomeIcon
                            icon={showPassword ? faEyeSlash : faEye}
                            onClick={() => setShowPassword(!showPassword)}
                            style={{ cursor: 'pointer' }}
                        />
                    }
                />
                <Button disabled={dataIsNotValid} loading={loading} onClick={() => void startLoginProcedure()}>
                    Accedi
                </Button>
                <Link externalRoute="#">Privacy Policy</Link>
                <Link internalRoute={ROUTES.REQUEST_RESET_PASSWORD_ROUTE}>Password dimenticata?</Link>
            </div>
            <div className="auth-page-version-number">
                <ProjectVersionTag />
            </div>
        </div>
    )
}

export default LoginPage
