import React from 'react'
import './TabsSelector.sass'
import { NotificationEntityType } from '../../types/data'

export interface Tab {
    value: string
    label: string
    notification?: {
        value: number
        type: NotificationEntityType
    }
}

const TabsSelector: React.FC<{ tabs: Tab[]; activeTabValue: string; onChangeTab: (selectedTab: Tab) => void }> = ({
    tabs,
    activeTabValue,
    onChangeTab,
}) => {
    return (
        <div className="tabs-selector-wrapper typography-uppercase-label-text normal-text">
            {tabs.map((tab) => (
                <div
                    className={`tabs-selector-wrapper__tab-button ${activeTabValue === tab.value ? 'active' : ''}`}
                    onClick={() => onChangeTab(tab)}
                    key={tab.value}
                >
                    <div className="notification-dot-container">
                        {tab.label}
                        {tab.notification && tab.notification.value > 0 && (
                            <div
                                className={`notification-dot no-overlap ${tab.notification.type.toLowerCase()}-notification no-overlap`}
                            />
                        )}
                    </div>
                </div>
            ))}
        </div>
    )
}

export default TabsSelector
