import React, { useEffect, useState } from 'react'
import { Result } from '../../types/data'
import { getCommunityResults, getResultMedia } from '../../resources/api-constants'
import { useAppSelector } from '../../store/reducers/store'
import Paragraph from '../../components/Typography/Paragraph'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { ROUTES } from '../../resources/routes-constants'
import { useNavigate } from 'react-router-dom'
const ResultsMobile: React.FC = () => {
    const navigate = useNavigate()
    const user = useAppSelector((data) => data.user)
    const [results, setResults] = useState<Result[]>([])

    const fetchResults = async () => {
        try {
            const items = await getCommunityResults(user.loggedUserData?.authToken || '', '')
            if (items) {
                await Promise.all(
                    items.items.map(async (result) => {
                        result.media = await getResultMedia(user.loggedUserData?.authToken || '', result.id)
                        if (result.media === undefined || result.media.length === 0) {
                            result.media = [
                                {
                                    url: 'https://icoone-media.s3.eu-west-1.amazonaws.com/defaults/badge_avatar_placeholder.png',
                                    updateDate: '',
                                    sessionIndex: 0,
                                    idResult: parseInt(result.id, 10),
                                    idBodyPart: parseInt(result.bodyAreas[0].id, 10),
                                    sessionDate: '',
                                    creationDate: '',
                                },
                                {
                                    url: 'https://icoone-media.s3.eu-west-1.amazonaws.com/defaults/badge_avatar_placeholder.png',
                                    updateDate: '',
                                    sessionIndex: result.sessionsNumber - 1,
                                    idResult: parseInt(result.id, 10),
                                    idBodyPart: parseInt(result.bodyAreas[0].id, 10),
                                    sessionDate: '',
                                    creationDate: '',
                                },
                            ]
                        }
                        if (result.media[0].url === undefined) {
                            result.media[0].url =
                                'https://icoone-media.s3.eu-west-1.amazonaws.com/defaults/badge_avatar_placeholder.png'
                        }
                        if (result.media.length === 1) {
                            result.media.push({
                                url: 'https://icoone-media.s3.eu-west-1.amazonaws.com/defaults/badge_avatar_placeholder.png',
                                updateDate: '',
                                sessionIndex: result.sessionsNumber - 1,
                                idResult: parseInt(result.id, 10),
                                idBodyPart: parseInt(result.bodyAreas[0].id, 10),
                                sessionDate: '',
                                creationDate: '',
                            })
                        }
                    })
                )
                setResults(items.items)
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        void fetchResults()
    }, [])

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <div
                style={{
                    width: '50%',
                    height: 800,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: 'black',
                    borderRadius: 30,
                    border: '5px solid gray',
                }}
            >
                <div
                    style={{
                        width: '90%',
                        maxHeight: '90%',
                        margin: '4%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        backgroundColor: 'rgba(255, 255, 255, .9)',
                        borderRadius: 30,
                        border: '5px solid gray',
                        overflow: 'scroll',
                    }}
                >
                    <div style={{ width: '90%' }}>
                        <p style={{ left: 4, fontWeight: 600, fontSize: 25 }}>Migliori risultati</p>
                    </div>

                    {results.map((result) => (
                        <div
                            onClick={() => navigate(`${ROUTES.RESULT_DETAIL_ROUTE}${result.id}`)}
                            key={result.id}
                            style={{
                                width: '90%',
                                height: 500,
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '1%',
                                backgroundColor: 'rgba(0, 0, 0, .03)',
                                marginBottom: '2%',
                                borderRadius: 10,
                                borderColor: 'red',
                                borderWidth: 1,
                                padding: 10,
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    height: 80,
                                    backgroundColor: '#ffffff',
                                    width: '100%',
                                    borderRadius: 10,
                                    padding: '0 20px',
                                }}
                            >
                                {result.center !== null ? (
                                    <>
                                        {' '}
                                        <p style={{ fontWeight: 600, fontSize: '1.2em' }}>{result.center?.name}</p>
                                        <div
                                            style={{
                                                display: 'flex',
                                                gap: 10,
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <p style={{ fontWeight: 600, fontSize: '1.2em' }}>{result.center?.stars}</p>
                                            <FontAwesomeIcon icon={faStar} />
                                        </div>{' '}
                                    </>
                                ) : (
                                    <p style={{ fontWeight: 600, fontSize: '1.2em' }}>ICOONE RESULTS LAB</p>
                                )}
                            </div>
                            {result.media && result.media.length > 0 && (
                                <div style={{ height: '100%', overflow: 'hidden', borderRadius: 10, display: 'flex' }}>
                                    <div
                                        style={{
                                            width: '50%',
                                            justifyContent: 'center',
                                            display: 'flex',
                                            position: 'relative',
                                        }}
                                    >
                                        <img
                                            src={
                                                result.media[0].url ??
                                                'https://icoone-media.s3.eu-west-1.amazonaws.com/defaults/badge_avatar_placeholder.png'
                                            }
                                            alt={result.media[0].url}
                                            style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                                        />
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignSelf: 'center',
                                                position: 'absolute',
                                                bottom: 10,
                                                backgroundColor: '#b1b5b5',
                                                padding: '0px 30px',
                                                borderRadius: 30,
                                                color: 'white',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <p>Prima</p>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            width: '50%',
                                            justifyContent: 'center',
                                            display: 'flex',
                                            position: 'relative',
                                        }}
                                    >
                                        <img
                                            src={
                                                result.media[1].url ??
                                                'https://icoone-media.s3.eu-west-1.amazonaws.com/defaults/badge_avatar_placeholder.png'
                                            }
                                            alt={result.media[1].url}
                                            style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                                        />
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignSelf: 'center',
                                                position: 'absolute',
                                                bottom: 10,
                                                backgroundColor: '#D8C198',
                                                padding: '0px 30px',
                                                borderRadius: 30,
                                                color: 'white',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <p>Dopo</p>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '20%',
                                    backgroundColor: '#F6F8F8',
                                    width: '100%',
                                    borderRadius: 10,
                                    padding: '0 10px',
                                }}
                            >
                                {result.goals?.map((goal) => (
                                    <div
                                        key={goal.id}
                                        style={{
                                            backgroundColor: '#D8C198',
                                            padding: 10,
                                            borderRadius: 30,
                                            color: 'white',
                                        }}
                                    >
                                        <Paragraph>{goal.name}</Paragraph>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ResultsMobile
