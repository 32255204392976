import React, { useCallback, useEffect, useRef, useState } from 'react'
import ChatUserInput from '../../components/ChatUserInput/ChatUserInput'
import ChatList, { ChatListElement } from '../ChatList/ChatList'
import './ChatPanel.sass'
import { EntityType, NotificationEntityType, NotificationType } from '../../types/data'
import useChat, { groupListByDate } from '../../utility/hooks/useChat'
import { useAppSelector } from '../../store/reducers/store'
import Button from '../Buttons/Button'
import { markNotificationAsRead } from '../../resources/api-constants'

interface Props {
    centerId: string
}

const ChatPanel: React.FC<Props> = (props) => {
    const user = useAppSelector((data) => data.user)
    const notification = useAppSelector((appData) => appData.notification.items)
    const graphic = useAppSelector((data) => data.graphic)
    const listRef = useRef<ChatListElement | null>(null)

    const [messageText, setMessageText] = useState<string>('')

    const [list, loading, sendMessage, onLoadMore, fetchHistory] = useChat(EntityType.center, props.centerId || '')

    const markAllMessagesAsRead = useCallback(async () => {
        const currentCenterMessageNotifications = notification.filter(
            (notif) =>
                notif.idEntity === props.centerId.toString() &&
                notif.entity === NotificationEntityType.center &&
                notif.type === NotificationType.message
        )

        await Promise.all(
            currentCenterMessageNotifications.map(
                async (notif) => await markNotificationAsRead(user.loggedUserData?.authToken || '', notif.id)
            )
        )
    }, [notification, props.centerId])

    useEffect(() => {
        void markAllMessagesAsRead()
    }, [notification])

    useEffect(() => {
        if (props.centerId && props.centerId !== '-1') {
            void fetchHistory()
        }
    }, [props.centerId])

    const groupedList = groupListByDate(list)

    const onSendMessage = useCallback(() => {
        // eslint-disable-next-line no-async-promise-executor
        void new Promise<void>(async (resolve) => {
            if (!props.centerId) return
            await sendMessage(messageText)
            resolve()
        }).then(() =>
            setTimeout(() => {
                listRef.current?.scrollToBottom()
                setMessageText('')
            }, 100)
        )

        setTimeout(() => {
            listRef.current?.scrollToBottom()
            setMessageText('')
        }, 100)
    }, [messageText, listRef, props.centerId])

    return (
        <div className="chat-panel-wrapper">
            <ChatList
                ref={listRef}
                messageList={groupedList}
                onSendError={(id: any) => {
                    const foundMessage = list.find((message) => message.id === `${parseInt(id, 10)}-request`)

                    if (!props.centerId) return
                    void sendMessage(foundMessage?.message ?? '')
                }}
                loading={loading}
                onLoadMore={() => void onLoadMore()}
            />
            <div
                className={`chat-input-section ${
                    graphic.sidebarIsCollapsed ? 'remove-left-margin' : 'add-left-margin'
                }`}
            >
                <div className="chat-input-section__inner">
                    <ChatUserInput
                        value={messageText}
                        onChange={(newVal) => setMessageText(newVal)}
                        handleSendMessage={() => onSendMessage()}
                    />
                    <div
                        className={`chat-input-section__inner__button chat-input-section__inner__button--primary ${
                            messageText ? '' : 'disabled-chat-input-button'
                        }`}
                        onClick={() => onSendMessage()}
                    >
                        <Button buttonType="primary">Invia</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChatPanel
