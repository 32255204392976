import React, { ReactNode, useEffect, useState } from 'react'
import './modals.sass'
import Button from '../Buttons/Button'
import { Device, EntityStatus } from '../../types/data'
import MultiSelect from '../Inputs/MultiSelect'

const PendingDeviceModal: React.FC<{
    modalTitle?: string | ReactNode
    modalMessage?: string | ReactNode
    devices: Device[]
    onConfirm: (devices: Device[]) => void
    onClose: () => void
}> = ({ modalTitle, devices, onConfirm, onClose }) => {
    const [resetDeviceSelectors, setResetDeviceSelectors] = useState(false)
    const [selectedDevices, setSelectedDevices] = useState<string[]>([])
    const [isFirstTime, setIsFirstTime] = useState(true)

    const handleConfirm = () => {
        const items: Device[] = selectedDevices.map(
            (device) => devices.find((d) => d.id.toString() === device) || devices[0]
        )

        onConfirm(
            items.map((device: Device) => {
                return { ...device, status: 'ACTIVE' as EntityStatus }
            })
        )
    }

    useEffect(() => {
        if (devices && devices.length > 0 && isFirstTime) {
            setIsFirstTime(false)
            setSelectedDevices(devices.map((device) => device.id.toString()))
        }
    }, [devices, isFirstTime])

    return (
        <div className="modal-wrapper">
            <div className="dark-cover" />

            <div className="modal-component">
                <div className="modal-component__header">
                    <span className="page-title">{modalTitle}</span>
                </div>
                
                <div className="modal-component__body">
                    <div className="horizontal-line-separator" />
                    <MultiSelect
                        inputLabel={'DISPOSITIVI'}
                        placeholder="Seleziona uno o più dispositivi"
                        reset={resetDeviceSelectors}
                        values={selectedDevices.map((device) => ({
                            value: device,
                            label: `${devices.find((d) => d.id.toString() === device)?.name || ''} (S.N.: ${devices.find((d) => d.id.toString() === device)?.serialNumber || ''})`,
                        }))}
                        options={devices
                            .filter((device) => !selectedDevices.includes(device.id.toString()))
                            .map((device) => ({ value: device.id.toString(), label: `${device.name} (S.N.: ${device.serialNumber}) ` }))}
                        onValueChange={(selectedOption, action) => {
                            if (action === 'select-option') {
                                setSelectedDevices([
                                    ...selectedDevices,
                                    ...(selectedOption.map((opt) => opt.value) || []),
                                ])
                            }
                            if (action === 'remove-value') {
                                setSelectedDevices(selectedOption.map((opt) => opt.value) || [])

                                return
                            }
                            setResetDeviceSelectors(true)
                        }}
                        hideSelectedOptions
                        closeMenuOnSelect={false}
                    />
                </div>
                <div className="modal-component__footer">
                    <Button buttonType="secondary" onClick={() => onClose()}>
                        Annulla
                    </Button>
                    <Button buttonType="primary" onClick={() => handleConfirm()}>
                        Invia
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default PendingDeviceModal
