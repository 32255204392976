import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { EntityStatus, NotificationEntityType, Result } from '../../../types/data'
import Table, { TableAction, TableColumn } from '../../../components/Table/Table'
import { ROUTES } from '../../../resources/routes-constants'
import Link from '../../../components/Link/Link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faEye } from '@fortawesome/free-solid-svg-icons'
import TableHeader from '../../../components/Table/TableHeader'
import Checkbox from '../../../components/Checkbox/Checkbox'
import StatusLabel from '../../../components/Badges/StatusLabel'

const CenterResults: React.FC<{ centerId: string }> = ({ centerId }) => {
    const [showOnlyPendingResults, setShowOnlyPendingResults] = useState(false)
    const [finalFilters, setFinalFilters] = useState<{ statuses: string[]; center: string } | undefined>({
        statuses: [EntityStatus.PENDING, EntityStatus.ACTIVE, EntityStatus.CANCELED],
        center: centerId,
    })
    const [resetFlag, setResetFlag] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        if (resetFlag) setResetFlag(!resetFlag)
    }, [resetFlag])

    const columns: TableColumn[] = [
        {
            title: '',
            field: '',
            render: (rowData: Result) => (
                <div className="notification-dot-container">
                    {rowData.notifications &&
                        rowData.notifications.length > 0 &&
                        rowData.notifications.map((notification) => (
                            <div
                                key={notification.id}
                                className={`notification-dot ${notification.type.toLowerCase()}-notification`}
                            />
                        ))}
                </div>
            ),
            width: '3%',
        },
        {
            title: 'ID',
            field: 'id',
            render: (data: Result) => (
                <Link internalRoute={`${ROUTES.RESULT_DETAIL_ROUTE}${data.id}`}>RIS-{data.id}</Link>
            ),
        },
        {
            title: 'Stato',
            field: 'status',
            render: (data: Result) => <StatusLabel status={data.status} key={data.id} />,
        },
        {
            title: 'Data di invio',
            field: 'sendDate',
            render: (data: Result) => <p>{new Date(data.creationDate).toLocaleDateString()}</p>,
        },
    ]

    const actions: TableAction[] = [
        (rowData: Result) => ({
            icon: () => <FontAwesomeIcon icon={rowData.evaluation ? faEye : faPen} size="xs" />,
            onClick: () => navigate(`${ROUTES.RESULT_DETAIL_ROUTE}${rowData.id}`),
            tooltip: rowData.evaluation ? 'Visualizza' : 'Valuta',
        }),
    ]

    return (
        <>
            <div className="page-header-section">
                <div className="page-header-section__left-box" />

                <div className="page-header-section__right-box">
                    <Checkbox
                        label="Mostra solo risultati in attesa"
                        checked={showOnlyPendingResults}
                        onCheckChange={(val) => {
                            setShowOnlyPendingResults(val)
                            // setResetFlag(true)
                            if (val) {
                                setFinalFilters({ statuses: [EntityStatus.PENDING], center: centerId })
                            } else {
                                setFinalFilters({
                                    statuses: [EntityStatus.PENDING, EntityStatus.ACTIVE, EntityStatus.CANCELED],
                                    center: centerId,
                                })
                            }
                        }}
                    />
                </div>
            </div>
            <div className="page-content-block">
                <div className="relative">
                    <TableHeader positionIndex={23} />
                    <Table
                        actions={actions}
                        reloads={resetFlag}
                        columns={columns}
                        endpoint="results"
                        filterParams={finalFilters}
                        notificationFetcher={{ entity: NotificationEntityType.results }}
                    />
                </div>
            </div>
        </>
    )
}

export default CenterResults
