import React, { useEffect, useState } from 'react'
import Table, { TableAction, TableColumn } from '../../../components/Table/Table'
import TableHeader from '../../../components/Table/TableHeader'
import Button from '../../../components/Buttons/Button'
import AdvancedSearch from '../../../components/AdvancedSearch/AdvancedSearch'
import TextInput from '../../../components/Inputs/TextInput'
import { ROUTES } from '../../../resources/routes-constants'
import { useNavigate } from 'react-router-dom'
import { Badge, Dealer } from '../../../types/data'
import { useAppSelector } from '../../../store/reducers/store'
import { ToastError } from '../../../utility/toast'
import Link from '../../../components/Link/Link'
import { deleteSingleBadge } from '../../../resources/api-constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { confirmFilterObject } from '../../../utility/functions'
import AlertModal from '../../../components/Modal/AlertModal'

interface BadgeSearchFilters {
    name: string
}

const defaultFiltersState: BadgeSearchFilters = {
    name: '',
}

const BadgesPage: React.FC = () => {
    const user = useAppSelector((data) => data.user)
    const [selectedBadge, setselectedBadge] = useState<Dealer | null>(null)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [forceDeleteReload, setForceDeleteReload] = useState(false)
    const navigate = useNavigate()

    const [showAdvancedSearch, setShowAdvancedSearch] = useState(false)
    const [resetFlag, setResetFlag] = useState(false)
    const [badgeFilters, setBadgeFilters] = useState<BadgeSearchFilters>(defaultFiltersState)
    const [finalFilters, setFinalFilters] = useState<BadgeSearchFilters | undefined>(undefined)

    useEffect(() => {
        if (resetFlag) setResetFlag(!resetFlag)
    }, [resetFlag])

    useEffect(() => {
        if (forceDeleteReload) setForceDeleteReload(!forceDeleteReload)
    }, [forceDeleteReload])

    const resetSearchParams = () => {
        if (JSON.stringify(badgeFilters) === JSON.stringify(defaultFiltersState)) return
        setResetFlag(true)
        setBadgeFilters(defaultFiltersState)
        setFinalFilters(confirmFilterObject(defaultFiltersState))
    }

    const startDeleteProcedure = (dealer: Dealer) => {
        setselectedBadge(dealer)
        setShowDeleteModal(true)
    }

    const endRemoveProcedure = () => {
        setselectedBadge(null)
        setShowDeleteModal(false)
        setForceDeleteReload(true)
    }

    const removeDealer = async () => {
        try {
            const res = await deleteSingleBadge(user.loggedUserData?.authToken || '', selectedBadge?.id || '')
            if (res) endRemoveProcedure()
            else ToastError('Si è verificato un errore durante la procedura di eliminazione!')
        } catch (error) {
            console.error(error)
        }
    }

    const columns: TableColumn[] = [
        {
            title: 'Nome',
            field: 'name',
            render: (data: Badge) => <Link internalRoute={`${ROUTES.BADGE_DETAIL_ROUTE}${data.id}`}>{data.name}</Link>,
        },
        {
            title: 'Descrizione',
            field: 'description',
            render: (data: Badge) => <p>{data.description}</p>,
        },
        {
            title: 'Stars',
            field: 'stars',
            render: (data: Badge) => <p>{data.stars}</p>,
        },
    ]

    const actions: TableAction[] = [
        (rowData: Dealer) => ({
            icon: () => <FontAwesomeIcon icon={faPen} size="xs" />,
            onClick: () => navigate(`${ROUTES.BADGE_DETAIL_ROUTE}${rowData.id}`),
            tooltip: 'Modifica',
        }),
        (rowData: Dealer) => ({
            icon: () => <FontAwesomeIcon icon={faTrash} size="xs" />,
            onClick: () => startDeleteProcedure(rowData),
            tooltip: 'Elimina',
        }),
    ]

    return (
        <>
            {showDeleteModal && (
                <AlertModal
                    modalTitle="Elimina badge"
                    modalMessage={`Sei sicuro di voler eliminare l'elemento ${
                        selectedBadge?.name || ''
                    }?\nTutti i centri che hanno ottenuto questo badge lo perderanno. Questa operazione è irreversibile.`}
                    onClose={() => setShowDeleteModal(false)}
                    onConfirm={() => void removeDealer()}
                />
            )}
            <div className="page-header-section">
                <div className="page-header-section__left-box">
                    <span className="page-title">Elenco badge</span>
                </div>
                <div className="page-header-section__right-box">
                    <Button
                        customClassName={`${showAdvancedSearch ? 'active' : ''}`}
                        buttonType="ghost"
                        onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
                    >
                        Ricerca avanzata
                    </Button>
                    <Button buttonType="primary" onClick={() => navigate(`${ROUTES.BADGE_DETAIL_ROUTE}new`)}>
                        Crea nuovo badge
                    </Button>
                </div>
            </div>
            <div className="page-content-block">
                <AdvancedSearch
                    isVisible={showAdvancedSearch}
                    onReset={() => resetSearchParams()}
                    onSearch={() => setFinalFilters(confirmFilterObject(badgeFilters))}
                >
                    <div className="input-form-box__three-col-row">
                        <TextInput
                            value={badgeFilters.name}
                            inputLabel="nome"
                            placeholder="Cerca per nome"
                            onValueChange={(newVal) => setBadgeFilters({ ...badgeFilters, name: newVal })}
                        />
                    </div>
                </AdvancedSearch>
                <div className="relative">
                    <TableHeader positionIndex={23} />
                    <Table
                        actions={actions}
                        columns={columns}
                        reloads={resetFlag}
                        forceDeleteReload={forceDeleteReload}
                        filterParams={finalFilters}
                        endpoint="badges"
                    />
                </div>
            </div>
        </>
    )
}

export default BadgesPage
