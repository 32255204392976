import React, { useCallback, useEffect, useState } from 'react'
import MaterialTable from '@material-table/core'
import { useAppDispatch, useAppSelector } from '../../store/reducers/store'
import { setUserTablePageSize } from '../../store/actions/user'
import CustomAxios from '../../utility/customAxios'
import { getRequestHeader, toSnakeCase } from '../../utility/functions'
import { baseUrl, getNotifications } from '../../resources/api-constants'
import { ToastError } from '../../utility/toast'
import { NotificationEntityType } from '../../types/data'

export type TableColumn = {
    title: string | JSX.Element
    field: string
    sorting?: boolean
    align?: 'center' | 'inherit' | 'justify' | 'left' | 'right'
    render?: (rowData: any) => JSX.Element
    width?: string
    cellStyle?: any
    headerStyle?: any
}

export type TableAction = (rowData: any) => {
    icon: () => JSX.Element
    onClick: () => void
    tooltip: string
    hidden?: boolean
}

export interface NotificationFetcher {
    entity: NotificationEntityType
}

type TableProps = {
    actions: TableAction[]
    columns: TableColumn[]
    data?: any[]
    items?: any[]
    endpoint?: string
    filterParams?: any
    reloads?: boolean
    forceDeleteReload?: boolean
    notificationFetcher?: NotificationFetcher
    onTotalCountChange?: (newTotal: number) => void
}

const Table: React.FC<TableProps> = ({
    actions,
    columns,
    items,
    endpoint,
    filterParams,
    reloads,
    forceDeleteReload,
    notificationFetcher,
    onTotalCountChange,
}) => {
    const user = useAppSelector((state) => state.user)
    const [currentPage, setCurrentPage] = useState(0)
    const [pageSize, setPageSize] = useState<number>(user.userTablePageSize ? parseInt(user.userTablePageSize, 10) : 10)
    const dispatch = useAppDispatch()
    const tableRef = React.useRef<any>()

    useEffect(() => {
        if (tableRef.current && filterParams !== undefined) {
            resetData()
        }
    }, [filterParams])

    useEffect(() => {
        if (tableRef.current && reloads) {
            resetData()
        }
    }, [reloads])

    useEffect(() => {
        if (tableRef.current && forceDeleteReload) {
            resetData(currentPage)
        }
    }, [forceDeleteReload])

    useEffect(() => {
        if (tableRef.current && items && items.length > 0) {
            resetData(currentPage)
        }
    }, [items])

    useEffect(() => {
        if (tableRef.current && pageSize) {
            resetData()
        }
    }, [pageSize])

    const resetData = useCallback((page = 0, headerToSortBy = '', singlePageSize = pageSize) => {
        tableRef.current.onQueryChange({ page, orderByCollection: headerToSortBy, pageSize: singlePageSize })
    }, [])

    return (
        <>
            {endpoint ? (
                <MaterialTable
                    tableRef={tableRef}
                    columns={columns}
                    data={async (query) =>
                        new Promise((resolve) => {
                            if (!endpoint) return []
                            const newOffset = pageSize + pageSize * (query.page - 1)
                            let url = endpoint
                            url += `?limit=${pageSize}`
                            url += `&offset=${newOffset}`
                            if (
                                query.orderByCollection !== undefined &&
                                query.orderByCollection.length > 0 &&
                                query.orderByCollection[0].sortOrder !== undefined
                            ) {
                                url += `&sort_by=${columns[
                                    query.orderByCollection[0].orderBy
                                ].field.toString()}&order_by=${query.orderByCollection[0].orderDirection.toUpperCase()}`
                            }
                            if (filterParams) {
                                // eslint-disable-next-line array-callback-return
                                Object.keys(filterParams).map((key: string) => {
                                    url += `&${toSnakeCase(key)}=${filterParams[key]}`
                                })
                            }

                            void CustomAxios.get(
                                `${baseUrl}${url}`,
                                getRequestHeader(user.loggedUserData?.authToken || '')
                            )
                                .then(async (response) => {
                                    if (notificationFetcher) {
                                        await Promise.all(
                                            response.data.items.map(async (item: any) => {
                                                const notifications = await getNotifications({
                                                    authToken: user.loggedUserData?.authToken || '',
                                                    entity: notificationFetcher.entity,
                                                    entityId: item.id,
                                                    isRead: false,
                                                })
                                                item.notifications = notifications
                                            })
                                        )
                                    }

                                    resolve({
                                        data: response.data.items,
                                        page: query.page,
                                        totalCount: response.data.count,
                                    })
                                    onTotalCountChange ? onTotalCountChange(response.data.count) : null
                                })
                                .catch((error) => {
                                    console.error(error)
                                    ToastError('Si è verificato un errore durante lo scaricamento dei dati.')
                                })
                        })
                    }
                    actions={actions}
                    title=""
                    style={{ padding: '8px 24px 0' }}
                    options={{
                        actionsCellStyle: { paddingRight: 24, opacity: '.6' },
                        search: false,
                        actionsColumnIndex: -1,
                        pageSize,
                        pageSizeOptions: [10, 20, 50],
                    }}
                    onRowsPerPageChange={(pageSize) => {
                        if (pageSize) {
                            dispatch(setUserTablePageSize(pageSize.toString()))
                            setPageSize(pageSize)
                        }
                    }}
                    onPageChange={(pageIndex) => {
                        setCurrentPage(pageIndex)
                    }}
                    localization={tableLocaleIt}
                />
            ) : (
                items && (
                    <MaterialTable
                        tableRef={tableRef}
                        columns={columns}
                        data={async (query) => {
                            return Promise.resolve({
                                data: items,
                                page: query.page,
                                totalCount: items.length,
                            })
                        }}
                        actions={actions}
                        title=""
                        style={{ padding: '8px 24px 0' }}
                        options={{
                            actionsCellStyle: { paddingRight: 24, opacity: '.6' },
                            search: false,
                            actionsColumnIndex: -1,
                            pageSize,
                            pageSizeOptions: [10, 20, 50],
                        }}
                        onRowsPerPageChange={(pageSize) => {
                            dispatch(setUserTablePageSize(pageSize.toString()))
                            setPageSize(pageSize)
                        }}
                        onPageChange={(pageIndex) => {
                            setCurrentPage(pageIndex)
                        }}
                        localization={tableLocaleIt}
                    />
                )
            )}
        </>
    )
}

export default Table

export const tableLocaleIt = {
    body: {
        emptyDataSourceMessage: 'Non sono presenti elementi',
        addTooltip: 'Aggiungi',
        deleteTooltip: 'Rimuovi',
        editTooltip: 'Modifica',
        filterRow: {
            filterTooltip: 'Filtra',
            filterPlaceHolder: 'Filtra',
        },
        editRow: {
            deleteText: 'Vuoi rimuovere questa riga?',
            cancelTooltip: 'Annulla',
            saveTooltip: 'Salva',
        },
    },
    grouping: {
        groupedBy: '',
    },
    header: {
        actions: '',
    },
    pagination: {
        labelDisplayedRows: '{from}-{to} di {count}',
        labelRowsPerPage: 'Righe per pagina:',
        firstAriaLabel: 'Prima pagina',
        firstTooltip: 'Prima pagina',
        previousAriaLabel: 'Pagina precedente',
        previousTooltip: 'Pagina precedente',
        nextAriaLabel: 'Pagina successiva',
        nextTooltip: 'Pagina successiva',
        lastAriaLabel: 'Ultima pagina',
        lastTooltip: 'Ultima pagina',
        labelRows: 'righe',
    },
    toolbar: {
        addRemoveColumns: 'Aggiungi o rimuovi colonne',
        nRowsSelected: '{0} righe selezionate',
        showColumnsTitle: 'Vedi le colonne',
        showColumnsAriaLabel: 'Vedi le colonne',
        exportTitle: 'Esporta',
        exportAriaLabel: 'Esporta',
        exportCSVName: 'Esporta in CSV',
        exportPDFName: 'Esporta in PDF',
        searchTooltip: 'Cerca',
        searchPlaceholder: 'Cerca',
    },
}
