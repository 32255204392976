import React from 'react'
import './InfiniteLoader.sass'
import Lottie from 'lottie-react'
import circularLoader from '../../resources/animations/circular-loader.json'

const CircularLoader: React.FC = () => {
    return (
        <div className="circular-loader-wrapper">
            <div className="circular-loader">
                <Lottie className="lottie" animationData={circularLoader} autoPlay loop />
            </div>
        </div>
    )
}

export default CircularLoader
