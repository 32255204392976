import React, { useEffect, useRef, useState } from 'react'
import MainLogoContainer from '../../components/MainLogoContainer/MainLogoContainer'
import Paragraph from '../../components/Typography/Paragraph'
import TextInput from '../../components/Inputs/TextInput'
import Button from '../../components/Buttons/Button'
import ProjectVersionTag from '../../components/ProjectVersionTag/ProjectVersionTag'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { useAppSelector } from '../../store/reducers/store'
import { resetPassword } from '../../resources/api-constants'
import { useKeyDetect } from '../../utility/customHooks'

const ResetPassView: React.FC<{ onPasswordChange: () => void }> = ({ onPasswordChange }) => {
    const user = useAppSelector((data) => data.user)
    const [newPassword, setNewPassword] = useState('')
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const detectedKey = useKeyDetect()
    const formRef = useRef<any>(null)

    useEffect(() => {
        if (
            detectedKey &&
            detectedKey.key === 'Enter' &&
            formRef.current &&
            formRef.current.contains(document.activeElement)
        )
            // eslint-disable-next-line nonblock-statement-body-position
            void confirmNewPasswordChange()
    }, [detectedKey, formRef.current])

    const confirmNewPasswordChange = async () => {
        try {
            const newPassUpdate = await resetPassword(user.loggedUserData?.authToken || '', {
                newPassword,
                newPasswordConfirm,
            })
            if (newPassUpdate) onPasswordChange()
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <div className="center-box full-height-page">
            <div ref={formRef} className="auth-form-container">
                <MainLogoContainer />
                <Paragraph>Inserisci la nuova password per il tuo account ICOONE.</Paragraph>
                <TextInput
                    value={newPassword}
                    placeholder="Nuova password"
                    type={showPassword ? 'text' : 'password'}
                    onValueChange={(userPassword) => setNewPassword(userPassword)}
                    iconButton={
                        <FontAwesomeIcon
                            icon={showPassword ? faEyeSlash : faEye}
                            onClick={() => setShowPassword(!showPassword)}
                            style={{ cursor: 'pointer' }}
                        />
                    }
                />
                <TextInput
                    value={newPasswordConfirm}
                    placeholder="Ripeti password"
                    type={showPassword ? 'text' : 'password'}
                    onValueChange={(userPassword) => setNewPasswordConfirm(userPassword)}
                />
                <Button disabled={!newPassword && !newPasswordConfirm} onClick={() => void confirmNewPasswordChange()}>
                    Invia
                </Button>
            </div>
            <div className="auth-page-version-number">
                <ProjectVersionTag />
            </div>
        </div>
    )
}

export default ResetPassView
