import React, { useEffect, useRef } from 'react'
import './inputs.sass'
import Select from 'react-select'
import UppercaseLabel from '../Typography/UppercaseLabel'

export interface SelectOption {
    value: string
    label: string
}

const MultiSelect: React.FC<{
    values?: readonly SelectOption[]
    options: SelectOption[]
    onValueChange: (selectedOption: readonly SelectOption[], action: string) => void
    placeholder?: string
    inputLabel?: string
    disabled?: boolean
    reset?: boolean
    closeMenuOnSelect?: boolean
    hideSelectedOptions?: boolean
}> = ({
    values,
    options,
    onValueChange,
    placeholder,
    inputLabel,
    disabled,
    reset,
    closeMenuOnSelect,
    hideSelectedOptions,
}) => {
    const selectRef = useRef<any>(null)

    useEffect(() => {
        if (reset && selectRef.current) selectRef.current.clearValue()
    }, [reset, selectRef.current])

    return (
        <div>
            {inputLabel && (
                <div className="text-input-wrapper__label-container">
                    <UppercaseLabel>{inputLabel}</UppercaseLabel>
                </div>
            )}
            <Select
                ref={selectRef}
                classNames={{
                    control: () => 'select-input-object',
                }}
                styles={{
                    control: (styles) => ({
                        ...styles,
                        border: '2px solid rgba(17, 17, 17, 0.14)',
                        borderRadius: 4,
                        padding: 1,
                    }),
                }}
                closeMenuOnSelect={closeMenuOnSelect}
                isDisabled={disabled}
                value={values}
                maxMenuHeight={120}
                noOptionsMessage={() => 'Nessuna opzione'}
                hideSelectedOptions={hideSelectedOptions}
                options={options}
                isMulti
                onChange={(selectedOption, action) => onValueChange(selectedOption, action.action || '')}
                placeholder={placeholder || ''}
            />
        </div>
    )
}

export default MultiSelect
