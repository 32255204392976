import { useEffect, useId, useRef, useState } from 'react'
import { handleFileUploadFromInput } from '../../utility/functions'
import './inputs.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons'
import { AcceptedMedia } from '../../types/data'

interface Props {
    currentFile: string
    acceptedFormat: AcceptedMedia | string
    onFileChange: (newFile: any) => void
}

const sanitizeFileName = (file: any): any => {
    if (!file || !file.name) return file
    const extension = file.name.slice(file.name.lastIndexOf('.')).toLocaleLowerCase()
    const fileName = file.name.replaceAll(/[', $!@|-]/g, '_').toLocaleLowerCase()
    const blob = file.slice(0, file.size, file.type)
    return new File([blob], `${fileName}${extension}`, { type: file.type })
}

const FileSelector: React.FC<Props> = ({ currentFile, acceptedFormat, onFileChange }) => {
    const [newFile, setNewFile] = useState<any>(null)
    const fileRef = useRef<any>(null)
    const id = useId()

    useEffect(() => {
        setNewFile(currentFile)
    }, [currentFile])

    useEffect(() => {
        if (newFile && currentFile !== newFile) onFileChange(sanitizeFileName(newFile))
    }, [newFile])

    return (
        <div className="file-selector-input-box">
            <label className="file-selector-input-box__icon-button" htmlFor={`avatar-input_${id}`}>
                <FontAwesomeIcon icon={faUpload} />
            </label>
            <span className="file-selector-input-box__filename">{newFile ? newFile.name : 'Seleziona un file'}</span>
            <input
                className="image-input-box__hidden-input"
                id={`avatar-input_${id}`}
                type="file"
                onChange={(e) => handleFileUploadFromInput(e, setNewFile, fileRef)}
                accept={acceptedFormat}
            />
        </div>
    )
}

export default FileSelector
