/* eslint-disable jsx-a11y/media-has-caption */
import React, { useMemo } from 'react'
import './modals.sass'
import Button from '../Buttons/Button'
import { AcceptedMedia, MediaType } from '../../types/data'

const MediaModal: React.FC<{
    media: string
    mediaType: MediaType | null
    onClose: () => void
}> = ({ media, mediaType, onClose }) => {
    const getMediaPlayer = useMemo(() => {
        const extension = media.slice(media.lastIndexOf('.')).toLocaleLowerCase()

        if (AcceptedMedia.AUDIO.includes(extension)) {
            return (
                <audio controls src={media}>
                    <track />
                </audio>
            )
        }

        if (AcceptedMedia.VIDEO.includes(extension)) {
            return (
                <video controls src={media} style={{ height: '100%', width: '100%' }}>
                    <track />
                </video>
            )
        }

        if (AcceptedMedia.IMAGE.includes(extension)) {
            return (
                <img
                    src={media}
                    style={{
                        height: '100%',
                        width: '100%',
                        objectFit: 'contain',
                    }}
                    alt=""
                />
            )
        }

        if (AcceptedMedia.PDF.includes(extension)) {
            return (
                <>
                    <object data={media} type="application/pdf" width="100%" height="500px">
                        <p>
                            Unable to display PDF file. <a href={media}>Download</a> instead.
                        </p>
                    </object>
                </>
            )
        }
    }, [media, mediaType])

    return (
        <div className="modal-wrapper">
            <div className="dark-cover" />

            <div className="modal-component media-modal" style={{ maxHeight: '90%' }}>
                <div
                    className="modal-component__body"
                    style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 600, fontSize: 20 }}
                >
                    <p>ANTEPRIMA</p>
                    <Button buttonType="primary" onClick={() => onClose()}>
                        Chiudi
                    </Button>
                </div>
                <div
                    className="modal-media-player-container"
                    style={{
                        flex: 1,
                        height: '80%',
                        width: '100%',
                        borderRadius: 30,
                        overflow: 'hidden',
                        backgroundColor: '#F6F8F8',
                    }}
                >
                    {getMediaPlayer}
                </div>
            </div>
        </div>
    )
}

export default MediaModal
