/* eslint-disable indent */
import moment from 'moment'
import { SelectOption } from '../components/Inputs/MultiSelect'
import { EntityStatus, ModelUploadStatus } from '../types/data'

export const handleDropdownClick = (
    e: MouseEvent,
    openStatus: boolean,
    triggerElement: React.MutableRefObject<null>,
    panelElement: React.MutableRefObject<null>
): boolean => {
    if (triggerElement.current && (triggerElement.current as HTMLElement).contains(e.target as HTMLElement)) {
        return !openStatus
    }
    if (panelElement.current && (panelElement.current as HTMLElement).contains(e.target as HTMLElement)) {
        return true
    }
    return false
}

export const getRequestHeader = (token: string, type?: string) => {
    return { headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': type || 'application/json' } }
}

export const getElementIdFromCurrentRoute = (pathname: string): string => {
    const currentPathValue = pathname.slice(pathname.lastIndexOf('/') + 1)
    return !Number.isNaN(parseInt(currentPathValue, 10)) ? currentPathValue : '-1'
}

export const formatEntityStatusName = (entityStatus: ModelUploadStatus | EntityStatus) => {
    switch (entityStatus) {
        case ModelUploadStatus.DONE:
            return 'Caricato'
        case ModelUploadStatus.ERROR:
            return 'Errore'
        case ModelUploadStatus.UPLOADING:
            return 'Caricamento in corso'

        case EntityStatus.ACTIVE:
            return 'Attivo'
        case EntityStatus.CANCELED:
            return 'Cancellato'
        case EntityStatus.PENDING:
        default:
            return 'In attesa'
    }
}

export const confirmFilterObject = (filters: any): any => {
    const filterTempCopy = JSON.parse(JSON.stringify(filters))
    Object.keys(filterTempCopy).forEach((key) => {
        if (
            (!filterTempCopy[key] && filterTempCopy[key] !== 0) ||
            (Array.isArray(filterTempCopy[key]) && filterTempCopy[key].length === 0)
        ) {
            delete filterTempCopy[key]
        }
    })
    return filterTempCopy
}

export const formatItemsForSelectOptions = <
    T extends { id: number | string; name?: string; label?: string; value?: string }
>(
    items: T[] | undefined
): SelectOption[] | undefined => {
    if (!items || items.length === 0) return

    return items.map((item) => ({
        value: typeof item.id === 'string' ? item.id : item.id.toString(),
        label: (item.name !== undefined ? item.name : item.label || item.value) ?? '',
    }))
}

export const formatItemForSelectOptions = <
    T extends { id: number | string; name?: string; label?: string; value?: string }
>(
    item: T | undefined
): SelectOption | undefined => {
    if (item === undefined) {
        return
    }

    return {
        value: typeof item.id === 'string' ? item.id : item.id.toString(),
        label: (item.name !== undefined ? item.name : item.label || item.value) ?? '',
    }
}

export const handleFileUploadFromInput = (
    e: File | any,
    callback: (file: any) => void,
    defaultRefValue?: React.MutableRefObject<null>
): void => {
    if (e.target.files[0]) {
        const file = e.target.files[0]
        callback(file)
        const fr = new FileReader()
        fr.onload = function () {
            if (defaultRefValue && defaultRefValue.current) (defaultRefValue.current as any).src = fr.result
        }
        fr.readAsDataURL(file)
    }
}

const fulldays = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato']
const months = [
    'gennaio',
    'febbraio',
    'marzo',
    'aprile',
    'maggio',
    'giugno',
    'luglio',
    'agosto',
    'settembre',
    'ottobre',
    'novembre',
    'dicembre',
]

export const formatDateToFriendlyString = (someDateTimeStamp: string): string => {
    const dt = moment(someDateTimeStamp, 'DD/M/yyyy')
    const date = dt.get('date')
    const month = months[dt.get('month')]
    const diffDays = new Date().getDate() - date
    const diffMonths = new Date().getMonth() - dt.get('month')
    const diffYears = new Date().getFullYear() - dt.get('year')

    if (diffYears === 0 && diffDays === 0 && diffMonths === 0) {
        return 'Oggi'
    }
    if (diffYears === 0 && diffDays === 1) {
        return 'Ieri'
    }
    if (diffYears === 0 && diffDays < -1 && diffDays > -7) {
        return fulldays[dt.get('day')]
    }
    if (diffYears >= 1) {
        return `${date} ${month}, ${dt.get('year')}`
    }

    return `${date} ${month}`
}

/**
 * It takes a timestamp and returns a string in the format of HH:MM
 * @param {number} timestamp - number - the timestamp to convert
 * @returns A string with the hours and minutes of the timestamp.
 */
export const timestampToString = (timestamp: number): string => {
    const date = new Date(timestamp)

    const minutesFromDate = date.getMinutes().toString()

    return `${date.getHours()}:${minutesFromDate.length === 2 ? minutesFromDate : `0${minutesFromDate}`}`
}

export const getFileTypeFromURL = (url: string) => {
    const segments = url.split('/') // Split the URL by '/'
    const lastSegment = segments[segments.length - 1] // Extract the last segment (file name)
    const fileParts = lastSegment.split('.') // Split the file name by '.'

    if (fileParts.length === 1) {
        return 'No file extension found'
    }
    const extension = fileParts[fileParts.length - 1] // Retrieve the extension
    return extension.toLowerCase() // Return the extension in lowercase for consistency
}

export const getFileNameFromURL = (url: string) => {
    if (url === null) {
        return
    }

    const segments = url.split('/') // Split the URL by '/'
    const lastSegment = segments[segments.length - 1] // Extract the last segment (file name)
    const fileParts = lastSegment.split('.') // Split the file name by '.'

    if (fileParts.length === 1) {
        return 'No file name found'
    }

    const filename = fileParts[0].split(':')[2] // Retrieve the extension
    
    return filename.toLowerCase() // Return the extension in lowercase for consistency
}

export function toSnakeCase(str: string) {
    // @eslint-disable-next-line no-restricted-syntax
    return str.replace(/\.?([A-Z]+)/g, (_: string, y: string) => `_${y.toLowerCase()}`).replace(/^_/, '')
}

export function isValidUrl(url: string): boolean {
    const pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name and extension
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
        'i'
    ) // fragment locator
    return !!pattern.test(url)
}
