import React from 'react'
import './entityCards.sass'
import { Badge } from '../../types/data'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faStar } from '@fortawesome/free-solid-svg-icons'
import UppercaseLabel from '../Typography/UppercaseLabel'
import Paragraph from '../Typography/Paragraph'

const BadgeCard: React.FC<{
    badge: Badge
    onRemove: () => void
}> = ({ badge, onRemove }) => {
    return (
        <div className="badge-card-wrapper">
            <div className="remove-circle-icon" onClick={() => onRemove()}>
                <FontAwesomeIcon icon={faTimes} />
            </div>
            <div className="badge-card-body green-card-body">
                <div className="badge-avatar-box">
                    <img className="badge-avatar-box__image" src={badge.avatar} alt={badge.name} />
                </div>
                <div className="badge-avatar-box-shadow" />
                <div className="badge-text-box">
                    <UppercaseLabel>{badge.name}</UppercaseLabel>
                    <Paragraph>{badge.description}</Paragraph>
                    <span className="stars-chip">
                        <FontAwesomeIcon icon={faStar} />
                        <label>{badge.stars}</label>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default BadgeCard
