import React, { useEffect, useState } from 'react'
import Table, { TableAction, TableColumn } from '../../components/Table/Table'
import TableHeader from '../../components/Table/TableHeader'
import Button from '../../components/Buttons/Button'
import AdvancedSearch from '../../components/AdvancedSearch/AdvancedSearch'
import TextInput from '../../components/Inputs/TextInput'
import MultiSelect, { SelectOption } from '../../components/Inputs/MultiSelect'
import UppercaseLabel from '../../components/Typography/UppercaseLabel'
import Checkbox from '../../components/Checkbox/Checkbox'
import { ROUTES } from '../../resources/routes-constants'
import { useNavigate } from 'react-router-dom'
import { Center, Country, Device, NotificationEntityType } from '../../types/data'
import { useAppSelector } from '../../store/reducers/store'
import { ToastError } from '../../utility/toast'
import { deleteSingleCenter } from '../../resources/api-constants'
import Link from '../../components/Link/Link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import SingleSelect from '../../components/Inputs/SingleSelect'
import { confirmFilterObject, formatItemsForSelectOptions } from '../../utility/functions'
import AlertModal from '../../components/Modal/AlertModal'
import AsyncSingleSelect from '../../components/Inputs/AsyncSingleSelect'
import StatusLabel from '../../components/Badges/StatusLabel'

export interface CenterSearchFilters {
    name: string
    country: string
    pendingNotifications: boolean
    province: string
    dealer?: string
    devices: string[]
}

const defaultFiltersState: CenterSearchFilters = {
    name: '',
    country: '',
    pendingNotifications: false,
    province: '',
    dealer: '',
    devices: [],
}

const CentersPage: React.FC = () => {
    const user = useAppSelector((data) => data.user)
    const data = useAppSelector((data) => data.data)
    const [selectedCenter, setSelectedCenter] = useState<Center | null>(null)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [forceDeleteReload, setForceDeleteReload] = useState(false)
    const navigate = useNavigate()

    const [showAdvancedSearch, setShowAdvancedSearch] = useState(false)
    const [resetFlag, setResetFlag] = useState(false)
    const [centerFilters, setCenterFilters] = useState<CenterSearchFilters>(defaultFiltersState)
    const [finalFilters, setFinalFilters] = useState<CenterSearchFilters | undefined>(undefined)

    useEffect(() => {
        if (resetFlag) setResetFlag(!resetFlag)
    }, [resetFlag])

    useEffect(() => {
        if (forceDeleteReload) setForceDeleteReload(!forceDeleteReload)
    }, [forceDeleteReload])

    const resetSearchParams = () => {
        if (JSON.stringify(centerFilters) === JSON.stringify(defaultFiltersState)) return
        setResetFlag(true)
        setCenterFilters(defaultFiltersState)
        setFinalFilters(confirmFilterObject(defaultFiltersState))
    }

    const startDeleteProcedure = (center: Center) => {
        setSelectedCenter(center)
        setShowDeleteModal(true)
    }

    const endRemoveCenterProcedure = () => {
        setSelectedCenter(null)
        setShowDeleteModal(false)
        setForceDeleteReload(true)
    }

    const removeCenter = async () => {
        try {
            const res = await deleteSingleCenter(user.loggedUserData?.authToken || '', selectedCenter?.id || '')
            if (res) endRemoveCenterProcedure()
            else ToastError('Si è verificato un errore durante la procedura di eliminazione!')
        } catch (error) {
            console.error(error)
        }
    }

    const formatItemForCountry = (item: Country | undefined): SelectOption | undefined => {
        if (!item) return
        return { value: item.id, label: item.name }
    }

    const columns: TableColumn[] = [
        {
            title: '',
            field: '',
            sorting: false,
            render: (rowData: Center) => (
                <div className="notification-dot-container">
                    {rowData.notifications &&
                        rowData.notifications.length > 0 &&
                        rowData.notifications.map((notification) => (
                            <div
                                key={notification.id}
                                className={`notification-dot ${notification.type.toLowerCase()}-notification`}
                            />
                        ))}
                </div>
            ),
            width: '3%',
        },
        {
            title: 'Ragione sociale',
            field: 'name',
            render: (rowData: Center) => (
                <Link internalRoute={`${ROUTES.CENTER_DETAIL_ROUTE}${rowData.id}`}>{rowData.name}</Link>
            ),
        },
        {
            title: 'Stato',
            field: 'status',
            render: (rowData: Center) => <StatusLabel status={rowData.status} />,
        },
        {
            title: 'Nazione',
            field: 'country',
            render: (rowData: Center) => <p>{rowData.country.name}</p>,
        },
        {
            title: 'Provincia',
            field: 'province',
            render: (rowData: Center) => <p>{rowData.province}</p>,
        },
        {
            title: 'Distributore',
            field: 'dealer',
            sorting: false,
            render: (rowData: Center) => {
                return rowData.dealer && rowData.dealer.id ? (
                    <Link internalRoute={`${ROUTES.DEALER_DETAIL_ROUTE}${rowData.dealer.id}`}>
                        {rowData.dealer.name}
                    </Link>
                ) : (
                    <p>Distributore non associato</p>
                )
            },
        },
        {
            title: 'Dispositivi',
            field: 'devices',
            sorting: false,
            render: (rowData: Center) => (
                <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                    {rowData.devices.map((dev) => {
                        const device = data.devices.find((d) => d.id === dev.id.toString())
                        if (!device) return
                        return (
                            <Link key={device.id} internalRoute={`${ROUTES.DEVICE_DETAIL_ROUTE}${device.id}`}>
                                {device.name}
                            </Link>
                        )
                    })}
                </div>
            ),
        },
    ]

    const actions: TableAction[] = [
        (rowData: Center) => ({
            icon: () => <FontAwesomeIcon icon={faPen} size="xs" />,
            onClick: () => navigate(`${ROUTES.CENTER_DETAIL_ROUTE}${rowData.id}`),
            tooltip: 'Modifica',
        }),
        (rowData: Center) => ({
            icon: () => <FontAwesomeIcon icon={faTrash} size="xs" />,
            onClick: () => startDeleteProcedure(rowData),
            tooltip: 'Elimina',
        }),
    ]

    return (
        <>
            {showDeleteModal && (
                <AlertModal
                    modalTitle="Elimina centro"
                    modalMessage={`Sei sicuro di voler eliminare l'elemento ${selectedCenter?.name}? Questa operazione è irreversibile.`}
                    onClose={() => setShowDeleteModal(false)}
                    onConfirm={() => void removeCenter()}
                />
            )}
            <div className="page-header-section">
                <div className="page-header-section__left-box">
                    <span className="page-title">Elenco centri</span>
                </div>
                <div className="page-header-section__right-box">
                    <Button
                        customClassName={`${showAdvancedSearch ? 'active' : ''}`}
                        buttonType="ghost"
                        onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
                    >
                        Ricerca avanzata
                    </Button>
                    <Button buttonType="primary" onClick={() => navigate(`${ROUTES.CENTER_DETAIL_ROUTE}new`)}>
                        Crea nuovo centro
                    </Button>
                </div>
            </div>
            <div className="page-content-block">
                <AdvancedSearch
                    isVisible={showAdvancedSearch}
                    onReset={() => resetSearchParams()}
                    onSearch={() => setFinalFilters(confirmFilterObject(centerFilters))}
                >
                    <div className="input-form-box__three-col-row">
                        <TextInput
                            inputLabel="ragione sociale"
                            value={centerFilters.name}
                            onValueChange={(newVal) => setCenterFilters({ ...centerFilters, name: newVal })}
                        />
                        <SingleSelect
                            inputLabel="nazione"
                            placeholder="Seleziona una nazione"
                            options={data.countries.map((country) => ({ value: country.id, label: country.name }))}
                            value={formatItemForCountry(
                                data.countries.find((country) => country.id === centerFilters.country)
                            )}
                            onValueChange={(selectedOption) =>
                                setCenterFilters({ ...centerFilters, country: selectedOption?.value || '' })
                            }
                        />
                        <div>
                            <div style={{ marginLeft: 6 }}>
                                <UppercaseLabel>notifiche</UppercaseLabel>
                            </div>
                            <Checkbox
                                checked={centerFilters.pendingNotifications}
                                onCheckChange={() =>
                                    setCenterFilters({
                                        ...centerFilters,
                                        pendingNotifications: !centerFilters.pendingNotifications,
                                    })
                                }
                                label="Notifiche in attesa"
                            />
                        </div>
                        <TextInput
                            inputLabel="provincia"
                            value={centerFilters.province}
                            onValueChange={(newVal) => setCenterFilters({ ...centerFilters, province: newVal })}
                        />
                        <AsyncSingleSelect
                            reset={resetFlag}
                            inputLabel="distributore"
                            placeholder="Seleziona un distributore"
                            options={[]}
                            endpoint="dealers"
                            userToken={user.loggedUserData?.authToken}
                            onValueChange={(newVal) => setCenterFilters({ ...centerFilters, dealer: newVal?.value })}
                        />
                        <MultiSelect
                            inputLabel="dispositivi associati"
                            placeholder="Seleziona uno o più dispositivi"
                            options={data.devices.map((device) => ({ value: device.id, label: device.name }))}
                            reset={resetFlag}
                            values={formatItemsForSelectOptions<Device>(
                                data.devices.filter((device) => centerFilters.devices.indexOf(device.id) !== -1)
                            )}
                            onValueChange={(selectedOption) =>
                                setCenterFilters({
                                    ...centerFilters,
                                    devices: selectedOption.map((opt) => opt.value) || [],
                                })
                            }
                        />
                    </div>
                </AdvancedSearch>
                <div className="relative">
                    <TableHeader positionIndex={23} />
                    <Table
                        actions={actions}
                        columns={columns}
                        reloads={resetFlag}
                        forceDeleteReload={forceDeleteReload}
                        filterParams={finalFilters}
                        notificationFetcher={{ entity: NotificationEntityType.center }}
                        endpoint="centers"
                    />
                </div>
            </div>
        </>
    )
}

export default CentersPage
