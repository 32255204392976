import React from 'react'
import ChatPanel from '../../../components/ChatPanel/ChatPanel'

const Messages: React.FC<{ centerId: string }> = ({ centerId }) => {
    return (
        <>
            <div className="page-content-block">
                <div className="relative">
                    <ChatPanel centerId={centerId} />
                </div>
            </div>
        </>
    )
}

export default Messages
