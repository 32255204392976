import React from 'react'
import './inputs.sass'
import UppercaseLabel from '../Typography/UppercaseLabel'

interface Props extends React.InputHTMLAttributes<HTMLTextAreaElement> {
    onValueChange: (newVal: string) => void
    inputLabel?: string
}

const TextareaInput: React.FC<Props> = ({ onValueChange, inputLabel, ...props }) => {
    return (
        <div className="textarea-input-wrapper">
            {inputLabel && (
                <div className="textarea-input-wrapper__label-container">
                    <UppercaseLabel>{inputLabel}</UppercaseLabel>
                </div>
            )}
            <div className="textarea-input-component">
                <textarea
                    className="textarea-input-component__input-element reset-appearance"
                    type={props.type}
                    value={props.value}
                    onChange={(e) => onValueChange(e.target.value)}
                    {...props}
                />
            </div>
        </div>
    )
}

export default TextareaInput
