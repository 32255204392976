export enum UserTypes {
    ADMINISTRATOR = 'ADMINISTRATOR',
    CENTER_ADMIN = 'CENTER_ADMIN',
    DISTRIBUTOR_ADMIN = 'DISTRIBUTOR_ADMIN',
}

export interface LoggedUser {
    id: string
    email: string
    firstName: string
    lastName: string
    emailConfirmed: boolean
    isFirstAccess: boolean
    createdOn: string
    updatedOn: string
    lastAccess: string
    type: UserTypes
    language: string
    authToken: string
}
