import React, {
    forwardRef,
    ReactElement,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react'
import './InfiniteLoader.sass'
import { usePrevious } from '../../utility/hooks/common'

interface Props {
    children: ReactElement[]
    loading: boolean
    loadMore: () => void
    dataLength: number
    loaderComponent: ReactElement
}

export interface InfiniteLoaderElement {
    scrollToBottom: () => void
}

const InfiniteLoader = forwardRef<InfiniteLoaderElement, Props>((props, ref) => {
    const [reachedTop, setReachedTop] = useState(false)

    const scrollerRef = useRef<HTMLDivElement | null>(null)

    useImperativeHandle(ref, () => ({
        scrollToBottom: (): void => {
            if (!scrollerRef || !scrollerRef.current) {
                return
            }

            // scrollerRef.current.scrollTo({ top: scrollerRef.current.scrollHeight + 110, behavior: 'smooth' })
            scrollerRef.current.scrollTop = scrollerRef.current.scrollHeight
        },
    }))

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('scroller')
        if (isTop(wrappedElement)) {
            document.removeEventListener('scroll', () => trackScrolling())

            setReachedTop(true)
        } else {
            setReachedTop(false)
        }
    }

    useEffect(() => {
        if (!scrollerRef || !scrollerRef.current) return

        scrollerRef.current.addEventListener('scroll', () => trackScrolling())
        return () => {
            document.removeEventListener('scroll', () => trackScrolling())
        }
    }, [])

    const isTop = useCallback((el: HTMLElement | null) => {
        if (!el) return
        return 80 >= el.scrollTop
    }, [])

    const previousFetchingState = usePrevious(props.loading)

    useEffect(() => {
        if (previousFetchingState !== undefined) {
            if (previousFetchingState === true && props.loading === false) {
                document.addEventListener('scroll', () => trackScrolling())
            }
        }
    }, [props.loading])

    const previousReachedTop = usePrevious(reachedTop)
    useEffect(() => {
        if (previousReachedTop !== undefined && previousReachedTop === false && reachedTop === true) {
            props.loadMore()
        }
    }, [reachedTop])

    const loaderComponent = useMemo(() => {
        if (!props.loading) {
            return null
        }

        if (props.loaderComponent) {
            return props.loaderComponent
        }

        return <span>Loading</span>
    }, [props.loading, props.loaderComponent])

    return (
        <div ref={scrollerRef} id="scroller" className="scroller-container">
            {props.children}
            {loaderComponent}
        </div>
    )
})

InfiniteLoader.displayName = 'InfiniteLoader'

export default InfiniteLoader
