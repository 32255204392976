import React from 'react'
import ResetPassView from './ResetPassView'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../resources/routes-constants'
import { ToastError, ToastSuccess } from '../../utility/toast'

const ResetPassPage: React.FC = () => {
    const navigate = useNavigate()

    const navigateToLoginPage = (forceNavigate?: boolean) => {
        if (forceNavigate) ToastError('Questa pagina è scaduta, prova ad effettuare nuovamente il login.')
        else ToastSuccess('Password reimpostata con successo!')
        navigate(ROUTES.LOGIN_ROUTE)
    }

    return <ResetPassView onPasswordChange={() => navigateToLoginPage()} />
}

export default ResetPassPage
