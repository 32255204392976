import React from 'react'

const Analytics: React.FC = () => {
    const analyticsDashboard =
        'https://dlkqlj26fjxim.cloudfront.net/d/b446bcca-6333-4889-9a41-c67724eafb14000/kpis-v-2-0?orgId=1&auth_token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiYWRtaW4iLCJzdWIiOiJhZG1pbkBsb2NhbGhvc3QiLCJpYXQiOjE3MDY3MTcyNDZ9.joHY4FAwKnuDSmiBhgyXsuPfbIZD7i0MCJrVmflDLVmkdA-gcipQByLqLZGC8ilNPG1IQ_zRODJB9SeNAcFaPolk2d-fGXwRkC4zLI2Klu1lD02GtbDgTkWqBASmYC_ow-LXZAxOt9efHd8P_Nv45jvmqQdM-RVm8Z29aLy3xxfdQ-LaLCbcE_frRsGawKR-wQ847CGmwmwgivr6Us9uK3sIT38UMAeOEf2F_NFqez6LSI5ymtmbdL1i3dyl3HyWF596uRUmNVbk87j4jdJJLejHdLNKBZOJC5BQbzKPvpBrP-dUZbkDddxd7At4lcy4niE01lT7e8zRzFXFqeyHfcnV3RC4N7I8v7TiMI0GB60nb7xO7B5JC03R0T9kEq9Aq8pnGZdcXA8mogT4xD4GRLO0HOUWAXUOpAbXsFRAyYUJeNDq9ONxED_vz2j0_JXjdO5_Y-T2J4OkLVcgeMuKMR9-_ex4Agfxpm9sipooQSbWm6FNJDSramwRQRoipTAidGZQpY-7bzAjUwMqV3GK8DP7i65tg03NeRhnn9fRZm1F_AE_R6YYxft2KThuvOtCqW-oMNNZstvGeZPbSNzXBqw9vYEtScnrxXqwMSUPxhep18QYGrK4Mku3Q7oytJTVanEIXvtyioP2cFjNz0vwIgWuZtmXtPhsdyV2Rc0GDCM'

    return (
        <>
            <div className="page-header-section">
                <span className="page-title">Analitiche</span>
            </div>
            <div className="page-content-flow">
                <div style={{ width: '100%', height: 'calc(100vh - 136px)', overflow: 'hidden', borderRadius: 8 }}>
                    <iframe src={analyticsDashboard} title="analytics" height="100%" width="100%" frameBorder={0} />
                </div>
            </div>
        </>
    )
}

export default Analytics
