import React, { useEffect, useState } from 'react'
import './modals.sass'
import Button from '../Buttons/Button'
import CircularLoader from '../InfiniteLoader/CircularLoader'
import { useAppSelector } from '../../store/reducers/store'
import { createSingleDirectory, updateSingleDirectory } from '../../resources/api-constants'
import { ToastError, ToastSuccess } from '../../utility/toast'
import { MediaDirectory } from '../../types/data'
import TextInput from '../Inputs/TextInput'

const defaultDirectory: MediaDirectory = {
    id: '',
    name: '',
    creationDate: '',
    updateDate: '',
    idMediaScope: '',
}

const FolderDetailModal: React.FC<{
    mediaDirectory?: MediaDirectory
    idMediaScope: string
    onClose: () => void
}> = ({ mediaDirectory, idMediaScope, onClose }) => {
    const user = useAppSelector((data) => data.user)
    const [directory, setDirectory] = useState<MediaDirectory>({ ...defaultDirectory, idMediaScope })
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (mediaDirectory) setDirectory(mediaDirectory)
    }, [mediaDirectory])

    const startCreateProcedure = async () => {
        try {
            setIsLoading(true)
            const res = await createSingleDirectory(user.loggedUserData?.authToken || '', directory).then(
                (response) => response
            )
            if (res) onClose()
            ToastSuccess('Cartella creata con successo')
        } catch (error) {
            console.error(error)
            ToastError('Errore durante la procedura di creazione')
        }
        setIsLoading(false)
    }

    const startUpdateProcedure = async () => {
        try {
            setIsLoading(true)
            const res = await updateSingleDirectory(user.loggedUserData?.authToken || '', directory).then(
                (response) => response
            )
            if (res) onClose()
            ToastSuccess('Cartella aggiornata con successo')
        } catch (error) {
            console.error(error)
            ToastError('Errore durante la procedura di aggiornamento')
        }
        setIsLoading(false)
    }

    return (
        <div className="modal-wrapper">
            <div className="dark-cover" />

            <div className="modal-component">
                <div className="modal-component__header">
                    <span className="page-title">
                        {mediaDirectory ? `Modifica ${mediaDirectory.name}` : 'Creazione nuova cartella'}
                    </span>
                </div>
                <div className="modal-component__body">
                    {isLoading ? (
                        <CircularLoader />
                    ) : (
                        <TextInput
                            placeholder="Nome cartella"
                            value={directory.name}
                            onValueChange={(e) => {
                                setDirectory({ ...directory, name: e })
                            }}
                        />
                    )}
                </div>
                <div className="modal-component__footer">
                    <Button buttonType="secondary" disabled={isLoading} onClick={() => onClose()}>
                        Annulla
                    </Button>
                    <Button
                        buttonType="primary"
                        disabled={isLoading || directory.name === ''}
                        onClick={() => {
                            mediaDirectory ? void startUpdateProcedure() : void startCreateProcedure()
                        }}
                    >
                        {mediaDirectory ? 'Modifica' : 'Crea'}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default FolderDetailModal
