import React, { useEffect, useId, useRef } from 'react'
import './ChatUserInput.sass'
import ContentEditable from '../../custom-components/ContentEditable'

interface ChatUserInputProps {
    value: string
    onChange: (newVal: string) => void
    handleSendMessage: () => void
}

const ChatUserInput: React.FC<ChatUserInputProps> = (props) => {
    const textInputRef = useRef<HTMLInputElement | null>(null)
    const inputId = useId()

    useEffect(() => {
        const customInput = document.getElementById(inputId)
        if (!customInput || !props.value) return
        customInput.innerHTML = props.value
    }, [props.value])

    return (
        <div className="chat-user-input-wrapper">
            <div className="chat-user-input">
                <ContentEditable
                    placeholder="Scrivi un messaggio..."
                    className="chat-user-input-editable"
                    innerRef={textInputRef}
                    html={props.value} // innerHTML of the editable div
                    disabled={false} // use true to disable editing
                    onKeyDownCapture={(e) => {
                        if (e.which === 13 || e.keyCode === 13 || e.code === 'Enter' || e.key === 'Enter') {
                            e.stopPropagation()
                            e.preventDefault()
                            props.handleSendMessage()
                        }
                    }}
                    onChange={(e) => {
                        props.onChange(e.currentTarget.textContent)
                    }}
                />
            </div>
        </div>
    )
}

export default ChatUserInput
