import React, { useEffect, useState } from 'react'
import Table, { TableAction, TableColumn } from '../../../components/Table/Table'
import TableHeader from '../../../components/Table/TableHeader'
import Button from '../../../components/Buttons/Button'
import AdvancedSearch from '../../../components/AdvancedSearch/AdvancedSearch'
import TextInput from '../../../components/Inputs/TextInput'
import MultiSelect from '../../../components/Inputs/MultiSelect'
import { ROUTES } from '../../../resources/routes-constants'
import { useNavigate } from 'react-router-dom'
import { AppType, Device, InfoItem } from '../../../types/data'
import { useAppSelector } from '../../../store/reducers/store'
import { ToastError, ToastSuccess } from '../../../utility/toast'
import Link from '../../../components/Link/Link'
import { deleteSingleDevice } from '../../../resources/api-constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { confirmFilterObject, formatItemsForSelectOptions } from '../../../utility/functions'
import AlertModal from '../../../components/Modal/AlertModal'
import TextLabel from '../../../components/Badges/TextLabel'

interface DeviceSearchFilters {
    name: string
    application_types: string[]
    body_areas: string[]
}

const defaultFiltersState: DeviceSearchFilters = {
    name: '',
    application_types: [],
    body_areas: [],
}

const DevicesPage: React.FC = () => {
    const user = useAppSelector((data) => data.user)
    const data = useAppSelector((data) => data.data)
    const [selectedDevice, setSelectedDevice] = useState<Device | null>(null)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [forceDeleteReload, setForceDeleteReload] = useState(false)
    const navigate = useNavigate()

    const [showAdvancedSearch, setShowAdvancedSearch] = useState(false)
    const [resetFlag, setResetFlag] = useState(false)
    const [deviceFilters, setDeviceFilters] = useState<DeviceSearchFilters>(defaultFiltersState)
    const [finalFilters, setFinalFilters] = useState<DeviceSearchFilters | undefined>(undefined)

    useEffect(() => {
        if (resetFlag) setResetFlag(!resetFlag)
    }, [resetFlag])

    useEffect(() => {
        if (forceDeleteReload) setForceDeleteReload(!forceDeleteReload)
    }, [forceDeleteReload])

    const resetSearchParams = () => {
        if (JSON.stringify(deviceFilters) === JSON.stringify(defaultFiltersState)) return
        setResetFlag(true)
        setDeviceFilters(defaultFiltersState)
        setFinalFilters(confirmFilterObject(defaultFiltersState))
    }

    const startDeleteProcedure = (device: Device) => {
        setSelectedDevice(device)
        setShowDeleteModal(true)
    }

    const endRemoveDeviceProcedure = () => {
        setSelectedDevice(null)
        setShowDeleteModal(false)
        setForceDeleteReload(true)
        ToastSuccess('Dispositivo eliminato con successo')
    }

    const removeDevice = async () => {
        try {
            const res = await deleteSingleDevice(user.loggedUserData?.authToken || '', selectedDevice?.id || '')
            if (res) endRemoveDeviceProcedure()
            else ToastError('Si è verificato un errore durante la procedura di eliminazione!')
        } catch (error) {
            console.error(error)
        }
    }

    const columns: TableColumn[] = [
        {
            title: 'Nome',
            field: 'name',
            render: (data: Device) => (
                <Link internalRoute={`${ROUTES.DEVICE_DETAIL_ROUTE}${data.id}`}>{data.name}</Link>
            ),
        },
        {
            title: 'Tipologia applicazione',
            field: 'application_types',
            sorting: false,
            render: (data) =>
                data.applicationTypes.map((type: AppType) => (
                    <TextLabel key={type.id} customColor={type.color}>
                        {type.name}
                    </TextLabel>
                )),
        },
        {
            title: 'Aree del corpo',
            field: 'body_areas',
            sorting: false,
            render: (data: Device) => <p>{data.bodyAreas?.map((area) => area.name).join(', ')}</p>,
        },
    ]

    const actions: TableAction[] = [
        (rowData: Device) => ({
            icon: () => <FontAwesomeIcon icon={faPen} size="xs" />,
            onClick: () => navigate(`${ROUTES.DEVICE_DETAIL_ROUTE}${rowData.id}`),
            tooltip: 'Modifica',
        }),
        (rowData: Device) => ({
            icon: () => <FontAwesomeIcon icon={faTrash} size="xs" />,
            onClick: () => startDeleteProcedure(rowData),
            tooltip: 'Elimina',
        }),
    ]

    return (
        <>
            {showDeleteModal && (
                <AlertModal
                    modalTitle="Elimina dispositivo"
                    modalMessage={`Sei sicuro di voler eliminare l'elemento ${selectedDevice?.name}? Questa operazione è irreversibile.`}
                    onClose={() => setShowDeleteModal(false)}
                    onConfirm={() => void removeDevice()}
                />
            )}
            <div className="page-header-section">
                <div className="page-header-section__left-box">
                    <span className="page-title">Elenco dispositivi</span>
                </div>
                <div className="page-header-section__right-box">
                    <Button
                        customClassName={`${showAdvancedSearch ? 'active' : ''}`}
                        buttonType="ghost"
                        onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
                    >
                        Ricerca avanzata
                    </Button>
                    <Button buttonType="primary" onClick={() => navigate(`${ROUTES.DEVICE_DETAIL_ROUTE}new`)}>
                        Crea nuovo dispositivo
                    </Button>
                </div>
            </div>
            <div className="page-content-block">
                <AdvancedSearch
                    isVisible={showAdvancedSearch}
                    onReset={() => resetSearchParams()}
                    onSearch={() => setFinalFilters(confirmFilterObject(deviceFilters))}
                >
                    <div className="input-form-box__three-col-row">
                        <TextInput
                            value={deviceFilters.name}
                            inputLabel="nome"
                            onValueChange={(newVal) => setDeviceFilters({ ...deviceFilters, name: newVal })}
                        />
                        <MultiSelect
                            reset={resetFlag}
                            inputLabel="Tipologia applicazione"
                            placeholder="Seleziona una o più tipologie"
                            options={data.appTypes.map((appType) => ({
                                value: appType.id.toString(),
                                label: appType.name,
                            }))}
                            values={formatItemsForSelectOptions<AppType>(
                                data.appTypes.filter(
                                    (appType) => deviceFilters.application_types.indexOf(appType.name) !== -1
                                )
                            )}
                            onValueChange={(newVals) =>
                                setDeviceFilters({
                                    ...deviceFilters,
                                    application_types: newVals.map((val) => val.value),
                                })
                            }
                        />
                        <MultiSelect
                            reset={resetFlag}
                            inputLabel="Area del corpo"
                            placeholder="Seleziona una o più aree"
                            options={data.bodyAreas.map((bodyArea) => ({
                                value: bodyArea.id,
                                label: bodyArea.name,
                            }))}
                            values={formatItemsForSelectOptions<InfoItem>(
                                data.bodyAreas.filter(
                                    (bodyArea) => deviceFilters.body_areas.indexOf(bodyArea.name) !== -1
                                )
                            )}
                            onValueChange={(newVals) =>
                                setDeviceFilters({
                                    ...deviceFilters,
                                    body_areas: newVals.map((val) => val.value),
                                })
                            }
                        />
                    </div>
                </AdvancedSearch>
                <div className="relative">
                    <TableHeader positionIndex={23} />
                    <Table
                        actions={actions}
                        columns={columns}
                        reloads={resetFlag}
                        forceDeleteReload={forceDeleteReload}
                        filterParams={finalFilters}
                        endpoint="devices"
                    />
                </div>
            </div>
        </>
    )
}

export default DevicesPage
