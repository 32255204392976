import React from 'react'
import './entityCards.sass'
import { Certification } from '../../types/data'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faCalendar } from '@fortawesome/free-solid-svg-icons'
import UppercaseLabel from '../Typography/UppercaseLabel'
import Paragraph from '../Typography/Paragraph'

const CertificationCard: React.FC<{
    certification: Certification
    validUntil: string
    onRemove: () => void
}> = ({ certification, validUntil, onRemove }) => {
    return (
        <div className="badge-card-wrapper">
            <div className="remove-circle-icon" onClick={() => onRemove()}>
                <FontAwesomeIcon icon={faTimes} />
            </div>
            <div className="badge-card-body blue-card-body">
                <div className="badge-avatar-box">
                    <img
                        className="badge-avatar-box__image"
                        src={certification.avatar}
                        alt={certification.name}
                    />
                </div>
                <div className="badge-avatar-box-shadow" />
                <div className="badge-text-box">
                    <UppercaseLabel>{certification.name}</UppercaseLabel>
                    <Paragraph>{certification.description}</Paragraph>
                    <span className="stars-chip">
                        <FontAwesomeIcon icon={faCalendar} />
                        <label>Valido fino al {new Date(validUntil).toLocaleDateString('it')}</label>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default CertificationCard
