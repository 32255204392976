import React from 'react'
import './MainLogoContainer.sass'
import mainLogo from '../../resources/images/icoone.png'

const MainLogoContainer: React.FC = () => {
    return (
        <div className="main-logo-container">
            <img src={mainLogo} alt="iCOONE Panel" />
        </div>
    )
}

export default MainLogoContainer
