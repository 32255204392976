import {
    MediaFilesEntity,
    MediaScopeCategory,
    IcooneMediaItem,
    ResultsEvaluation,
    BodyPart,
    ResultMedia,
    ResultMediaToCreate,
    MediaDirectory,
    MediaFile,
    FAQItem,
    NotificationItem,
    DealerToEdit,
    CommercialArea,
    User,
} from './../types/data'
import axios from 'axios'
import { DeviceToEdit } from '../pages/Configurations/Devices/DeviceDetailPage'
import {
    Badge,
    Center,
    CenterToEdit,
    Certification,
    Dealer,
    Device,
    Protocol,
    ProtocolToEdit,
    Result,
    ResultToCreate,
} from '../types/data'
import CustomAxios from '../utility/customAxios'
import { getRequestHeader, toSnakeCase } from '../utility/functions'

export let baseUrl = ''
export let frontendBaseUrl = ''

switch (process.env.REACT_APP_RELEASE_ENV) {
    case 'development':
        baseUrl = 'http://3.254.135.102:3201/'
        // baseUrl = 'http://172.16.0.124:3201/'
        frontendBaseUrl = '/'
        break
    case 'production':
        baseUrl = 'https://d1lbl9idd6ejec.cloudfront.net/'
        frontendBaseUrl = '/'
}

interface PaginatedResponse<T> {
    items: T[]
    count: number
}

interface NewPassObject {
    newPassword: string
    newPasswordConfirm: string
}

// --- AUTH ---

export const sendUserCredentials = async (authToken: string, userId: string): Promise<any> => {
    try {
        const res = await CustomAxios.post(
            `${baseUrl}users/${userId}/send-credentials`,
            {},
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const requestPasswordReset = async (userEmail: string): Promise<PaginatedResponse<Center>> => {
    try {
        const res = await CustomAxios.post(`${baseUrl}/auth/reset-password?email=${userEmail}`).then(
            (response) => response.data
        )
        return res
    } catch (error) {
        console.error(error)
        return { items: [], count: 0 }
    }
}

export const createUser = async (authToken: string, item: User): Promise<User | undefined> => {
    try {
        const res = await CustomAxios.post(`${baseUrl}/users`, item, getRequestHeader(authToken)).then(
            (response) => response.data
        )
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const updateUser = async (authToken: string, item: User): Promise<User | undefined> => {
    try {
        const res = await CustomAxios.patch(`${baseUrl}/users/${item.id}`, item, getRequestHeader(authToken)).then(
            (response) => response.data
        )
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const resetPassword = async (authToken: string, newPass: NewPassObject): Promise<PaginatedResponse<Center>> => {
    try {
        const res = await CustomAxios.post(
            `${baseUrl}auth/set-new-password`,
            newPass,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return { items: [], count: 0 }
    }
}

// --- CENTERS ---

export const getCenters = async (authToken: string, filters?: string): Promise<PaginatedResponse<Center>> => {
    try {
        const res = await CustomAxios.get(`${baseUrl}centers${filters || ''}`, getRequestHeader(authToken)).then(
            (response) => response.data
        )
        return res
    } catch (error) {
        console.error(error)
        return { items: [], count: 0 }
    }
}

export const getSingleCenter = async (authToken: string, centerId: string): Promise<Center | undefined> => {
    try {
        const res = await CustomAxios.get(`${baseUrl}centers/${centerId}`, getRequestHeader(authToken)).then(
            (response) => response.data
        )
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const createSingleCenter = async (authToken: string, newCenter: CenterToEdit): Promise<Center | undefined> => {
    try {
        const res = await CustomAxios.post(`${baseUrl}centers`, newCenter, getRequestHeader(authToken)).then(
            (response) => response.data
        )
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const createSingleCenterDeviceAssociation = async (
    authToken: string,
    idCenter: string,
    idDevice: string
): Promise<undefined> => {
    try {
        const res = await CustomAxios.post(
            `${baseUrl}centers/${idCenter}/devices/${idDevice}`,
            {},
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const activateSingleCenterDeviceAssociation = async (
    authToken: string,
    idCenter: string,
    idDevice: string
): Promise<undefined> => {
    try {
        const res = await CustomAxios.patch(
            `${baseUrl}centers/${idCenter}/devices/${idDevice}`,
            { status: 'ACTIVE' },
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const deleteSingleCenterDeviceAssociation = async (
    authToken: string,
    idCenter: string,
    idDevice: string
): Promise<undefined> => {
    try {
        const res = await CustomAxios.delete(
            `${baseUrl}centers/${idCenter}/devices/${idDevice}`,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const createSingleCenterBadgeAssociation = async (
    authToken: string,
    idCenter: string,
    idBadge: string
): Promise<undefined> => {
    try {
        const res = await CustomAxios.post(
            `${baseUrl}centers/${idCenter}/badges/${idBadge}`,
            {},
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const deleteSingleCenterBadgeAssociation = async (
    authToken: string,
    idCenter: string,
    idBadge: string
): Promise<undefined> => {
    try {
        const res = await CustomAxios.delete(
            `${baseUrl}centers/${idCenter}/badges/${idBadge}`,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const updateSingleCenter = async (
    authToken: string,
    centerId: string,
    updatedCenter: CenterToEdit
): Promise<Center | undefined> => {
    try {
        const res = await CustomAxios.put(
            `${baseUrl}centers/${centerId}`,
            updatedCenter,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const deleteSingleCenter = async (authToken: string, centerId: string): Promise<string | undefined> => {
    try {
        await CustomAxios.delete(`${baseUrl}centers/${centerId}`, getRequestHeader(authToken))
        return 'ok'
    } catch (error) {
        console.error(error)
        return
    }
}

// --- RESULTS ---

export const getResults = async (authToken: string, filters?: string): Promise<PaginatedResponse<Result>> => {
    try {
        const res = await CustomAxios.get(`${baseUrl}results${filters || ''}`, getRequestHeader(authToken)).then(
            (response) => response.data
        )
        return res
    } catch (error) {
        console.error(error)
        return { items: [], count: 0 }
    }
}

export const getCommunityResults = async (authToken: string, filters?: string): Promise<PaginatedResponse<Result>> => {
    try {
        const res = await CustomAxios.get(
            `${baseUrl}community/results${filters || ''}`,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return { items: [], count: 0 }
    }
}

export const getSingleResult = async (authToken: string, centerId: string): Promise<Result | undefined> => {
    try {
        const res = await CustomAxios.get(`${baseUrl}results/${centerId}`, getRequestHeader(authToken)).then(
            (response) => response.data
        )
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const createSingleResult = async (authToken: string, newResult: ResultToCreate): Promise<Result | undefined> => {
    try {
        const res = await CustomAxios.post(`${baseUrl}results`, newResult, getRequestHeader(authToken)).then(
            (response) => response.data
        )
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const createResultMedia = async (
    authToken: string,
    newResultMedia: ResultMediaToCreate
): Promise<ResultMedia | undefined> => {
    try {
        const res = await CustomAxios.post(`${baseUrl}result-media`, newResultMedia, getRequestHeader(authToken)).then(
            (response) => response.data
        )
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const updateSingleResult = async (
    authToken: string,
    resultId: string,
    updatedResult: ResultToCreate
): Promise<Result | undefined> => {
    try {
        const res = await CustomAxios.put(
            `${baseUrl}results/${resultId}`,
            updatedResult,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const deleteSingleResult = async (authToken: string, resultId: string): Promise<string | undefined> => {
    try {
        await CustomAxios.delete(`${baseUrl}results/${resultId}`, getRequestHeader(authToken))
        return 'ok'
    } catch (error) {
        console.error(error)
        return
    }
}

export const deleteResultMedia = async (authToken: string, resultId: string): Promise<string | undefined> => {
    try {
        await CustomAxios.delete(`${baseUrl}result-media/${resultId}`, getRequestHeader(authToken))
        return 'ok'
    } catch (error) {
        console.error(error)
        return
    }
}

export const evaluateResult = async (
    authToken: string,
    resultId: string,
    evaluation: ResultsEvaluation
): Promise<Result | undefined> => {
    try {
        const res = await CustomAxios.patch(
            `${baseUrl}results/${resultId}`,
            { evaluation },
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

// --- DEALERS ---

export const getDealers = async (authToken: string, filters?: string): Promise<PaginatedResponse<Dealer>> => {
    try {
        const res = await CustomAxios.get(`${baseUrl}dealers${filters || ''}`, getRequestHeader(authToken)).then(
            (response) => response.data
        )
        return res
    } catch (error) {
        console.error(error)
        return { items: [], count: 0 }
    }
}

export const getSingleDealer = async (authToken: string, dealerId: string): Promise<Dealer | undefined> => {
    try {
        const res = await CustomAxios.get(`${baseUrl}dealers/${dealerId}`, getRequestHeader(authToken)).then(
            (response) => response.data
        )
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const createSingleDealer = async (authToken: string, newDealer: DealerToEdit): Promise<Dealer | undefined> => {
    try {
        const res = await CustomAxios.post(`${baseUrl}dealers`, newDealer, getRequestHeader(authToken)).then(
            (response) => response.data
        )
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const updateSingleDealer = async (
    authToken: string,
    dealerId: string,
    updatedDealer: DealerToEdit
): Promise<Dealer | undefined> => {
    try {
        const res = await CustomAxios.put(
            `${baseUrl}dealers/${dealerId}`,
            updatedDealer,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const deleteSingleDealer = async (authToken: string, dealerId: string): Promise<string | undefined> => {
    try {
        await CustomAxios.delete(`${baseUrl}dealers/${dealerId}`, getRequestHeader(authToken))
        return 'ok'
    } catch (error) {
        console.error(error)
        return
    }
}

export const createSingleDealerDeviceAssociation = async (
    authToken: string,
    idDealer: string,
    idDevice: string
): Promise<undefined> => {
    try {
        const res = await CustomAxios.post(
            `${baseUrl}dealers/${idDealer}/devices/${idDevice}`,
            {},
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const activateSingleDealerDeviceAssociation = async (
    authToken: string,
    idCenter: string,
    idDevice: string
): Promise<undefined> => {
    try {
        const res = await CustomAxios.patch(
            `${baseUrl}dealers/${idCenter}/devices/${idDevice}`,
            { status: 'ACTIVE' },
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const deleteSingleDealerDeviceAssociation = async (
    authToken: string,
    idDealer: string,
    idDevice: string
): Promise<undefined> => {
    try {
        const res = await CustomAxios.delete(
            `${baseUrl}dealers/${idDealer}/devices/${idDevice}`,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const createSingleDealerCertificationAssociation = async (
    authToken: string,
    idDealer: string,
    idCertification: string
): Promise<undefined> => {
    try {
        const res = await CustomAxios.post(
            `${baseUrl}dealers/${idDealer}/certifications/${idCertification}`,
            {},
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const deleteSingleDealerCertificationAssociation = async (
    authToken: string,
    idDealer: string,
    idCertification: string
): Promise<undefined> => {
    try {
        const res = await CustomAxios.delete(
            `${baseUrl}dealers/${idDealer}/certifications/${idCertification}`,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

// --- PROTOCOLS ---

export const getProtocols = async (authToken: string): Promise<PaginatedResponse<Protocol>> => {
    try {
        const res = await CustomAxios.get(
            `${baseUrl}protocols?show_unconfigured=true`,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return { items: [], count: 0 }
    }
}

export const getSingleProtocol = async (authToken: string, protocolId: string): Promise<Protocol | undefined> => {
    try {
        const res = await CustomAxios.get(`${baseUrl}protocols/${protocolId}`, getRequestHeader(authToken)).then(
            (response) => response.data
        )
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const createSingleProtocol = async (
    authToken: string,
    newProtocol: ProtocolToEdit
): Promise<Protocol | undefined> => {
    try {
        const res = await CustomAxios.post(`${baseUrl}protocols`, newProtocol, getRequestHeader(authToken)).then(
            (response) => response.data
        )
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const updateSingleProtocol = async (
    authToken: string,
    protocolId: string,
    updatedProtocol: ProtocolToEdit
): Promise<Protocol | undefined> => {
    try {
        const res = await CustomAxios.put(
            `${baseUrl}protocols/${protocolId}`,
            updatedProtocol,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const deleteSingleProtocol = async (authToken: string, protocolId: string): Promise<string | undefined> => {
    try {
        await CustomAxios.delete(`${baseUrl}protocols/${protocolId}`, getRequestHeader(authToken))
        return 'ok'
    } catch (error) {
        console.error(error)
        return
    }
}

// --- BADGES ---

export const getBadges = async (authToken: string): Promise<PaginatedResponse<Device>> => {
    try {
        const res = await CustomAxios.get(`${baseUrl}badges`, getRequestHeader(authToken)).then(
            (response) => response.data
        )
        return res
    } catch (error) {
        console.error(error)
        return { items: [], count: 0 }
    }
}

export const getSingleBadge = async (authToken: string, badgeId: string): Promise<Badge | undefined> => {
    try {
        const res = await CustomAxios.get(`${baseUrl}badges/${badgeId}`, getRequestHeader(authToken)).then(
            (response) => response.data
        )
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const createSingleBadge = async (authToken: string, newBadge: any): Promise<Badge | undefined> => {
    try {
        const res = await CustomAxios.post(`${baseUrl}badges`, newBadge, getRequestHeader(authToken)).then(
            (response) => response.data
        )
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const updateSingleBadge = async (
    authToken: string,
    badgeId: string,
    updatedBadge: any
): Promise<Badge | undefined> => {
    try {
        const res = await CustomAxios.put(
            `${baseUrl}badges/${badgeId}`,
            updatedBadge,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const deleteSingleBadge = async (authToken: string, badgeId: string): Promise<string | undefined> => {
    try {
        await CustomAxios.delete(`${baseUrl}badges/${badgeId}`, getRequestHeader(authToken))
        return 'ok'
    } catch (error) {
        console.error(error)
        return
    }
}

// --- CERTIFICATIONS ---

export const getCertifications = async (authToken: string): Promise<PaginatedResponse<Certification>> => {
    try {
        const res = await CustomAxios.get(`${baseUrl}certifications`, getRequestHeader(authToken)).then(
            (response) => response.data
        )
        return res
    } catch (error) {
        console.error(error)
        return { items: [], count: 0 }
    }
}

export const getSingleCertification = async (
    authToken: string,
    certificationId: string
): Promise<Certification | undefined> => {
    try {
        const res = await CustomAxios.get(
            `${baseUrl}certifications/${certificationId}`,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const createSingleCertification = async (
    authToken: string,
    newCertification: any
): Promise<Certification | undefined> => {
    try {
        const res = await CustomAxios.post(
            `${baseUrl}certifications`,
            newCertification,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const updateSingleCertification = async (
    authToken: string,
    certificationId: string,
    updatedCertification: any
): Promise<Certification | undefined> => {
    try {
        const res = await CustomAxios.put(
            `${baseUrl}certifications/${certificationId}`,
            updatedCertification,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const deleteSingleCertification = async (
    authToken: string,
    certificationId: string
): Promise<string | undefined> => {
    try {
        await CustomAxios.delete(`${baseUrl}certifications/${certificationId}`, getRequestHeader(authToken))
        return 'ok'
    } catch (error) {
        console.error(error)
        return
    }
}

// --- ICOONE MEDIA ---

export const getSingleIcooneMedia = async (authToken: string, itemId: string): Promise<IcooneMediaItem | undefined> => {
    try {
        const res = await CustomAxios.get(`${baseUrl}media-files/${itemId}`, getRequestHeader(authToken)).then(
            (response) => response.data
        )
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const createSingleIcooneMedia = async (authToken: string, item: any): Promise<IcooneMediaItem | undefined> => {
    try {
        const res = await CustomAxios.post(`${baseUrl}media-files`, item, getRequestHeader(authToken)).then(
            (response) => response.data
        )
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const updateSingleIcooneMedia = async (
    authToken: string,
    itemId: string,
    item: any
): Promise<IcooneMediaItem | undefined> => {
    try {
        const res = await CustomAxios.put(`${baseUrl}media-files/${itemId}`, item, getRequestHeader(authToken)).then(
            (response) => response.data
        )
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const deleteSingleIcooneMedia = async (authToken: string, itemId: string): Promise<string | undefined> => {
    try {
        await CustomAxios.delete(`${baseUrl}media-files/${itemId}`, getRequestHeader(authToken))
        return 'ok'
    } catch (error) {
        console.error(error)
        return
    }
}

export const deleteSingleIcooneMediaFile = async (
    authToken: string,
    icooneMediaId: string
): Promise<string | undefined> => {
    try {
        await CustomAxios.delete(`${baseUrl}media-files/${icooneMediaId}`, getRequestHeader(authToken))
        return 'ok'
    } catch (error) {
        console.error(error)
        return
    }
}

// --- DEVICES ---

export const getDevices = async (authToken: string): Promise<PaginatedResponse<Device>> => {
    try {
        const res = await CustomAxios.get(`${baseUrl}devices`, getRequestHeader(authToken)).then(
            (response) => response.data
        )
        return res
    } catch (error) {
        console.error(error)
        return { items: [], count: 0 }
    }
}

export const getSingleDevice = async (authToken: string, deviceId: string): Promise<Device | undefined> => {
    try {
        const res = await CustomAxios.get(`${baseUrl}devices/${deviceId}`, getRequestHeader(authToken)).then(
            (response) => response.data
        )
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const createSingleDevice = async (authToken: string, newDevice: DeviceToEdit): Promise<Device | undefined> => {
    try {
        const res = await CustomAxios.post(`${baseUrl}devices`, newDevice, getRequestHeader(authToken)).then(
            (response) => response.data
        )
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const updateSingleDevice = async (
    authToken: string,
    deviceId: string,
    updatedDevice: DeviceToEdit
): Promise<Device | undefined> => {
    try {
        const res = await CustomAxios.put(
            `${baseUrl}devices/${deviceId}`,
            updatedDevice,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const deleteSingleDevice = async (authToken: string, deviceId: string): Promise<string | undefined> => {
    try {
        await CustomAxios.delete(`${baseUrl}devices/${deviceId}`, getRequestHeader(authToken))
        return 'ok'
    } catch (error) {
        console.error(error)
        return
    }
}

// --- DIRECTORIES ---
export const createSingleDirectory = async (
    authToken: string,
    directory: MediaDirectory
): Promise<Dealer | undefined> => {
    try {
        const res = await CustomAxios.post(`${baseUrl}media-directories`, directory, getRequestHeader(authToken)).then(
            (response) => response.data
        )
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const updateSingleDirectory = async (
    authToken: string,
    directory: MediaDirectory
): Promise<Dealer | undefined> => {
    try {
        const res = await CustomAxios.put(
            `${baseUrl}media-directories/${directory.id}`,
            directory,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const getSingleMediaDirectory = async (
    authToken: string,
    itemId: string
): Promise<MediaDirectory | undefined> => {
    try {
        const res = await CustomAxios.get(`${baseUrl}media-directories/${itemId}`, getRequestHeader(authToken)).then(
            (response) => response.data
        )
        return res
    } catch (error) {
        console.error(error)
        return
    }
}
export const deleteSingleMediaDirectory = async (
    authToken: string,
    itemId: string
): Promise<MediaDirectory | undefined> => {
    try {
        const res = await CustomAxios.delete(`${baseUrl}media-directories/${itemId}`, getRequestHeader(authToken)).then(
            (response) => response.data
        )
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

// --- FAQ ---
export const createSingleFAQ = async (authToken: string, item: FAQItem): Promise<FAQItem | undefined> => {
    try {
        const res = await CustomAxios.post(`${baseUrl}faq`, item, getRequestHeader(authToken)).then(
            (response) => response.data
        )
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const updateSingleFAQ = async (authToken: string, item: FAQItem): Promise<FAQItem | undefined> => {
    try {
        const res = await CustomAxios.put(`${baseUrl}faq/${item.id}`, item, getRequestHeader(authToken)).then(
            (response) => response.data
        )
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const patchSingleFAQ = async (authToken: string, item: FAQItem): Promise<FAQItem | undefined> => {
    try {
        const res = await CustomAxios.patch(`${baseUrl}faq/${item.id}`, item, getRequestHeader(authToken)).then(
            (response) => response.data
        )
        return res
    } catch (error) {
        console.error(error)
        return
    }
}
export const getSingleFAQ = async (authToken: string, itemId: string): Promise<FAQItem | undefined> => {
    try {
        const res = await CustomAxios.get(`${baseUrl}faq/${itemId}`, getRequestHeader(authToken)).then(
            (response) => response.data
        )
        return res
    } catch (error) {
        console.error(error)
        return
    }
}
export const deleteSingleFAQ = async (authToken: string, itemId: string): Promise<FAQItem | undefined> => {
    try {
        const res = await CustomAxios.delete(`${baseUrl}faq/${itemId}`, getRequestHeader(authToken)).then(
            (response) => response.data
        )
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

// --- ICOONE START MATRIX ---

export const getRawIcooneStartMatrix = async (authToken: string): Promise<any | undefined> => {
    try {
        const res = await CustomAxios.post(
            `${baseUrl}generate-icoone-start-model-file`,
            {},
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

// --- SERIALS ---

export const getRawSerialsFile = async (authToken: string): Promise<any | undefined> => {
    try {
        const res = await CustomAxios.post(
            `${baseUrl}generate-serials-model-file`,
            {},
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const importSerials = async (authToken: string, file: any): Promise<string | undefined> => {
    try {
        const formData = new FormData()
        formData.append('file', file)
        await axios
            .post(`${baseUrl}read-serials-model-file`, formData, getRequestHeader(authToken, 'multipart/form-data'))
            .then((response) => response.data)
        return 'ok'
    } catch (error) {
        console.error(error)
        return
    }
}

// --- GENERIC ---

export const getResultBodyParts = async (authToken: string, bodyAreasIds: string): Promise<BodyPart[] | undefined> => {
    try {
        const bodyParts: BodyPart[] = await CustomAxios.get(
            `${baseUrl}body-parts?body_areas=${bodyAreasIds}`,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return bodyParts
    } catch (error) {
        console.error(error)
        return
    }
}

export const getResultMedia = async (authToken: string, resultId: string): Promise<ResultMedia[] | undefined> => {
    try {
        const bodyParts: ResultMedia[] = await CustomAxios.get(
            `${baseUrl}result-media?id_result=${resultId}`,
            getRequestHeader(authToken)
        ).then((response) => response.data.items)
        return bodyParts
    } catch (error) {
        console.error(error)
        return
    }
}

export const getResultMediaOfBodyPart = async (
    authToken: string,
    resultId: string,
    bodyPartId: string
): Promise<ResultMedia[] | undefined> => {
    try {
        const bodyParts: ResultMedia[] = await CustomAxios.get(
            `${baseUrl}result-media?id_result=${resultId}&id_body_part=${bodyPartId}`,
            getRequestHeader(authToken)
        ).then((response) => response.data.items)
        return bodyParts
    } catch (error) {
        console.error(error)
        return
    }
}

export const importIcooneStartMatrix = async (authToken: string, file: any): Promise<string | undefined> => {
    try {
        const formData = new FormData()
        formData.append('file', file)
        await axios
            .post(
                `${baseUrl}read-icoone-start-model-file`,
                formData,
                getRequestHeader(authToken, 'multipart/form-data')
            )
            .then((response) => response.data)
        return 'ok'
    } catch (error) {
        console.error(error)
        throw error
    }
}

export const addMediaFile = async (
    authToken: string,
    media: any,
    entityId: string,
    entity: MediaFilesEntity,
    locale?: string
): Promise<MediaFile | undefined> => {
    try {
        const formData = new FormData()
        formData.append('entity', entity)
        formData.append('media', media)
        formData.append('entity_id', entityId)
        if (locale && locale.length > 0) formData.append('locale', locale)
        const res = await axios
            .post(`${baseUrl}media-manager`, formData, getRequestHeader(authToken, 'multipart/form-data'))
            .then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const deleteMediaFile = async (
    authToken: string,
    mediaFileId: string,
    entity: MediaFilesEntity,
    locale: string
): Promise<string | undefined> => {
    try {
        await CustomAxios.delete(
            `${baseUrl}media-manager/${entity}/${mediaFileId}?locale=${locale}`,
            getRequestHeader(authToken)
        )
        return 'ok'
    } catch (error) {
        console.error(error)
        return
    }
}

export const translate = async (
    authToken: string,
    data: any,
    sourceLanguage: string,
    targetLanguages: string[],
    terms: string[]
): Promise<any> => {
    try {
        const payload = {
            data,
            source_language_code: sourceLanguage,
            target_language_code: targetLanguages,
            terms,
        }
        const res = await CustomAxios.post(
            `${baseUrl}translate`,
            JSON.stringify(payload),
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const getMediaScopeCategories = async (
    authToken: string,
    mediaScopeId: number
): Promise<MediaScopeCategory[] | undefined> => {
    try {
        const res = await CustomAxios.get(
            `${baseUrl}media-categories?media_scope=${mediaScopeId}`,
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}

export const getNotifications = async (params: {
    authToken: string
    entity: string
    entityId: string
    type?: string
    isRead?: boolean
}): Promise<NotificationItem[] | undefined> => {
    try {
        let url = `${baseUrl}notifications?`
        // eslint-disable-next-line array-callback-return
        Object.keys(params).map((key: string) => {
            url += `&${toSnakeCase(key)}=${params[key as keyof typeof params]}`
        })

        const notifications: NotificationItem[] = await CustomAxios.get(url, getRequestHeader(params.authToken)).then(
            (response) => response.data
        )
        return notifications
    } catch (error) {
        console.error(error)
        return
    }
}

export const getCommercialAreas = async (authToken: string): Promise<PaginatedResponse<CommercialArea>> => {
    try {
        const res = await CustomAxios.get(`${baseUrl}commercial-areas`, getRequestHeader(authToken)).then(
            (response) => response.data
        )
        return res
    } catch (error) {
        console.error(error)
        return { items: [], count: 0 }
    }
}

export const markNotificationAsRead = async (
    authToken: string,
    idNotification: string
): Promise<Protocol | undefined> => {
    try {
        const res = await CustomAxios.post(
            `${baseUrl}notifications/${idNotification}/read`,
            {},
            getRequestHeader(authToken)
        ).then((response) => response.data)
        return res
    } catch (error) {
        console.error(error)
        return
    }
}
