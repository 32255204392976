import React, { useMemo } from 'react'
import './badges.sass'
import { EntityStatus, ModelUploadStatus } from '../../types/data'
import { formatEntityStatusName } from '../../utility/functions'

const StatusLabel: React.FC<{ status: EntityStatus | ModelUploadStatus, label?: string }> = ({ status, label }) => {
    const getStatusLabel = useMemo(() => {
        return formatEntityStatusName(status)
    }, [status])

    const getStatusClass = useMemo(() => {
        switch (status) {
            case EntityStatus.ACTIVE:
            case ModelUploadStatus.DONE:
                return 'active'
            case EntityStatus.CANCELED:
            case ModelUploadStatus.ERROR:
                return 'cancelled'
            case EntityStatus.PENDING:
            case ModelUploadStatus.UPLOADING:
            default:
                return 'pending'
        }
    }, [status])

    return <div className={`status-label ${getStatusClass}-status-label`}>{label || getStatusLabel}</div>
}

export default StatusLabel
