/* eslint-disable nonblock-statement-body-position */
import React, { useEffect, useState } from 'react'
import TextInput from '../../../components/Inputs/TextInput'
import SingleSelect from '../../../components/Inputs/SingleSelect'
import Button from '../../../components/Buttons/Button'
import {
    BodyPart,
    BodySectionMeasure,
    EntityStatus,
    InfoItem,
    MediaFilesEntity,
    Protocol,
    ResultMedia,
    ResultMediaToCreate,
    ResultToCreate,
    SimpleEntity,
} from '../../../types/data'
import { ToastError, ToastSuccess } from '../../../utility/toast'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../resources/routes-constants'
import MultiSelect from '../../../components/Inputs/MultiSelect'
import { useAppSelector } from '../../../store/reducers/store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import UppercaseLabel from '../../../components/Typography/UppercaseLabel'
import ResultMediaSection from '../../../custom-components/ResultMediaSection'
import FixedMeasureInput from '../../../components/Inputs/FixedMeasureInput'
import { formatItemForSelectOptions, formatItemsForSelectOptions } from '../../../utility/functions'
import {
    addMediaFile,
    createResultMedia,
    createSingleResult,
    getResultBodyParts,
    getSingleProtocol,
} from '../../../resources/api-constants'
import Datepicker from '../../../components/DatePicker/Datepicker'
import ImportProtocolModal from '../../../components/Modal/ImportProtocolModal'
import TextareaInput from '../../../components/Inputs/TextareaInput'
import { cloneDeep } from 'lodash'

const pregnancyOptions = [
    {
        value: '1',
        label: 'No',
    },
    {
        value: '2',
        label: 'Si',
    },
    {
        value: '3',
        label: 'In corso',
    },
]

const smokeOptions = [
    {
        value: 'no',
        label: 'No',
    },
    {
        value: 'yes',
        label: 'Si',
    },
]

const hydrationOptions = [
    {
        value: '<1L',
        label: '<1L',
    },
    {
        value: '>1L<2L',
        label: '>1L<2L',
    },
    {
        value: '>2L',
        label: '>2L',
    },
]

const defaultResult: ResultToCreate = {
    startDate: '',
    endDate: '',
    idCenter: -1,
    status: EntityStatus.PENDING,
    evaluation: null,
    idPriorityIntensity: '',
    priorityDescription: '',
    resultDescription: '',
    notes: '',
    bodyAreas: [],
    goals: [],
    priorities: [],
    applicationTypes: [],
    protocols: [],
    idLifeMoment: null,
    idPhisicalActivity: '-1',
    skinTypes: [],
    idCustomerGender: '-1',
    customerAge: '',
    devices: [],
    idFrequency: '-1',
    sessionsNumber: '',
    bodySectionMeasures: [],
    customerHydration: null,
    customerPregnancy: null,
    customerPregnancyNumber: null,
    customerSmoke: null,
    customerWeight: null,
    idChildbirthType: null,
    idAlcoholConsumption: null,
    idHealtyDiet: null,
    idStressLevel: null,
}

const NewResultPage: React.FC = () => {
    const data = useAppSelector((data) => data.data)
    const user = useAppSelector((data) => data.user)
    const [showImportProtocolModal, setShowImportProtocolModal] = useState(false)
    const [result, setResult] = useState<ResultToCreate>(defaultResult)
    const [protocolsToImportIds, setProtocolsToImportIds] = useState<string[]>([])
    const [selectedGoalsIds, setSelectedGoalsIds] = useState<string[]>([])
    const [protocolsToImport, setProtocolsToImport] = useState<(Protocol | undefined)[]>([])
    const [resultBodyParts, setResultBodyParts] = useState<BodyPart[]>([])
    const [resultMediaToUpload, setResultMediaToUpload] = useState<any[][]>([[]])
    const [isLoading, setIsLoading] = useState(false)

    const navigate = useNavigate()

    const calculateBodyParts = async () => {
        try {
            const fetchedResultBodyParts = await getResultBodyParts(
                user.loggedUserData?.authToken || '',
                result.bodyAreas.join(',')
            )
            if (fetchedResultBodyParts) setResultBodyParts(fetchedResultBodyParts)
            setResultMediaToUpload(new Array<any[]>(fetchedResultBodyParts?.length ?? 0).fill([]))
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (result.bodyAreas && result.bodyAreas.length > 0) {
            void calculateBodyParts()
            const defaultMeasures = data.bodySections.map((bodySection) => ({
                idBodySection: bodySection.id,
                measure: '',
            }))
            if (result.bodyAreas.includes('2')) setResult({ ...result, bodySectionMeasures: defaultMeasures })
        } else setResultBodyParts([])
    }, [result.bodyAreas])

    const startCreateResultProcedure = async () => {
        setIsLoading(true)
        try {
            const data = JSON.parse(JSON.stringify(result))
            delete data.id
            data.idCenter = null
            data.bodySectionMeasures = data.bodySectionMeasures.filter((bsm: BodySectionMeasure) => bsm.measure)
            data.protocols = protocolsToImport.map((p) => ({
                idProtocol: p?.id || '',
                sessionDuration: p?.sessionDuration?.value || '',
            }))
            data.applicationTypes = protocolsToImport.flatMap((p) => p?.applicationTypes.map((pAT) => pAT.id))
            const res = await createSingleResult(user.loggedUserData?.authToken || '', data)
            for (let i = 0; i < resultMediaToUpload.length; i++) {
                if (resultMediaToUpload[i].length === 0) {
                    continue
                }
                await startUploadProcess(
                    res?.id || '-1',
                    resultMediaToUpload[i],
                    data.startDate || '',
                    data.endDate || ''
                )
            }
            if (res) {
                setIsLoading(false)
                ToastSuccess('Risultato creato con successo')
                navigate(`${ROUTES.RESULT_DETAIL_ROUTE}${res.id}`)
            }
        } catch (error) {
            setIsLoading(false)
            console.error(error)
            ToastError('Si è verificato un errore durante la procedura di creazione!')
        }
    }

    const startUploadProcess = async (
        resultId: string,
        rawMediaArray: { file: any; idBodyPart: number }[],
        resultStartDate: string,
        resultEndDate: string
    ) => {
        try {
            for (let i = 0; i < rawMediaArray.length; i++) {
                const rawMedia = rawMediaArray[i]

                if (!rawMedia || !rawMedia.file || !(rawMedia.file instanceof File)) return

                const newResultMedia: ResultMediaToCreate = {
                    idResult: parseInt(resultId, 10),
                    idBodyPart: rawMedia.idBodyPart,
                    sessionDate: i === 0 ? resultStartDate : resultEndDate,
                    sessionIndex: i,
                }
                const createdMedia: ResultMedia | undefined = await createResultMedia(
                    user.loggedUserData?.authToken || '',
                    newResultMedia
                )
                if (!createdMedia) throw new Error('Error during creation of new result media')

                const mediaFile = await addMediaFile(
                    user.loggedUserData?.authToken || '',
                    rawMedia.file,
                    createdMedia.id?.toString() || '',
                    MediaFilesEntity.RESULT_MEDIA
                )

                mediaFile
            }
        } catch (error) {
            console.error(error)
            ToastError('Si è verificato un errore durante la procedura di caricamento dei media!')
        }
    }

    const getProtocolsData = async () => {
        try {
            const fetchedProtocols: (Protocol | undefined)[] = await Promise.all(
                protocolsToImportIds.map(
                    async (protocolId) => await getSingleProtocol(user.loggedUserData?.authToken || '', protocolId)
                )
            )
            if (fetchedProtocols) {
                const validProtocols = fetchedProtocols.filter((protocol) => protocol !== undefined)
                setProtocolsToImport(validProtocols)
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (!protocolsToImportIds) return
        void getProtocolsData()
    }, [protocolsToImportIds])

    return (
        <>
            {showImportProtocolModal && (
                <ImportProtocolModal
                    modalTitle="Importa protocolli"
                    importedIds={protocolsToImportIds}
                    onClose={() => setShowImportProtocolModal(false)}
                    onConfirm={(protocolIds) => {
                        setProtocolsToImportIds([...protocolsToImportIds, ...protocolIds])
                        setShowImportProtocolModal(false)
                    }}
                />
            )}
            <div className="page-header-section">
                <div className="page-header-section__left-box" />
                <div className="page-header-section__right-box">
                    <div style={{ display: 'flex', gap: 16 }}>
                        <Button
                            buttonType="secondary"
                            onClick={() => navigate(ROUTES.RESULTS_ROUTE)}
                            loading={isLoading}
                        >
                            Annulla
                        </Button>
                        <Button
                            loading={isLoading}
                            buttonType="primary"
                            onClick={() => {
                                void startCreateResultProcedure()
                            }}
                        >
                            Carica risultato
                        </Button>
                    </div>
                </div>
            </div>
            <div className="page-content-flow">
                <div className="elevated-card full-width">
                    <div className="input-form-box">
                        <div className="input-form-box__three-col-row">
                            <div style={{ marginLeft: 6 }}>
                                <div style={{ marginBottom: 6 }}>
                                    <UppercaseLabel>Data di inizio</UppercaseLabel>
                                </div>
                                <Datepicker
                                    sendDate={(date) => setResult({ ...result, startDate: date?.toISOString() || '' })}
                                />
                            </div>
                            <div style={{ marginLeft: 6 }}>
                                <div style={{ marginBottom: 6 }}>
                                    <UppercaseLabel>Data di fine</UppercaseLabel>
                                </div>
                                <Datepicker
                                    sendDate={(date) => setResult({ ...result, endDate: date?.toISOString() || '' })}
                                />
                            </div>
                        </div>
                        <div className="horizontal-line-separator" />
                        <div className="input-form-box__three-col-row">
                            <SingleSelect
                                inputLabel="genere"
                                placeholder="Seleziona il genere"
                                options={data.customerGenders.map((customerGender) => ({
                                    value: customerGender.id,
                                    label: customerGender.name,
                                }))}
                                onValueChange={(newVal) => {
                                    if (!newVal) return
                                    setResult({
                                        ...result,
                                        idCustomerGender: newVal.value,
                                    })
                                }}
                            />
                            <TextInput
                                inputLabel="età cliente"
                                value={result?.customerAge || ''}
                                onValueChange={(newVal) => setResult({ ...result, customerAge: newVal })}
                            />
                            {result.idCustomerGender ===
                                data.customerGenders.find((gender) => gender.name === 'Femmina')?.id && (
                                <>
                                    <SingleSelect
                                        inputLabel="momento della vita"
                                        placeholder="Seleziona un momento della vita"
                                        options={data.lifeMoments.map((lifeMoment) => ({
                                            value: lifeMoment.id,
                                            label: lifeMoment.name,
                                        }))}
                                        onValueChange={(newVal) =>
                                            setResult({ ...result, idLifeMoment: newVal?.value || '' })
                                        }
                                    />
                                    <SingleSelect
                                        inputLabel="gravidanze"
                                        placeholder="Seleziona un valore"
                                        options={pregnancyOptions}
                                        onValueChange={(newVal) =>
                                            setResult({ ...result, customerPregnancy: newVal?.value === '2' ?? null })
                                        }
                                    />
                                    {result.customerPregnancy && result.customerPregnancy && (
                                        <TextInput
                                            inputLabel="numero di gravidanze"
                                            value={result.customerPregnancyNumber || ''}
                                            onValueChange={(newVal) =>
                                                setResult({ ...result, customerPregnancyNumber: newVal })
                                            }
                                        />
                                    )}
                                    <SingleSelect
                                        inputLabel="parto"
                                        placeholder="Seleziona un tipo di parto"
                                        options={data.childbirthTypes.map((childbirthType) => ({
                                            value: childbirthType.id,
                                            label: childbirthType.name,
                                        }))}
                                        onValueChange={(newVal) =>
                                            setResult({ ...result, idChildbirthType: newVal?.value || '' })
                                        }
                                    />
                                </>
                            )}
                            <SingleSelect
                                inputLabel="Livello attività fisica"
                                placeholder="Seleziona un livello"
                                options={data.phisicalActivities.map((phisicalActivity) => ({
                                    value: phisicalActivity.id,
                                    label: phisicalActivity.name,
                                }))}
                                onValueChange={(newVal) =>
                                    setResult({ ...result, idPhisicalActivity: newVal?.value || '' })
                                }
                            />
                            <SingleSelect
                                inputLabel="idratazione"
                                placeholder="Seleziona un livello"
                                options={hydrationOptions}
                                onValueChange={(newVal) =>
                                    setResult({ ...result, customerHydration: newVal?.value || '' })
                                }
                            />
                            <SingleSelect
                                inputLabel="Dieta sana"
                                placeholder="Seleziona un parametro"
                                options={data.healthyDiets.map((healthyDiet) => ({
                                    value: healthyDiet.id,
                                    label: healthyDiet.name,
                                }))}
                                onValueChange={(newVal) => setResult({ ...result, idHealtyDiet: newVal?.value || '' })}
                            />
                            <SingleSelect
                                inputLabel="Fumo"
                                placeholder="Seleziona un valore"
                                options={smokeOptions}
                                onValueChange={(newVal) =>
                                    setResult({ ...result, customerSmoke: newVal?.value === 'yes' ?? null })
                                }
                            />
                            <SingleSelect
                                inputLabel="Livello di stress"
                                placeholder="Seleziona un livello"
                                options={data.stressLevels.map((stressLevel) => ({
                                    value: stressLevel.id,
                                    label: stressLevel.name,
                                }))}
                                onValueChange={(newVal) => setResult({ ...result, idStressLevel: newVal?.value || '' })}
                            />
                            <SingleSelect
                                inputLabel="Consumo di alcolici"
                                placeholder="Seleziona un parametro"
                                options={data.alcoholConsumptions.map((alcoholConsumption) => ({
                                    value: alcoholConsumption.id,
                                    label: alcoholConsumption.name,
                                }))}
                                onValueChange={(newVal) =>
                                    setResult({ ...result, idAlcoholConsumption: newVal?.value || '' })
                                }
                            />
                        </div>
                        {result.bodyAreas.includes('7') && (
                            <>
                                <div className="horizontal-line-separator" />
                                <div className="input-form-box__three-col-row">
                                    <div>
                                        <div style={{ marginBottom: 6 }}>
                                            <UppercaseLabel>Misure corpo</UppercaseLabel>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-form-box__three-col-row">
                                    {data.bodySections.map((bodySection) => (
                                        <FixedMeasureInput
                                            key={bodySection.id}
                                            inputLabel={bodySection.name}
                                            value={
                                                result.bodySectionMeasures.find(
                                                    (section) => section.idBodySection.toString() === bodySection.id
                                                )?.measure || ''
                                            }
                                            type="number"
                                            min={0}
                                            onValueChange={(newVal) =>
                                                setResult({
                                                    ...result,
                                                    bodySectionMeasures: result.bodySectionMeasures.map(
                                                        (bodySectionMeasure) => {
                                                            if (
                                                                bodySectionMeasure.idBodySection.toString() ===
                                                                bodySection.id
                                                            )
                                                                return {
                                                                    ...bodySectionMeasure,
                                                                    measure: newVal,
                                                                }
                                                            return bodySectionMeasure
                                                        }
                                                    ),
                                                })
                                            }
                                            valuePostfix="cm"
                                        />
                                    ))}
                                    <FixedMeasureInput
                                        inputLabel="peso"
                                        value={result.customerWeight || ''}
                                        min={0}
                                        type="number"
                                        onValueChange={(newVal) => setResult({ ...result, customerWeight: newVal })}
                                        valuePostfix="Kg"
                                    />
                                </div>
                            </>
                        )}
                        <div className="horizontal-line-separator" />
                        <div className="input-form-box__three-col-row">
                            <MultiSelect
                                inputLabel="tipo di pelle"
                                placeholder="Seleziona uno o più tipi di pelle"
                                values={formatItemsForSelectOptions<InfoItem>(
                                    data.skinTypes.filter((skinType) => result.skinTypes.indexOf(skinType.id) > -1)
                                )}
                                options={data.skinTypes.map((skinType) => ({
                                    value: skinType.id.toString(),
                                    label: skinType.name,
                                }))}
                                onValueChange={(newValues) =>
                                    setResult({ ...result, skinTypes: newValues.map((value) => value.value) })
                                }
                            />
                            <MultiSelect
                                inputLabel="area interessata"
                                placeholder="Seleziona una o più aree del corpo"
                                values={formatItemsForSelectOptions<InfoItem>(
                                    data.bodyAreas.filter((bodyArea) => result.bodyAreas.indexOf(bodyArea.id) > -1)
                                )}
                                options={data.bodyAreas.map((bodyArea) => ({
                                    value: bodyArea.id.toString(),
                                    label: bodyArea.name,
                                }))}
                                onValueChange={(newValues) =>
                                    setResult({ ...result, bodyAreas: newValues.map((value) => value.value) })
                                }
                            />
                            <MultiSelect
                                inputLabel="obiettivi"
                                placeholder="Seleziona uno o più obiettivi"
                                values={formatItemsForSelectOptions<InfoItem>(
                                    data.goals.filter((goal) => result.goals.indexOf(goal.id) > -1)
                                )}
                                options={data.priorities
                                    .filter((priority) =>
                                        result.bodyAreas.find(
                                            (bodyArea) =>
                                                priority.bodyAreas
                                                    .map((pBodyArea) => pBodyArea.id.toString())
                                                    .indexOf(bodyArea) > -1
                                        )
                                    )
                                    .map((priority) => {
                                        return {
                                            value: priority.goal.id.toString(),
                                            label: priority.goal.name,
                                        }
                                    })
                                    .filter(
                                        (goal, index, self) => self.findIndex((g) => g.value === goal.value) === index
                                    )}
                                onValueChange={(newValues) => {
                                    setResult({ ...result, goals: newValues.map((value) => value.value) })
                                    setSelectedGoalsIds(newValues.map((value) => value.value))
                                }}
                            />
                            <MultiSelect
                                inputLabel="priorità"
                                placeholder="Seleziona una o più priorità"
                                disabled={result.goals.length === 0}
                                values={formatItemsForSelectOptions<InfoItem>(
                                    data.priorities.filter((priority) => result.priorities.indexOf(priority.id) > -1)
                                )}
                                options={data.priorities
                                    .filter((priority) =>
                                        result.bodyAreas.find(
                                            (bodyArea) =>
                                                priority.bodyAreas
                                                    .map((pBodyArea) => pBodyArea.id.toString())
                                                    .indexOf(bodyArea) > -1
                                        )
                                    )
                                    .filter((problem) => selectedGoalsIds.indexOf(problem.goal.id.toString()) > -1)
                                    .map((priority) => ({
                                        value: priority.id.toString(),
                                        label: priority.name,
                                    }))}
                                onValueChange={(newValues) =>
                                    setResult({ ...result, priorities: newValues.map((value) => value.value) })
                                }
                            />
                            <SingleSelect
                                inputLabel="intensità del problema"
                                placeholder="Seleziona l'intensità del problema"
                                value={formatItemForSelectOptions<InfoItem>(
                                    data.priorityIntensities.find(
                                        (problemIntensity) => result.idPriorityIntensity === problemIntensity.id
                                    )
                                )}
                                options={data.priorityIntensities.map((priorityIntensity) => ({
                                    value: priorityIntensity.id,
                                    label: priorityIntensity.name,
                                }))}
                                onValueChange={(newValue) => {
                                    if (!newValue) return
                                    setResult({ ...result, idPriorityIntensity: newValue.value })
                                }}
                            />
                            <TextareaInput
                                inputLabel="Descrizione problema"
                                placeholder="Descrivi brevemente il problema del cliente"
                                value={result.priorityDescription}
                                onValueChange={(newVal) => setResult({ ...result, priorityDescription: newVal })}
                            />
                            <TextareaInput
                                inputLabel="Descrizione risultato"
                                placeholder="Descrivi brevemente il risultato raggiunto"
                                value={result.resultDescription}
                                onValueChange={(newVal) => setResult({ ...result, resultDescription: newVal })}
                            />
                            <SingleSelect
                                inputLabel="numero di sessioni"
                                placeholder="Seleziona il numero di sessioni"
                                value={formatItemForSelectOptions<SimpleEntity>(
                                    data.sessionsNumbers.find(
                                        (sessionsNumber) => result.sessionsNumber === sessionsNumber.id
                                    )
                                )}
                                options={data.sessionsNumbers.map((sessionsNumber) => ({
                                    value: sessionsNumber.id,
                                    label: sessionsNumber.value,
                                }))}
                                onValueChange={(newValue) => {
                                    if (!newValue) return
                                    setResult({ ...result, sessionsNumber: newValue.value })
                                }}
                            />
                            <SingleSelect
                                inputLabel="frequenza"
                                placeholder="Seleziona la frequenza"
                                value={formatItemForSelectOptions<SimpleEntity>(
                                    data.frequencies.find((frequency) => result.idFrequency === frequency.id)
                                )}
                                options={data.frequencies.map((frequency) => ({
                                    value: frequency.id,
                                    label: frequency.name ?? '',
                                }))}
                                onValueChange={(newValue) => {
                                    if (!newValue) return
                                    setResult({ ...result, idFrequency: newValue.value })
                                }}
                            />
                            <MultiSelect
                                inputLabel="Dispositivi"
                                placeholder="Seleziona uno o più dispositivi"
                                options={data.devices.map((device) => {
                                    return { value: device.id, label: device.name }
                                })}
                                onValueChange={(newValues) => {
                                    setResult({
                                        ...result,
                                        devices: newValues.map((newVal) => newVal.value),
                                    })
                                }}
                            />
                        </div>
                        <div className="horizontal-line-separator" />
                        <Button buttonType="primary" onClick={() => setShowImportProtocolModal(true)}>
                            Importa protocolli
                        </Button>
                        <div className="input-form-box__three-col-row">
                            {protocolsToImport &&
                                protocolsToImport.map((protocolToImport) => {
                                    if (!protocolToImport) return
                                    return (
                                        <div key={protocolToImport.id} style={{ width: '100%' }}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    gap: 16,
                                                    margin: '12px 0',
                                                }}
                                            >
                                                <div style={{ marginLeft: 6 }}>
                                                    <UppercaseLabel>Protocollo</UppercaseLabel>
                                                    <div>{protocolToImport.name}</div>
                                                </div>
                                                <Button
                                                    buttonType="ghost"
                                                    onClick={() => {
                                                        setProtocolsToImport(
                                                            protocolsToImport.filter(
                                                                (protocol) => protocol?.id !== protocolToImport.id
                                                            )
                                                        )
                                                        setProtocolsToImportIds(
                                                            protocolsToImportIds.filter(
                                                                (protocolId) =>
                                                                    protocolId !== protocolToImport.id.toString()
                                                            )
                                                        )
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faTrash} size="xs" color="red" />
                                                </Button>
                                            </div>
                                            <TextInput
                                                inputLabel="Durata sessioni"
                                                placeholder="Esprimi la durata in minuti"
                                                value={protocolToImport.sessionDuration?.value}
                                                onValueChange={(newVal) =>
                                                    setProtocolsToImport(
                                                        protocolsToImport.map((p) => {
                                                            if (p?.id === protocolToImport.id)
                                                                return {
                                                                    ...protocolToImport,
                                                                    sessionDuration: {
                                                                        id: '-1',
                                                                        value: newVal,
                                                                    },
                                                                }
                                                            return protocolToImport
                                                        })
                                                    )
                                                }
                                            />
                                        </div>
                                    )
                                })}
                        </div>
                        {resultBodyParts && resultBodyParts.length > 0 && (
                            <>
                                <div className="horizontal-line-separator" />
                                <div className="input-form-box__three-col-row">
                                    <div style={{ marginLeft: 6 }}>
                                        <div style={{ marginBottom: 6 }}>
                                            <UppercaseLabel>avanzamento temporale</UppercaseLabel>
                                        </div>
                                    </div>
                                    <div />
                                    <div />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'flex-start', gap: 16, overflowX: 'auto' }}>
                                    {resultBodyParts.map((resultBodyPart, i) => {
                                        return (
                                            <ResultMediaSection
                                                index={i}
                                                key={resultBodyPart.id}
                                                canEdit
                                                canDelete
                                                idResult="-1"
                                                bodyPart={resultBodyPart}
                                                onRawMediaChange={(rawMediaArray) => {
                                                    const currentResultMediaToUpload = cloneDeep(resultMediaToUpload)
                                                    currentResultMediaToUpload[i] = rawMediaArray
                                                    setResultMediaToUpload(currentResultMediaToUpload)
                                                }}
                                            />
                                        )
                                    })}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default NewResultPage
