import React, { useEffect, useState } from 'react'
import Table, { TableAction, TableColumn } from '../../../components/Table/Table'
import TableHeader from '../../../components/Table/TableHeader'
import Button from '../../../components/Buttons/Button'
import AdvancedSearch from '../../../components/AdvancedSearch/AdvancedSearch'
import TextInput from '../../../components/Inputs/TextInput'
import { ROUTES } from '../../../resources/routes-constants'
import { useNavigate } from 'react-router-dom'
import { Certification } from '../../../types/data'
import { useAppSelector } from '../../../store/reducers/store'
import { ToastError } from '../../../utility/toast'
import Link from '../../../components/Link/Link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { confirmFilterObject } from '../../../utility/functions'
import AlertModal from '../../../components/Modal/AlertModal'
import { deleteSingleCertification } from '../../../resources/api-constants'

interface CertificationSearchFilters {
    name: string
}

const defaultFiltersState: CertificationSearchFilters = {
    name: '',
}

const CertificationsPage: React.FC = () => {
    const user = useAppSelector((data) => data.user)
    const [selectedCertification, setSelectedCertification] = useState<Certification | null>(null)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [forceDeleteReload, setForceDeleteReload] = useState(false)
    const navigate = useNavigate()

    const [showAdvancedSearch, setShowAdvancedSearch] = useState(false)
    const [resetFlag, setResetFlag] = useState(false)
    const [certificationFilters, setCertificationFilters] = useState<CertificationSearchFilters>(defaultFiltersState)
    const [finalFilters, setFinalFilters] = useState<CertificationSearchFilters | undefined>(undefined)

    useEffect(() => {
        if (resetFlag) setResetFlag(!resetFlag)
    }, [resetFlag])

    useEffect(() => {
        if (forceDeleteReload) setForceDeleteReload(!forceDeleteReload)
    }, [forceDeleteReload])

    const resetSearchParams = () => {
        if (JSON.stringify(certificationFilters) === JSON.stringify(defaultFiltersState)) return
        setCertificationFilters(defaultFiltersState)
        setFinalFilters(confirmFilterObject(defaultFiltersState))
        setResetFlag(true)
    }

    const startDeleteProcedure = (certification: Certification) => {
        setSelectedCertification(certification)
        setShowDeleteModal(true)
    }

    const endRemoveCertificationProcedure = () => {
        setSelectedCertification(null)
        setShowDeleteModal(false)
        setForceDeleteReload(true)
    }

    const removeCertification = async () => {
        try {
            const res = await deleteSingleCertification(
                user.loggedUserData?.authToken || '',
                selectedCertification?.id || ''
            )
            if (res) endRemoveCertificationProcedure()
            else ToastError('Si è verificato un errore durante la procedura di eliminazione!')
        } catch (error) {
            console.error(error)
        }
    }

    const columns: TableColumn[] = [
        {
            title: 'Nome',
            field: 'name',
            render: (data: Certification) => (
                <Link internalRoute={`${ROUTES.CERTIFICATION_DETAIL_ROUTE}${data.id}`}>{data.name}</Link>
            ),
        },
        {
            title: 'Descrizione',
            field: 'description',
            render: (data: Certification) => <p>{data.description}</p>,
        },
        {
            title: 'Validità',
            field: 'validity',
            render: (data: Certification) => <p>{data.validity}</p>,
        },
    ]

    const actions: TableAction[] = [
        (rowData: Certification) => ({
            icon: () => <FontAwesomeIcon icon={faPen} size="xs" />,
            onClick: () => navigate(`${ROUTES.CERTIFICATION_DETAIL_ROUTE}${rowData.id}`),
            tooltip: 'Modifica',
        }),
        (rowData: Certification) => ({
            icon: () => <FontAwesomeIcon icon={faTrash} size="xs" />,
            onClick: () => startDeleteProcedure(rowData),
            tooltip: 'Elimina',
        }),
    ]

    return (
        <>
            {showDeleteModal && (
                <AlertModal
                    modalTitle="Elimina certificazione"
                    modalMessage={`Sei sicuro di voler eliminare l'elemento ${selectedCertification?.name}? Questa operazione è irreversibile.`}
                    onClose={() => setShowDeleteModal(false)}
                    onConfirm={() => void removeCertification()}
                />
            )}
            <div className="page-header-section">
                <div className="page-header-section__left-box">
                    <span className="page-title">Elenco certificazioni</span>
                </div>
                <div className="page-header-section__right-box">
                    <Button
                        customClassName={`${showAdvancedSearch ? 'active' : ''}`}
                        buttonType="ghost"
                        onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
                    >
                        Ricerca avanzata
                    </Button>
                    <Button buttonType="primary" onClick={() => navigate(`${ROUTES.CERTIFICATION_DETAIL_ROUTE}new`)}>
                        Crea nuova certificazione
                    </Button>
                </div>
            </div>
            <div className="page-content-block">
                <AdvancedSearch
                    isVisible={showAdvancedSearch}
                    onReset={() => resetSearchParams()}
                    onSearch={() => setFinalFilters(confirmFilterObject(certificationFilters))}
                >
                    <div className="input-form-box__three-col-row">
                        <TextInput
                            value={certificationFilters.name}
                            inputLabel="nome"
                            placeholder="Cerca per nome..."
                            onValueChange={(newVal) =>
                                setCertificationFilters({ ...certificationFilters, name: newVal })
                            }
                        />
                    </div>
                </AdvancedSearch>
                <div className="relative">
                    <TableHeader positionIndex={23} />
                    <Table
                        actions={actions}
                        columns={columns}
                        reloads={resetFlag}
                        forceDeleteReload={forceDeleteReload}
                        filterParams={finalFilters}
                        endpoint="certifications"
                    />
                </div>
            </div>
        </>
    )
}

export default CertificationsPage
