import React, { useEffect, useMemo, useState } from 'react'
import TextInput from '../../../components/Inputs/TextInput'
import SingleSelect from '../../../components/Inputs/SingleSelect'
import { SelectOption } from '../../../components/Inputs/MultiSelect'
import Button from '../../../components/Buttons/Button'
import Paragraph from '../../../components/Typography/Paragraph'
import {
    Country,
    Dealer,
    DealerCertification,
    DealerToEdit,
    Device,
    EntityStatus,
    Language,
    MinifiedDealerCertification,
    User,
    UserRole,
} from '../../../types/data'
import { ToastError, ToastSuccess } from '../../../utility/toast'
import { ROUTES } from '../../../resources/routes-constants'
import { useAppSelector } from '../../../store/reducers/store'
import { useNavigate } from 'react-router-dom'
import {
    activateSingleDealerDeviceAssociation,
    createSingleDealer,
    createSingleDealerCertificationAssociation,
    createSingleDealerDeviceAssociation,
    createUser,
    deleteSingleDealer,
    deleteSingleDealerCertificationAssociation,
    deleteSingleDealerDeviceAssociation,
    getSingleDealer,
    sendUserCredentials,
    updateSingleDealer,
    updateUser,
} from '../../../resources/api-constants'
import DevicesAssociation from '../../../components/DevicesAssociation/DevicesAssociation'
import AlertModal from '../../../components/Modal/AlertModal'
import AsyncMultiSelect from '../../../components/Inputs/AsyncMultiSelect'
import CertificationCard from '../../../components/EntityCards/CertificationCard'

const emptyDealer: DealerToEdit = {
    id: '-1',
    city: '',
    name: '',
    status: EntityStatus.ACTIVE,
    address: '',
    province: '',
    zipCode: '',
    idCountry: '',
    devices: [],
    certifications: [],
    idReferent: '',
}

const emptyReferent: User = {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    language: '',
    idRole: UserRole.DealerAdministrator,
    createdOn: '',
    updatedOn: '',
    emailConfirmed: false,
    isFirstAccess: false,
}

const DealerAnagraphic: React.FC<{
    dealerId: string
    emitDealerData: (id: string, name: string, status: EntityStatus) => void
}> = ({ dealerId, emitDealerData }) => {
    const user = useAppSelector((data) => data.user)
    const data = useAppSelector((data) => data.data)
    const [dealer, setDealer] = useState<DealerToEdit>(emptyDealer)
    const [originalDealerObject, setOriginalDealerObject] = useState<Dealer | null>(null)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [resetBadgesSelector, setResetBadgesSelector] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [dealerDevices, setDealerDevices] = useState<string[]>([])
    const [dealerDevicesApprovated, setDealerDevicesApprovated] = useState<string[]>([])
    const [dealerCertifications, setDealerCertifications] = useState<MinifiedDealerCertification[]>([])
    const [referent, setReferent] = useState<User>(emptyReferent)

    const navigate = useNavigate()

    const parseDealer = (dealer: Dealer): DealerToEdit => {
        return {
            ...dealer,
            idCountry: dealer.country.id,
            devices: dealer.devices.map((device) => device.id.toString()),
            certifications: dealer.certifications.map((certification) => ({
                idCertification: certification.idCertification?.toString() || '',
                validUntil: certification.validUntil,
            })),
            idReferent: dealer.referent.id.toString(),
        }
    }

    const fetchDealerData = async () => {
        setIsLoading(true)

        try {
            const fetchedDealer = await getSingleDealer(user.loggedUserData?.authToken || '', dealerId)
            if (fetchedDealer) {
                setReferent(fetchedDealer.referent)
                setOriginalDealerObject(fetchedDealer)
                emitDealerData(fetchedDealer.id ?? '', fetchedDealer.name, fetchedDealer.status)
                setDealer(parseDealer(fetchedDealer))
                setDealerDevices(fetchedDealer.devices.map((device) => device.id))
                setDealerCertifications(
                    fetchedDealer.certifications.map((certification) => ({
                        idCertification: certification.idCertification?.toString() || '',
                        validUntil: certification.validUntil,
                    }))
                )
            }
        } catch (error) {
            console.error(error)
            ToastError('Si è verificato un errore durante il recupero dei dati!')
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (dealerId !== '-1') void fetchDealerData()
    }, [dealerId])

    useEffect(() => {
        if (resetBadgesSelector) setResetBadgesSelector(false)
    }, [resetBadgesSelector])

    const startDeleteProcedure = () => {
        setShowDeleteModal(true)
    }

    const endRemoveCenterProcedure = () => {
        ToastSuccess('Distributore rimosso con successo')
        navigate(ROUTES.DEALERS_ROUTE)
    }

    const startRemoveDealerProcedure = async () => {
        try {
            setIsLoading(true)
            const res = await deleteSingleDealer(user.loggedUserData?.authToken || '', dealerId)
            if (!res) ToastError('Errore: questo distributore potrebbe avere dei centri collegati!')
            else {
                endRemoveCenterProcedure()
            }
            setShowDeleteModal(false)
        } catch (error) {
            console.error(error)
        }
        setIsLoading(false)
    }

    const startCreateDealerProcedure = async () => {
        try {
            setIsLoading(true)
            const data = {
                ...dealer,
                devices: dealerDevices,
                id_referent: '',
            }
            delete data.id

            try {
                const userCreated = await createUser(user.loggedUserData?.authToken || '', referent)
                if (parseInt(userCreated?.idRole?.toString() || '-1', 10) !== UserRole.DealerAdministrator) {
                    switch (parseInt(userCreated?.idRole?.toString() || '-1', 10)) {
                        case UserRole.CenterAdministrator:
                            throw new Error("L'utente è già Amministratore di un centro")
                        case UserRole.Administrator:
                            throw new Error("L'utente è già Amministratore di sistema")
                        default:
                            break
                    }
                }
                if (userCreated === undefined) {
                    ToastError('Email utente già utilizzata')
                } else {
                    data.id_referent = userCreated?.id
                }
            } catch (error: any) {
                ToastError(error.message)
                setIsLoading(false)
                return
            }

            const res = await createSingleDealer(user.loggedUserData?.authToken || '', data)

            if (res) {
                await startAssociateDealerCertificationsProcedure(res.id ?? '')
                await startAssociateDealerDevicesProcedure(res.id ?? '')

                ToastSuccess('Distributore creato con successo')
                emitDealerData(res.id ?? '', res.name, res.status)
                navigate(`${ROUTES.DEALER_DETAIL_ROUTE}${res.id}`)
            }
        } catch (error) {
            console.error(error)
            ToastError('Si è verificato un errore durante la procedura di creazione!')
        }
        setIsLoading(false)
    }

    const startUpdateDealerProcedure = async () => {
        setIsLoading(true)

        try {
            const userPatch = await updateUser(user.loggedUserData?.authToken || '', referent)
            if (userPatch === undefined) {
                ToastError('Email utente già utilizzata')
            }
            // if (parseInt(userPatch?.idRole?.toString() || '-1', 10) !== UserRole.DealerAdministrator) {
            //     switch (parseInt(userPatch?.idRole?.toString() || '-1', 10)) {
            //         case UserRole.CenterAdministrator:
            //             throw new Error("L'utente è già Amministratore di un centro")
            //         case UserRole.Administrator:
            //             throw new Error("L'utente è già Amministratore di sistema")
            //         default:
            //             break
            //     }
            // }
        } catch (error: any) {
            ToastError(error.message)
            setIsLoading(false)
            return
        }

        try {
            const res = await updateSingleDealer(user.loggedUserData?.authToken || '', dealerId, {
                ...dealer,
                devices: dealerDevices,
            })

            await startAssociateDealerCertificationsProcedure(dealerId)
            await startAssociateDealerDevicesProcedure(dealerId)

            if (res) {
                ToastSuccess('Distributore aggiornato con successo')
                await fetchDealerData()
            }
        } catch (error) {
            console.error(error)
            ToastError('Si è verificato un errore durante la procedura di aggiornamento!')
        }
        setIsLoading(false)
    }

    const startSendCredentialsProcedure = async () => {
        try {
            setIsLoading(true)
            await sendUserCredentials(user.loggedUserData?.authToken || '', referent.id)
            ToastSuccess('Credenziali inviate con successo')
        } catch (error) {
            console.error(error)
            ToastError("Si è verificato un errore durante l'invio delle credenziali!")
        }
        setIsLoading(false)
    }

    const startVerifyDealerProcedure = async () => {
        try {
            setIsLoading(true)
            const res = await updateSingleDealer(user.loggedUserData?.authToken || '', dealerId, {
                ...dealer,
                status: EntityStatus.ACTIVE,
            })

            if (res) {
                ToastSuccess('Distributore verificato con successo')
                await fetchDealerData()
            }
        } catch (error) {
            console.error(error)
            ToastError('Si è verificato un errore durante la procedura di verifica!')
        }
        setIsLoading(false)
    }

    const startAssociateDealerDevicesProcedure = async (idCenter: string) => {
        try {
            const deviceToRemove = originalDealerObject?.devices
                .filter((item) => item.status === EntityStatus.ACTIVE)
                .filter((device) => !dealerDevices.includes(device.id.toString()))

            const devicetoAdd = dealerDevices.filter(
                (device) =>
                    !originalDealerObject?.devices
                        .filter((item) => item.status === EntityStatus.ACTIVE)
                        .map((d) => d.id.toString())
                        .includes(device)
            )

            await Promise.all(
                devicetoAdd.map(async (device) => {
                    await createSingleDealerDeviceAssociation(user.loggedUserData?.authToken || '', idCenter, device)
                    if (dealerDevicesApprovated.includes(device)) {
                        await activateSingleDealerDeviceAssociation(
                            user.loggedUserData?.authToken || '',
                            idCenter,
                            device
                        )
                    }
                })
            )

            if (deviceToRemove) {
                await Promise.all(
                    deviceToRemove.map(async (device: Device) => {
                        await deleteSingleDealerDeviceAssociation(
                            user.loggedUserData?.authToken || '',
                            idCenter,
                            device.id.toString()
                        )
                    })
                )
            }
        } catch (error) {
            console.error(error)
        }
    }

    const startAssociateDealerCertificationsProcedure = async (idCenter: string) => {
        try {
            const itemToRemove = originalDealerObject?.certifications.filter(
                (certification) =>
                    dealerCertifications.filter(
                        (cert) => cert.idCertification === certification.idCertification.toString()
                    ).length === 0
            )

            const itemToAdd = dealerCertifications.filter(
                (certification) =>
                    !originalDealerObject?.certifications
                        .map((d) => d.idCertification)
                        .includes(certification.idCertification)
            )

            await Promise.all(
                itemToAdd.map(async (item) => {
                    await createSingleDealerCertificationAssociation(
                        user.loggedUserData?.authToken || '',
                        idCenter,
                        item.idCertification
                    )
                })
            )

            if (itemToRemove) {
                await Promise.all(
                    itemToRemove.map(async (item: DealerCertification) => {
                        item.idCertification &&
                            (await deleteSingleDealerCertificationAssociation(
                                user.loggedUserData?.authToken || '',
                                idCenter,
                                item.idCertification.toString()
                            ))
                    })
                )
            }
        } catch (error) {
            console.error(error)
        }
    }

    const formatItemForCountry = (item: Country | undefined): SelectOption | undefined => {
        if (!item) return
        return { value: item.id, label: item.name }
    }

    const formatItemForLanguage = (item: Language | undefined): SelectOption | undefined => {
        if (!item) return
        return { value: item.code, label: item.label }
    }

    const dataIsNotValid = useMemo(() => {
        return (
            !dealer.name ||
            !dealer.address ||
            !dealer.city ||
            !dealer.province ||
            !dealer.zipCode ||
            !dealer.idCountry ||
            dealer.idCountry === '-1'
        )
    }, [dealer.name, dealer.address, dealer.city, dealer.province, dealer.zipCode, dealer.idCountry])

    return (
        <>
            {showDeleteModal && (
                <AlertModal
                    modalTitle="Elimina distributore"
                    modalMessage={`Sei sicuro di voler eliminare l'elemento ${originalDealerObject?.name}? Questa operazione è irreversibile.`}
                    onClose={() => setShowDeleteModal(false)}
                    onConfirm={() => void startRemoveDealerProcedure()}
                />
            )}
            <div className="page-header-section">
                <div />
                <div className="page-header-section__right-box">
                    <Button disabled={isLoading} buttonType="secondary" onClick={() => null}>
                        Annulla
                    </Button>
                    {dealer.status === EntityStatus.PENDING && (
                        <Button
                            disabled={isLoading}
                            buttonType="primary"
                            onClick={() => void startVerifyDealerProcedure()}
                        >
                            Approva distributore
                        </Button>
                    )}

                    <Button disabled={isLoading} buttonType="secondary-error" onClick={() => startDeleteProcedure()}>
                        Elimina
                    </Button>
                    <Button
                        disabled={dataIsNotValid}
                        loading={isLoading}
                        buttonType="primary"
                        onClick={() => {
                            if (dealerId === '-1') {
                                void startCreateDealerProcedure()
                            } else {
                                void startUpdateDealerProcedure()
                            }
                        }}
                    >
                        {dealerId === '-1' ? 'Aggiungi distributore' : 'Aggiorna distributore'}
                    </Button>
                </div>
            </div>
            <div className="page-content-flow">
                <div className="elevated-card full-width">
                    <div className="input-form-box">
                        <div className="input-form-box__three-col-row">
                            <TextInput
                                value={referent.firstName}
                                inputLabel="nome referente"
                                onValueChange={(newVal) => setReferent({ ...referent, firstName: newVal })}
                            />
                            <TextInput
                                value={referent.lastName}
                                inputLabel="cognome referente"
                                onValueChange={(newVal) => setReferent({ ...referent, lastName: newVal })}
                            />
                            <TextInput
                                value={referent.email}
                                inputLabel="email"
                                onValueChange={(newVal) => setReferent({ ...referent, email: newVal })}
                            />
                            <SingleSelect
                                inputLabel="Lingua"
                                placeholder="Seleziona una lingua"
                                options={data.languages.map((language) => ({
                                    value: language.code,
                                    label: language.label,
                                }))}
                                value={formatItemForLanguage(
                                    data.languages.find((language) => language.code === referent.language.toLowerCase())
                                )}
                                onValueChange={(selectedOption) =>
                                    setReferent({ ...referent, language: selectedOption?.value || '' })
                                }
                            />
                            {referent.id && (
                                <div style={{ paddingTop: 16, display: 'flex', alignItems: 'flex-end' }}>
                                    <Button
                                        loading={isLoading}
                                        buttonType="primary"
                                        onClick={() => {
                                            void startSendCredentialsProcedure()
                                        }}
                                    >
                                        {'Invia le credenziali'}
                                    </Button>
                                </div>
                            )}
                            {/* <TextInput inputLabel="password" onValueChange={() => null} />
                            <div>
                                <div>
                                    <UppercaseLabel>&nbsp;</UppercaseLabel>
                                </div>
                                <div>
                                    La password per questo account è già stata specificata. Inserendone una nuova
                                    eseguirai il reset per questo centro.
                                </div>
                            </div> */}
                        </div>
                        <div className="horizontal-line-separator" />
                        <div className="input-form-box__three-col-row">
                            <TextInput
                                inputLabel="ragione sociale"
                                value={dealer.name}
                                onValueChange={(newVal) => setDealer({ ...dealer, name: newVal })}
                            />
                            <TextInput
                                value={dealer.address}
                                inputLabel="indirizzo"
                                onValueChange={(newVal) => setDealer({ ...dealer, address: newVal })}
                            />
                            <TextInput
                                value={dealer.city}
                                inputLabel="città"
                                onValueChange={(newVal) => setDealer({ ...dealer, city: newVal })}
                            />
                            <TextInput
                                value={dealer.province}
                                inputLabel="provincia"
                                onValueChange={(newVal) => setDealer({ ...dealer, province: newVal })}
                            />
                            <TextInput
                                value={dealer.zipCode}
                                inputLabel="cap"
                                onValueChange={(newVal) => setDealer({ ...dealer, zipCode: newVal })}
                            />
                            <SingleSelect
                                inputLabel="nazione"
                                placeholder="Seleziona una nazione"
                                value={formatItemForCountry(
                                    data.countries.find((country) => country.id === dealer.idCountry)
                                )}
                                options={data.countries.map((country) => {
                                    return { value: country.id, label: country.name }
                                })}
                                onValueChange={(newOpt) => setDealer({ ...dealer, idCountry: newOpt?.value || '' })}
                            />
                            <SingleSelect
                                inputLabel="paesi di attività"
                                placeholder=""
                                options={[]}
                                disabled
                                onValueChange={() => null}
                            />
                        </div>
                    </div>
                </div>
                <DevicesAssociation
                    title="dispositivi distribuiti"
                    emptyMessage="I dispositivi distribuiti su questo distributore verranno mostrati qui."
                    currentSelection={originalDealerObject?.devices.map((device) => device) || []}
                    onSelectionChange={(devices, pendingApprovated) => {
                        setDealerDevicesApprovated(pendingApprovated.map((device) => device.id.toString()))
                        setDealerDevices(devices.map((device) => device.id.toString()))
                    }}
                />
                <div className="elevated-card full-width">
                    <div className="input-form-box">
                        <div className="input-form-box__three-col-row">
                            <AsyncMultiSelect
                                reset={resetBadgesSelector}
                                closeMenuOnSelect={false}
                                inputLabel="certificazioni ottenute"
                                placeholder="Assegna nuova certificazione"
                                filterOption={(option) =>
                                    dealerCertifications.map((cert) => cert.idCertification).indexOf(option.value) ===
                                    -1
                                }
                                options={[]}
                                endpoint="certifications"
                                userToken={user.loggedUserData?.authToken}
                                onValueChange={(newVals) => {
                                    setDealerCertifications([
                                        ...dealerCertifications,
                                        ...newVals.map((val) => ({ idCertification: val.value, validUntil: '' })),
                                    ])
                                    setResetBadgesSelector(true)
                                }}
                            />
                        </div>
                        <div
                            className={`chip-container-box ${
                                dealerCertifications.length > 0 ? 'not-empty-box' : 'empty-box'
                            }`}
                        >
                            {dealerCertifications.length > 0 ? (
                                <>
                                    {dealerCertifications.map((dealerCertification) => {
                                        const certificationObject = data.certifications.find(
                                            (certification) =>
                                                certification.id?.toString() === dealerCertification.idCertification
                                        )
                                        if (!certificationObject) return
                                        return (
                                            <CertificationCard
                                                key={certificationObject.id}
                                                validUntil={dealerCertification.validUntil}
                                                certification={certificationObject}
                                                onRemove={() =>
                                                    setDealerCertifications(
                                                        dealerCertifications.filter(
                                                            (dealerCert) =>
                                                                dealerCert.idCertification !==
                                                                dealerCertification.idCertification
                                                        )
                                                    )
                                                }
                                            />
                                        )
                                    })}
                                </>
                            ) : (
                                <Paragraph>
                                    Le certificazioni ottenute da questo distributore verranno mostrate qui.
                                </Paragraph>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DealerAnagraphic
