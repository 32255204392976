import React, { useEffect, useState } from 'react'
import Table, { TableAction, TableColumn } from '../../components/Table/Table'
import TableHeader from '../../components/Table/TableHeader'
import Button from '../../components/Buttons/Button'
import AdvancedSearch from '../../components/AdvancedSearch/AdvancedSearch'
import TextInput from '../../components/Inputs/TextInput'
import MultiSelect, { SelectOption } from '../../components/Inputs/MultiSelect'
import UppercaseLabel from '../../components/Typography/UppercaseLabel'
import Checkbox from '../../components/Checkbox/Checkbox'
import { ROUTES } from '../../resources/routes-constants'
import { useNavigate } from 'react-router-dom'
import { Country, Dealer, EntityStatus, NotificationEntityType } from '../../types/data'
import { useAppSelector } from '../../store/reducers/store'
import { ToastError } from '../../utility/toast'
import Link from '../../components/Link/Link'
import { deleteSingleDealer } from '../../resources/api-constants'
import StatusLabel from '../../components/Badges/StatusLabel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import SingleSelect from '../../components/Inputs/SingleSelect'
import { confirmFilterObject, formatEntityStatusName } from '../../utility/functions'
import AlertModal from '../../components/Modal/AlertModal'

interface DealerSearchFilters {
    name: string
    country: string
    pendingNotifications: boolean
    statuses: (EntityStatus | string)[]
}

const defaultFiltersState: DealerSearchFilters = {
    name: '',
    country: '',
    pendingNotifications: false,
    statuses: [],
}

const statusSelectOptions = [
    {
        value: EntityStatus.ACTIVE,
        label: 'Attivo',
    },
    {
        value: EntityStatus.CANCELED,
        label: 'Cancellato',
    },
    {
        value: EntityStatus.PENDING,
        label: 'In attesa',
    },
]

const DealersPage: React.FC = () => {
    const user = useAppSelector((data) => data.user)
    const data = useAppSelector((data) => data.data)
    const [selectedDealer, setSelectedDealer] = useState<Dealer | null>(null)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [forceDeleteReload, setForceDeleteReload] = useState(false)
    const navigate = useNavigate()

    const [showAdvancedSearch, setShowAdvancedSearch] = useState(false)
    const [resetFlag, setResetFlag] = useState(false)
    const [dealerFilters, setDealerFilters] = useState<DealerSearchFilters>(defaultFiltersState)
    const [finalFilters, setFinalFilters] = useState<DealerSearchFilters | undefined>(undefined)

    useEffect(() => {
        if (resetFlag) setResetFlag(!resetFlag)
    }, [resetFlag])

    useEffect(() => {
        if (forceDeleteReload) setForceDeleteReload(!forceDeleteReload)
    }, [forceDeleteReload])

    const resetSearchParams = () => {
        if (JSON.stringify(dealerFilters) === JSON.stringify(defaultFiltersState)) return
        setResetFlag(true)
        setDealerFilters(defaultFiltersState)
        setFinalFilters(confirmFilterObject(defaultFiltersState))
    }

    const startDeleteProcedure = (dealer: Dealer) => {
        setSelectedDealer(dealer)
        setShowDeleteModal(true)
    }

    const endRemoveDealerProcedure = () => {
        setSelectedDealer(null)
        setShowDeleteModal(false)
        setForceDeleteReload(true)
    }

    const removeDealer = async () => {
        try {
            const res = await deleteSingleDealer(user.loggedUserData?.authToken || '', selectedDealer?.id || '')
            if (res) endRemoveDealerProcedure()
            else ToastError('Si è verificato un errore durante la procedura di eliminazione!')
        } catch (error) {
            console.error(error)
        }
    }

    const formatItemForCountry = (item: Country | undefined): SelectOption | undefined => {
        if (!item) return
        return { value: item.id, label: item.name }
    }

    const formatItemsForStatuses = (items: EntityStatus[] | undefined): SelectOption[] | undefined => {
        if (!items || items.length === 0) return
        return items.map((item) => ({ value: item, label: formatEntityStatusName(item) }))
    }

    const columns: TableColumn[] = [
        {
            title: '',
            field: '',
            sorting: false,
            render: (rowData: Dealer) => (
                <div className="notification-dot-container">
                    {rowData.notifications &&
                        rowData.notifications.length > 0 &&
                        rowData.notifications.map((notification) => (
                            <div
                                key={notification.id}
                                className={`notification-dot ${notification.type.toLowerCase()}-notification`}
                            />
                        ))}
                </div>
            ),
            width: '3%',
        },
        {
            title: 'Ragione sociale',
            field: 'name',
            render: (data: Dealer) => (
                <Link internalRoute={`${ROUTES.DEALER_DETAIL_ROUTE}${data.id}`}>{data.name}</Link>
            ),
        },
        {
            title: 'Nazione',
            field: 'country',
            render: (data: Dealer) => <p>{data.country.name}</p>,
        },
        {
            title: 'Referente',
            field: 'referent_first_name',
            render: (data: Dealer) => (
                <p>
                    {data.referent.firstName} {data.referent.lastName}
                </p>
            ),
        },
        {
            title: 'Status',
            field: 'status',
            render: (data: Dealer) => <StatusLabel status={data.status} key={data.id} />,
        },
    ]

    const actions: TableAction[] = [
        (rowData: Dealer) => ({
            icon: () => <FontAwesomeIcon icon={faPen} size="xs" />,
            onClick: () => navigate(`${ROUTES.DEALER_DETAIL_ROUTE}${rowData.id}`),
            tooltip: 'Modifica',
        }),
        (rowData: Dealer) => ({
            icon: () => <FontAwesomeIcon icon={faTrash} size="xs" />,
            onClick: () => startDeleteProcedure(rowData),
            tooltip: 'Elimina',
        }),
    ]

    return (
        <>
            {showDeleteModal && (
                <AlertModal
                    modalTitle="Elimina protocollo"
                    modalMessage={`Sei sicuro di voler eliminare l'elemento ${selectedDealer?.name}? Questa operazione è irreversibile.`}
                    onClose={() => setShowDeleteModal(false)}
                    onConfirm={() => void removeDealer()}
                />
            )}
            <div className="page-header-section">
                <div className="page-header-section__left-box">
                    <span className="page-title">Elenco distributori</span>
                </div>
                <div className="page-header-section__right-box">
                    <Button
                        customClassName={`${showAdvancedSearch ? 'active' : ''}`}
                        buttonType="ghost"
                        onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
                    >
                        Ricerca avanzata
                    </Button>
                    <Button buttonType="primary" onClick={() => navigate(`${ROUTES.DEALER_DETAIL_ROUTE}new`)}>
                        Crea nuovo distributore
                    </Button>
                </div>
            </div>
            <div className="page-content-block">
                <AdvancedSearch
                    isVisible={showAdvancedSearch}
                    onReset={() => resetSearchParams()}
                    onSearch={() => setFinalFilters(confirmFilterObject(dealerFilters))}
                >
                    <div className="input-form-box__three-col-row">
                        <TextInput
                            value={dealerFilters.name}
                            inputLabel="ragione sociale"
                            onValueChange={(newVal) => setDealerFilters({ ...dealerFilters, name: newVal })}
                        />
                        <SingleSelect
                            reset={resetFlag}
                            inputLabel="nazione"
                            placeholder="Seleziona una nazione"
                            options={data.countries.map((country) => ({ value: country.id, label: country.name }))}
                            value={formatItemForCountry(
                                data.countries.find((country) => country.id === dealerFilters.country)
                            )}
                            onValueChange={(selectedOption) =>
                                setDealerFilters({ ...dealerFilters, country: selectedOption?.value || '' })
                            }
                        />
                        <div>
                            <div style={{ marginLeft: 6 }}>
                                <UppercaseLabel>notifiche</UppercaseLabel>
                            </div>
                            <Checkbox
                                checked={dealerFilters.pendingNotifications}
                                onCheckChange={() =>
                                    setDealerFilters({
                                        ...dealerFilters,
                                        pendingNotifications: !dealerFilters.pendingNotifications,
                                    })
                                }
                                label="Notifiche in attesa"
                            />
                        </div>
                        <MultiSelect
                            reset={resetFlag}
                            inputLabel="status"
                            placeholder="Seleziona uno status"
                            options={statusSelectOptions}
                            values={formatItemsForStatuses(
                                statusSelectOptions
                                    .filter((status) => dealerFilters.statuses.indexOf(status.value) !== -1)
                                    .map((val) => val.value)
                            )}
                            onValueChange={(newVals) =>
                                setDealerFilters({ ...dealerFilters, statuses: newVals.map((val) => val.value) })
                            }
                        />
                    </div>
                </AdvancedSearch>
                <div className="relative">
                    <TableHeader positionIndex={23} />
                    <Table
                        actions={actions}
                        columns={columns}
                        reloads={resetFlag}
                        forceDeleteReload={forceDeleteReload}
                        filterParams={finalFilters}
                        notificationFetcher={{ entity: NotificationEntityType.dealer }}
                        endpoint="dealers"
                    />
                </div>
            </div>
        </>
    )
}

export default DealersPage
