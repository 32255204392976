/* eslint-disable indent */
import * as React from 'react'
import * as PropTypes from 'prop-types'

function replaceCaret(el: HTMLElement) {
    // Place the caret at the end of the element
    const target = document.createTextNode('')
    el.appendChild(target)
    // do not move caret if element was not focused
    const isTargetFocused = document.activeElement === el
    if (target !== null && target.nodeValue !== null && isTargetFocused) {
        const sel = window.getSelection()
        if (sel !== null) {
            const range = document.createRange()
            range.setStart(target, target.nodeValue.length)
            range.collapse(true)
            sel.removeAllRanges()
            sel.addRange(range)
        }
        if (el instanceof HTMLElement) el.focus()
    }
}

/**
 * A simple component for an html element with editable contents.
 */
export default class ContentEditable extends React.Component<Props> {
    lastHtml: string = this.props.html
    el: any = typeof this.props.innerRef === 'function' ? { current: null } : React.createRef<HTMLElement>()

    getEl = () =>
        (this.props.innerRef && typeof this.props.innerRef !== 'function' ? this.props.innerRef : this.el).current

    render() {
        const { html, innerRef, ...props } = this.props

        return (
            <div
                {...props}
                ref={
                    typeof innerRef === 'function'
                        ? (current: HTMLElement) => {
                              innerRef(current)
                              this.el.current = current
                          }
                        : innerRef || this.el
                }
                onInput={this.emitChange}
                contentEditable={!this.props.disabled}
                dangerouslySetInnerHTML={{ __html: html }}
            >
                {this.props.children}
            </div>
        )
    }

    componentDidUpdate() {
        const el = this.getEl()
        if (!el) return

        // Perhaps React (whose VDOM gets outdated because we often prevent
        // rerendering) did not update the DOM. So we update it manually now.
        if (this.props.html !== el.innerHTML) {
            el.innerHTML = this.props.html
        }
        this.lastHtml = this.props.html
        replaceCaret(el)
    }

    emitChange = (originalEvt: React.SyntheticEvent<any>) => {
        const el = this.getEl()
        if (!el) return

        const html = el.innerHTML
        if (this.props.onChange && html !== this.lastHtml) {
            // Clone event with Object.assign to avoid
            // "Cannot assign to read only property 'target' of object"
            const evt = {
                ...originalEvt,
                target: {
                    value: html,
                },
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.props.onChange(evt)
        }
        this.lastHtml = html
    }

    static propTypes = {
        html: PropTypes.string.isRequired,
        onChange: PropTypes.func,
        disabled: PropTypes.bool,
        tagName: PropTypes.string,
        className: PropTypes.string,
        style: PropTypes.object,
        innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    }
}

export type ContentEditableEvent = React.SyntheticEvent<any, Event> & { target: { value: string } }
type Modify<T, R> = Pick<T, Exclude<keyof T, keyof R>> & R
type DivProps = Modify<JSX.IntrinsicElements['div'], { onChange: (event: ContentEditableEvent) => void }>

export interface Props extends DivProps {
    html: string
    disabled?: boolean
    tagName?: string
    className?: string
    style?: object
    // eslint-disable-next-line @typescript-eslint/ban-types
    innerRef?: React.RefObject<HTMLElement> | Function
}
