import React, { useId } from 'react'
import './Checkbox.sass'

const Checkbox: React.FC<{
    label: string
    checked: boolean
    disabled?: boolean
    onCheckChange: (newVal: boolean) => void
}> = ({ label, checked, disabled, onCheckChange }) => {
    const id = useId()
    return (
        <div className={`md-checkbox ${disabled ? 'disabled-checkbox' : ''}`}>
            <input
                id={`icoone-checkbox__${id}`}
                type="checkbox"
                disabled={disabled}
                checked={checked}
                onChange={(e) => onCheckChange(e.target.checked)}
            />
            <label htmlFor={`icoone-checkbox__${id}`}>{label}</label>
        </div>
    )
}

export default Checkbox
