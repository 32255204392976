import React, { ReactNode } from 'react'
import './buttons.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    customClassName?: string
    children?: string | ReactNode
    buttonType?: 'primary' | 'secondary' | 'ghost' | 'secondary-error'
    loading?: boolean
}

const Button: React.FC<Props> = ({ children, buttonType = 'primary', loading, customClassName, ...props }) => {
    return (
        <button
            className={`reset-appearance button ${buttonType}--variant ${customClassName}`}
            {...props}
            disabled={props.disabled || loading}
        >
            {children}
            {loading && (
                <div className="button__absolute-loading-wrapper center-box">
                    <div className="button__absolute-loading-wrapper__spinner rotating">
                        <FontAwesomeIcon icon={faCircleNotch} />
                    </div>
                </div>
            )}
        </button>
    )
}

export default Button
