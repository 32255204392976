import React, { useEffect, useState } from 'react'
import './Navbar.sass'
import Paragraph from '../Typography/Paragraph'
import { useAppSelector } from '../../store/reducers/store'
import { NotificationItem } from '../../types/data'
import Button from '../Buttons/Button'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../resources/routes-constants'

interface NotificationSectionViewProps {
    title: string
    items: NotificationItem[]
    onClick: () => void
}
const NotificationSectionView: React.FC<NotificationSectionViewProps> = (props) => {
    const navigate = useNavigate()

    const handleNotificationClick = (item: NotificationItem) => {
        props.onClick()
        switch (item.type) {
            case 'MESSAGE':
                navigate(`${ROUTES.CENTER_DETAIL_ROUTE}${item.additionalData?.fatherId}`)
                break
            case 'RESULT':
                navigate(`${ROUTES.RESULT_DETAIL_ROUTE}${item.idEntity}`)
                break
            case 'DEVICE':
                navigate(`${ROUTES.DEVICE_DETAIL_ROUTE}${item.idEntity}`)
                break
            case 'SUBSCRIPTION':
                navigate(`${ROUTES.HOMEPAGE_ROUTE}`)
                break
            case 'CERTIFICATION':
                navigate(`${ROUTES.DEALER_DETAIL_ROUTE}${item.idEntity}`)
                break
            case 'FAQ':
                navigate(`${ROUTES.FAQ_DETAIL_ROUTE}${item.idEntity}`)
                break
        }
    }
    return (
        <>
            {props.items.length > 0 ? (
                <>
                    <div className="notifications-section-title">
                        <Paragraph>{props.title}</Paragraph>
                    </div>
                    {props.items.map((item) => (
                        <Button
                            key={item.id}
                            className="notifications-section-flow__notification-box"
                            style={{ border: 'none' }}
                            onClick={() => handleNotificationClick(item)}
                        >
                            <div className="notifications-section-flow__notification-box__dot-container">
                                <div className={`notification-dot ${item.type.toLowerCase()}-notification`} />
                            </div>
                            <div className="notifications-section-flow__notification-box__message-container">
                                {item.title}
                            </div>
                        </Button>
                    ))}
                </>
            ) : null}
        </>
    )
}

export interface NotificationWidgetProps {
    onClose: () => void
}
const NotificationWidget: React.FC<NotificationWidgetProps> = (props) => {
    const notificationItems = useAppSelector((appData) => appData.notification.items)
    const [recents, setRecents] = useState<NotificationItem[]>([])
    const [old, setOld] = useState<NotificationItem[]>([])

    useEffect(() => {
        const recNot = notificationItems.filter((notif) => {
            const today = new Date()
            const yesterday = new Date(today)
            yesterday.setDate(yesterday.getDate() - 1)
            const createdOn = new Date(notif.createdOn)
            return createdOn > yesterday && createdOn <= today
        })

        const oldNot = notificationItems.filter((notif) => {
            const today = new Date()
            const yesterday = new Date(today)
            yesterday.setDate(yesterday.getDate() - 1)
            const createdOn = new Date(notif.createdOn)
            return createdOn <= yesterday
        })

        setOld(oldNot)
        setRecents(recNot)
    }, [notificationItems])

    return (
        <div className="notifications-flow action-panel">
            {/* <span className="notifications-section-title">ieri</span> */}
            <div className="notifications-section-flow">
                {notificationItems.length === 0 && (
                    <div className="empty-notifications-list">
                        <Paragraph>Nessuna notifica da visualizzare</Paragraph>
                    </div>
                )}

                <NotificationSectionView items={recents} title="RECENTI" onClick={() => props.onClose()} />
                <NotificationSectionView items={old} title="PIÙ VECCHIE" onClick={() => props.onClose()} />
            </div>
        </div>
    )
}

export default NotificationWidget
