import React, { useEffect, useMemo, useState } from 'react'
import { ROUTES } from '../../../resources/routes-constants'
import TextInput from '../../../components/Inputs/TextInput'
import UppercaseLabel from '../../../components/Typography/UppercaseLabel'
import MultiSelect, { SelectOption } from '../../../components/Inputs/MultiSelect'
import { useAppSelector } from '../../../store/reducers/store'
import { Center, Country, Device, NotificationEntityType } from '../../../types/data'
import Table, { TableAction, TableColumn } from '../../../components/Table/Table'
import Link from '../../../components/Link/Link'
import AdvancedSearch from '../../../components/AdvancedSearch/AdvancedSearch'
import Checkbox from '../../../components/Checkbox/Checkbox'
import TableHeader from '../../../components/Table/TableHeader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { CenterSearchFilters } from '../../Centers/CentersPage'
import SingleSelect from '../../../components/Inputs/SingleSelect'
import { confirmFilterObject, formatItemsForSelectOptions } from '../../../utility/functions'
import StatusLabel from '../../../components/Badges/StatusLabel'

const AssociatedCenters: React.FC<{ dealerId: string }> = ({ dealerId }) => {
    const data = useAppSelector((data) => data.data)
    const [totalItemsCount, setTotalItemsCount] = useState(0)
    const [isFirstRender, setIsFirstRender] = useState(true)
    const [resetFlag, setResetFlag] = useState(false)
    const navigate = useNavigate()

    const defaultFilters = useMemo((): CenterSearchFilters => {
        return {
            name: '',
            country: '',
            pendingNotifications: false,
            province: '',
            devices: [],
            dealer: dealerId,
        }
    }, [])

    const [centerFilters, setCenterFilters] = useState<CenterSearchFilters>(defaultFilters)
    const [finalFilters, setFinalFilters] = useState<CenterSearchFilters | undefined>(undefined)

    const formatItemForCountry = (item: Country | undefined): SelectOption | undefined => {
        if (!item) return
        return { value: item.id, label: item.name }
    }

    useEffect(() => {
        if (resetFlag) setResetFlag(!resetFlag)
    }, [resetFlag])

    const resetSearchParams = () => {
        if (JSON.stringify(centerFilters) === JSON.stringify(defaultFilters)) return
        setResetFlag(true)
        setCenterFilters(defaultFilters)
        setFinalFilters(confirmFilterObject(defaultFilters))
    }

    useEffect(() => {
        setFinalFilters(confirmFilterObject(centerFilters))
    }, [])

    const columns: TableColumn[] = [
        {
            title: '',
            field: '',
            render: (rowData: Center) => (
                <div className="notification-dot-container">
                    {rowData.notifications &&
                        rowData.notifications.length > 0 &&
                        rowData.notifications.map((notification) => (
                            <div
                                key={notification.id}
                                className={`notification-dot ${notification.type.toLowerCase()}-notification`}
                            />
                        ))}
                </div>
            ),
            width: '3%',
        },
        {
            title: 'Ragione sociale',
            field: 'name',
            render: (rowData: Center) => (
                <Link internalRoute={`${ROUTES.CENTER_DETAIL_ROUTE}${rowData.id}`}>{rowData.name}</Link>
            ),
        },
        {
            title: 'Stato',
            field: 'status',
            render: (rowData: Center) => <StatusLabel status={rowData.status} />,
        },
        {
            title: 'Nazione',
            field: 'country',
            render: (rowData: Center) => <p>{rowData.country.name}</p>,
        },
        {
            title: 'Provincia',
            field: 'province',
            render: (rowData: Center) => <p>{rowData.province}</p>,
        },
        {
            title: 'Dispositivi',
            field: 'devices',
            sorting: false,
            render: (rowData: Center) => (
                <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                    {rowData.devices.map((dev) => {
                        const device = data.devices.find((d) => d.id === dev.id.toString())
                        if (!device) return
                        return (
                            <Link key={device.id} internalRoute={`${ROUTES.DEVICE_DETAIL_ROUTE}${device.id}`}>
                                {device.name}
                            </Link>
                        )
                    })}
                </div>
            ),
        },
    ]

    const actions: TableAction[] = [
        (rowData: Center) => ({
            icon: () => <FontAwesomeIcon icon={faPen} size="xs" />,
            onClick: () => navigate(`${ROUTES.CENTER_DETAIL_ROUTE}${rowData.id}`),
            tooltip: 'Modifica',
        }),
    ]

    return (
        <>
            <div className="page-content-block">
                {totalItemsCount > 0 && (
                    <AdvancedSearch
                        isVisible
                        onReset={() => resetSearchParams()}
                        onSearch={() => setFinalFilters(confirmFilterObject(centerFilters))}
                    >
                        <div className="input-form-box__three-col-row">
                            <TextInput
                                inputLabel="ragione sociale"
                                value={centerFilters.name}
                                onValueChange={(newVal) => setCenterFilters({ ...centerFilters, name: newVal })}
                            />
                            <SingleSelect
                                inputLabel="nazione"
                                placeholder="Seleziona una nazione"
                                reset={resetFlag}
                                options={data.countries.map((country) => ({ value: country.id, label: country.name }))}
                                value={formatItemForCountry(
                                    data.countries.find((country) => country.id === centerFilters.country)
                                )}
                                onValueChange={(selectedOption) =>
                                    setCenterFilters({ ...centerFilters, country: selectedOption?.value || '' })
                                }
                            />
                            <div>
                                <div style={{ marginLeft: 6 }}>
                                    <UppercaseLabel>notifiche</UppercaseLabel>
                                </div>
                                <Checkbox
                                    checked={centerFilters.pendingNotifications}
                                    onCheckChange={() =>
                                        setCenterFilters({
                                            ...centerFilters,
                                            pendingNotifications: !centerFilters.pendingNotifications,
                                        })
                                    }
                                    label="Notifiche in attesa"
                                />
                            </div>
                            <TextInput
                                inputLabel="provincia"
                                value={centerFilters.province}
                                onValueChange={(newVal) => setCenterFilters({ ...centerFilters, province: newVal })}
                            />
                            <MultiSelect
                                inputLabel="dispositivi associati"
                                placeholder="Seleziona uno o più dispositivi"
                                options={data.devices.map((device) => ({ value: device.id, label: device.name }))}
                                reset={resetFlag}
                                values={formatItemsForSelectOptions<Device>(
                                    data.devices.filter((device) => centerFilters.devices.indexOf(device.id) !== -1)
                                )}
                                onValueChange={(selectedOption) =>
                                    setCenterFilters({
                                        ...centerFilters,
                                        devices: selectedOption.map((opt) => opt.value) || [],
                                    })
                                }
                            />
                        </div>
                    </AdvancedSearch>
                )}
                <div className="relative">
                    <TableHeader positionIndex={23} />
                    <Table
                        actions={actions}
                        columns={columns}
                        endpoint="centers"
                        filterParams={isFirstRender ? defaultFilters : finalFilters}
                        notificationFetcher={{ entity: NotificationEntityType.center }}
                        onTotalCountChange={(newTotal) => {
                            if (isFirstRender) {
                                setTotalItemsCount(newTotal)
                                setIsFirstRender(false)
                            }
                        }}
                    />
                </div>
            </div>
        </>
    )
}

export default AssociatedCenters
