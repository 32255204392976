import { baseUrl } from '../../resources/api-constants'
import CustomAxios from '../../utility/customAxios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch } from '../reducers/store'
import { setLoggedUserData } from './user'
import { LoggedUser } from '../../types/user'
import { LoginCredentials } from '../../types/reducers'
import {
    setAlcoholConsumptions,
    setAppTypes,
    setBadges,
    setBodyAreas,
    setBodySections,
    setCertifications,
    setChildbirthTypes,
    setCountries,
    setCurrencies,
    setCustomerGenders,
    setDevices,
    setFrequencies,
    setGoals,
    setHealthyDiets,
    setLanguages,
    setLifeMoments,
    setMediaScopes,
    setMediaTargets,
    setMediaTypes,
    setPhisicalActivities,
    setPriorities,
    setPriorityIntensities,
    setSessionsDurations,
    setSessionsNumbers,
    setSkinTypes,
    setStressLevels,
} from './data'
import {
    AppType,
    Badge,
    Certification,
    Country,
    Currency,
    Device,
    InfoItem,
    Language,
    MediaScope,
    MediaTarget,
    MediaType,
    NotificationItem,
    Priority,
    SimpleEntity,
} from '../../types/data'
import { getRequestHeader } from '../../utility/functions'
import { setNotifications } from './notification'

// --- AUTH ---

export const performUserLogin = createAsyncThunk<void, LoginCredentials, { dispatch: AppDispatch }>(
    'groupedActions/performUserLogin',
    async (loginCredentials, { dispatch }) => {
        try {
            const loggedUserData: LoggedUser = await CustomAxios.post(`${baseUrl}auth/login`, loginCredentials).then(
                (response) => response.data
            )
            dispatch(setLoggedUserData(loggedUserData))
        } catch (e) {
            console.error(e)
            throw e
        }
    }
)

// --- GOALS ---

export const getGoals = createAsyncThunk<void, string, { dispatch: AppDispatch }>(
    'groupedActions/getTags',
    async (authToken, { dispatch }) => {
        try {
            const goals: InfoItem[] = await CustomAxios.get(`${baseUrl}goals`, getRequestHeader(authToken)).then(
                (response) => response.data
            )

            dispatch(setGoals(goals))
        } catch (e) {
            console.error(e)
            throw e
        }
    }
)

// --- PROBLEMS ---

export const getPriorities = createAsyncThunk<void, string, { dispatch: AppDispatch }>(
    'groupedActions/getPriorities',
    async (authToken, { dispatch }) => {
        try {
            const priorities: Priority[] = await CustomAxios.get(
                `${baseUrl}priorities`,
                getRequestHeader(authToken)
            ).then((response) => response.data)

            dispatch(setPriorities(priorities))
        } catch (e) {
            console.error(e)
            throw e
        }
    }
)

// --- BODY AREAS ---

export const getBodyAreas = createAsyncThunk<void, string, { dispatch: AppDispatch }>(
    'groupedActions/getBodyAreas',
    async (authToken, { dispatch }) => {
        try {
            const bodyAreas: InfoItem[] = await CustomAxios.get(
                `${baseUrl}body-areas`,
                getRequestHeader(authToken)
            ).then((response) => response.data)

            dispatch(setBodyAreas(bodyAreas))
        } catch (e) {
            console.error(e)
            throw e
        }
    }
)

// --- DEVICES ---

export const getDevices = createAsyncThunk<void, string, { dispatch: AppDispatch }>(
    'groupedActions/getDevices',
    async (authToken, { dispatch }) => {
        try {
            const devices: Device[] = await CustomAxios.get(`${baseUrl}devices`, getRequestHeader(authToken)).then(
                (response) => response.data.items
            )

            dispatch(setDevices(devices))
        } catch (e) {
            console.error(e)
            throw e
        }
    }
)

// --- COUNTRIES ---

export const getCountries = createAsyncThunk<void, string, { dispatch: AppDispatch }>(
    'groupedActions/getCountries',
    async (authToken, { dispatch }) => {
        try {
            const countries: Country[] = await CustomAxios.get(`${baseUrl}countries`, getRequestHeader(authToken)).then(
                (response) => response.data
            )

            dispatch(setCountries(countries))
        } catch (e) {
            console.error(e)
            throw e
        }
    }
)

// --- CURRENCIES ---

export const getCurrencies = createAsyncThunk<void, string, { dispatch: AppDispatch }>(
    'groupedActions/getCurrencies',
    async (authToken, { dispatch }) => {
        try {
            const currencies: Currency[] = await CustomAxios.get(
                `${baseUrl}currencies`,
                getRequestHeader(authToken)
            ).then((response) => response.data)

            dispatch(setCurrencies(currencies))
        } catch (e) {
            console.error(e)
            throw e
        }
    }
)

// --- APP TYPES ---

export const getAppTypes = createAsyncThunk<void, string, { dispatch: AppDispatch }>(
    'groupedActions/getAppTypes',
    async (authToken, { dispatch }) => {
        try {
            const appTypes: AppType[] = await CustomAxios.get(
                `${baseUrl}application-types`,
                getRequestHeader(authToken)
            ).then((response) => response.data)

            dispatch(setAppTypes(appTypes))
        } catch (e) {
            console.error(e)
            throw e
        }
    }
)

// --- BADGES ---

export const getBadges = createAsyncThunk<void, string, { dispatch: AppDispatch }>(
    'groupedActions/getBadges',
    async (authToken, { dispatch }) => {
        try {
            const badges: Badge[] = await CustomAxios.get(`${baseUrl}badges`, getRequestHeader(authToken)).then(
                (response) => response.data.items
            )

            dispatch(setBadges(badges))
        } catch (e) {
            console.error(e)
            throw e
        }
    }
)

// --- CERTIFICATIONS ---

export const getCertifications = createAsyncThunk<void, string, { dispatch: AppDispatch }>(
    'groupedActions/getCertifications',
    async (authToken, { dispatch }) => {
        try {
            const certifications: Certification[] = await CustomAxios.get(
                `${baseUrl}certifications`,
                getRequestHeader(authToken)
            ).then((response) => response.data.items)

            dispatch(setCertifications(certifications))
        } catch (e) {
            console.error(e)
            throw e
        }
    }
)

// --- LIFE MOMENTS ---

export const getLifeMoments = createAsyncThunk<void, string, { dispatch: AppDispatch }>(
    'groupedActions/getLifeMoments',
    async (authToken, { dispatch }) => {
        try {
            const lifeMoments: InfoItem[] = await CustomAxios.get(
                `${baseUrl}life-moments`,
                getRequestHeader(authToken)
            ).then((response) => response.data)

            dispatch(setLifeMoments(lifeMoments))
        } catch (e) {
            console.error(e)
            throw e
        }
    }
)

// --- PHISICAL ACTIVITIES ---

export const getPhisicalActivities = createAsyncThunk<void, string, { dispatch: AppDispatch }>(
    'groupedActions/getPhisicalActivities',
    async (authToken, { dispatch }) => {
        try {
            const phisicalActivities: InfoItem[] = await CustomAxios.get(
                `${baseUrl}phisical-activities`,
                getRequestHeader(authToken)
            ).then((response) => response.data)

            dispatch(setPhisicalActivities(phisicalActivities))
        } catch (e) {
            console.error(e)
            throw e
        }
    }
)

// --- LANGUAGES ---

export const getLanguages = createAsyncThunk<void, string, { dispatch: AppDispatch }>(
    'groupedActions/getLanguages',
    async (authToken, { dispatch }) => {
        try {
            const languages: Language[] = await CustomAxios.get(
                `${baseUrl}languages`,
                getRequestHeader(authToken)
            ).then((response) => response.data)
            dispatch(setLanguages(languages))
        } catch (e) {
            console.error(e)
            throw e
        }
    }
)

// --- SKIN TYPES ---

export const getSkinTypes = createAsyncThunk<void, string, { dispatch: AppDispatch }>(
    'groupedActions/getSkinTypes',
    async (authToken, { dispatch }) => {
        try {
            const skinTypes: InfoItem[] = await CustomAxios.get(
                `${baseUrl}skin-types`,
                getRequestHeader(authToken)
            ).then((response) => response.data)
            dispatch(setSkinTypes(skinTypes))
        } catch (e) {
            console.error(e)
            throw e
        }
    }
)

// --- FREQUENCIES ---

export const getFrequencies = createAsyncThunk<void, string, { dispatch: AppDispatch }>(
    'groupedActions/getFrequencies',
    async (authToken, { dispatch }) => {
        try {
            const frequencies: SimpleEntity[] = await CustomAxios.get(
                `${baseUrl}frequencies`,
                getRequestHeader(authToken)
            ).then((response) => response.data)
            dispatch(setFrequencies(frequencies))
        } catch (e) {
            console.error(e)
            throw e
        }
    }
)

// --- PRIORITY INTENSITIES ---

export const getPriorityIntensities = createAsyncThunk<void, string, { dispatch: AppDispatch }>(
    'groupedActions/getPriorityIntensities',
    async (authToken, { dispatch }) => {
        try {
            const priorityIntensities: InfoItem[] = await CustomAxios.get(
                `${baseUrl}priority-intensities`,
                getRequestHeader(authToken)
            ).then((response) => response.data)
            dispatch(setPriorityIntensities(priorityIntensities))
        } catch (e) {
            console.error(e)
            throw e
        }
    }
)

// --- CUSTOMER GENDERS ---

export const getCustomerGenders = createAsyncThunk<void, string, { dispatch: AppDispatch }>(
    'groupedActions/getCustomerGenders',
    async (authToken, { dispatch }) => {
        try {
            const customerGenders: InfoItem[] = await CustomAxios.get(
                `${baseUrl}customer-genders`,
                getRequestHeader(authToken)
            ).then((response) => response.data)
            dispatch(setCustomerGenders(customerGenders))
        } catch (e) {
            console.error(e)
            throw e
        }
    }
)

// --- BODY SECTIONS ---

export const getBodySections = createAsyncThunk<void, string, { dispatch: AppDispatch }>(
    'groupedActions/getBodySections',
    async (authToken, { dispatch }) => {
        try {
            const bodySections: InfoItem[] = await CustomAxios.get(
                `${baseUrl}body-sections`,
                getRequestHeader(authToken)
            ).then((response) => response.data)
            dispatch(setBodySections(bodySections))
        } catch (e) {
            console.error(e)
            throw e
        }
    }
)

// --- STRESS LEVELS ---

export const getStressLevels = createAsyncThunk<void, string, { dispatch: AppDispatch }>(
    'groupedActions/getStressLevels',
    async (authToken, { dispatch }) => {
        try {
            const stressLevels: InfoItem[] = await CustomAxios.get(
                `${baseUrl}stress-levels`,
                getRequestHeader(authToken)
            ).then((response) => response.data)
            dispatch(setStressLevels(stressLevels))
        } catch (e) {
            console.error(e)
            throw e
        }
    }
)

// --- HEALTHY DIETS ---

export const getHealthyDiets = createAsyncThunk<void, string, { dispatch: AppDispatch }>(
    'groupedActions/getHealthyDiets',
    async (authToken, { dispatch }) => {
        try {
            const healthyDiets: InfoItem[] = await CustomAxios.get(
                `${baseUrl}healty-diets`,
                getRequestHeader(authToken)
            ).then((response) => response.data)
            dispatch(setHealthyDiets(healthyDiets))
        } catch (e) {
            console.error(e)
            throw e
        }
    }
)

// --- CHILDBIRTH TYPES ---

export const getChildbirthTypes = createAsyncThunk<void, string, { dispatch: AppDispatch }>(
    'groupedActions/getChildbirthTypes',
    async (authToken, { dispatch }) => {
        try {
            const childbirthTypes: InfoItem[] = await CustomAxios.get(
                `${baseUrl}childbirth-types`,
                getRequestHeader(authToken)
            ).then((response) => response.data)
            dispatch(setChildbirthTypes(childbirthTypes))
        } catch (e) {
            console.error(e)
            throw e
        }
    }
)

// --- ALCOHOL CONSUMPTION ---

export const getAlcoholConsumptions = createAsyncThunk<void, string, { dispatch: AppDispatch }>(
    'groupedActions/getAlcoholConsumptions',
    async (authToken, { dispatch }) => {
        try {
            const alcoholConsumptions: InfoItem[] = await CustomAxios.get(
                `${baseUrl}alcohol-consumptions`,
                getRequestHeader(authToken)
            ).then((response) => response.data)
            dispatch(setAlcoholConsumptions(alcoholConsumptions))
        } catch (e) {
            console.error(e)
            throw e
        }
    }
)

// --- SESSIONS NUMBERS AND DURATIONS ---

export const getSessionsNumbers = createAsyncThunk<void, string, { dispatch: AppDispatch }>(
    'groupedActions/getSessionsNumbers',
    async (authToken, { dispatch }) => {
        try {
            const sessionsNumbers: SimpleEntity[] = await CustomAxios.get(
                `${baseUrl}sessions-numbers`,
                getRequestHeader(authToken)
            ).then((response) => response.data)
            dispatch(setSessionsNumbers(sessionsNumbers))
        } catch (e) {
            console.error(e)
            throw e
        }
    }
)

export const getSessionsDurations = createAsyncThunk<void, string, { dispatch: AppDispatch }>(
    'groupedActions/getSessionsDurations',
    async (authToken, { dispatch }) => {
        try {
            const sessionsDurations: SimpleEntity[] = await CustomAxios.get(
                `${baseUrl}session-durations`,
                getRequestHeader(authToken)
            ).then((response) => response.data)
            dispatch(setSessionsDurations(sessionsDurations))
        } catch (e) {
            console.error(e)
            throw e
        }
    }
)

// --- MEDIA ---

export const getMediaScopes = createAsyncThunk<void, string, { dispatch: AppDispatch }>(
    'groupedActions/getMediaScopes',
    async (authToken, { dispatch }) => {
        try {
            const mediaScopes: MediaScope[] = await CustomAxios.get(
                `${baseUrl}session-durations`,
                getRequestHeader(authToken)
            ).then((response) => response.data)
            dispatch(setMediaScopes(mediaScopes))
        } catch (e) {
            console.error(e)
            throw e
        }
    }
)

export const getMediaTargets = createAsyncThunk<void, string, { dispatch: AppDispatch }>(
    'groupedActions/getMediaTargets',
    async (authToken, { dispatch }) => {
        try {
            const mediaTargets: MediaTarget[] = await CustomAxios.get(
                `${baseUrl}media-targets`,
                getRequestHeader(authToken)
            ).then((response) => response.data)
            dispatch(setMediaTargets(mediaTargets))
        } catch (e) {
            console.error(e)
            throw e
        }
    }
)

export const getMediaTypes = createAsyncThunk<void, string, { dispatch: AppDispatch }>(
    'groupedActions/getMediaTypes',
    async (authToken, { dispatch }) => {
        try {
            const mediaTypes: MediaType[] = await CustomAxios.get(
                `${baseUrl}media-types`,
                getRequestHeader(authToken)
            ).then((response) => response.data)
            dispatch(setMediaTypes(mediaTypes))
        } catch (e) {
            console.error(e)
            throw e
        }
    }
)

export const getNotifications = createAsyncThunk<void, string, { dispatch: AppDispatch }>(
    'groupedActions/getNotifications',
    async (authToken, { dispatch }) => {
        try {
            const notifications: NotificationItem[] = await CustomAxios.get(
                `${baseUrl}notifications?is_read=false`,
                getRequestHeader(authToken)
            ).then((response) => response.data)

            dispatch(setNotifications(notifications))
        } catch (e) {
            console.error(e)
            throw e
        }
    }
)

// --- GENERAL ---

export const fetchGeneralData = createAsyncThunk<void, string, { dispatch: AppDispatch }>(
    'groupedActions/fetchGeneralData',
    async (authToken, { dispatch }) => {
        try {
            await dispatch(getGoals(authToken))
            await dispatch(getPriorities(authToken))
            await dispatch(getBodyAreas(authToken))
            await dispatch(getDevices(authToken))
            await dispatch(getCountries(authToken))
            await dispatch(getCurrencies(authToken))
            await dispatch(getAppTypes(authToken))
            await dispatch(getBadges(authToken))
            await dispatch(getCertifications(authToken))
            await dispatch(getLanguages(authToken))
            await dispatch(getMediaScopes(authToken))
            await dispatch(getMediaTargets(authToken))
            await dispatch(getMediaTypes(authToken))
            await dispatch(getLifeMoments(authToken))
            await dispatch(getPhisicalActivities(authToken))
            await dispatch(getSkinTypes(authToken))
            await dispatch(getFrequencies(authToken))
            await dispatch(getSessionsDurations(authToken))
            await dispatch(getSessionsNumbers(authToken))
            await dispatch(getPriorityIntensities(authToken))
            await dispatch(getCustomerGenders(authToken))
            await dispatch(getBodySections(authToken))
            await dispatch(getHealthyDiets(authToken))
            await dispatch(getStressLevels(authToken))
            await dispatch(getAlcoholConsumptions(authToken))
            await dispatch(getChildbirthTypes(authToken))
            await dispatch(getNotifications(authToken))
        } catch (e) {
            console.error(e)
            throw e
        }
    }
)
