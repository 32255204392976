import { LoggedUser } from './user'

export enum AcceptedMedia {
    IMAGE = '.png, .jpg, .jpeg',
    VIDEO = '.mp4, .mov',
    AUDIO = '.mp3, .wav',
    PDF = '.pdf',
    DOC = '.xls, .xlsx, .csv, .doc, .docx, .ttf, .otf',
    ALL = '.png, .jpg, .jpeg, .mp4, .mov, .mp3, .wav, .pdf, .xls, .xlsx, .csv, .doc, .docx, .ttf, .otf',
}

export enum EntityStatus {
    ACTIVE = 'ACTIVE',
    PENDING = 'PENDING',
    CANCELED = 'CANCELED',
}

export enum ModelUploadStatus {
    DONE = 'DONE',
    UPLOADING = 'UPLOADING',
    ERROR = 'ERROR',
}
export enum UserRole {
    Administrator = 1,
    CenterAdministrator = 2,
    DealerAdministrator = 3,
}
export enum MediaFilesEntity {
    BADGE = 'badge',
    CERTIFICATION = 'certification',
    ICOONE_MEDIA = 'icoone-media',
    RESULT_MEDIA = 'result-media',
}

export enum MediaShareGrant {
    View = 'VIEW',
    Download = 'DOWNLOAD',
    DownloadRequest = 'DOWNLOAD_REQUEST',
}

export enum MediaVisibility {
    dealer = 'DEALER',
    center = 'CENTER',
    all = 'ALL',
}

export enum EntityType {
    dealer = 'DEALER',
    center = 'CENTER',
}

export enum ResultsEvaluation {
    ONE = '1',
    TWO = '2',
    THREE = '3',
    THREE_PLUS = '3plus',
}

export enum NotificationType {
    message = 'MESSAGE',
    result = 'RESULT',
    device = 'DEVICE',
    subscription = 'SUBSCRIPTION',
    certification = 'CERTIFICATION',
    faq = 'FAQ',
}

export enum NotificationEntityType {
    dealer = 'DEALER',
    center = 'CENTER',
    faq = 'FAQ',
    results = 'RESULT',
    message = 'MESSAGE',
}

export interface CustomerGender {
    id: string
    name: string
}

export interface PriorityIntensity {
    id: string
    name: string
    descriprion: string
}

export interface Priority {
    id: string
    name: string
    descriprion: string
    idGoal: string
    bodyAreas: InfoItem[]
}

export interface SimpleEntity {
    id: string
    value: string
    name?: string
}

export interface InfoItem {
    id: string
    name: string
    description?: string
}

export interface Priority extends InfoItem {
    goal: InfoItem
}

export interface AppType {
    id: string
    name: string
    description: string
    color: string | null
}

export interface Price {
    id: number
    name: string
    price: number
    value: string
}

export interface Localization<T> {
    [key: string]: T
}

export interface LocalizedProtocol {
    description: string
}

export interface BodySectionMeasure {
    id: string
    measure: string
    name: string
}

export interface Protocol {
    id: string
    name: string
    description: string
    localized: Localization<LocalizedProtocol>
    creationDate?: string
    updateDate?: string
    sessionDuration: { id: string; value: string } | null
    sessionsNumber: { id: string; value: string } | null
    frequency: SimpleEntity | null
    priorityIntensities: PriorityIntensity[]
    devices: Device[]
    bodyAreas: InfoItem[]
    goals: InfoItem[]
    priorities: Priority[]
    applicationTypes: InfoItem[]
    customerGenders: CustomerGender[]
    lifeMoments: InfoItem[]
    skinTypes: InfoItem[]
    phisicalActivities: InfoItem[]
}

export interface ProtocolToEdit {
    id?: string
    name: string
    description: string
    localized: Localization<LocalizedProtocol>
    creationDate?: string
    updateDate?: string
    idSessionDuration: string | null
    idSessionsNumber: string | null
    idFrequency: string | null
    associations: {
        priorityIntensities: number[]
        lifeMoments: number[]
        phisicalActivities: number[]
        skinTypes: number[]
        devices: number[]
        priorities: number[]
        applicationTypes: number[]
        bodyAreas: number[]
        goals: number[]
        customerGenders: number[]
    }
}

export interface Device {
    id: string
    name: string
    serialNumber?: string
    applicationTypes: AppType[]
    bodyAreas: InfoItem[]
    status?: EntityStatus
}

export interface User {
    id: string
    firstName: string
    lastName: string
    email: string
    language: string
    idRole: number
    createdOn: string
    updatedOn: string
    emailConfirmed: boolean
    isFirstAccess: boolean
}

export interface DealerToEdit {
    id?: string
    city: string
    name: string
    status: EntityStatus
    address: string
    province: string
    zipCode: string
    idCountry: string
    updateDate?: string
    creationDate?: string
    devices: string[]
    certifications: MinifiedDealerCertification[]
    idReferent: string
}

export interface Dealer {
    id?: string
    city: string
    name: string
    status: EntityStatus
    address: string
    province: string
    zipCode: string
    idCountry: string
    updateDate?: string
    creationDate?: string
    country: {
        id: string
        name: string
        idCommercialArea: string | null
    }
    devices: Device[]
    certifications: DealerCertification[]
    notifications?: NotificationItem[]
    referent: User
}

export interface CenterToEdit {
    id: string
    name: string
    address: string
    zipCode: string
    province: string
    city: string
    status: EntityStatus
    creationDate?: string
    updateDate?: string
    idCountry: string
    idDealer?: string | null
    devices: string[]
    badges: string[]
    idReferent: string
    stars?: string
}

export interface Center {
    id: string
    name: string
    referentEmail: string
    address: string
    zipCode: string
    province: string
    city: string
    status: EntityStatus
    creationDate?: string
    updateDate?: string
    country: {
        id: string
        name: string
        idCommercialArea: string | null
    }
    dealer: Dealer
    devices: Device[]
    badges: Badge[]
    notifications?: NotificationItem[]
    referent: User
}

export interface Result {
    id: string
    startDate: string
    endDate: string
    creationDate: string
    updateDate: string
    status: EntityStatus
    evaluation: ResultsEvaluation
    priorityDescription: string
    resultDescription: string
    notes: string
    bodyAreas: InfoItem[]
    goals: InfoItem[]
    priorities: Priority[]
    applicationTypes: AppType[]
    protocols: Protocol[]
    devices: Device[]
    frequency: SimpleEntity
    center: CenterToEdit | null
    priorityIntensity: InfoItem
    sessionsNumber: number
    customer: {
        gender: InfoItem
        age: string
        lifeMoment: InfoItem | null
        skinTypes: InfoItem[]
        phisicalActivity: InfoItem
        weight: string | null
        bodySectionMeasures: BodySectionMeasure[] | null
        pregnancy: InfoItem | null
        pregnancyNumber: number | null
        childbirthType: InfoItem | null
        hydration: InfoItem | null
        smoke: boolean | null
        stressLevel: InfoItem | null
        alcoholConsumption: InfoItem | null
        healtyDiet: InfoItem | null
    }
    notifications?: NotificationItem[]
    media?: ResultMedia[]
}

export interface ResultToCreate {
    startDate: string
    endDate: string
    idCenter: number
    status: string
    evaluation: string | null
    priorityDescription: string
    resultDescription: string
    sessionsNumber: string
    idPriorityIntensity: string
    idFrequency: string
    customerWeight: string | null
    customerPregnancy: boolean | null
    customerPregnancyNumber: string | null
    idChildbirthType: string | null
    customerHydration: string | null
    customerSmoke: boolean | null
    idStressLevel: string | null
    idAlcoholConsumption: string | null
    idHealtyDiet: string | null
    idCustomerGender: string
    idPhisicalActivity: string
    customerAge: string
    idLifeMoment: string | null
    skinTypes: string[]
    notes: string
    bodyAreas: string[]
    goals: string[]
    priorities: string[]
    applicationTypes: string[]
    protocols: {
        idProtocol: string
        sessionDuration: string
    }[]
    bodySectionMeasures: {
        idBodySection: string
        measure: string
    }[]
    devices: string[]
}

interface GraphicEntity {
    id?: string
    name: string
    description: string
    avatar: string
    localized: {
        [key: string]: {
            name: string
            description: string
        }
    }
}

export interface Badge extends GraphicEntity {
    stars: string
}

export interface Certification extends GraphicEntity {
    validity: string
}

export interface Language {
    id: number
    isDefault: boolean
    code: string
    label: string
}

export interface MediaFile {
    name: string
    url: string
    lastModified: string
    locale: string
}

export interface IcooneMediaItem {
    id: string
    name: string
    visibility: string
    shareGrant: MediaShareGrant
    targets: MediaTarget[]
    files: MediaFile[]
    creationDate: string
    updateDate: string
    devices: Device[]
    mediaDirectory: MediaDirectory
    url?: string
    hasLockedControls: boolean
    isTracked: boolean
}

export interface EditableIcooneMediaItem
    extends Omit<IcooneMediaItem, 'targets' | 'devices' | 'shareGrant' | 'files' | 'mediaDirectory'> {
    targets: string[]
    devices: string[]
    shareGrant: string
    idMediaDirectory: string
}

export interface MediaScope {
    id: string
    label: string
}

export interface MediaScopeCategory {
    id: string
    idMediaScope: number
    name: string
}

export interface MediaDirectory {
    id: string
    name: string
    creationDate: string
    updateDate: string
    mediaScope?: MediaScope
    idMediaScope?: string
}

export interface MediaTarget {
    id: string
    name: string
}

export interface MediaType extends MediaTarget {
    extensions: AcceptedMedia
}

export interface DealerCertification {
    idCertification: string
    idDealer: string
    validUntil: string
}

export type MinifiedDealerCertification = Omit<DealerCertification, 'idDealer'>

export interface Message {
    id: string
    message: string
    creationDate: string
    idSender: string
    idLinkedEntity: number
    linkedEntityType: EntityType
    isReadFromReceiver: boolean
    sender: Omit<LoggedUser, 'authToken'>
}

export enum StaleMessageType {
    Loading = 'LOADING',
    Errored = 'ERRORED',
}

export interface StaleMessage {
    id: string
    message: string
    creationDate: string
    type: StaleMessageType
}

export interface IcooneStartModel {
    id: string
    model: any
    uploadDate: string
    isActive: boolean
    status: ModelUploadStatus
}

export interface BodyPart {
    id: string
    image: string
    bodyAreas: InfoItem[]
    name: string
}

export interface ResultMedia {
    id?: number
    idResult: number
    url: string
    idBodyPart: number
    sessionDate: string
    creationDate: string
    updateDate: string
    sessionIndex: number
}

export interface ResultMediaToCreate {
    idResult: number
    idBodyPart: number
    sessionDate: string
    sessionIndex: number
}

export interface FAQItem {
    id: string
    status: EntityStatus
    creationDate: string
    updateDate: string
    answer: string
    question: string
    localized: Localization<{ question: string; answer: string }>
    notifications?: NotificationItem[]
    user?: User
}

export interface NotificationItem {
    id: string
    idUser: string
    type: NotificationType
    isRead: boolean
    createdOn: string
    readOn?: string
    entity: NotificationEntityType
    idEntity?: string
    title: string
    additionalData?: {
        father: NotificationEntityType
        fatherId: number
        message: string
    }
}

export interface Currency {
    id: string
    name: string
    value: string
}

export interface Country {
    id: string
    name: string
    commercialArea: {
        id: string
        name: string
        description: string
        currency: Currency
    }
}

export interface CommercialArea {
    id: string
    idCurrency: string
    name: string
    description: string
    countries: Country[]
}
