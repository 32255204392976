import React, { useEffect, useMemo, useState } from 'react'
import TextInput from '../../../components/Inputs/TextInput'
import SingleSelect from '../../../components/Inputs/SingleSelect'
import { SelectOption } from '../../../components/Inputs/MultiSelect'
import Button from '../../../components/Buttons/Button'
import Paragraph from '../../../components/Typography/Paragraph'
import { useAppSelector } from '../../../store/reducers/store'
import {
    Badge,
    Center,
    CenterToEdit,
    Country,
    Dealer,
    Device,
    EntityStatus,
    Language,
    User,
    UserRole,
} from '../../../types/data'
import { ToastError, ToastSuccess } from '../../../utility/toast'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../resources/routes-constants'
import {
    activateSingleCenterDeviceAssociation,
    createSingleCenter,
    createSingleCenterBadgeAssociation,
    createSingleCenterDeviceAssociation,
    createUser,
    deleteSingleCenter,
    deleteSingleCenterBadgeAssociation,
    deleteSingleCenterDeviceAssociation,
    getDealers,
    getSingleCenter,
    sendUserCredentials,
    updateSingleCenter,
    updateUser,
} from '../../../resources/api-constants'
import DevicesAssociation from '../../../components/DevicesAssociation/DevicesAssociation'
import AsyncMultiSelect from '../../../components/Inputs/AsyncMultiSelect'
import BadgeCard from '../../../components/EntityCards/BadgeCard'
import AlertModal from '../../../components/Modal/AlertModal'

const emptyCenter: CenterToEdit = {
    id: '-1',
    name: '',
    address: '',
    zipCode: '',
    province: '',
    city: '',
    status: EntityStatus.ACTIVE,
    idCountry: '-1',
    devices: [],
    badges: [],
    idReferent: '',
}
const emptyReferent: User = {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    language: '',
    idRole: UserRole.CenterAdministrator,
    createdOn: '',
    updatedOn: '',
    emailConfirmed: false,
    isFirstAccess: false,
}

const CenterAnagraphic: React.FC<{
    idCenter: string
    emitCenterData: (id: string, name: string, status: EntityStatus) => void
}> = ({ idCenter, emitCenterData }) => {
    const user = useAppSelector((data) => data.user)
    const data = useAppSelector((data) => data.data)
    const [originalCenterObject, setOriginalCenterObject] = useState<Center | null>(null)
    const [resetBadgesSelector, setResetBadgesSelector] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [center, setCenter] = useState<CenterToEdit>(emptyCenter)
    const [centerDevices, setCenterDevices] = useState<string[]>([])
    const [centerDevicesApprovated, setCenterDevicesApprovated] = useState<string[]>([])
    const [availableDealers, setAvailableDealers] = useState<Dealer[]>([])
    const [centerBadges, setCenterBadges] = useState<string[]>([])
    const [referent, setReferent] = useState<User>(emptyReferent)
    const navigate = useNavigate()

    const parseCenter = (center: Center): CenterToEdit => {
        setReferent(center.referent)
        return {
            ...center,
            idCountry: center.country.id,
            idDealer: center.dealer ? center.dealer.id : undefined,
            devices: center.devices.map((device) => device.id.toString()),
            badges: center.badges.map((badge) => badge.id?.toString() || ''),
            idReferent: center.referent.id.toString(),
        }
    }

    const fetchCenterData = async () => {
        try {
            setIsLoading(true)
            const fetchedCenter = await getSingleCenter(user.loggedUserData?.authToken || '', idCenter)
            if (fetchedCenter) {
                setOriginalCenterObject(fetchedCenter)
                const parsedCenter = parseCenter(fetchedCenter)
                emitCenterData(fetchedCenter.id, fetchedCenter.name, fetchedCenter.status)
                setCenter(parsedCenter)
                setCenterDevices(parsedCenter.devices)
                setCenterBadges(parsedCenter.badges)
            }
        } catch (error) {
            console.error(error)
            ToastError('Si è verificato un errore durante il recupero dei dati!')
        }
        setIsLoading(false)
    }

    const fetchDealers = async () => {
        try {
            setIsLoading(true)
            const res = await getDealers(user.loggedUserData?.authToken || '')
            if (res) {
                setAvailableDealers(res.items)
            }
        } catch (error) {
            console.error(error)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        void fetchDealers()
    }, [])

    useEffect(() => {
        if (idCenter !== '-1') void fetchCenterData()
    }, [idCenter])

    useEffect(() => {
        if (resetBadgesSelector) setResetBadgesSelector(false)
    }, [resetBadgesSelector])

    const startDeleteProcedure = () => {
        setShowDeleteModal(true)
    }

    const endRemoveCenterProcedure = () => {
        setShowDeleteModal(false)
        ToastSuccess('Centro rimosso con successo')
        navigate(ROUTES.CENTERS_ROUTE)
    }

    const removeCenter = async () => {
        try {
            setIsLoading(true)
            const res = await deleteSingleCenter(user.loggedUserData?.authToken || '', idCenter)
            if (!res) ToastError('Si è verificato un errore durante la procedura di eliminazione!')
            else {
                endRemoveCenterProcedure()
            }
        } catch (error) {
            console.error(error)
        }
        setIsLoading(false)
    }

    const startCreateCenterProcedure = async () => {
        try {
            setIsLoading(true)
            const data = JSON.parse(JSON.stringify(center))
            delete data.id
            try {
                const userCreated = await createUser(user.loggedUserData?.authToken || '', {
                    ...referent,
                    email: referent.email.trim(),
                })

                if (parseInt(userCreated?.idRole?.toString() || '-1', 10) !== UserRole.CenterAdministrator) {
                    switch (parseInt(userCreated?.idRole?.toString() || '-1', 10)) {
                        case UserRole.DealerAdministrator:
                            throw new Error("L'utente è già Amministratore di un distributore")

                        case UserRole.Administrator:
                            throw new Error("L'utente è già Amministratore di sistema")

                        default:
                            break
                    }
                }
                if (userCreated === undefined) {
                    ToastError('Email utente già utilizzata')
                } else {
                    data.id_referent = userCreated?.id
                }
            } catch (error: any) {
                ToastError(error.message)
                setIsLoading(false)
                return
            }

            const res = await createSingleCenter(user.loggedUserData?.authToken || '', data)

            await startAssociateCenterDevicesProcedure(res?.id || '')
            await startAssociateCenterBadgesProcedure(res?.id || '')

            if (res) {
                ToastSuccess('Centro creato con successo')
                emitCenterData(res.id, res.name, res.status)
                navigate(`${ROUTES.CENTER_DETAIL_ROUTE}${res.id}`)
            }
        } catch (error) {
            console.error(error)
            ToastError('Si è verificato un errore durante la procedura di creazione!')
        }
        setIsLoading(false)
    }

    const startUpdateCenterProcedure = async () => {
        setIsLoading(true)
        try {
            const userPatch = await updateUser(user.loggedUserData?.authToken || '', {
                ...referent,
                email: referent.email.trim(),
            })

            // if (parseInt(userPatch?.idRole?.toString() || '-1', 10) !== UserRole.CenterAdministrator) {
            //     switch (parseInt(userPatch?.idRole?.toString() || '-1', 10)) {
            //         case UserRole.DealerAdministrator:
            //             throw new Error("L'utente è già Amministratore di un distributore")
            //         case UserRole.Administrator:
            //             throw new Error("L'utente è già Amministratore di sistema")
            //         default:
            //             break
            //     }
            // }
            if (userPatch === undefined) {
                ToastError('Email utente già utilizzata')
            }
        } catch (error: any) {
            ToastError(error.message)
            setIsLoading(false)
            return
        }
        try {
            const res = await updateSingleCenter(user.loggedUserData?.authToken || '', idCenter, center)

            if (res) {
                await startAssociateCenterDevicesProcedure(res?.id || '')
                await startAssociateCenterBadgesProcedure(res?.id || '')
                ToastSuccess('Centro aggiornato con successo')
                await fetchCenterData()
            }
        } catch (error) {
            console.error(error)
            ToastError('Si è verificato un errore durante la procedura di aggiornamento!')
        }
        setIsLoading(false)
    }

    const startAssociateCenterDevicesProcedure = async (idCenter: string) => {
        try {
            const deviceToRemove = originalCenterObject?.devices
                .filter((item) => item.status === EntityStatus.ACTIVE)
                .filter((device) => !centerDevices.includes(device.id.toString()))

            const devicetoAdd = centerDevices.filter(
                (device) =>
                    !originalCenterObject?.devices
                        .filter((item) => item.status === EntityStatus.ACTIVE)
                        .map((d) => d.id.toString())
                        .includes(device)
            )

            await Promise.all(
                devicetoAdd.map(async (device) => {
                    await createSingleCenterDeviceAssociation(user.loggedUserData?.authToken || '', idCenter, device)
                    if (centerDevicesApprovated.includes(device)) {
                        await activateSingleCenterDeviceAssociation(
                            user.loggedUserData?.authToken || '',
                            idCenter,
                            device
                        )
                    }
                })
            )

            if (deviceToRemove) {
                await Promise.all(
                    deviceToRemove.map(async (device: Device) => {
                        await deleteSingleCenterDeviceAssociation(
                            user.loggedUserData?.authToken || '',
                            idCenter,
                            device.id.toString()
                        )
                    })
                )
            }
        } catch (error) {
            console.error(error)
        }
    }

    const startAssociateCenterBadgesProcedure = async (idCenter: string) => {
        try {
            const itemToRemove = originalCenterObject?.badges.filter(
                (badge) => badge.id && !centerBadges.includes(badge.id.toString())
            )

            const itemToAdd = centerBadges.filter(
                (item) => !originalCenterObject?.badges.map((d) => d.id && d.id.toString()).includes(item)
            )

            await Promise.all(
                itemToAdd.map(async (item) => {
                    await createSingleCenterBadgeAssociation(user.loggedUserData?.authToken || '', idCenter, item)
                })
            )

            if (itemToRemove) {
                await Promise.all(
                    itemToRemove.map(async (item: Badge) => {
                        item.id &&
                            (await deleteSingleCenterBadgeAssociation(
                                user.loggedUserData?.authToken || '',
                                idCenter,
                                item.id.toString()
                            ))
                    })
                )
            }
        } catch (error) {
            console.error(error)
        }
    }

    const startVerifyCenterProcedure = async () => {
        try {
            setIsLoading(true)
            const res = await updateSingleCenter(user.loggedUserData?.authToken || '', idCenter, {
                ...center,
                status: EntityStatus.ACTIVE,
                devices: centerDevices,
                badges: centerBadges,
            })
            if (res) {
                ToastSuccess('Centro approvato con successo')
                await fetchCenterData()
            }
        } catch (error) {
            console.error(error)
            ToastError('Si è verificato un errore durante la procedura di approvazione!')
        }
        setIsLoading(false)
    }

    const formatItemForDealer = (item: Dealer | undefined): SelectOption | undefined => {
        if (!item) return
        return { value: item.id ?? '', label: item.name }
    }

    const formatItemForCountry = (item: Country | undefined): SelectOption | undefined => {
        if (!item) return
        return { value: item.id, label: item.name }
    }

    const formatItemForLanguage = (item: Language | undefined): SelectOption | undefined => {
        if (!item) return
        return { value: item.code, label: item.label }
    }

    const dataIsNotValid = useMemo(() => {
        return (
            !center.name ||
            !center.address ||
            !center.city ||
            !center.province ||
            !center.zipCode ||
            !center.idCountry ||
            center.idCountry === '-1' ||
            !referent.firstName ||
            !referent.lastName ||
            !referent.email ||
            !referent.language
        )
    }, [center.name, center.address, center.city, center.province, center.zipCode, center.idCountry, referent])

    const filteredDealers = useMemo(() => {
        return availableDealers.filter(
            (dealer) => dealer.country.id === center.idCountry || dealer.id === center.idDealer
        )
    }, [center.idCountry, center.idDealer, availableDealers])

    const startSendCredentialsProcedure = async () => {
        try {
            setIsLoading(true)
            await sendUserCredentials(user.loggedUserData?.authToken || '', referent.id)
            ToastSuccess('Credenziali inviate con successo')
        } catch (error) {
            console.error(error)
            ToastError("Si è verificato un errore durante l'invio delle credenziali!")
        }
        setIsLoading(false)
    }

    return (
        <>
            {showDeleteModal && (
                <AlertModal
                    modalTitle="Elimina centro"
                    modalMessage={`Sei sicuro di voler eliminare l'elemento ${originalCenterObject?.name}? Questa operazione è irreversibile.`}
                    onClose={() => setShowDeleteModal(false)}
                    onConfirm={() => void removeCenter()}
                />
            )}
            <div className="page-header-section">
                <div className="page-header-section__left-box" />
                <div className="page-header-section__right-box">
                    <Button disabled={isLoading} buttonType="secondary" onClick={() => navigate(ROUTES.CENTERS_ROUTE)}>
                        Annulla
                    </Button>
                    {idCenter !== '-1' && (
                        <>
                            {center.status === EntityStatus.PENDING && (
                                <Button
                                    disabled={isLoading}
                                    buttonType="primary"
                                    onClick={() => void startVerifyCenterProcedure()}
                                >
                                    Approva centro
                                </Button>
                            )}

                            <Button
                                disabled={isLoading}
                                buttonType="secondary-error"
                                onClick={() => void startDeleteProcedure()}
                            >
                                Elimina
                            </Button>
                        </>
                    )}
                    <Button
                        loading={isLoading}
                        disabled={dataIsNotValid}
                        buttonType="primary"
                        onClick={() => {
                            if (idCenter === '-1') {
                                void startCreateCenterProcedure()
                            } else {
                                void startUpdateCenterProcedure()
                            }
                        }}
                    >
                        {idCenter === '-1' ? 'Aggiungi centro' : 'Aggiorna centro'}
                    </Button>
                </div>
            </div>
            <div className="page-content-flow">
                <div className="elevated-card full-width">
                    <div className="input-form-box">
                        <div className="input-form-box__five-col-row">
                            <TextInput
                                inputLabel="nome referente"
                                value={referent.firstName || ''}
                                onValueChange={(newVal) => setReferent({ ...referent, firstName: newVal })}
                            />
                            <TextInput
                                inputLabel="cognome referente"
                                value={referent.lastName}
                                onValueChange={(newVal) => setReferent({ ...referent, lastName: newVal })}
                            />
                            <TextInput
                                inputLabel="email"
                                value={referent.email}
                                onValueChange={(newVal) => setReferent({ ...referent, email: newVal })}
                            />
                            <SingleSelect
                                inputLabel="Lingua"
                                placeholder="Seleziona una lingua"
                                options={data.languages.map((language) => ({
                                    value: language.code,
                                    label: language.label,
                                }))}
                                value={formatItemForLanguage(
                                    data.languages.find((language) => language.code === referent.language.toLowerCase())
                                )}
                                onValueChange={(selectedOption) =>
                                    setReferent({ ...referent, language: selectedOption?.value || '' })
                                }
                            />
                            {referent.id && (
                                <div style={{ paddingTop: 16, display: 'flex', alignItems: 'flex-end' }}>
                                    <Button
                                        loading={isLoading}
                                        buttonType="primary"
                                        onClick={() => {
                                            void startSendCredentialsProcedure()
                                        }}
                                    >
                                        {'Invia le credenziali'}
                                    </Button>
                                </div>
                            )}
                            {/* <TextInput inputLabel="password" onValueChange={() => null} />
                            <div>
                                <div>
                                    <UppercaseLabel>&nbsp;</UppercaseLabel>
                                </div>
                                <div>
                                    La password per questo account è già stata specificata. Inserendone una nuova
                                    eseguirai il reset per questo centro.
                                </div>
                            </div> */}
                        </div>
                        <div className="horizontal-line-separator" />

                        <div className="input-form-box__three-col-row">
                            <TextInput
                                inputLabel="ragione sociale"
                                value={center.name}
                                onValueChange={(newVal) => setCenter({ ...center, name: newVal })}
                            />
                            <TextInput
                                inputLabel="indirizzo"
                                value={center.address}
                                onValueChange={(newVal) => setCenter({ ...center, address: newVal })}
                            />
                            <TextInput
                                inputLabel="città"
                                value={center.city}
                                onValueChange={(newVal) => setCenter({ ...center, city: newVal })}
                            />
                            <TextInput
                                inputLabel="provincia"
                                value={center.province}
                                onValueChange={(newVal) => setCenter({ ...center, province: newVal })}
                            />
                            <TextInput
                                inputLabel="cap"
                                value={center.zipCode}
                                onValueChange={(newVal) => setCenter({ ...center, zipCode: newVal })}
                            />
                            <SingleSelect
                                inputLabel="nazione"
                                placeholder="Seleziona uno nazione"
                                options={data.countries.map((country) => ({ value: country.id, label: country.name }))}
                                value={formatItemForCountry(
                                    data.countries.find((country) => country.id === center.idCountry)
                                )}
                                onValueChange={(selectedOption) =>
                                    setCenter({ ...center, idCountry: selectedOption?.value || '' })
                                }
                            />
                            <SingleSelect
                                disabled={availableDealers.length === 0 || center.idCountry === '-1'}
                                inputLabel="distributore associato"
                                placeholder="Seleziona un distributore"
                                options={filteredDealers.map((dealer) => ({
                                    value: dealer.id ?? '',
                                    label: dealer.name,
                                }))}
                                value={formatItemForDealer(
                                    filteredDealers.find((dealer) => center.idDealer && dealer.id === center.idDealer)
                                )}
                                onValueChange={(selectedOption) => {
                                    if (!selectedOption || !selectedOption.value) return
                                    setCenter({
                                        ...center,
                                        idDealer: selectedOption.value,
                                    })
                                }}
                            />
                            {center.idDealer !== undefined && center.idDealer !== '-1' && center.idDealer !== null && (
                                <span style={{ paddingTop: 22, width: '100%' }}>
                                    <Button
                                        loading={isLoading}
                                        disabled={isLoading}
                                        buttonType="primary"
                                        onClick={() => {
                                            setCenter({ ...center, idDealer: null })
                                        }}
                                    >
                                        Scollega distributore
                                    </Button>
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                <DevicesAssociation
                    title="dispositivi associati"
                    emptyMessage="I dispositivi associati a questo centro verranno mostrati qui."
                    currentSelection={originalCenterObject?.devices.map((device) => device) || []}
                    onSelectionChange={(devices, pendingApprovated) => {
                        setCenterDevices(devices.map((device) => device.id.toString()))
                        setCenterDevicesApprovated(pendingApprovated.map((device) => device.id.toString()))
                    }}
                />
                <div className="elevated-card full-width">
                    <div className="input-form-box">
                        <div className="input-form-box__three-col-row">
                            <AsyncMultiSelect
                                reset={resetBadgesSelector}
                                closeMenuOnSelect={false}
                                inputLabel="badge ottenuti"
                                placeholder="Assegna nuovo badge"
                                filterOption={(option) => centerBadges.indexOf(option.value) === -1}
                                options={[]}
                                endpoint="badges"
                                userToken={user.loggedUserData?.authToken}
                                onValueChange={(newVals) => {
                                    setCenterBadges([...centerBadges, ...newVals.map((val) => val.value)])
                                    setResetBadgesSelector(true)
                                }}
                            />
                        </div>
                        <div
                            className={`chip-container-box ${centerBadges.length > 0 ? 'not-empty-box' : 'empty-box'}`}
                        >
                            {centerBadges.length > 0 ? (
                                <>
                                    {centerBadges.map((centerBadgeId) => {
                                        const badgeObject = data.badges.find(
                                            (badge) => badge.id?.toString() === centerBadgeId
                                        )
                                        if (!badgeObject) return
                                        return (
                                            <BadgeCard
                                                key={badgeObject.id}
                                                badge={badgeObject}
                                                onRemove={() =>
                                                    setCenterBadges(
                                                        centerBadges.filter(
                                                            (centerBadge) => centerBadge !== centerBadgeId
                                                        )
                                                    )
                                                }
                                            />
                                        )
                                    })}
                                </>
                            ) : (
                                <Paragraph>I badge ottenuti da questo centro verranno mostrati qui.</Paragraph>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CenterAnagraphic
