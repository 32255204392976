import { createReducer } from '@reduxjs/toolkit'
import { setExpandedSidebarSection, setSidebarIsCollapsed } from '../actions/graphic'

interface GraphicReducer {
    sidebarIsCollapsed: boolean
    expandedSidebarSection: string
}

const initialState: GraphicReducer = {
    sidebarIsCollapsed: false,
    expandedSidebarSection: '',
}

const userReducer = createReducer<GraphicReducer>(initialState, (builder) => {
    builder.addCase(setSidebarIsCollapsed, (state, action) => {
        state.sidebarIsCollapsed = action.payload
    })
    builder.addCase(setExpandedSidebarSection, (state, action) => {
        state.expandedSidebarSection = action.payload
    })
})

export default userReducer
