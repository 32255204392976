import {
    setAlcoholConsumptions,
    setBodySections,
    setChildbirthTypes,
    setHealthyDiets,
    setStressLevels,
} from './../actions/data'
import { SimpleEntity, MediaType, Priority } from './../../types/data'
import { createReducer } from '@reduxjs/toolkit'
import {
    AppType,
    Badge,
    Certification,
    Country,
    Currency,
    Device,
    InfoItem,
    Language,
    MediaScope,
    MediaTarget,
} from '../../types/data'
import {
    setAppTypes,
    setBadges,
    setBodyAreas,
    setCertifications,
    setCountries,
    setCurrencies,
    setCustomerGenders,
    setDevices,
    setFrequencies,
    setGoals,
    setLanguages,
    setLifeMoments,
    setMediaScopes,
    setMediaTargets,
    setMediaTypes,
    setPhisicalActivities,
    setPriorities,
    setPriorityIntensities,
    setSessionsDurations,
    setSessionsNumbers,
    setSkinTypes,
} from '../actions/data'

interface DataReducer {
    goals: InfoItem[]
    priorities: Priority[]
    bodyAreas: InfoItem[]
    devices: Device[]
    countries: Country[]
    currencies: Currency[]
    appTypes: AppType[]
    badges: Badge[]
    certifications: Certification[]
    languages: Language[]
    defaultLanguageCode: string
    mediaScopes: MediaScope[]
    mediaTargets: MediaTarget[]
    mediaTypes: MediaType[]
    lifeMoments: InfoItem[]
    phisicalActivities: InfoItem[]
    skinTypes: InfoItem[]
    frequencies: SimpleEntity[]
    sessionsDurations: SimpleEntity[]
    sessionsNumbers: SimpleEntity[]
    priorityIntensities: InfoItem[]
    customerGenders: InfoItem[]
    bodySections: InfoItem[]
    healthyDiets: InfoItem[]
    stressLevels: InfoItem[]
    childbirthTypes: InfoItem[]
    alcoholConsumptions: InfoItem[]
}

const initialState: DataReducer = {
    goals: [],
    priorities: [],
    bodyAreas: [],
    devices: [],
    countries: [],
    currencies: [],
    appTypes: [],
    badges: [],
    certifications: [],
    languages: [],
    defaultLanguageCode: 'en',
    mediaScopes: [],
    mediaTargets: [],
    mediaTypes: [],
    lifeMoments: [],
    phisicalActivities: [],
    skinTypes: [],
    frequencies: [],
    sessionsDurations: [],
    sessionsNumbers: [],
    priorityIntensities: [],
    customerGenders: [],
    bodySections: [],
    healthyDiets: [],
    stressLevels: [],
    childbirthTypes: [],
    alcoholConsumptions: [],
}

const dataReducer = createReducer<DataReducer>(initialState, (builder) => {
    builder.addCase(setGoals, (state, action) => {
        state.goals = action.payload
    })
    builder.addCase(setPriorities, (state, action) => {
        state.priorities = action.payload
    })
    builder.addCase(setBodyAreas, (state, action) => {
        state.bodyAreas = action.payload
    })
    builder.addCase(setDevices, (state, action) => {
        state.devices = action.payload
    })
    builder.addCase(setCountries, (state, action) => {
        state.countries = action.payload
    })
    builder.addCase(setCurrencies, (state, action) => {
        state.currencies = action.payload
    })
    builder.addCase(setAppTypes, (state, action) => {
        state.appTypes = action.payload
    })
    builder.addCase(setBadges, (state, action) => {
        state.badges = action.payload
    })
    builder.addCase(setCertifications, (state, action) => {
        state.certifications = action.payload
    })
    builder.addCase(setLanguages, (state, action) => {
        state.languages = action.payload
        state.defaultLanguageCode = action.payload.find((i) => i.isDefault === true)?.code ?? 'en'
    })
    builder.addCase(setMediaScopes, (state, action) => {
        state.mediaScopes = action.payload
    })
    builder.addCase(setMediaTargets, (state, action) => {
        state.mediaTargets = action.payload
    })
    builder.addCase(setMediaTypes, (state, action) => {
        state.mediaTypes = action.payload
    })
    builder.addCase(setLifeMoments, (state, action) => {
        state.lifeMoments = action.payload
    })
    builder.addCase(setPhisicalActivities, (state, action) => {
        state.phisicalActivities = action.payload
    })
    builder.addCase(setSkinTypes, (state, action) => {
        state.skinTypes = action.payload
    })
    builder.addCase(setFrequencies, (state, action) => {
        state.frequencies = action.payload
    })
    builder.addCase(setSessionsDurations, (state, action) => {
        state.sessionsDurations = action.payload
    })
    builder.addCase(setSessionsNumbers, (state, action) => {
        state.sessionsNumbers = action.payload
    })
    builder.addCase(setPriorityIntensities, (state, action) => {
        state.priorityIntensities = action.payload
    })
    builder.addCase(setCustomerGenders, (state, action) => {
        state.customerGenders = action.payload
    })
    builder.addCase(setBodySections, (state, action) => {
        state.bodySections = action.payload
    })
    builder.addCase(setHealthyDiets, (state, action) => {
        state.healthyDiets = action.payload
    })
    builder.addCase(setStressLevels, (state, action) => {
        state.stressLevels = action.payload
    })
    builder.addCase(setChildbirthTypes, (state, action) => {
        state.childbirthTypes = action.payload
    })
    builder.addCase(setAlcoholConsumptions, (state, action) => {
        state.alcoholConsumptions = action.payload
    })
})

export default dataReducer
