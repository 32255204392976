import React, { useEffect, useState } from 'react'
import Button from '../../../components/Buttons/Button'
import { useAppSelector } from '../../../store/reducers/store'
import { BodyPart, EntityStatus, Result, ResultMedia, ResultsEvaluation } from '../../../types/data'
import { ToastError, ToastSuccess } from '../../../utility/toast'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../resources/routes-constants'
import {
    deleteSingleResult,
    evaluateResult,
    getResultBodyParts,
    getSingleResult,
} from '../../../resources/api-constants'
import UppercaseLabel from '../../../components/Typography/UppercaseLabel'
import EvaluationModal from '../../../components/Modal/EvaluationModal'
import AlertModal from '../../../components/Modal/AlertModal'
import TextLabel from '../../../components/Badges/TextLabel'
import Link from '../../../components/Link/Link'
import ResultMediaSection from '../../../custom-components/ResultMediaSection'

const emptyInfoItem = { id: '-1', name: '' }

const emptyResult: Result = {
    id: '-1',
    startDate: '',
    endDate: '',
    creationDate: '',
    updateDate: '',
    center: null,
    status: '' as EntityStatus,
    evaluation: '' as ResultsEvaluation,
    priorityIntensity: emptyInfoItem,
    priorityDescription: '',
    resultDescription: '',
    notes: '',
    bodyAreas: [],
    goals: [],
    priorities: [],
    applicationTypes: [],
    protocols: [],
    devices: [],
    frequency: {
        id: '-1',
        value: '',
    },
    sessionsNumber: 0,
    customer: {
        gender: emptyInfoItem,
        age: '',
        lifeMoment: null,
        skinTypes: [emptyInfoItem],
        phisicalActivity: emptyInfoItem,
        weight: null,
        bodySectionMeasures: null,
        pregnancy: null,
        pregnancyNumber: null,
        childbirthType: null,
        hydration: null,
        smoke: null,
        stressLevel: null,
        alcoholConsumption: null,
        healtyDiet: null,
    },
}

const ResultInfoPage: React.FC<{
    idResult: string
    emitResultData: (status: EntityStatus, evaluation: string) => void
}> = ({ idResult, emitResultData }) => {
    const user = useAppSelector((data) => data.user)
    const data = useAppSelector((data) => data.data)
    const [result, setResult] = useState<Result>(emptyResult)
    const [showEvaluationModal, setShowEvaluationModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [resultWasEvaluated, setResultWasEvaluated] = useState(false)
    const [resultBodyParts, setResultBodyParts] = useState<BodyPart[]>([])
    const [resultMediaToDelete, setResultMediaToDelete] = useState<ResultMedia[]>([])
    const navigate = useNavigate()

    const fetchResultData = async () => {
        try {
            const fetchedResult = await getSingleResult(user.loggedUserData?.authToken || '', idResult)
            if (fetchedResult) {
                emitResultData(
                    fetchedResult.status,
                    fetchedResult.status !== EntityStatus.PENDING ? fetchedResult.evaluation : ''
                )
                setResult(fetchedResult)
            }
        } catch (error) {
            console.error(error)
            ToastError('Si è verificato un errore durante il recupero dei dati!')
        }
    }

    const calculateBodyParts = async () => {
        try {
            const fetchedResultBodyParts = await getResultBodyParts(
                user.loggedUserData?.authToken || '',
                result.bodyAreas.map((bodyArea) => bodyArea.id).join(',')
            )
            if (fetchedResultBodyParts) setResultBodyParts(fetchedResultBodyParts)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (result.bodyAreas && result.bodyAreas.length > 0) {
            void calculateBodyParts()
        } else setResultBodyParts([])
    }, [result.bodyAreas])

    useEffect(() => {
        if (idResult !== '-1') void fetchResultData()
    }, [idResult])

    const startDeleteProcedure = () => {
        setShowDeleteModal(true)
    }

    const endRemoveProtocolProcedure = () => {
        setShowDeleteModal(false)
        ToastSuccess('Risultato rimosso con successo')
        navigate(ROUTES.RESULTS_ROUTE)
    }

    const removeResult = async () => {
        try {
            const res = await deleteSingleResult(user.loggedUserData?.authToken || '', idResult)
            if (!res) ToastError('Si è verificato un errore durante la procedura di eliminazione!')
            else endRemoveProtocolProcedure()
        } catch (error) {
            console.error(error)
        }
    }

    const sendEvaluation = async () => {
        try {
            const res = await evaluateResult(user.loggedUserData?.authToken || '', idResult, result.evaluation)
            if (res) {
                setResultWasEvaluated(false)
                ToastSuccess('Risultato valutato con successo')
                await fetchResultData()
            }
        } catch (error) {
            console.error(error)
            ToastError('Si è verificato un errore durante la procedura di valutazione!')
        }
    }

    // const removeResultMediaItems = async () => {
    //     try {
    //         await Promise.all(
    //             resultMediaToDelete.map(async (resultMedia) => {
    //                 await startDeleteProcess(resultMedia)
    //             })
    //         )
    //         await fetchResultData()
    //         ToastSuccess('Risultato aggiornato con successo')
    //     } catch (error) {
    //         console.error(error)
    //         ToastError('Si è verificato un errore durante la procedura di aggiornamento!')
    //     }
    // }

    // const startDeleteProcess = async (rawMedia: ResultMedia) => {
    //     try {
    //         if (!rawMedia) return
    //         const res = await deleteResultMedia(user.loggedUserData?.authToken || '', rawMedia.id?.toString() || '')
    //         if (!res) throw new Error('Error during removal of result media')
    //     } catch (error) {
    //         console.error(error)
    //     }
    // }

    useEffect(() => {
        if (result && result.evaluation && resultWasEvaluated) void sendEvaluation()
    }, [result.evaluation, resultWasEvaluated])

    const startEvaluationProcess = () => {
        setShowEvaluationModal(true)
    }

    const getKeyLabel = (key: string) => {
        switch (key) {
            case 'alcoholConsumption':
                return 'Consumo alcolici'
            case 'childbirthType':
                return 'Parto'
            case 'healtyDiet':
                return 'Alimentazione sana'
            case 'hydration':
                return 'Apporto idrico'
            case 'smoke':
                return 'Fumo'
            case 'pregnancy':
                return 'Gravidanze'
            case 'pregnancyNumber':
                return 'Numero gravidanze'
            case 'stressLevel':
                return 'Livello di stress'
            case 'weight':
                return 'Peso'
            case 'bodySectionMeasures':
                return 'Misure'
            default:
                return key
        }
    }

    const getCustomerAdditionalInfo = () => {
        const customer = JSON.parse(JSON.stringify(result.customer))
        const removableKeys = ['age', 'gender', 'lifeMoment', 'phisicalActivity', 'skinType']
        removableKeys.forEach((key: string) => {
            delete customer[key]
        })
        return Object.keys(customer).map((customerKey) => {
            if (customer[customerKey] || (customerKey === 'smoke' && customer[customerKey] !== null)) {
                if (customerKey === 'bodySectionMeasures') {
                    return (
                        <div key={customerKey}>
                            {customer.bodySectionMeasures.length > 0 && <span>{getKeyLabel(customerKey)}</span>}
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {customer[customerKey].map(
                                    (bodyMeasure: { id: string; name: string; measure: string }) => (
                                        <span key={bodyMeasure.id}>
                                            {bodyMeasure.name}: <b>{bodyMeasure.measure}</b>
                                        </span>
                                    )
                                )}
                            </div>
                        </div>
                    )
                }
                // eslint-disable-next-line nonblock-statement-body-position
                return (
                    <span key={customerKey}>
                        {getKeyLabel(customerKey)}:{' '}
                        <b>
                            {typeof customer[customerKey] !== 'object'
                                ? typeof customer[customerKey] === 'boolean'
                                    ? customer[customerKey]
                                        ? 'Si'
                                        : 'No'
                                    : customer[customerKey]
                                : customer[customerKey].name}
                        </b>
                    </span>
                )
            }
            return ''
        })
    }

    return (
        <>
            {showEvaluationModal && (
                <EvaluationModal
                    modalTitle="Valutazione risultato"
                    onClose={() => setShowEvaluationModal(false)}
                    // onConfirm={(evaluation) => void sendEvaluation(evaluation)}
                    onConfirm={(evaluation) => {
                        setResult({ ...result, evaluation })
                        setResultWasEvaluated(true)
                        setShowEvaluationModal(false)
                    }}
                />
            )}
            {showDeleteModal && (
                <AlertModal
                    modalTitle="Elimina risultato"
                    modalMessage={`Sei sicuro di voler eliminare l'elemento RIS-${
                        idResult || ''
                    }? Questa operazione è irreversibile.`}
                    onClose={() => setShowDeleteModal(false)}
                    onConfirm={() => void removeResult()}
                />
            )}
            <div className="page-header-section">
                <div className="page-header-section__left-box" />
                <div className="page-header-section__right-box">
                    <Button buttonType="secondary" onClick={() => navigate(ROUTES.RESULTS_ROUTE)}>
                        Annulla
                    </Button>
                    <Button buttonType="secondary-error" onClick={() => void startDeleteProcedure()}>
                        Elimina
                    </Button>
                    {result.status === EntityStatus.PENDING && (
                        <>
                            <Button buttonType="primary" onClick={() => startEvaluationProcess()}>
                                Valuta risultato
                            </Button>
                            {/* <Button
                                buttonType="primary"
                                disabled={resultMediaToDelete.length === 0}
                                onClick={() => void removeResultMediaItems()}
                            >
                                Aggiorna risultato
                            </Button> */}
                        </>
                    )}
                </div>
            </div>
            <div className="page-content-flow">
                <div className="elevated-card full-width">
                    <div className="input-form-box">
                        <div className="input-form-box__three-col-row">
                            <div style={{ marginLeft: 6 }}>
                                <div style={{ marginBottom: 6 }}>
                                    <UppercaseLabel>cliente</UppercaseLabel>
                                </div>
                                <div>
                                    <p>Genere: {result.customer.gender.name}</p>
                                    <p>
                                        Attività fisica:{' '}
                                        {data.phisicalActivities.find(
                                            (phisicalActivity) =>
                                                phisicalActivity.id === result.customer.phisicalActivity.id
                                        )?.name || ''}
                                    </p>
                                </div>
                            </div>
                            <div style={{ marginLeft: 6 }}>
                                <div style={{ marginBottom: 6 }}>
                                    <UppercaseLabel>periodo della vita</UppercaseLabel>
                                </div>
                                <div>
                                    <p>Età: {result.customer.age}</p>
                                    <p>
                                        Periodo:{' '}
                                        {data.lifeMoments.find(
                                            (lifeMoment) => lifeMoment.id === result.customer.lifeMoment?.id
                                        )?.name || '-'}
                                    </p>
                                </div>
                            </div>
                            <div style={{ marginLeft: 6 }}>
                                <div style={{ marginBottom: 6 }}>
                                    <UppercaseLabel>periodo trattamento</UppercaseLabel>
                                </div>
                                <div>
                                    <p>Inizio: {new Date(result.startDate).toLocaleDateString()}</p>
                                    <p>Fine: {new Date(result.endDate).toLocaleDateString()}</p>
                                </div>
                            </div>
                        </div>
                        <div className="input-form-box__three-col-row">
                            <div style={{ marginLeft: 6 }}>
                                <div style={{ marginBottom: 6 }}>
                                    <UppercaseLabel>Informazioni aggiuntive</UppercaseLabel>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    {getCustomerAdditionalInfo()}
                                </div>
                            </div>
                            <div />
                            <div />
                        </div>
                        <div className="horizontal-line-separator" />
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 32, width: '100%' }}>
                            <div className="input-form-box__three-col-row">
                                <div style={{ marginLeft: 6 }}>
                                    <div style={{ marginBottom: 6 }}>
                                        <UppercaseLabel>tipo di pelle</UppercaseLabel>
                                    </div>
                                    <div>
                                        {data.skinTypes.filter((skinType) =>
                                            result.customer.skinTypes
                                                .map((sT) => sT.id.toString())
                                                .includes(skinType.id.toString())
                                        ).map((skinType) => skinType.name).join(', ') || ''}
                                    </div>
                                </div>
                                <div style={{ marginLeft: 6 }}>
                                    <div style={{ marginBottom: 6 }}>
                                        <UppercaseLabel>area interessata</UppercaseLabel>
                                    </div>
                                    <div>{result.bodyAreas.map((bodyArea) => bodyArea.name).join(', ')}</div>
                                </div>
                                <div style={{ marginLeft: 6 }}>
                                    <div style={{ marginBottom: 6 }}>
                                        <UppercaseLabel>tipologia applicazione</UppercaseLabel>
                                    </div>
                                    <div style={{ display: 'flex', gap: 8 }}>
                                        {result.applicationTypes.map((type) => (
                                            <TextLabel key={type.id} customColor={type.color}>
                                                {type.name}
                                            </TextLabel>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="input-form-box__three-col-row">
                                <div style={{ marginLeft: 6 }}>
                                    <div style={{ marginBottom: 6 }}>
                                        <UppercaseLabel>obiettivi da raggiungere</UppercaseLabel>
                                    </div>
                                    <div>{result.goals.map((goal) => goal.name).join(', ')}</div>
                                </div>
                                <div style={{ marginLeft: 6 }}>
                                    <div style={{ marginBottom: 6 }}>
                                        <UppercaseLabel>problemi da risolvere</UppercaseLabel>
                                    </div>
                                    <div>{result.priorities.map((problem) => problem.name).join(', ')}</div>
                                </div>
                                <div style={{ marginLeft: 6 }}>
                                    <div style={{ marginBottom: 6 }}>
                                        <UppercaseLabel>intensità problema</UppercaseLabel>
                                    </div>
                                    <div>{result.priorityIntensity.name}</div>
                                </div>
                            </div>
                            <div className="input-form-box__three-col-row">
                                <div style={{ marginLeft: 6 }}>
                                    <div style={{ marginBottom: 6 }}>
                                        <UppercaseLabel>Dispositivi</UppercaseLabel>
                                    </div>
                                    <div>{result.devices.map((problem) => problem.name).join(', ')}</div>
                                </div>
                                <div style={{ marginLeft: 6 }}>
                                    <div style={{ marginBottom: 6 }}>
                                        <UppercaseLabel>Descrizione problema</UppercaseLabel>
                                    </div>
                                    <div>{result.priorityDescription || '-'}</div>
                                </div>
                                <div style={{ marginLeft: 6 }}>
                                    <div style={{ marginBottom: 6 }}>
                                        <UppercaseLabel>Descrizione risultato</UppercaseLabel>
                                    </div>
                                    <div>{result.resultDescription || '-'}</div>
                                </div>
                            </div>
                            <div className="input-form-box__three-col-row">
                                <div style={{ marginLeft: 6 }}>
                                    <div style={{ marginBottom: 6 }}>
                                        <UppercaseLabel>Frequenza sessioni</UppercaseLabel>
                                    </div>
                                    <div>{result.frequency.value}</div>
                                </div>
                                <div style={{ marginLeft: 6 }}>
                                    <div style={{ marginBottom: 6 }}>
                                        <UppercaseLabel>numero di sessioni</UppercaseLabel>
                                    </div>
                                    <div>{result.sessionsNumber}</div>
                                </div>
                            </div>
                        </div>
                        <div className="horizontal-line-separator" />
                        <div className="input-form-box__three-col-row">
                            <div style={{ marginLeft: 6 }}>
                                <div style={{ marginBottom: 6 }}>
                                    <UppercaseLabel>Protocolli</UppercaseLabel>
                                </div>
                            </div>
                            <div />
                            <div />
                        </div>
                        {result.protocols.map((protocol) => (
                            <div
                                key={protocol.id}
                                style={{ display: 'flex', flexDirection: 'column', gap: 32, width: '100%' }}
                            >
                                <div className="input-form-box__three-col-row">
                                    <div style={{ marginLeft: 6 }}>
                                        <div style={{ marginBottom: 6 }}>
                                            <UppercaseLabel>nome</UppercaseLabel>
                                        </div>
                                        <Link internalRoute={`${ROUTES.PROTOCOL_DETAIL_ROUTE}${protocol.id}`}>
                                            {protocol.name}
                                        </Link>
                                    </div>
                                    <div style={{ marginLeft: 6 }}>
                                        <div style={{ marginBottom: 6 }}>
                                            <UppercaseLabel>descrizione</UppercaseLabel>
                                        </div>
                                        <div>{protocol.description}</div>
                                    </div>
                                    <div style={{ marginLeft: 6 }}>
                                        <div style={{ marginBottom: 6 }}>
                                            <UppercaseLabel>Durata sessioni</UppercaseLabel>
                                        </div>
                                        <div style={{ display: 'flex', gap: 8 }}>
                                            {protocol.sessionDuration?.toString()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {resultBodyParts && resultBodyParts.length > 0 && (
                    <div className="elevated-card full-width">
                        <div className="input-form-box">
                            <div className="input-form-box__three-col-row">
                                <div style={{ marginLeft: 6 }}>
                                    <div style={{ marginBottom: 6 }}>
                                        <UppercaseLabel>avanzamento temporale</UppercaseLabel>
                                    </div>
                                </div>
                                <div />
                                <div />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'flex-start', gap: 16, overflowX: 'auto' }}>
                                {resultBodyParts.map((resultBodyPart, i) => {
                                    return (
                                        <ResultMediaSection
                                            index={i}
                                            key={resultBodyPart.id}
                                            idResult={result.id}
                                            canDelete
                                            bodyPart={resultBodyPart}
                                            onRawMediaDelete={(rawMediaArray) => {
                                                const mediaToDeleteIds = rawMediaArray.map((rma) =>
                                                    rma.idBodyPart?.toString()
                                                )
                                                setResultMediaToDelete([...resultMediaToDelete, ...rawMediaArray])

                                                setResultBodyParts(
                                                    resultBodyParts.filter(
                                                        (bodyPart) => mediaToDeleteIds.indexOf(bodyPart.id) > -1
                                                    )
                                                )
                                            }}
                                        />
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default ResultInfoPage
