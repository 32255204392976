import React, { useEffect, useState } from 'react'
import { CommercialArea } from '../../../types/data'
import { getCommercialAreas } from '../../../resources/api-constants'
import { useAppSelector } from '../../../store/reducers/store'
import Paragraph from '../../../components/Typography/Paragraph'
import UppercaseLabel from '../../../components/Typography/UppercaseLabel'

const CommercialAreasPage: React.FC = () => {
    const user = useAppSelector((data) => data.user)
    const [items, setItems] = useState<CommercialArea[]>([])
    const fetchData = async () => {
        const data = await getCommercialAreas(user.loggedUserData?.authToken || '')
        setItems(data.items)
    }
    useEffect(() => {
        void fetchData()
    }, [])
    return (
        <>
            <div className="page-header-section">
                <div
                    className="page-header-section__left-box"
                    style={{ flexDirection: 'column', alignItems: 'flex-start' }}
                >
                    <span className="page-title">Aree commerciali</span>
                    <span className="page-subtitle">
                        Visualizzazione delle aree commerciali e relativa associazione nazione-valuta
                    </span>
                </div>
            </div>
            <div className="page-content-flow">
                <div className="elevated-card full-width">
                    {items.map(
                        (item) =>
                            item.countries.length > 0 && (
                                <div key={item.id}>
                                    <div style={{ display: 'flex', marginBottom: '8px', gap: '8px' }}>
                                        <UppercaseLabel>{item.name}</UppercaseLabel>
                                        <Paragraph>
                                            {item.countries[0].commercialArea.currency.name}(
                                            {item.countries[0].commercialArea.currency.value})
                                        </Paragraph>
                                    </div>
                                    <div style={{ marginTop: '16px' }}>
                                        {item.countries.map((country) => (
                                            <div key={country.id}>
                                                <Paragraph>{country.name}</Paragraph>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )
                    )}
                </div>
            </div>
        </>
    )
}

export default CommercialAreasPage
