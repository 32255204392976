/* eslint-disable indent */
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ROUTES } from '../../resources/routes-constants'
import { getElementIdFromCurrentRoute } from '../../utility/functions'
import { EntityStatus, FAQItem } from '../../types/data'
import StatusLabel from '../../components/Badges/StatusLabel'
import {
    createSingleFAQ,
    deleteSingleFAQ,
    getSingleFAQ,
    translate,
    updateSingleFAQ,
} from '../../resources/api-constants'
import { useAppSelector } from '../../store/reducers/store'
import { ToastError, ToastSuccess } from '../../utility/toast'
import Button from '../../components/Buttons/Button'
import UppercaseLabel from '../../components/Typography/UppercaseLabel'
import AlertModal from '../../components/Modal/AlertModal'
import TextareaInput from '../../components/Inputs/TextareaInput'

const defaultTranslations = { en: { question: '', answer: '' } }

const editableItem: FAQItem = {
    id: '-1',
    status: EntityStatus.ACTIVE,
    question: '',
    answer: '',
    creationDate: '',
    updateDate: '',
    localized: defaultTranslations,
}

export interface FAQItemProps {
    faq?: FAQItem
}

const FAQDetailPage: React.FC<FAQItemProps> = (props) => {
    const user = useAppSelector((data) => data.user)
    const data = useAppSelector((data) => data.data)

    const currentItemId = getElementIdFromCurrentRoute(window.location.pathname)
    const [faqItem, setFaqItem] = useState<FAQItem>(props.faq || editableItem)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const navigate = useNavigate()

    const fetchDetail = async () => {
        try {
            setIsLoading(true)
            const fetchedItem = await getSingleFAQ(user.loggedUserData?.authToken || '', currentItemId)
            if (fetchedItem) {
                setFaqItem(fetchedItem)
            }
        } catch (error) {
            console.error(error)
            ToastError('Si è verificato un errore durante il recupero dei dati!')
        }
        setIsLoading(false)
    }

    const translateData = async (sourceLanguage: string) => {
        const targetsLanguages = data.languages.map((i) => i.code)
        const defaultLocaleData = faqItem.localized[sourceLanguage]

        const response = await translate(
            user.loggedUserData?.authToken ?? '',
            defaultLocaleData,
            sourceLanguage,
            targetsLanguages,
            []
        )

        const local = JSON.parse(JSON.stringify(faqItem.localized))

        Object.keys(response).forEach((locale) => {
            local[locale] = response[locale]
        })

        setFaqItem({ ...faqItem, localized: local })
    }

    const startUpdateFAQProcedure = async () => {
        try {
            setIsLoading(true)
            const res = await updateSingleFAQ(user.loggedUserData?.authToken || '', {
                ...faqItem,
            })
            if (res) {
                ToastSuccess('FAQ aggiornata con successo')
                await fetchDetail()
            }
        } catch (error) {
            console.error(error)
            ToastError('Si è verificato un errore durante la procedura di aggiornamento!')
        }
        setIsLoading(false)
    }

    const startCreateFAQProcedure = async () => {
        try {
            setIsLoading(true)
            const res = await createSingleFAQ(user.loggedUserData?.authToken || '', faqItem)
            if (res) {
                ToastSuccess('FAQ creata con successo')
                navigate(ROUTES.FAQS_ROUTE)
            }
        } catch (error) {
            console.error(error)
            ToastError('Si è verificato un errore durante la procedura di creazione!')
        }
        setIsLoading(false)
    }
    const startValidateFAQProcedure = async () => {
        try {
            setIsLoading(true)
            const res = await updateSingleFAQ(user.loggedUserData?.authToken || '', {
                ...faqItem,
                status: EntityStatus.ACTIVE,
            })
            if (res) {
                ToastSuccess('FAQ validata con successo')
                navigate(ROUTES.FAQS_ROUTE)
            }
        } catch (error) {
            console.error(error)
            ToastError('Si è verificato un errore durante la procedura di validazione!')
        }
        setIsLoading(false)
    }

    const startDeleteProcedure = () => {
        setShowDeleteModal(true)
    }

    const endRemoveProcedure = () => {
        setShowDeleteModal(false)
        ToastSuccess('FAQ rimossa con successo')
        navigate(ROUTES.FAQS_ROUTE)
    }

    const removeItem = async () => {
        try {
            setIsLoading(true)
            await deleteSingleFAQ(user.loggedUserData?.authToken || '', currentItemId)
            endRemoveProcedure()
        } catch {
            ToastError('Si è verificato un errore durante la procedura di eliminazione!')
        }
        setIsLoading(false)
    }

    const dataIsNotValid = useMemo((): boolean => {
        return !faqItem.localized || Object.keys(faqItem.localized).length < 2
    }, [faqItem.localized])

    useEffect(() => {
        if (currentItemId !== '-1') {
            void fetchDetail()
        }
    }, [currentItemId])

    return (
        <>
            {showDeleteModal && (
                <AlertModal
                    modalTitle="Elimina FAQ"
                    modalMessage={'Sei sicuro di voler eliminare la FAQ? Questa operazione è irreversibile.'}
                    onClose={() => setShowDeleteModal(false)}
                    onConfirm={() => void removeItem()}
                />
            )}
            <div className="page-header-section">
                <div className="page-header-section__left-box">
                    <span className="page-title" style={{ display: 'flex', gap: 16 }}>
                        <Link to={ROUTES.FAQS_ROUTE}>FAQ</Link> /{' '}
                        {currentItemId === '-1' ? 'Nuova FAQ' : `FAQ-${currentItemId}` || ''}
                        {currentItemId !== '-1' && (
                            <div style={{ display: 'flex', gap: 8, fontSize: '.6em' }}>
                                <StatusLabel status={faqItem?.status ?? EntityStatus.PENDING} />
                            </div>
                        )}
                    </span>
                </div>
                <div className="page-header-section__right-box">
                    <Button disabled={isLoading} buttonType="secondary-error" onClick={() => startDeleteProcedure()}>
                        Elimina FAQ
                    </Button>

                    {faqItem.status === EntityStatus.PENDING && (
                        <Button
                            disabled={isLoading}
                            buttonType="primary"
                            onClick={() => void startValidateFAQProcedure()}
                        >
                            Approva FAQ
                        </Button>
                    )}
                    <Button
                        disabled={isLoading || dataIsNotValid}
                        buttonType="secondary"
                        onClick={() => {
                            currentItemId !== '-1' ? void startUpdateFAQProcedure() : void startCreateFAQProcedure()
                        }}
                    >
                        {currentItemId !== '-1' ? 'Modifica FAQ' : 'Crea FAQ'}
                    </Button>
                </div>
            </div>
            <div className="page-content-flow">
                <div className="elevated-card full-width">
                    <div style={{ display: 'flex', gap: '8px', justifyContent: 'center', alignItems: 'center' }}>
                        Creata da:
                        <a href={`mailto:${faqItem.user?.email}}`} target="_blank" rel="noreferrer">
                            {faqItem.user?.firstName} {faqItem.user?.lastName}
                        </a>
                    </div>
                </div>
                <UppercaseLabel>Localizzazione</UppercaseLabel>
                {data.languages.map((language) => (
                    <div className="elevated-card full-width" key={language.id}>
                        <div className="input-form-box" style={{ gap: '32px' }}>
                            <div className="status-label active-status-label">{language.label} </div>
                            {faqItem.localized[language.code] &&
                            faqItem?.localized[language.code].question &&
                            faqItem.localized[language.code] &&
                            faqItem?.localized[language.code].answer ? (
                                <Button
                                    style={{ width: 'auto', height: 12 }}
                                    disabled={isLoading}
                                    buttonType="primary"
                                    onClick={() => void translateData(language.code)}
                                >
                                    Traduci automaticamente altre lingue
                                </Button>
                            ) : null}

                            <div className="input-form-box__three-col-row">
                                <TextareaInput
                                    inputLabel={'Domanda'}
                                    placeholder="Domanda"
                                    value={
                                        (faqItem.localized[language.code] &&
                                            faqItem?.localized[language.code].question) ||
                                        ''
                                    }
                                    onValueChange={(newVal) => {
                                        const local = JSON.parse(JSON.stringify(faqItem.localized))
                                        if (local[language.code] === undefined) {
                                            local[language.code] = { question: newVal, answer: '' }
                                        } else {
                                            local[language.code].question = newVal
                                        }

                                        setFaqItem({ ...faqItem, localized: local })
                                    }}
                                />
                                <TextareaInput
                                    inputLabel={'Risposta'}
                                    placeholder="Risposta"
                                    value={
                                        (faqItem.localized[language.code] &&
                                            faqItem?.localized[language.code].answer) ||
                                        ''
                                    }
                                    onValueChange={(newVal) => {
                                        const local = JSON.parse(JSON.stringify(faqItem.localized))
                                        if (local[language.code] === undefined) {
                                            local[language.code] = { question: '', answer: newVal }
                                        } else {
                                            local[language.code].answer = newVal
                                        }
                                        setFaqItem({ ...faqItem, localized: local })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default FAQDetailPage
