import React, { useEffect, useRef, useState } from 'react'
import MainLogoContainer from '../../components/MainLogoContainer/MainLogoContainer'
import Paragraph from '../../components/Typography/Paragraph'
import TextInput from '../../components/Inputs/TextInput'
import { ROUTES } from '../../resources/routes-constants'
import Link from '../../components/Link/Link'
import Button from '../../components/Buttons/Button'
import ProjectVersionTag from '../../components/ProjectVersionTag/ProjectVersionTag'
import { requestPasswordReset } from '../../resources/api-constants'
import { ToastError, ToastSuccess } from '../../utility/toast'
import { useKeyDetect } from '../../utility/customHooks'

const RequestResetPassPage: React.FC = () => {
    const [email, setEmail] = useState('')
    const detectedKey = useKeyDetect()
    const formRef = useRef<any>(null)

    useEffect(() => {
        if (
            detectedKey &&
            detectedKey.key === 'Enter' &&
            formRef.current &&
            formRef.current.contains(document.activeElement)
        )
            // eslint-disable-next-line nonblock-statement-body-position
            void startRequestResetPasswordProcedure()
    }, [detectedKey, formRef.current])

    const startRequestResetPasswordProcedure = async () => {
        try {
            await requestPasswordReset(email.trim())
            ToastSuccess('Richiesta inviata con successo, controlla la tua casella email.')
        } catch (error) {
            console.error(error)
            ToastError('Errore durante l’invio della richiesta, riprova più tardi.')
        }
    }

    return (
        <div className="center-box full-height-page">
            <div ref={formRef} className="auth-form-container">
                <MainLogoContainer />
                <Paragraph>Inserisci l’indirizzo email del tuo account ICOONE per reimpostare la password.</Paragraph>
                <TextInput
                    value={email}
                    placeholder="Email"
                    type="email"
                    onValueChange={(userEmail) => setEmail(userEmail)}
                />
                <Button disabled={!email} onClick={async () => await startRequestResetPasswordProcedure()}>
                    Invia
                </Button>
                <Link externalRoute="#">Privacy Policy</Link>
                <Link internalRoute={ROUTES.LOGIN_ROUTE}>Accedi</Link>
            </div>
            <div className="auth-page-version-number">
                <ProjectVersionTag />
            </div>
        </div>
    )
}

export default RequestResetPassPage
